import React from "react";

import { Box, Typography } from "@mui/material";

const DialogOrderItems = ({ items }) => (
  <Box sx={{ paddingRight: "1rem", marginTop: "2rem" }}>
    <Typography variant="h5" fontWeight="bold">
      Itens do Pedido:
    </Typography>
    {items.map((item, index) => (
      <Box key={index} sx={{ margin: "0.5rem" }}>
        <Typography variant="body1">
          - <strong>{item.quantity}x {item.name} (R${item.totalPrice?.toFixed(2)})</strong>
        </Typography>
      </Box>
    ))}
  </Box>
);

export default DialogOrderItems;
