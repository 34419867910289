import {
  Avatar,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PREFIX = "IntegrationAvatar";

export const classes = {
  root: `${PREFIX}-root`,
  avatarEmpresa: `${PREFIX}-avatarEmpresa`,
};

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${classes.avatarEmpresa}`]: {
    color: "#fff",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));
