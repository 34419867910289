import { enqueueSnackbar } from "notistack";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDefaultPeriodFormated } from "../../../../../helpers/dateHelper";
import AuthService from "../../../../../service/AuthService";
import { createLoadingCards } from "../../../../../util/board/BoardUtil";
import { extractErrorMessage } from "../../../../../util/http/HttpUtil";
import {
  getKeyDespacho,
  setKeyDespacho,
} from "../../../../../util/indexedDB/IdbUtils";
import { postSituacaoMultiplosPedidosOnServer } from "../services/postSituacaoMultiplosPedidosOnServer";
import { convertToDespacho } from "./utils";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const service = new AuthService();
  const [qtdOrders, setQtdOrders] = useState(0);
  const [allOrders, setAllOrders] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);
  const [ordersDispatched, setOrdersDispatched] = useState([]);
  const [orderDeliveryOnly, setOrderDeliveryOnly] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [delay, setDelay] = useState(0);

  const dispatch = useDispatch();
  const apiFilter = {
    dataPedido: `${getDefaultPeriodFormated().startDate},${
      getDefaultPeriodFormated().endDate
    }`,
    importados: "undefined",
  };

  const [filtersType, setFiltersType] = useState(() => {
    const saved = localStorage.getItem("filtersType");
    return saved ? JSON.parse(saved) : ["DELIVERY"];
  });

  const [filtersBoolean, setFiltersBoolean] = useState(() => {
    const saved = localStorage.getItem("filtersBoolean");
    return saved
      ? JSON.parse(saved)
      : {
          pedidosPagos: false,
          pedidosPagosOnline: false,
        };
  });

  const [filterSituation, setFilterSituation] = useState(() => {
    const saved = localStorage.getItem("filterSituation");
    return saved ? JSON.parse(saved) : ["Todos"];
  });

  useEffect(() => {
    localStorage.setItem("filtersType", JSON.stringify(filtersType));
  }, [filtersType]);

  useEffect(() => {
    localStorage.setItem("filtersBoolean", JSON.stringify(filtersBoolean));
  }, [filtersBoolean]);

  useEffect(() => {
    localStorage.setItem("filterSituation", JSON.stringify(filterSituation));
  }, [filterSituation]);

  useEffect(() => {
    const hasNonDeliveryOrder = dataOrders.some(
      (order) => order.tipo !== "DELIVERY"
    );

    const invalidOrders = dataOrders
      .filter((order) => {
        const address = order.iFoodOrder?.deliveryAddress;
        return !(address?.city && address?.streetName && address?.streetNumber);
      })
      .map((order) => order.id);

    setInvalidAddress(invalidOrders);

    let updatedAllOrders = [...dataOrders];

    let updatedOrders = [...updatedAllOrders];

    if (selectedOrders.length) {
      const selectedOrderIds = new Set(selectedOrders.map((order) => order.id));

      const validSelectedOrders = updatedAllOrders.filter((order) =>
        selectedOrderIds.has(order.id)
      );
      setSelectedOrders(validSelectedOrders);

      updatedOrders = updatedOrders.filter(
        (order) => !selectedOrderIds.has(order.id)
      );
    }
    if (ordersDispatched.length) {
      const dispatchedOrderIds = new Set(
        ordersDispatched.flatMap((dispatch) =>
          dispatch.orders.map((order) => order.id)
        )
      );

      const filteredAllOrders = updatedAllOrders.filter(
        (order) => !dispatchedOrderIds.has(order.id)
      );
      const filteredOrders = updatedOrders.filter(
        (order) => !dispatchedOrderIds.has(order.id)
      );

      const existingOrderIds = new Set(dataOrders.map((order) => order.id));
      const remainingDispatchedOrders = ordersDispatched
        .map((dispatch) => ({
          ...dispatch,
          orders: dispatch.orders.filter((order) =>
            existingOrderIds.has(order.id)
          ),
        }))
        .filter((dispatch) => dispatch.orders.length > 0);
      console.log("remainingDispatchedOrders", remainingDispatchedOrders);
      console.log("existingOrderIds", existingOrderIds);
      setOrdersDispatched(remainingDispatchedOrders);

      updatedAllOrders = filteredAllOrders;
      updatedOrders = filteredOrders;
    }

    setAllOrders(updatedAllOrders);
    setQtdOrders(updatedAllOrders.length);
    setOrders(updatedOrders);
    setOrderDeliveryOnly(hasNonDeliveryOrder);
    //eslint-disable-next-line
  }, [dataOrders]);

  useEffect(() => {
    const reloadCardsFromApi = () => {
      dispatch({ type: "LOAD_BOARD_FROM_DATA", board: createLoadingCards() });

      let queryString = "";
      let filterArray = Object.keys(apiFilter);

      filterArray.map((filterKey) => {
        let value = apiFilter[filterKey]?.value;

        if (!value) {
          value = apiFilter[filterKey];
        }

        if (value !== "undefined") {
          queryString = queryString.concat(filterKey + "=" + value + "&");
        }
        return null;
      });

      service
        .get(
          `/api-v2/pedidoIntegracao/list?ativo=true&tipo=DELIVERY,BALCAO&${queryString}`
        )
        .then((pedidos) => {
          dispatch({ type: "LOAD_BOARD_FROM_ORDERS", orders: pedidos });
        })
        .catch((err) => {
          enqueueSnackbar("Erro ao buscar pedidos", { variant: "error" });
          dispatch({ type: "LOAD_BOARD_FROM_ORDERS", orders: [] });
        });
    };
    reloadCardsFromApi();
    //eslint-disable-next-line
  }, []);

  const toggleOrder = (order) => {
    const isSelected = selectedOrders.some((p) => p.id === order.id);
    const isDespachado =
      order.situacao === "AGUARDANDO_RETIRADA" && order.agrupadorDespacho;
    const isInvalidStatus =
      order.situacao !== "AGUARDANDO_RETIRADA" &&
      order.situacao !== "CONFIRMADO";

    if (isSelected) {
      setOrders((prev) => [...prev, order]);
      setSelectedOrders((prev) => prev.filter((p) => p.id !== order.id));
    } else if (isDespachado || isInvalidStatus) {
      enqueueSnackbar(
        "Não é possível criar uma nova rota para um pedido que já foi despachado.",
        { variant: "error" }
      );
    } else {
      setOrders((prev) => prev.filter((p) => p.id !== order.id));
      setSelectedOrders((prev) => [...prev, order]);
    }
  };

  async function storeStep({ passo, dados }) {
    try {
      const arr = (await getKeyDespacho("dados")) || [];

      await setKeyDespacho("dados", [
        ...arr,
        { createdAt: new Date(), passo, dados },
      ]);
    } catch (err) {
      console.error("Houve um erro ao gravar o log", err);
    }
  }

  const dispatchOrders = () => {
    const dispatchedData = {
      deliveryPerson: null,
      orders: selectedOrders.map((order) => order),
      id: selectedOrders[0].id,
    };
    setAllOrders((prev) =>
      prev.filter((p) => !selectedOrders.some((s) => s.id === p.id))
    );
    setOrdersDispatched((prev) => [...prev, dispatchedData]);
    setSelectedOrders([]);
  };

  const handleEditDispatch = (index) => {
    if (index !== -1) {
      const updatedOrders = [...ordersDispatched];
      const dispatchOld = updatedOrders.splice(index, 1);
      setOrdersDispatched(updatedOrders);
      setAllOrders((prev) => [...prev, ...dispatchOld[0].orders]);
      setSelectedOrders((prev) => [...prev, ...dispatchOld[0].orders]);
    }
  };

  const handleDispatchDeliveryMan = (deliveryMan, id) => {
    const index = ordersDispatched.findIndex((item) => item.id === id);

    if (index !== -1) {
      const updatedOrders = [...ordersDispatched];
      updatedOrders[index] = {
        ...updatedOrders[index],
        deliveryPerson: deliveryMan,
      };
      setOrdersDispatched(updatedOrders);
    }
  };

  const handleDispatchCancel = (id) => {
    const index = ordersDispatched.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedOrders = [...ordersDispatched];
      const dispatchOld = updatedOrders.splice(index, 1);
      setOrdersDispatched(updatedOrders);
      setOrders((prev) => [...prev, ...dispatchOld[0].orders]);
      setAllOrders((prev) => [...prev, ...dispatchOld[0].orders]);
    }
  };

  const handleDispatchConfirm = (id, situacao) => {
    const index = ordersDispatched.findIndex((item) => item.id === id);
    if (index !== -1) {
      const updatedOrders = [...ordersDispatched];
      updatedOrders.splice(index, 1);
      setOrdersDispatched(updatedOrders);
      const selectedDeliveryManId =
        ordersDispatched[index].deliveryPerson?.id || "";
      const ids = ordersDispatched[index].orders.map((item) => item.id);
      processOrderStatusChange(ids, situacao, selectedDeliveryManId || "")
        .then(() => {
          storeStep({
            passo: "DESPACHO FINALIZADO",
            dados: { ids, selectedDeliveryManId },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleDispatchSend = (
    item,
    situacao,
    selectedDeliveryManId,
    idDespacho
  ) => {
    const ids = item.pedidos.map((item) => item.id);
    processOrderStatusChange(
      ids,
      situacao,
      selectedDeliveryManId || "",
      idDespacho || ""
    )
      .then(() => {
        storeStep({
          passo: "DESPACHO FINALIZADO",
          dados: { ids, selectedDeliveryManId },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const processOrderStatusChange = (
    ids,
    situacao,
    selectedDeliveryManId,
    idDespacho
  ) => {
    return new Promise((resolve, reject) => {
      let pedidoErro = [];
      let errorList = [];

      postSituacaoMultiplosPedidosOnServer(
        ids,
        situacao,
        selectedDeliveryManId,
        idDespacho
      )
        .then((response) => {
          dispatch({
            type: "SEND_MESSAGE_SOCKET",
            message: {
              type: "ADD_DISPATCH",
              order: convertToDespacho(response),
            },
          });
          response.forEach((item) => {
            if (item.err) {
              const errOrder = selectedOrders.find(
                (order) => order.id === Number(item.id)
              );
              pedidoErro.push(errOrder);
              errorList.push(item);
              return;
            }
            setRefetch(true);
            dispatch({
              type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER",
              newOrder: item,
            });
            dispatch({
              type: "SEND_MESSAGE_SOCKET",
              message: {
                type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER",
                newOrder: item,
              },
            });
          });

          if (errorList.length < 1) {
            enqueueSnackbar(
              "Situações dos pedidos selecionados alteradas com sucesso.",
              { variant: "success", autoHideDuration: 10000 }
            );
            resolve();
          } else {
            enqueueSnackbar(
              "Um ou mais pedidos não foram movidos. Favor verificar.",
              { variant: "error" }
            );
            storeStep({ passo: "ERRO AO DESPACHAR PEDIDOS", dados: errorList });
            reject(errorList);
          }
        })
        .catch((err) => {
          console.error(err);
          extractErrorMessage(
            err,
            "Falha ao alterar as situações dos pedidos selecionados"
          ).then((msg) => enqueueSnackbar(msg, { variant: "error" }));
          reject(err);
        });
    });
  };

  const handleClickSelectAllOrders = (select) => {
    if (!select) {
      setSelectedOrders([]);
      setOrders(allOrders);
    } else {
      if (
        allOrders.some(
          (order) =>
            (order.situacao === "AGUARDANDO_RETIRADA" &&
              order.agrupadorDespacho) ||
            (order.situacao !== "AGUARDANDO_RETIRADA" &&
              order.situacao !== "CONFIRMADO")
        )
      ) {
        const msg =
          "Não é possível criar uma nova rota para um pedido que já foi despachado.";
        enqueueSnackbar(msg, { variant: "error" });
        return;
      }

      setSelectedOrders(allOrders);
      setOrders([]);
    }
  };

  return (
    <OrderContext.Provider
      value={{
        orders,
        selectedOrders,
        ordersDispatched,
        filtersType,
        filterSituation,
        filtersBoolean,
        refetch,
        allOrders,
        qtdOrders,
        dataOrders,
        orderDeliveryOnly,
        invalidAddress,
        isFullScreen,
        delay,
        setDelay,
        setIsFullScreen,
        setFiltersType,
        setDataOrders,
        setQtdOrders,
        toggleOrder,
        dispatchOrders,
        setRefetch,
        setFiltersBoolean,
        handleDispatchConfirm,
        handleDispatchCancel,
        handleDispatchSend,
        handleDispatchDeliveryMan,
        setFilterSituation,
        handleEditDispatch,
        handleClickSelectAllOrders,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  return useContext(OrderContext);
};