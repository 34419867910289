

import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  bottom: "6.5rem",
  left: "50%",
  borderRadius: "4rem",
  transform: "translateX(-50%)",
  width: "40vw",
  maxWidth: "16rem",
  height: "4rem",
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "4rem",
  color: "white",
  width: "4rem",
  height: "4rem",
  marginTop: "-13rem",
  background: theme.palette.primary.main,
}));

export const StyledBoxButton = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "0",
  left: "50%",
  transform: "translateX(-50%)",
  width: "95vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  border: "1px red solid",
  maxWidth: "39rem",
  height: "4rem",
}));
