import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import { lime } from '@mui/material/colors';
import { PAGE_STYLE_PRINT } from '../../../../util/printUtils';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ListItemsGrouped from '..';

const PREFIX = 'DialogListItemsGrouped'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    subitem: `${PREFIX}-subitem`,
    paddingLeft: `${PREFIX}-paddingLeft`,
    deliveryFree: `${PREFIX}-deliveryFree`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.subitem}`]: {
        opacity: "0.8",
        fontSize: "0.9em"
    },

    [`& .${classes.paddingLeft}`]: {
        paddingLeft: '4px'
    },

    [`& .${classes.deliveryFree}`]: {
        '& label, & input': {
            color: theme.palette.mode === 'dark' ? lime[700] : lime[700],
        },
        '& input': {
            textAlign: "right"
        },
    }
}))

export default function DialogListItemsGrouped() {

    const dispatch = useDispatch()
    const open = useSelector(state => state.listItemsGrouped.open)
    const groupedItems = useSelector(state => state.listItemsGrouped.groupedItems)
    const tableRef = useRef()

    function handleCancel() {
        handleClose()
    }

    function handleClose() {

        dispatch({ type: 'LIST_ITEM_GROUPED_DIALOG', listItemsGrouped: { open: false, order: null } })
    }

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        pageStyle: () => PAGE_STYLE_PRINT
    });

    function onClickResume(e) {
        e.stopPropagation();
        //console.log("THEME", JSON.stringify(createThemeApp('light')))

        handlePrint()
    }

    return (
        <StyledDialog
            open={open}
            maxWidth='sm'
            fullWidth
            onClose={handleCancel}>

            <DialogTitle onClose={handleCancel}>
                <Typography variant="caption" display="block" gutterBottom>
                    Resumo dos itens
                </Typography>
            </DialogTitle>

            <DialogContent ref={tableRef}>

                <ListItemsGrouped groupedItems={groupedItems} />

            </DialogContent>

            <DialogActions>
                <Button
                    startIcon={<PrintIcon />}
                    size='small'
                    onClick={onClickResume}>
                    Imprimir
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}