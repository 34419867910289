import { AccordionSummary, Box, styled, Typography } from "@mui/material";
import React from "react";
import { formatFullAddressDelivery } from "../../../../../../../util/customer/helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RoomIcon from "@mui/icons-material/Room";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from "@mui/icons-material/Payment";

const StyledIconCard = styled(PaymentIcon)(({ theme }) => ({
    color: theme.palette.success.main,
  }));

const AccordionSummaryOrder = ({order, handleClickMap}) => {
    return (<AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ paddingRight: "1rem", width: "100%" }}>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <Box width="100%">
              <Typography variant="h4" fontWeight="bold">
                Pedido {order.id}
              </Typography>
            </Box>
            <Box
              onClick={(event) => {
                event.stopPropagation();
                handleClickMap(order);
              }}
              display="flex"
              mr="-1rem"
              alignItems={"center"}
              justifyContent={"end"}
            >
              <RoomIcon sx={{ color: "#4FA0F6" }} fontSize="small" />
              <Box width="5rem">
                <Typography color="#4FA0F6" variant="caption">
                  Abrir no mapa
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt="1rem">
            <Box display="flex" alignItems="center" gap={2}>
              <PersonIcon fontSize="small" />
              <Typography variant="h6">
                {order.pessoaCliente.nome}
              </Typography>
            </Box>
            <Box
              mt="0.5rem"
              display="flex"
              alignItems="center"
              gap={2}
            >
              <LocationOnIcon fontSize="small" />
              <Typography variant="h6">
                {
                  formatFullAddressDelivery(
                    order.iFoodOrder.deliveryAddress
                  ).address
                }
              </Typography>
            </Box>
            {!!order.pessoaCliente.fone && (
              <Box mt="0.5rem" display="flex" gap={2}>
                <SmartphoneIcon fontSize="small" />
                <Typography variant="h6">
                  {order.pessoaCliente.fone}
                </Typography>
              </Box>
            )}
            {!!order?.iFoodOrder?.levarMaquinaCartao && (
              <Box mt="0.5rem" display="flex" gap={1}>
                <StyledIconCard />
                <Box ml="0.2rem">
                  <Typography variant="h6">
                    Pagamento com cartão
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>)   
}

export default AccordionSummaryOrder;