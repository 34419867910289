import React, { useEffect, useRef, useState } from 'react'
import { getPesoBalanca, isModoPesoFakeAtivo } from '../../helpers/balancaHelper';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Box, Button, DialogActions, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { criaPedidoAutoatendimento, criarItemPedidoAutoatendimento, DIFERENCA_VARIACAO_PESO, gerarIdentificador, INTERVALO_CAPTURA_PESO_MS, isNovaPesagem } from './autoatendimentoHelper';
import useInterval from 'use-interval';
import AuthService from '../../service/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { extractErrorMessage } from '../../util/http/HttpUtil';
import SocketContext from '../../util/socket';
import { getParamByKey } from '../../component/parameter';
import { getOrdersByTipoAndIdentificador } from '../../util/order/orderUtil';
import { currencyFormat } from '../../util/formatter';
import { roundUp, useMultipleHotkeysCustom } from '../../util/genericUtil';
import { DialogContent, DialogTitle } from '../layout-components/CustomDialog/dialogComponents';
import { InputQuantity } from '../layout-components/NewOrder/ResumeOrder/InputQuantity';
import styled from '@emotion/styled'
import { usePdvContext } from '../../component/generics/PdvProvider';

const ProductWeightBox = styled(Box)(({ theme }) => ({
    '&': {
        position: 'absolute',
        bottom: '100%',
        margin: '10px 0',
        padding: '20px 30px',
        borderRadius: '10px',
        backgroundColor: theme.palette.personal.customArea,
    },
}))

const ProductsToWeighList = styled(Box)(({ theme }) => ({
    '& .MuiButtonBase-root:not(.MuiButton-containedPrimary)': {
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogQuantity({ open, value, onChange, onConfirm, onDismiss }) {

    // caso o valor for vazio ou 0 ele não deixa confirmar
    // mesmo processo feito pelo PDV OFF
    function handleConfirm() {
        if (value) {
            onConfirm(value)
        }
    }

    function handleKeyUp(event) {
        if (event.key === 'Enter') {
            handleConfirm()
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth={"xs"}
            onClose={onDismiss}>
            <DialogTitle onClose={onDismiss}>
                Quantidade
            </DialogTitle>

            <DialogContent>
                <Box display="flex" justifyContent="center">
                    <InputQuantity
                        autoFocus
                        value={value}
                        onChange={onChange}
                        onKeyUp={handleKeyUp} />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleConfirm}>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default function AutoatendimentoBalanca({ open, handleClose, telaAutoatendimento, setTelaAutoatendimento, configuracoes }) {

    const { enqueueSnackbar } = useSnackbar();
    const { confirmDialog } = usePdvContext()
    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const [valorTotalPedido, setValorTotalPedido] = React.useState(0.0);
    const [produtoSelecionado, setProdutoSelecionado] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [openQuantity, setOpenQuantity] = useState(false)
    const [livre, setLivre] = useState()
    const openMenu = Boolean(anchorElMenu);
    const buscandoPesoRef = useRef();
    const pausarBuscaRef = useRef();
    const pausarSalvandoComandaRef = useRef();
    const identificadorRef = useRef();
    const tentativaErroPesagemRef = useRef(0);
    const service = new AuthService()
    const dispatch = useDispatch()
    const generalParameters = useSelector(state => state.generalParameters)
    const TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA = getParamByKey(generalParameters, 'TIPO_SEQUENCIAL_IDENTIFICADOR_AUTOATENDIMENTO_BALANCA')?.valorString;
    const produtosSelecionados = Object.keys(configuracoes.produtosKGSelecionados).filter(key => configuracoes.produtosKGSelecionados[key])

    useEffect(() => {
        if (open) {
            setValorTotalPedido(null)
        }
    }, [open])

    useEffect(() => {
        if (produtoSelecionado && telaAutoatendimento?.retornoPesoAtual?.peso) {
            let peso = telaAutoatendimento.retornoPesoAtual.peso

            if (produtoSelecionado.unidadeMedida === 'UN') {
                peso = 0
            }

            updatePrice(peso, produtoSelecionado)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtoSelecionado])

    useInterval(() => {
        if (open) {
            if (buscandoPesoRef.current || pausarBuscaRef.current) {
                console.log("[TELA AUTOATENDIMENTO] TIMER >> Busca de peso na tela adiada. Busca anterior ainda não finalizou. ==/==")
            } else {
                console.log("[TELA AUTOATENDIMENTO] TIMER >> Iniciado leitura de peso na tela == ")
                lerPeso();
            }
        }
    }, INTERVALO_CAPTURA_PESO_MS);

    const handleClickCMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const lerPeso = () => {

        console.log("[TELA AUTOATENDIMENTO] lerPeso -> Iniciado leitura de peso")
        buscandoPesoRef.current = true;

        const telaAutoatendimentoCopy = Object.assign({}, telaAutoatendimento)

        getPesoBalanca()
            .then(peso => {
                console.log('PESO', peso)
                console.log("[TELA AUTOATENDIMENTO] lerPeso -> PESO RETORNADO", peso)

                telaAutoatendimentoCopy.retornoPesoAnterior = telaAutoatendimentoCopy.retornoPesoAtual
                telaAutoatendimentoCopy.retornoPesoAtual = { peso, mensagem: "" };

                if (!pausarBuscaRef.current)
                    notificarNovaPesagem(telaAutoatendimentoCopy)

            }).catch(error => {
                console.error("[TELA AUTOATENDIMENTO] lerPeso -> ERRO AO LER PESO", error.code, error.message)

                telaAutoatendimentoCopy.retornoPesoAnterior = telaAutoatendimentoCopy.retornoPesoAtual
                telaAutoatendimentoCopy.retornoPesoAtual = { peso: 0.0, mensagem: error.message, errorCode: error.code }

                if (!pausarBuscaRef.current)
                    notificarNovaPesagem(telaAutoatendimentoCopy)

            }).finally(() => {
                buscandoPesoRef.current = false;
            });

    }

    function updatePrice(peso, produto) {
        const { produtoLivreAssociado } = produto
        const totalPrice = roundUp(peso * produto.valorUnitarioVenda)

        if (produtoLivreAssociado && produtoLivreAssociado.valorUnitarioVenda < totalPrice) {
            setLivre(produtoLivreAssociado)
            return
        }

        setValorTotalPedido(totalPrice)
    }

    const notificarNovaPesagem = (tela) => {

        // quando ele volta a pesar remove o produto livre
        setLivre(null)

        const pesoAtual = tela?.retornoPesoAtual?.peso;
        const pesoAnterior = tela?.retornoPesoAnterior?.peso;

        if (tela.status === 'AGUARDANDO_PESAGEM') {

            if (pesoAtual > DIFERENCA_VARIACAO_PESO) {
                const solicitarComanda = tela.solicitarComanda;

                if (solicitarComanda === "S") {
                    tela.status = 'INFORMAR_COMANDA'
                    //solicitar comanda
                    pausarBuscaRef.current = true;

                    const produto = produtoSelecionado ? produtoSelecionado : telaAutoatendimento.produtoKg
                    updatePrice(pesoAtual, produto)

                    setTimeout(() => {
                        //solicita foco no input
                        identificadorRef.current.focus()
                    }, 600)

                } else {

                    tela.status = 'PESO_CAPTURADO'
                    pausarBuscaRef.current = true;

                    let identificador = gerarIdentificador(TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA);
                    const produto = produtoSelecionado ? produtoSelecionado : telaAutoatendimento.produtoKg
                    const order = criaPedidoAutoatendimento(identificador, produto, pesoAtual)

                    // setProdutoSelecionado(null)
                    updatePrice(pesoAtual, produto)

                    if (isModoPesoFakeAtivo()) {

                        setTimeout(() => {
                            setTelaAutoatendimento({ ...tela, status: "RETIRAR_PRATO" })
                            enqueueSnackbar('Pedido não gerado. Modo emulador');
                        }, 1000)

                    } else {
                        imprimePedido(configuracoes.localDeImpressao, order, true, tela)
                    }
                }
            }
        } else if (tela.status === 'PESO_CAPTURADO') {

            tela.status = 'RETIRAR_PRATO';

        } else if (tela.status === 'RETIRAR_PRATO') {

            if (isNovaPesagem(pesoAtual, pesoAnterior, tentativaErroPesagemRef.current)) {
                console.log("[RETIRAR_PRATO] CLIENTE RETIROU O PRATO")
                tela.status = 'AGUARDANDO_PESAGEM'
                setValorTotalPedido(0.0)
                setProdutoSelecionado(null)
            } else {
                console.log("[RETIRAR_PRATO] CLIENTE AINDA NAO RETIROU O PRATO")
            }

            if (tela.retornoPesoAtual.errorCode || tela.retornoPesoAtual.peso < DIFERENCA_VARIACAO_PESO) {
                tentativaErroPesagemRef.current++;
                console.log("[RETIRAR_PRATO] Tentativa de leitura com erro ou zero número: " + tentativaErroPesagemRef.current)
            } else {
                tentativaErroPesagemRef.current = 0;
            }
        }

        setTelaAutoatendimento(tela)
    }

    const handleCloseMenuETela = () => {
        handleClose();
        handleCloseMenu();
    };

    // método padrão para realizar impressões
    async function imprimePedido(localDeImpressao, order, isKg = true, tela = telaAutoatendimento) {
        try {
            let url = `/api-v2/pedidoIntegracao?impressao=BALANCA&localNotificacao=${localDeImpressao}`

            // caso não seja para imprimir ele recebe essa outra url e 
            // lá no backend existe uma validação que ignora caso os parâmetros não estejam presentes
            if (configuracoes.imprimirComanda !== 'S' && telaAutoatendimento.solicitarComanda === 'S') {
                url = `/api-v2/pedidoIntegracao`
            }

            const respOrder = await service.post(url, order)

            dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: respOrder } })

            if (identificadorRef.current) {
                //ao finalizar, limpar o input
                identificadorRef.current.value = "";
            }

            if (isKg) {
                setTelaAutoatendimento({ ...tela, status: "RETIRAR_PRATO" });
            } else {
                setTelaAutoatendimento({ ...tela, status: "AGUARDANDO_PESAGEM" });
            }
        } catch (err) {
            extractErrorMessage(err, "Erro ao salvar o pedido")
                .then(msg => {
                    enqueueSnackbar('Não foi possível salvar o pedido - Detalhes: ' + msg, { variant: "error" });
                })

            if (identificadorRef.current) {
                //SELECIONA O CONTEUDO DO INPUT
                identificadorRef.current.select();
            }
        } finally {
            // RETOMA BUSCA DE PESO
            pausarBuscaRef.current = false;
            pausarSalvandoComandaRef.current = false
        }
    }

    function handleSelectProduto(produto) {
        // se o produto já estiver selecionado, remove-o
        if (produtoSelecionado?.idProduto === produto.idProduto) {
            handleCloseQuantityDialog()
            return
        }

        setProdutoSelecionado(produto)

        if (produto.unidadeMedida === 'UN') {
            if (telaAutoatendimento.solicitarComanda === 'S') {
                pausarBuscaRef.current = true
                setTimeout(() => identificadorRef.current.focus(), 600)

                setTelaAutoatendimento({ ...telaAutoatendimento, status: 'INFORMAR_COMANDA' })
                return
            }

            setOpenQuantity(true)
        }
    }

    // gerencia os atalhos de acordo com os cadastrados anteriormente podendo ir do F1 ao F8
    // essa função cadastra apenas 1 evento e nesse evento ele gerencia qual produto pegar 
    // dependendo de qual tecla foi apertada
    useMultipleHotkeysCustom(produtosSelecionados, (key) => {
        const produto = configuracoes.produtosKGSelecionados[key]

        handleSelectProduto(produto)
    })

    async function handleQuantity(newQuantity) {
        setQuantity(newQuantity)
        setOpenQuantity(false)

        let identificador
        if (telaAutoatendimento.solicitarComanda === 'S') {
            identificador = identificadorRef.current.value
        } else {
            identificador = gerarIdentificador(TIPO_SEQ_IDENTIFICADOR_AUTO_BALANCA)
        }

        const localDeImpressao = configuracoes.localDeImpressao
        let order = criaPedidoAutoatendimento(identificador, produtoSelecionado, newQuantity)

        const existingOrder = await getOrdersByTipoAndIdentificador('COMANDA', identificador)
        if (existingOrder && existingOrder.length) {
            const item = criarItemPedidoAutoatendimento(produtoSelecionado, newQuantity)

            order = existingOrder[0]
            order.iFoodOrder.items.push(item)
        }

        console.log("[AUTOATENDIMENTO] ORDEM CRIADO AO INFORMAR COMANDA", order)

        if (isModoPesoFakeAtivo()) {
            setTimeout(() => {
                setTelaAutoatendimento({ ...telaAutoatendimento, status: 'RETIRAR_PRATO' })
                enqueueSnackbar('Pedido não gerado. Modo emulador')
            }, 1000)
        } else {
            await imprimePedido(localDeImpressao, order, false)
        }

        setQuantity(1)
        setProdutoSelecionado(null)

        enqueueSnackbar('Item adicionado a comanda ' + identificador, { variant: 'info' })
    }

    function handleCloseQuantityDialog() {
        pausarBuscaRef.current = false
        setQuantity(1)
        setOpenQuantity(false)
        setProdutoSelecionado(null)
        setTelaAutoatendimento({ ...telaAutoatendimento, status: 'AGUARDANDO_PESAGEM' })
    }

    function handleCancelarPesagem() {
        pausarBuscaRef.current = false
        setValorTotalPedido(0.0)
        setProdutoSelecionado(null)
        setTelaAutoatendimento({ ...telaAutoatendimento, status: 'AGUARDANDO_PESAGEM' })
    }

    function onKeyupIdentificador(event) {
        if (event.keyCode === 13 && !pausarSalvandoComandaRef.current) {
            identificadorRef.current.select()
            handleConfirmarPesagem()
        } else if (event.keyCode === 27) {
            handleCancelarPesagem()
        }
    }

    async function isValidIdentificador(identificador) {
        try {
            const response = await service.get(`/api-v2/identificador/tipo/COMANDA`);
            const unAvailableOrders = response || []
            const order = unAvailableOrders.find((order) => order.identificador === identificador);

            return order;

        } catch (err) {
            console.error(err);
            const msg = await extractErrorMessage(err, "Não foi possível buscar pedido pelo identificador principal.");
            enqueueSnackbar(msg, { variant: "error" });

            return null;
        }

    }

    async function handleConfirmarPesagem() {
        pausarBuscaRef.current = true
        pausarSalvandoComandaRef.current = true

        if (produtoSelecionado && produtoSelecionado.unidadeMedida === 'UN') {
            setOpenQuantity(true)
            return
        }

        const telaAutoatendimentoCopy = Object.assign({}, telaAutoatendimento)

        if (telaAutoatendimentoCopy.status === 'INFORMAR_COMANDA') {
            const identificador = identificadorRef.current?.value;
            const orders = await isValidIdentificador(identificador);
            const hasOrderAlready = orders && orders.identificador === identificadorRef.current?.value;

            if (!hasOrderAlready) {
                enqueueSnackbar("Comanda não localizada. Verifique o identificador e tente novamente ou entre em contato com o suporte.", { variant: "error" });
                return;
            }

            //pega o identificador e gera o pedido
            let inputValue = identificadorRef.current.value;

            const produto = produtoSelecionado ? produtoSelecionado : telaAutoatendimento.produtoKg
            let order = criaPedidoAutoatendimento(inputValue, produto, telaAutoatendimento.retornoPesoAtual.peso)

            const existingOrder = await getOrdersByTipoAndIdentificador('COMANDA', inputValue)
            if (existingOrder && existingOrder.length) {
                const item = criarItemPedidoAutoatendimento(produto, telaAutoatendimento.retornoPesoAtual.peso)

                order = existingOrder[0]
                order.iFoodOrder.items.push(item)
            }

            // setProdutoSelecionado(null)
            updatePrice(telaAutoatendimento.retornoPesoAtual.peso, produto)

            const localDeImpressao = configuracoes.localDeImpressao

            console.log("[AUTOATENDIMENTO] ORDEM CRIADO AO INFORMAR COMANDA", order)

            imprimePedido(localDeImpressao, order)
        }
    }

    function onClose() {
        confirmDialog({ content: 'Deseja realmente sair do Autoatendimento?' }).then(() => handleClose())
    }

    function weightDisplay() {
        if (livre) {
            return 'LIVRE'
        }

        if (produtoSelecionado && produtoSelecionado.unidadeMedida === 'UN') {
            return 0 + ' Kg'
        }

        return telaAutoatendimento.retornoPesoAtual?.peso + ' Kg'
    }

    function priceDisplay() {
        if (livre) {
            return currencyFormat(livre.valorUnitarioVenda)
        }

        return valorTotalPedido > 0 ? currencyFormat(valorTotalPedido) : ' -- '
    }

    return (
        <div>
            <Dialog
                fullScreen
                disableEscapeKeyDown={telaAutoatendimento.status === 'INFORMAR_COMANDA'}
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}>

                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        left: 8,
                        top: 8,
                    }}
                    id="balanca-button"
                    aria-controls={openMenu ? 'balanca-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClickCMenu}
                >
                    <SettingsIcon />
                </IconButton>


                <Menu
                    id="balanca-menu"
                    anchorEl={anchorElMenu}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'balanca-button',
                    }}
                >
                    <MenuItem disabled>Modo de atendimento: {telaAutoatendimento.solicitarComanda === "S" ? 'Solicitar comanda' : 'Automático'}</MenuItem>
                    <MenuItem onClick={handleCloseMenuETela}>Fechar</MenuItem>
                </Menu>

                <DialogContent style={{ padding: "0" }}>

                    <ImagemTelaAutoatendimento status={telaAutoatendimento.status} />

                </DialogContent>

                <DialogActions sx={{ flexDirection: 'column', alignItems: 'center', position: 'relative' }} >
                    <ProductWeightBox>
                        <Box sx={{ p: 1, }}>
                            <Box sx={{ color: 'text.secondary' }}>Produto padrão</Box>
                            {produtoSelecionado ? (
                                <Box sx={{ color: 'text.primary', fontSize: 24, fontWeight: 'medium' }}>
                                    {produtoSelecionado?.descricao} - {currencyFormat(produtoSelecionado?.valorUnitarioVenda)}
                                </Box>
                            ) : (
                                    <Box sx={{ color: 'text.primary', fontSize: 24, fontWeight: 'medium' }}>
                                        {telaAutoatendimento.produtoKg?.descricao} - {currencyFormat(telaAutoatendimento.produtoKg?.valorUnitarioVenda)}
                                    </Box>
                            )}
                        </Box>

                        <Box display="flex" flexDirection="row">
                            <Box sx={{ p: 1, }}>
                                <Box sx={{ color: 'text.secondary' }}>Peso</Box>
                                <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                                    {weightDisplay()}
                                </Box>
                            </Box>

                            <Box sx={{ p: 1, }}>
                                <Box sx={{ color: 'text.secondary' }}>Valor</Box>
                                <Box sx={{ color: 'success.main', fontSize: 34, fontWeight: 'medium' }}>
                                    {priceDisplay()}
                                </Box>
                            </Box>

                            {telaAutoatendimento.solicitarComanda === "S" && (
                                <Box sx={{
                                    p: 1,
                                    minWidth: 300,
                                    flexDirection: 'column',
                                    display: (telaAutoatendimento.status === 'INFORMAR_COMANDA' ? 'flex' : 'none'),
                                }}>
                                    <TextField variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="number"
                                        label="Informe o Cartão / Comanda"
                                        sx={{ pb: 1 }}
                                        inputRef={identificadorRef}
                                        onKeyUp={onKeyupIdentificador} />
                                    <Box>
                                        <Button
                                            onClick={handleCancelarPesagem}
                                            variant="text">
                                            Cancelar (Esc)
                                        </Button>
                                        <Button
                                            onClick={handleConfirmarPesagem}
                                            variant="contained">
                                            Confirmar (Enter)
                                        </Button>
                                    </Box>
                                </Box>
                            )}

                            {isModoPesoFakeAtivo() && (
                                <Box sx={{ color: 'warning.main', fontSize: 24, fontWeight: 'medium' }}>
                                    MODO SIMULADOR DE PESO ATIVADO
                                </Box>
                            )}
                        </Box>
                        {livre && (
                            <Box pl={1}>
                                PRODUTO LIVRE: {livre.descricao}
                            </Box>
                        )}
                    </ProductWeightBox>

                    {produtosSelecionados.length ? (
                        <ProductsToWeighList display="grid" gridTemplateColumns="repeat(8, 1fr)" gap="8px" width="100%">
                            {produtosSelecionados.map(key => {
                                const produto = configuracoes.produtosKGSelecionados[key]
                                const isProdutoSelecionado = produtoSelecionado?.idProduto === produto.idProduto

                                return (
                                    <Button
                                        key={key}
                                        variant={isProdutoSelecionado ? 'contained' : 'outlined'}
                                        onClick={() => handleSelectProduto(produto)}>
                                        {produto.descricao} - {key.toUpperCase()}
                                    </Button>
                                )
                            })}
                        </ProductsToWeighList>
                    ) : null}

                </DialogActions>

            </Dialog>

            <SocketContext />

            <DialogQuantity
                open={openQuantity}
                value={quantity}
                onChange={(newValue) => setQuantity(newValue)}
                onConfirm={handleQuantity}
                onDismiss={handleCloseQuantityDialog} />
        </div>
    );
}



const ImagemTelaAutoatendimento = ({ status }) => {

    return (
        <div style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }}>
            <img
                style={{
                    maxWidth: "100%",
                    width: "auto !important"
                }}
                src={`/static/images/autoatendimento-balanca/${status}.png`}
                alt={status} />
        </div>
    )
}