import { addDays, subHours } from "date-fns";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../../util/formatter";
import { fetchListByDeliveryPerson } from "../service/fetchListByDeliveryPerson";
import { changeSituacaoMultiplosPedidosOnServer } from "./utils";
import { useSnackbar } from "notistack";
import { fetchListByDeliveryPersonById } from "../service/fetchListByDeliveryPersonById";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthService from "../../../service/AuthService";

const AppDeliveryManProviderContext = createContext();

export const AppDeliveryManProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [selectedDispatch, setSelectedDispatch] = useState(() => {
    const savedOrder = localStorage.getItem("selectedDispatch");
    return savedOrder ? JSON.parse(savedOrder) : {};
  });
  const service = new AuthService();
  const profile = service?.getProfile();
  const [selectedOrder, setSelectedOrder] = useState(
    selectedDispatch.pedidos || []
  );
  const deliverymanProfile = profile?.pessoa;
  const previousOrdersDeliveryManRef = useRef(null);
  const previousOrdersNoDeliveryManRef = useRef(null);
  const [orderReporList, setOrderReportList] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const dispatchOrdersData = useSelector((state) => state.dispatchs);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const ordersForDelivery = dispatchOrdersData.orders.filter((order) => {
    return order.visible;
  });

  const ordersNoDeliveryMan = ordersForDelivery.filter((order) => {
    return order.pessoa.id === -1;
  });

  const ordersForDeliveryMan = ordersForDelivery.filter((order) => {
    return order.pessoa.id === deliverymanProfile.id;
  });
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const previousOrdersWithDelivery =
      previousOrdersDeliveryManRef?.current?.length || 0;
    const previousOrdersWithoutDelivery =
      previousOrdersNoDeliveryManRef?.current?.length || 0;
    const currentOrdersWithDelivery = ordersForDeliveryMan?.length || 0;
    const currentOrdersWithoutDelivery = ordersNoDeliveryMan?.length || 0;

    const showNotification = (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        SnackbarContentProps: {
          sx: {
            fontSize: "1.2rem",
            padding: "16px",
            "& .MuiSnackbarContent-message": { fontSize: "1.2rem" },
          },
        },
      });
    };
    if (
      previousOrdersDeliveryManRef.current !== null &&
      previousOrdersWithDelivery < currentOrdersWithDelivery
    ) {
      showNotification("Novo despacho associado a você");
    }

    if (
      previousOrdersNoDeliveryManRef.current !== null &&
      previousOrdersWithoutDelivery < currentOrdersWithoutDelivery
    ) {
      showNotification("Novo despacho sem entregador disponível");
    }

    previousOrdersDeliveryManRef.current = ordersForDeliveryMan;
    previousOrdersNoDeliveryManRef.current = ordersNoDeliveryMan;
    //eslint-disable-next-line
  }, [dispatchOrdersData]);

  useEffect(() => {
    setSelectedOrder(selectedDispatch.pedidos || []);
    localStorage.setItem("selectedDispatch", JSON.stringify(selectedDispatch));

  }, [selectedDispatch]);

  const isNotInitDelivery =
    selectedOrder.every((order) => order.situacao === "AGUARDANDO_RETIRADA") &&
    !!selectedOrder.length;

  const isFinallyDelivery =
    selectedOrder.every((order) => order.situacao === "ENTREGUE") &&
    !!selectedOrder.length;

  const inProgressId =
    selectedOrder.find((order) => order.situacao === "ENVIADO")?.id || -1;

  const startDate = `${dateFormat(
    subHours(new Date(), 6),
    "yyyy-MM-dd"
  )} 06:00:00`;
  const endDate = `${dateFormat(
    addDays(subHours(new Date(), 6), 1),
    "yyyy-MM-dd"
  )} 06:00:00`;

  const handleDeliveryCheck = (orderId) => {
    const updatedOrders = [...selectedOrder];
    const updatedDispatch = { ...selectedDispatch };
    const orderIndex = updatedOrders.findIndex((order) => order.id === orderId);

    if (orderIndex !== -1) {
      updatedOrders[orderIndex].situacao = "ENTREGUE";
    }

    setSelectedDispatch({ ...updatedDispatch, pedidos: updatedOrders });
    changeSituacaoMultiplosPedidosOnServer(
      [selectedOrder[orderIndex].id],
      "ENTREGUE",
      deliverymanProfile.id,
      selectedOrder[orderIndex].agrupadorDespacho
    ).then((response) => {
      if (response.err) {
        return;
      }
      dispatch({
        type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER",
        newOrder: response,
      });
      dispatch({
        type: "SEND_MESSAGE_SOCKET",
        message: {
          type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER",
          newOrder: response,
        },
      });
      dispatch({
        type: "UPDATE_DISPATCH",
        order: updatedOrders[orderIndex],
      });
      dispatch({
        type: "SEND_MESSAGE_SOCKET",
        message: {
          type: "UPDATE_DISPATCH",
          order: updatedOrders[orderIndex],
        },
      });
    });
  };

  const handleDispatchAll = () => {
    const updatedOrders = [...selectedOrder];
    updatedOrders.forEach((order) => {
      order.situacao = "ENTREGUE";
    });
    changeSituacaoMultiplosPedidosOnServer(
      updatedOrders.map((order) => order.id),
      "ENTREGUE",
      deliverymanProfile.id,
      updatedOrders[0].agrupadorDespacho
    ).then((response) => {
      response.forEach((item) => {
        if (item.err) {
          return;
        }
        setSelectedDispatch({ ...selectedDispatch, pedidos: updatedOrders });
        dispatch({ type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER", newOrder: item });
        dispatch({
          type: "SEND_MESSAGE_SOCKET",
          message: { type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER", newOrder: item },
        });
        dispatch({
          type: "UPDATE_DISPATCH",
          order: item,
        });
        dispatch({
          type: "SEND_MESSAGE_SOCKET",
          message: {
            type: "UPDATE_DISPATCH",
            order: item,
          },
        });
        handleFinnalyDelivery();
      });
    });
  };

  const handleFinnalyDelivery = () => {
    const agrupadorDespacho = selectedOrder.find(
      (order) => order.agrupadorDespacho
    ).agrupadorDespacho;

    dispatch({
      type: "FINISH_DISPATCH",
      agrupadorDespacho: agrupadorDespacho,
    });
    setSelectedDispatch({});
    history.push("/appentregador/home");
  };

  const handleInitDelivery = () => {
    const updatedOrders = [...selectedOrder];
    updatedOrders.forEach((order) => {
      order.situacao = "ENVIADO";
    });
    changeSituacaoMultiplosPedidosOnServer(
      updatedOrders.map((order) => order.id),
      "ENVIADO",
      deliverymanProfile.id,
      updatedOrders[0].agrupadorDespacho
    ).then((response) => {
      response.forEach((item) => {
        if (item.err) {
          return;
        }
        setSelectedDispatch({ ...selectedDispatch, pedidos: updatedOrders });
        dispatch({ type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER", newOrder: item });
        dispatch({
          type: "SEND_MESSAGE_SOCKET",
          message: { type: "ADD_OR_UPDATE_GENERIC_FROM_ORDER", newOrder: item },
        });
        dispatch({
          type: "UPDATE_DISPATCH",
          order: item,
        });
        dispatch({
          type: "SEND_MESSAGE_SOCKET",
          message: {
            type: "UPDATE_DISPATCH",
            order: item,
          },
        });
      });
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const despacho = await fetchListByDeliveryPerson(startDate, endDate);
        const index = despacho.findIndex(
          (item) =>
            item.agrupadorDespacho === selectedDispatch.agrupadorDespacho
        );
        if (index === -1) setSelectedDispatch({});

        dispatch({
          type: "LOAD_DISPATCHES",
          orders: despacho,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const loadOrderByDate = () => {
    setLoadingReport(true);
    fetchListByDeliveryPersonById(
      startDate,
      endDate,
      deliverymanProfile.id,
      "ENTREGUE"
    )
      .then((resp) => {
        setOrderReportList(resp);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadingReport(false);
      });
  };

  return (
    <AppDeliveryManProviderContext.Provider
      value={{
        inProgressId,
        selectedOrder,
        isNotInitDelivery,
        orderReporList,
        isFinallyDelivery,
        deliverymanProfile,
        ordersForDeliveryMan,
        ordersNoDeliveryMan,
        selectedDispatch,
        loadingReport,
        setSelectedDispatch,
        setSelectedOrder,
        handleInitDelivery,
        handleDispatchAll,
        loadOrderByDate,
        handleDeliveryCheck,
        setLoadingReport,
        handleFinnalyDelivery,
      }}
    >
      {children}
    </AppDeliveryManProviderContext.Provider>
  );
};

export const useAppDeliveryManProvider = () => {
  return useContext(AppDeliveryManProviderContext);
};
