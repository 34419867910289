import { Alert, AlertTitle, Typography } from "@mui/material";
import React from "react";
const InvalidAddressAlert = ({ numberOfInvalidAddress }) => {
    if (!numberOfInvalidAddress) {
      return null;
    }
  
    let msg = "";
    if (numberOfInvalidAddress === 1) {
      msg = `Há ${numberOfInvalidAddress} endereço incorreto. É necessário corrigir na listagem dos pedidos para que a rota seja criada`;
    } else {
      msg = `Há ${numberOfInvalidAddress} endereços incorretos. É necessário corrigir na listagem dos pedidos para que a rota seja criada`;
    }
  
    return (
      <Alert severity="error">
        <AlertTitle>Aviso</AlertTitle>
        <Typography variant="body1">{msg}</Typography>
      </Alert>
    );
  }

  export default InvalidAddressAlert;