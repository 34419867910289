import AuthService from "../../../service/AuthService";

export const fetchListByDeliveryPersonById = async (
  dtInicio,
  dtFinal,
  deliverymanId,
  situation
) => {
  const service = new AuthService();
  let situationQuery = "";

  if (situation) {
    situationQuery = `&situacao=${situation}`;
  }
  try {
    return await service.get(
      `/api-v2/pedidoIntegracao/listPorEntregadorById?dtInicio=${dtInicio}&dtFinal=${dtFinal}&showTakeout=true&idEntregador=${deliverymanId}${situationQuery}`
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
