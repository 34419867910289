import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AuthService from '../../service/AuthService';
import { useHistory, useLocation } from "react-router-dom";
import { Box, Collapse, List, ListItemButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import EventIcon from '@mui/icons-material/Event';
import MapIcon from '@mui/icons-material/Map';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ScaleIcon from '@mui/icons-material/Scale';
import DvrIcon from '@mui/icons-material/Dvr';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import { useDispatch } from 'react-redux';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const PREFIX = 'menuItens'

const classes = {
  centerIcon: `${PREFIX}-centerIcon`,
  nested: `${PREFIX}-nested`
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.centerIcon}`]: {
    paddingLeft: theme.spacing(0.8),
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(3),
  }
}))


export function MainManuList() {
  const history = useHistory()
  const location = useLocation()
  const [colapseDelivery, setColapseDelivery] = useState(localStorage.getItem('_side_panel_delivery_') ? localStorage.getItem('_side_panel_delivery_') === "true" : true)

  useEffect(() => {
    localStorage.setItem('_side_panel_delivery_', colapseDelivery)
  }, [colapseDelivery])

  const handleClickColapseDelivery = () => {
    setColapseDelivery(!colapseDelivery)
  }

  return (
    <StyledBox>

      <Tooltip title={`${colapseDelivery ? 'Esconder menu delivery' : 'Mostrar menu delivery'}`} placement="left" disableInteractive>
        <ListItemButton onClick={handleClickColapseDelivery}>
          <ListItemIcon className={classes.centerIcon}>
            <TwoWheelerIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Delivery" />
          {colapseDelivery ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
        </ListItemButton>
      </Tooltip>

      <Collapse in={colapseDelivery} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          <Tooltip title="Pedidos de delivery" placement="left" disableInteractive>
            <ListItemButton className={classes.nested} selected={location.pathname === "/delivery"} onClick={() => history.push("/delivery")}>
              <ListItemIcon className={classes.centerIcon}>
                <DashboardIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Pedidos de delivery" />
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Pedidos agendados" placement="right" disableInteractive>
            <ListItemButton className={classes.nested} selected={location.pathname === "/agendados"} onClick={() => history.push("/agendados")}>
              <ListItemIcon className={classes.centerIcon}>
                <EventIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Pedidos agendados" />
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Entregadores" placement="right" disableInteractive>
            <ListItemButton className={classes.nested} selected={location.pathname === "/deliveryman"} onClick={() => history.push("/deliveryman")}>
              <ListItemIcon className={classes.centerIcon}>
                <SportsMotorsportsIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Entregadores" />
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Despacho" placement="right" disableInteractive>
            <ListItemButton className={classes.nested} selected={location.pathname === "/dispatchDeliveryman"} onClick={() => history.push("/dispatchDeliveryman")}>
              <ListItemIcon className={classes.centerIcon}>
                <DeliveryDiningIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Despacho" />
            </ListItemButton>
          </Tooltip>

          <Tooltip title="Mapa de calor das vendas" placement="right" disableInteractive>
            <ListItemButton className={classes.nested} selected={location.pathname === "/salesHeatMap"} onClick={() => history.push("/salesHeatMap")}>
              <ListItemIcon className={classes.centerIcon}>
                <MapIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Mapa de calor" />
            </ListItemButton>
          </Tooltip>

        </List>
      </Collapse>

      <Tooltip title="Comanda e mesa" placement="left" disableInteractive>
        <ListItemButton selected={location.pathname === "/pedidosIdentificador"} onClick={() => history.push("/pedidosIdentificador")}>
          <ListItemIcon className={classes.centerIcon}>
            <FilterNoneIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Comanda e mesa" />
        </ListItemButton>
      </Tooltip>

      <Tooltip title="Venda rápida" placement="left" disableInteractive>
        <ListItemButton selected={location.pathname === "/fastSale"} onClick={() => history.push("/fastSale")}>
          <ListItemIcon className={classes.centerIcon}>
            <ElectricBoltIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Venda rápida" />
        </ListItemButton>
      </Tooltip>

      <Tooltip title="Monitor de preparo - KDS" placement="left" disableInteractive>
        <ListItemButton selected={location.pathname === "/kds"} onClick={() => history.push("/kds")}>
          <ListItemIcon className={classes.centerIcon}>
            <DvrIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="KDS" />
        </ListItemButton>
      </Tooltip>

      <Tooltip title="Atendimento de balança" placement="left" disableInteractive>
        <ListItemButton selected={location.pathname === "/atendimento-balanca"} onClick={() => history.push("/atendimento-balanca")}>
          <ListItemIcon className={classes.centerIcon}>
            <ScaleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Balança" />
        </ListItemButton>
      </Tooltip>

      <Tooltip title="Gestão de cardápios integrados" placement="left" disableInteractive>
        <ListItemButton selected={location.pathname === "/cardapio"} onClick={() => history.push("/integrated-menus")}>
          <ListItemIcon className={classes.centerIcon}>
            <MenuBookIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Cardápios Integrados" />
        </ListItemButton>
      </Tooltip>

      <Tooltip title="Configurações" placement="right" disableInteractive>
        <ListItemButton selected={location.pathname === "/settings"} onClick={() => history.push("/settings")}>
          <ListItemIcon className={classes.centerIcon}>
            <SettingsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
        </ListItemButton>
      </Tooltip>

    </StyledBox>
  )
}

export function LogoutButton() {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogout = () => { 
    let service = new AuthService()
    dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { productMenu: null } }) //Atualiza a lista de produtos para prepará-la para o próximo usuário.
    service.logout()
    history.push("/login")
  }

  return (
    <StyledBox display="flex" flexDirection='column' justifyContent="center" mt={1}>
      <List>
        <ListItemButton onClick={handleLogout} >
          <ListItemIcon className={classes.centerIcon}>
            <ExitToAppIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </List>
    </StyledBox>
  )
}