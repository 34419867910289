import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import React from "react";

const DialogConfim = ({open, onConfirm, onCancel}) => {
  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle my="1rem">
          <Typography variant="h4" component="h2">
            Entregar todos os Pedidos
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Você está prestes a entregar todos os pedidos. Esta ação não pode
            ser desfeita. Deseja continuar?
          </Typography>
        </DialogContent>

        <DialogActions sx={{ padding: "2rem" }}>
          <Button
            fullWidth
            onClick={() => onCancel(false)}
            color="grey"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            fullWidth
            onClick={onConfirm}
            color="primary"
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogConfim;
