import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';

const FullScreenWrapper = ({ children, isFullScreen, setOpen }) => {
  if (isFullScreen) {
    return (
      <Dialog onClose={() => { setOpen(false) }} open={isFullScreen} fullScreen>
        <DialogTitle>
          <Typography variant="h2" component="h1">
            Roteirização
          </Typography>

          <Typography variant="h5" component="h2">
            Crie rotas a partir dos pedidos pelo mapa
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { setOpen(false) }}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >{children}</DialogContent>
      </Dialog>
    );
  }

  return <>{children}</>;
};

export default FullScreenWrapper;
