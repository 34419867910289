import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/material/styles'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import OrderDispatch from './components/OrderDispatch'
import OrderRouter from './components/OrderRouter'
import { OrderProvider } from './context/OrderProvider'
import BoardUpdate from './components/BoardUpdate'

const PREFIX = 'Load'

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(5),
  }
}))

const DeliveryManOrder = () => {
  const location = useLocation()
  const history = useHistory()
  const [tab, setTab] = useState('1')

  useEffect(() => {
    if (location.pathname === '/dispatchDeliveryman') {
      setTab('1')
    } else if (location.pathname === '/dispatchDeliveryman/roteirizacao') {
      setTab('2')
    }
  }, [location.pathname])

  function handleTab(__, value) {
    setTab(value)
    history.push(value === '1' ? '/dispatchDeliveryman' : '/dispatchDeliveryman/roteirizacao')
  }

  return (
    <OrderProvider>
      <Root className={classes.root}>
        <CssBaseline />

        <Box>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTab}>
                <Tab label="Despacho de Pedidos" value="1" />
                <Tab label="Roteirização" value="2" />
              </TabList>
            </Box>

            <TabPanel sx={{ paddingX: 0 }} value="1">
              <OrderDispatch />
            </TabPanel>

            <TabPanel sx={{ paddingX: 0 }} value="2">
              <OrderRouter />
            </TabPanel>

          </TabContext>
        </Box>
      </Root>
      <BoardUpdate />
    </OrderProvider>
  )
}

export default DeliveryManOrder
