import AuthService from "../../../../service/AuthService";

export const changeSituacaoPedidoOnServer = (pedido, situacao) => {
  return new Promise((resolve, reject) => {
    const service = new AuthService();
    const id = pedido.id;
    const oldSituation = pedido.situacao;
    pedido.situacao = situacao;
    service
      .post(`/api-v2/pedidoIntegracao/situacao/${id}/${situacao}`)
      .then((pedidoResp) => {
        resolve(pedidoResp);
      })
      .catch((err) => {
        pedido.situacao = oldSituation;
        reject(err);
      });
  });
};

export const changeSituacaoMultiplosPedidosOnServer = (
  ids,
  situacao,
  idDeliveryMan,
  idDespacho,
) => {
  return new Promise((resolve, reject) => {
    let queryParams = new URLSearchParams();

    const service = new AuthService();
    if (idDeliveryMan) {
      queryParams.append('idDeliveryMan', idDeliveryMan);
    }

    if (idDespacho) {
      queryParams.append('idDespacho', idDespacho);
    }
    const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

    service
      .post(
        `/api-v2/pedidoIntegracao/associar-despacho/multiple/${ids}/${situacao}${queryString}`
      )
      .then((pedidoResp) => {
        resolve(pedidoResp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};