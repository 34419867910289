import { Button, Dialog, DialogActions, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack';
import { currencyFormat } from '../../../../util/formatter';
import { totalItems, pendingAmount, getTotalItem, totalOrder, totalPriceItem, distributeDiscountValueOnSubItens, totalDiscountInItem } from '../../../../util/order/orderUtil';
import { DialogTitle, DialogContent } from "../../CustomDialog/dialogComponents"
import { roundUp } from '../../../../util/genericUtil';
import { useHotkeys } from 'react-hotkeys-hook';
import { isAuthorized } from '../../../../routes';

export default function DiscountItem() {
    const dispatch = useDispatch()
    const open = useSelector(state => state.discountItemDialog.open)
    const item = useSelector(state => state.discountItemDialog.item)
    const order = useSelector(state => state.discountItemDialog.order)
    //const discountItemDialog = useSelector(state => state.discountItemDialog)
    const [input, setInput] = React.useState({ typeDiscount: 'R$', discountValue: 0})
    const [subTotal, setSubTotal] = React.useState(0)
    const [pendentTotal, setPendentTotal] = React.useState(0)
    const { enqueueSnackbar } = useSnackbar();
    const inputValueRef = useRef();
    const btnConfirmRef = useRef();

    useHotkeys('f10', (event) => {

        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
            confirmDiscount()
        }
    })

    function confirmDiscount() {

        if (isAuthorized('ROLE_PDV_ONLINE_SISCHEF_BLOQUEIO_DESCONTO_PEDIDO')) {
            enqueueSnackbar(`Seu usuário não possuí permissão para conceder ou alterar descontos.`, { variant: 'error', });
            return
        }


        if (input.typeDiscount === '%' && input.discountValue > 100) {
            enqueueSnackbar(`O desconto de  ${currencyFormat(input.discountValue, { simbol: input.typeDiscount })} excede o subtotal do pedido.`, { variant: 'error', });
            return
        }

        if (input.typeDiscount === 'R$' && input.discountValue > subTotal) {
            enqueueSnackbar(`O desconto de  ${currencyFormat(input.discountValue, { simbol: input.typeDiscount })} excede o subtotal do pedido.`, { variant: 'error', });
            return
        }

        let discountTotalValue = 0.0

        /*SE O USUÁRIO ENVIAR UM VALOR VAZIO É ENTENDIDO COMO ZERO*/
        if (input.discountValue === '' || input.discountValue <= 0) {
            input.discountValue = 0
        }

        if (input.typeDiscount === '%') {
            discountTotalValue = roundUp((subTotal * (input.discountValue / 100.0)), 2)
        } else {
            discountTotalValue = parseFloat(input.discountValue)
        }

        if (pendentTotal < discountTotalValue) {
            enqueueSnackbar(`O desconto de ${currencyFormat(input.discountValue, { simbol: input.typeDiscount })} excede o valor pendente: ${currencyFormat(pendentTotal)}`, { variant: 'error', });
            return
        }

        const orderCopy = Object.assign({}, order)
        let itemTemp = orderCopy.iFoodOrder.items?.find(element => element.referenceCode === item.referenceCode)
        let benefitIndex = null;

        //Verifica se possui algum desconto com valor maior do que o desconto atual do item
        if (!!orderCopy.iFoodOrder.benefits) {
            orderCopy.iFoodOrder.benefits.forEach((benefit, index) => {
                if (benefit.value >= (totalDiscountInItem(itemTemp) || 0.0) && benefit.sponsorshipValues?.MERCHANT >= (totalDiscountInItem(itemTemp) || 0.0)) {
                    benefitIndex = index;
                }
            })
        }

        //caso não exista cria um novo desconto para aplicar no pedido
        if (benefitIndex == null) {
            let benefit = {
                value: discountTotalValue,
                sponsorshipValues: {
                    MERCHANT: discountTotalValue
                },
                target: "CART",
            }

            if (!!!orderCopy.iFoodOrder.benefits) {
                orderCopy.iFoodOrder.benefits = []
            }

            orderCopy.iFoodOrder.benefits.push(benefit)
        } else {//caso exista atualiza o valor do desconto removendo o desconto antigo do item e adicionando o valor novo
            orderCopy.iFoodOrder.benefits[benefitIndex].value = orderCopy.iFoodOrder.benefits[benefitIndex].value - (totalDiscountInItem(itemTemp) || 0.0) + discountTotalValue;
            orderCopy.iFoodOrder.benefits[benefitIndex].sponsorshipValues.MERCHANT = orderCopy.iFoodOrder.benefits[benefitIndex].value
        }
       
        itemTemp.discount = discountTotalValue;
        itemTemp.totalPrice = totalPriceItem(item)

        distributeDiscountValueOnSubItens(item)
       
        orderCopy.iFoodOrder.subTotal = totalItems(orderCopy.iFoodOrder)
        orderCopy.iFoodOrder.totalPrice = totalOrder(orderCopy.iFoodOrder)

        dispatch({ type: 'UPDATE_ORDER_EDIT', dialogOrder: { order: orderCopy, open: true } })

        handleClose()
    }

    function handleClose() {
        dispatch({ type: 'TOOGLE_DISCOUNT_ITEM_DIALOG', discountItemDialog: { open: false, item: undefined } })
    }

    function onChange(event) {
        const nomeCampo = event.target.name
        const valorCampo = event.target.value

        setInput({ ...input, [nomeCampo]: valorCampo })
    }

    useEffect(() => {
        if (item) {
            const totalItemTemp = getTotalItem(item)
            setSubTotal(totalItemTemp)

            const pendent = pendingAmount(order.iFoodOrder)
            setPendentTotal(pendent)

            setInput({ typeDiscount: 'R$', discountValue: item.discount })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    useEffect(() => {
        if (order) {
            const pendent = pendingAmount(order.iFoodOrder)
            setPendentTotal(pendent)
        }
    }, [order])

    useEffect(() => {
        if (open) {
            // setInput({ typeDiscount: 'R$', discountValue: 0})

            setTimeout(() => inputValueRef.current?.select(), 200)    
        }
        
    }, [open])

    const handleKeyPress = (event) => {
        if (event.key === 'Enter')
            setTimeout(() => btnConfirmRef.current.focus(), 100)
    }

    return (
        <Dialog open={open}

            maxWidth={"xs"}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle
                onClose={handleClose}
            >
                <Typography variant="caption" display="block" gutterBottom>
                    {`O desconto será aplicado ao item ${item?.name}`}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <RadioGroup
                            row
                            name="typeDiscount"
                            value={input.typeDiscount}
                            defaultValue="R$"
                            onChange={(event) => onChange(event)}
                        >

                            <FormControlLabel
                                value="R$"
                                control={<Radio color="primary" />}
                                label="Valor"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="%"
                                control={<Radio color="primary" />}
                                label="Percentual"
                                labelPlacement="end"
                            />
                        </RadioGroup>

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            inputRef={inputValueRef}
                            variant='outlined'
                            name='discountValue'
                            type='number'
                            onKeyPress={handleKeyPress}
                            defaultValue={item?.discount}
                            autoFocus
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{input.typeDiscount}</InputAdornment>,
                            }}
                            onChange={(event) => onChange(event)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {input.typeDiscount === '%' ? ` Desconto calculado ${currencyFormat(subTotal * (input.discountValue / 100))}` : ''}

                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Tooltip title="Atalho F10" aria-label="Finalizar">
                    <Button
                        ref={btnConfirmRef}
                        variant="contained"
                        color="primary"
                        onClick={confirmDiscount}
                        endIcon={<DoneIcon />}>Confirmar</Button>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}