import { Box, Dialog, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '../CustomDialog/dialogComponents';
import { addMinutes } from 'date-fns';
import { LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBRLocaleAdapter from 'date-fns/locale/pt-BR';
import { ptBR } from '@mui/x-date-pickers/locales';

const ptBRLocale = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

const daysOfWeek = [
    "SUNDAY",    // 0
    "MONDAY",    // 1
    "TUESDAY",   // 2
    "WEDNESDAY", // 3
    "THURSDAY",  // 4
    "FRIDAY",    // 5
    "SATURDAY"   // 6
];

export default function HorariosIntegracoes({ open, setOpen, openingHoursList, setOpeningHoursList }) {
    const [selectedTab, setSelectedTab] = useState(0)
    const [filteredShifts, setFilteredShifts] = useState({});

    const onClickClose = () => {
        setOpen(false);
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    useEffect(() => {
        const filterShifts = (shifts) => {
            const groupedShifts = {};

            shifts.forEach((shift) => {
                const day = shift.dayOfWeek;
                const merchantId = shift.merchantId;

                if (!groupedShifts[day]) {
                    groupedShifts[day] = {};
                }

                if (!groupedShifts[day][merchantId]) {
                    groupedShifts[day][merchantId] = [];
                }

                groupedShifts[day][merchantId].push(shift);
            });

            setFilteredShifts(groupedShifts);
        };

        if (!!openingHoursList && openingHoursList.length > 0) {
            filterShifts(openingHoursList);
        }
    }, [openingHoursList])

    useEffect(() => {
    }, [filteredShifts, selectedTab])

    return (
        <Dialog
            maxWidth='md'
            open={open}
            onClose={onClickClose}>

            <DialogTitle onClose={onClickClose}>
                Horários de funcionamento
            </DialogTitle>

            <DialogContent>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    <Tab label="Domingo" tabIndex={0} />
                    <Tab label="Segunda" tabIndex={1} />
                    <Tab label="Terça" tabIndex={2} />
                    <Tab label="Quarta" tabIndex={3} />
                    <Tab label="Quinta" tabIndex={4} />
                    <Tab label="Sexta" tabIndex={5} />
                    <Tab label="Sábado" tabIndex={6} />
                </Tabs>
                <Box pt={2}>
                    {Object.keys(filteredShifts[daysOfWeek[selectedTab]] || {}).length > 0 ? (
                        <>
                            {Object.values(filteredShifts[daysOfWeek[selectedTab]]).map((merchant) => {
                                return merchant.map((shift, index) => {
                                    const [hours, minutes] = shift.start.split(':').map(Number);
                                    const horaInicio = new Date(1970, 0, 1, hours, minutes, 0);
                                    let novaData = addMinutes(horaInicio, parseInt(shift.duration, 10));
                                    return (
                                        <LocalizationProvider key={index} dateAdapter={AdapterDateFns} adapterLocale={ptBRLocaleAdapter} localeText={ptBRLocale}>
                                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 1 }}>
                                                <Box mr={2} sx={{ flex: 1 }}>
                                                    <TimePicker
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="time-picker-pedidoAgendado-inicio"
                                                        label="Horário de abertura"
                                                        readOnly
                                                        ampm={false}
                                                        value={horaInicio}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                        viewRenderers={{
                                                            hours: renderTimeViewClock,
                                                            minutes: renderTimeViewClock,
                                                        }}
                                                        slotProps={{
                                                            popper: { placement: "top" },
                                                        }}
                                                        sx={{ width: '100%' }}
                                                        />
                                                    </Box>
                                                    <Typography sx={{ mr: 2 }}>
                                                        -
                                                    </Typography>
                                                <Box sx={{ flex: 1 }}>
                                                        <TimePicker
                                                        size="small"
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        id="time-picker-pedidoAgendado-fim"
                                                        label="Horário de fechamento"
                                                        ampm={false}
                                                        readOnly
                                                        value={novaData}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change time',
                                                        }}
                                                        viewRenderers={{
                                                            hours: renderTimeViewClock,
                                                            minutes: renderTimeViewClock,
                                                        }}
                                                        slotProps={{
                                                            popper: { placement: "top" },
                                                        }}
                                                        sx={{ width: '100%' }}
                                                        />
                                                    </Box>
                                                </Box>
                                        </LocalizationProvider>
                                    )
                                })

                            })}
                        </>
                    ) : (
                        <>
                            Nenhum horário disponível para este dia
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>

            </DialogActions>

        </Dialog>
    )
}