import ChecklistIcon from "@mui/icons-material/Checklist";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { useOrderContext } from "../../../context/OrderProvider";
import CheckOrders from "./CheckOrders";
import SelectOrders from "./SelectOrders";

const ListOrders = () => {
  const {
    selectedOrders,
    dispatchOrders,
    orders,
    allOrders,
    handleClickSelectAllOrders,
  } = useOrderContext();
  const check =
    selectedOrders.length === 0 ? false : orders.length === 0 ? true : false;
  const ordersForDelivery = useMemo(
    () => allOrders?.filter((order) => !!order.iFoodOrder?.deliveryAddress),
    [allOrders]
  );
  const { isFullScreen } = useOrderContext();

  const handleClickDispatch = () => {
    dispatchOrders();
  };
  return (
    <Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          maxHeight: isFullScreen ? "60vh" : "50vh",
          marginBottom: "1rem",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Accordion
          disableGutters
          defaultExpanded
          sx={{
            boxShadow: "none",
            borderRadius: 0,
            background: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <AccordionSummary
            sx={{
              minHeight: "auto",
              "&.Mui-expanded": { minHeight: "auto" }, // Evita aumento de altura ao expandir
            }}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            <Box
              gap={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ChecklistIcon color="primary" />
              <Typography color="primary" fontSize="0.875rem" fontWeight="bold">
                Selecionados
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {selectedOrders?.map((pedido, index) => (
              <React.Fragment key={pedido.id}>
                {!!index && (
                  <Divider variant="middle" flexItem sx={{ my: 0.5 }} />
                )}
                <SelectOrders order={pedido} />
              </React.Fragment>
            ))}
            {!selectedOrders?.length && (
              <Box display="flex" justifyContent="center">
                <Typography width="100%" textAlign="center" variant="caption">
                  Nenhum pedido selecionado
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          defaultExpanded
          sx={{
            boxShadow: "none",
            borderRadius: 0,
            background: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <AccordionSummary
            sx={{
              minHeight: "auto",
              "&.Mui-expanded": { minHeight: "auto" }, // Evita aumento de altura ao expandir
            }}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            <Box
              gap={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ListIcon color="primary" />
              <Typography color="primary" fontSize="0.875rem" fontWeight="bold">
                Todos
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {ordersForDelivery?.length > 1 && (
              <Box mt="-0.5rem" mb="0.5rem">
                <Box display="flex" alignItems="center" width="100%">
                  <Checkbox
                    checked={check}
                    onChange={() => handleClickSelectAllOrders(!check)}
                  />
                  <Box ml="0.5rem">
                    <Typography
                      fontSize="0.8rem"
                      fontWeight="bold"
                      color="secondary"
                    >
                      Selecionar todos
                    </Typography>
                  </Box>
                </Box>
                <Divider variant="middle" flexItem sx={{ my: 0.5 }} />
              </Box>
            )}
            {ordersForDelivery?.map((pedido, index) => (
              <React.Fragment key={pedido.id}>
                {!!index && (
                  <Divider variant="middle" flexItem sx={{ my: 0.5 }} />
                )}
                <CheckOrders order={pedido} />
              </React.Fragment>
            ))}
            {!ordersForDelivery?.length && (
              <Box display="flex" justifyContent="center">
                <Typography width="100%" textAlign="center" variant="caption">
                  Nenhum pedido encontrado
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        marginBottom="1rem"
      >
        <Button
          disabled={!selectedOrders?.length}
          onClick={handleClickDispatch}
        >
          Criar rota{" "}
          {selectedOrders?.length > 0 && `(${selectedOrders.length})`}
        </Button>
      </Box>
    </Box>
  );
};

export default ListOrders;
