import React, { useState } from 'react';
import { Box, IconButton, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import CustomerSearch from './CustomerSearch'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import ClearIcon from '@mui/icons-material/Clear';
import { formatPhoneBR } from '../../../../util/formatter';

export default function CustomerSelector({ customer, setCustomer, ...others }) {
    const [openCustomerSearch, setOpenCustomerSearch] = useState(false)

    const onSelectCustomer = (customer) => {
        setCustomer(customer)
    }

    const openSelectSearch = () => {
        setOpenCustomerSearch(true)
    }

    const clearSelectedCustomer = () => {
        onSelectCustomer(null)
        setCustomer(null)
    }

    return (
        <>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <List
                        disablePadding
                        component="nav"
                        aria-label="Selecionar o cliente">
                        <ListItemButton
                            sx={{ p: 0 }}
                            aria-haspopup="true"
                            aria-controls="lock-menu"
                            onClick={openSelectSearch}>

                            <ListItemAvatar sx={{ marginLeft: 2 }}> 
                                {customer ? <PersonIcon /> : <PersonAddIcon />}
                            </ListItemAvatar>

                            <ListItemText primary={customer ? customer.nome : 'Selecionar/Cadastrar cliente'}
                                secondary={`${customer?.fone ?
                                    formatPhoneBR(customer?.fone) : 'telefone não informado'} 
                                    ${customer?.email ? ' | ' + customer?.email : ''}`}
                            />

                        </ListItemButton>

                    </List>
                </Box>
                <Box >
                    {!!customer && (
                        <IconButton onClick={clearSelectedCustomer} size="large">
                            <ClearIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>

            <CustomerSearch
                open={openCustomerSearch}
                defaultCustomer={customer}
                setOpen={setOpenCustomerSearch}
                onConfirm={onSelectCustomer} />
        </>
    )
}

