import { AccordionDetails, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useAppDeliveryManProvider } from "../../../../../context/AppDeliveryManProvider";

const AccordionDetailsOrder = ({ order, setExpanded, scrollToElement }) => {
  const { selectedOrder, inProgressId, handleDeliveryCheck } =
    useAppDeliveryManProvider();
  const handleClickDeliveryCheck = (order) => {
    const nextOrderIndex =
      selectedOrder.findIndex((o) => o.id === order.id) + 1;
    const nextOrder = selectedOrder[nextOrderIndex];
    handleDeliveryCheck(order.id);
    if (nextOrder) {
      setExpanded(nextOrder.id);
      scrollToElement(`accordion-${nextOrder.id}`);
    } else {
      setExpanded(false);
    }
  };

  return (
    <AccordionDetails>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: "100%", paddingRight: "1rem" }}>
          <Typography variant="h5" fontWeight="bold">
            Itens do Pedido:
          </Typography>
          {order.iFoodOrder.items.map((item, index) => (
            <Box key={index} sx={{ margin: "0.5rem" }}>
              <Typography variant="body1">
                -{" "}
                <strong>
                  {item.quantity}x {item.name} (R$ {item.totalPrice.toFixed(2)})
                </strong>
              </Typography>
            </Box>
          ))}
        </Box>

        <Box>
          <Typography variant="body1">
            Taxa de Entrega: R${" "}
            <strong>{`R$ ${
              order.iFoodOrder.productDeliveryFee?.valorUnitarioVenda?.toFixed(
                2
              ) || 0
            }`}</strong>
          </Typography>
          <Box width="100%"></Box>
          {!!order?.levarTrocoPara && (
            <Typography variant="body1">
              Troco para: <strong>{order.levarTrocoPara}</strong>
            </Typography>
          )}
          <Typography variant="body1">
            Forma de Pagamento:{" "}
            <strong>{order.pago ? "Pago" : "Não Pago"}</strong>
          </Typography>
        </Box>
      </Box>

      <Box mt="1rem">
        <Typography variant="h4">
          Valor Total: R${" "}
          <strong>{`R$ ${order.totalPedido.toFixed(2)}`}</strong>
        </Typography>
      </Box>

      <Box
        sx={{
          display: inProgressId === order.id ? "block" : "none",
        }}
        mt="2rem"
      >
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleClickDeliveryCheck(order)}
        >
          Entrega realizada
        </Button>
      </Box>
    </AccordionDetails>
  );
};

export default AccordionDetailsOrder;
