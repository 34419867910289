import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Checkbox,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    TableContainer,
    IconButton,
    Typography,
    Button,
} from '@mui/material';
import { currencyFormat, quantityFormat } from '../../../../../util/formatter';
import { Alert } from '@mui/material';
import { totalItemReal } from '../../../../../util/order/orderUtil';
import { roundUp } from '../../../../../util/genericUtil';
import CircularProgressWithLabel from '../../../elements/CircularProgressWithLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const PREFIX = 'SeparateItemsByCustomer'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    subitem: `${PREFIX}-subitem`,
    paddingLeft: `${PREFIX}-paddingLeft`,
    paidRow: `${PREFIX}-paidRow`,
    paidCell: `${PREFIX}-paidCell`,
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    [`&.${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.subitem}`]: {
        opacity: "0.8",
        fontSize: "0.9em"
    },

    [`& .${classes.paddingLeft}`]: {
        paddingLeft: '4px'
    },

    [`& .${classes.paidRow}`]: {
        paddingLeft: '4px',
        backgroundColor: theme.palette.mode === 'light' ? '#e5e5e5' : '#262d33'
    },

    [`& .${classes.paidCell}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#e5e5e5' : '#262d33'
    },

}))

const StyledButtonCollapse = styled(Button)(({ theme }) => ({
    '&': {
        color: theme.palette.mode === 'light' ? '#666264' : '#FFFFFF',
        backgroundColor: theme.palette.mode === 'light' ? '#e5e5e5' : '#262d33',
        '& .MuiSvgIcon-root': {
            color: theme.palette.mode === 'light' ? '#666264' : '#fff',
        },
        '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? '#d6d6d6' : '#3c4144',
        },
    }
}))


function OrderedItems({ item, getPercentualPago, handleChangeCheckBox, onClickAddOne, onClickRemoveOne, isPaid, }) {

    return (
        <React.Fragment key={item.referenceCode}>
            <TableRow>
                <TableCell component='th' scope='row' padding="none" className={isPaid ? classes.paidRow : classes.paddingLeft} >
                    <Typography>{item.name}</Typography>
                </TableCell>

                <TableCell className={isPaid ? classes.paidCell : ''} align='right'>{quantityFormat(item.quantity)}</TableCell>

                <TableCell className={isPaid ? classes.paidCell : ''} align='right'>
                    {currencyFormat(totalItemReal(item), { simbol: '' })}
                </TableCell>

                <TableCell className={isPaid ? classes.paidCell : ''} align='center'>
                    <Tooltip title={`Total item: ${currencyFormat(totalItemReal(item), { simbol: '' })}
                                                        | Pago ${currencyFormat(item.amountPaid || 0.0, { simbol: '' })}
                                                        | Saldo ${currencyFormat(totalItemReal(item) - (item.amountPaid || 0.0), { simbol: '' })}`}>
                        <div>
                            <CircularProgressWithLabel
                                thickness={2}
                                value={getPercentualPago(item)}
                                label={`${getPercentualPago(item)}%`} />
                        </div>
                    </Tooltip>
                </TableCell>

                <TableCell className={isPaid ? classes.paidCell : ''} align='center'>
                    {getPercentualPago(item) === 100 ? 'Pago' : (
                        <Box>
                            <IconButton
                                disabled={item.amountPaid > 0 || item.received}
                                component="span"
                                size='small'
                                onClick={() => onClickRemoveOne(item)}>
                                <RemoveIcon />
                            </IconButton>

                            {item.divideInto}

                            {item.divideInto >= 30 ? (
                                <Tooltip title="Limite máximo atingido" arrow>
                                    <span>
                                        <IconButton
                                            disabled
                                            component="span"
                                            size="small"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : (
                                <IconButton
                                    disabled={item.amountPaid > 0 || item.received}
                                    component="span"
                                        size="small"
                                    onClick={() => onClickAddOne(item)}
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                        </Box>
                    )}
                </TableCell>

                <TableCell className={isPaid ? classes.paidCell : ''} align='center'>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        {item.partialPayment?.reduce((acc, itemChecked, index) => {
                            const rowIndex = Math.floor(index / 5);
                            if (!acc[rowIndex]) acc[rowIndex] = [];
                            acc[rowIndex].push(
                                <Checkbox
                                    sx={{ p: 0.5 }}
                                    key={index}
                                    disabled={itemChecked === 'RECEIVED' || item.received || false}
                                    color="primary"
                                    checked={totalItemReal(item) === 0 || (itemChecked === 'CHECKED') || (itemChecked === 'RECEIVED')}
                                    onClick={(event) => handleChangeCheckBox(event, item, index)}
                                    name='checked'
                                />
                            );
                            return acc;
                        }, []).map((checkboxRow, rowIndex) => (
                            <Box key={rowIndex} display="flex" justifyContent="center">
                                {checkboxRow}
                            </Box>
                        ))}
                    </Box>
                </TableCell>

                <TableCell className={isPaid ? classes.paidCell : ''} align='center' style={{ width: 100 }}>
                    {item?.pagar > 0 ? currencyFormat(item?.pagar, { simbol: '' }) : ''}
                </TableCell>

            </TableRow>


            {item?.subItems?.map((sub, index) => (
                <TableRow key={index} selected={item.notSaved}>
                    <TableCell className={isPaid ? classes.paidCell : ''} component="th" scope="row">
                        {'+ '.concat(sub.name)}
                    </TableCell>
                    <TableCell className={isPaid ? classes.paidCell : ''} align="right" >{quantityFormat(sub.quantity)}</TableCell>
                    <TableCell className={isPaid ? classes.paidCell : ''} align="right">{currencyFormat(sub.totalPrice, { simbol: '' })}</TableCell>
                    <TableCell className={isPaid ? classes.paidCell : ''} colSpan={4}></TableCell>
                </TableRow>
            ))}
        </React.Fragment>
    )
}

export default function SeparateItemsByCustomer({ order, setValueSelected, opened, }) {

    const [dividedValue, setDivideValue] = useState(0.0)
    const [paidItems, setPaidItems] = useState([])
    const [unPaidItems, setUnPaidItems] = useState([])
    const [collapse, setCollapse] = useState(!!opened)

    /*Lista de itens que representam a tela,
    são alimentados pelo useefect quando houver alteração no order*/
    const [items, setItems] = useState([])

    const handleChangeCheckBox = (event, item, index) => {

        let itemsCopy = Object.assign([], items)
        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)

        const checkedValue = event.target.checked;

        if (itemTemp.partialPayment) {
            itemTemp.partialPayment[index] = checkedValue ? 'CHECKED' : 'NOT_CHECKED'
        }

        //subtraindo o valor já pago
        const totalItem = totalItemReal(itemTemp)
        const countPays = itemTemp.partialPayment?.filter((pay) => pay === 'CHECKED').length

        itemTemp.pagar = roundUp(((totalItem / itemTemp.divideInto) * countPays), 2)
        itemTemp.pagar = roundUp(itemTemp.pagar + getRestoAPagar(itemTemp), 2)//caso tenha uma diferença para o valor final de até 10 centavos é adicionado no ultimo pagamento

        setItems(itemsCopy)
    }

    useEffect(() => {

        if (order) {
            let itemsTemp = []

            order.iFoodOrder.items.forEach((item) => {
                const totalItem = totalItemReal(item)
                const foiPagoTotal = (item.amountPaid || 0.0) >= totalItem
                item.divideInto = item.divideInto || 1

                if (foiPagoTotal) {
                    item.partialPayment = Array(item.divideInto).fill('CHECKED')
                } else {
                    item.partialPayment = item.partialPayment || Array(item.divideInto).fill('NOT_CHECKED')
                }

                itemsTemp.push(item)
            })

            setItems(itemsTemp)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (order) {
            let itemsTemp = [];
            let paidTemp = [];
            let unPaidTemp = [];

            order.iFoodOrder.items.forEach((item) => {
                const totalItem = totalItemReal(item);
                const foiPagoTotal = (item.amountPaid || 0.0) >= totalItem;
                item.divideInto = item.divideInto || 1;

                if (foiPagoTotal) {
                    item.partialPayment = Array(item.divideInto).fill('CHECKED');
                    paidTemp.push(item);
                } else {
                    item.partialPayment = item.partialPayment || Array(item.divideInto).fill('NOT_CHECKED');
                    unPaidTemp.push(item);
                }

                itemsTemp.push(item);
            });
            setItems(itemsTemp);
            setPaidItems(paidTemp);
            setUnPaidItems(unPaidTemp);
        }
    }, [order]);

    useEffect(() => {

        const soma = roundUp(items.reduce((acumulador, item) => acumulador + (item.pagar || 0.0), 0), 2);
        setDivideValue(soma);
        setValueSelected(soma);

        // eslint-disable-next-line
    }, [items]);

    function getPercentualPago(item) {
        if (totalItemReal(item) > 0) {
            return roundUp(((item.amountPaid || 0.0) * 100) / totalItemReal(item), 0)
        }

        return 100
    }

    function getRestoAPagar(item) {
        const total = totalItemReal(item)
        const amountPaid = (item.amountPaid || 0.0) + (item.pagar || 0.0)
        const diferenca = total - amountPaid

        if (Math.abs(diferenca) <= 0.1) {
            return diferenca
        }
        return 0.0
    }

    const onClickAddOne = (item) => {
        let itemsCopy = Object.assign([], items)

        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)
        itemTemp.divideInto += 1

        itemTemp.pagar = undefined
        itemTemp.partialPayment = Array(itemTemp.divideInto).fill('NOT_CHECKED')

        setItems(itemsCopy)
    }

    const onClickRemoveOne = (item) => {
        let itemsCopy = Object.assign([], items)

        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)

        if (itemTemp.divideInto === 1) {
            return
        }

        itemTemp.divideInto -= 1
        itemTemp.pagar = undefined
        itemTemp.partialPayment = Array(itemTemp.divideInto).fill('NOT_CHECKED')

        setItems(itemsCopy)
    }

    return (
        <React.Fragment>
            <StyledTableContainer style={{ maxHeight: 360, width: '100%' }} className={classes.scrollBar}>
                <Table size='small' aria-label='resumo do pedido' stickyHeader >
                    <TableHead>
                        <TableRow>
                            <TableCell padding="none" className={classes.paddingLeft} >Item</TableCell>
                            <TableCell align='right'>Qtde</TableCell>
                            <TableCell align='right'>Total</TableCell>
                            <TableCell align='center'>Pago</TableCell>
                            <TableCell align='center'>Dividir em</TableCell>
                            <TableCell align='center' padding="checkbox">Pagar</TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {paidItems.length > 0 && (
                            <TableRow>
                                <TableCell colSpan={7} align='center' padding='none'>
                                    <StyledButtonCollapse
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            opacity: collapse ? 1 : 0.5,
                                            '& .MuiSvgIcon-root': {
                                                opacity: collapse ? 1 : 0.5,
                                            },
                                            textTransform: 'capitalize',
                                        }}
                                        fullWidth
                                        variant='text'
                                        endIcon={collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        onClick={() => setCollapse(!collapse)}>
                                        Itens Pagos
                                    </StyledButtonCollapse>
                                </TableCell>
                            </TableRow>
                        )}

                        {collapse && (
                            <>
                                {paidItems?.map((item, index) => (
                                    <OrderedItems
                                        key={index}
                                        item={item}
                                        isPaid={true}
                                        handleChangeCheckBox={handleChangeCheckBox}
                                        setDivideValue={setDivideValue}
                                        getPercentualPago={getPercentualPago}
                                        onClickAddOne={onClickAddOne}
                                        onClickRemoveOne={onClickRemoveOne}
                                    />
                                ))}
                            </>
                        )}

                        {unPaidItems?.map((item, index) => (
                            <OrderedItems
                                key={index}
                                item={item}
                                isPaid={false}
                                handleChangeCheckBox={handleChangeCheckBox}
                                setDivideValue={setDivideValue}
                                getPercentualPago={getPercentualPago}
                                onClickAddOne={onClickAddOne}
                                onClickRemoveOne={onClickRemoveOne}
                            />
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer >

            <Box pt={2}>
                {dividedValue > 0 &&
                    <Alert variant="outlined" severity='success'>
                        {`Os itens selecionados totalizaram ${currencyFormat(dividedValue)}.`}
                    </Alert>
                }
            </Box>

        </React.Fragment >


    );
}