import { Box, styled } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom"; // Mantenha o Switch e Route da versão 5.2.0
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Reports from "./modules/Reports";
import RouterList from "./modules/RouterList";
import RoutesPage from "./modules/RouterPage";

export const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  maxWidth: "40rem",
  pb: "8rem",
}));

const AppBody = () => {
  const pageRouter = {
    home: (
      <StyledBox>
        <RouterList />
      </StyledBox>
    ),
    rota: (
      <StyledBox>
        <RoutesPage />
      </StyledBox>
    ),
    relatorio: (
      <StyledBox>
        <Reports />
      </StyledBox>
    ),
  };
  const branches = [
    { path: "/appentregador/home", component: pageRouter.home },
    { path: "/appentregador/rota", component: pageRouter.rota },
    { path: "/appentregador/relatorio", component: pageRouter.relatorio },
  ];

  return (
    <Switch>
      <Route
        exact
        path="/appentregador"
        render={() => <Redirect to="/appentregador/home" />}
      />

      {branches.map((branch, index) => (
        <Route
          key={index}
          path={branch.path}
          component={() => branch.component}
        />
      ))}
    </Switch>
  );
};

export default AppBody;
