import AdjustIcon from "@mui/icons-material/Adjust";
import CheckIcon from "@mui/icons-material/Check";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React, { useEffect, useState } from "react";
import { useAppDeliveryManProvider } from "../../../../context/AppDeliveryManProvider";
import AccordionDetailsOrder from "./parts/AccordionDetailsOrder";
import AccordionSummaryOrder from "./parts/AccordionSummaryOrder";

const CustomizedTimeline = () => {
  const { selectedOrder, inProgressId } = useAppDeliveryManProvider();
  const [expanded, setExpanded] = useState(false);

  function iconStatus(order) {
    const iconStatusMap = {
      AGUARDANDO_RETIRADA: <></>,
      ENVIADO:
        order.id === inProgressId ? <AdjustIcon fontSize="small" /> : <></>,
      ENTREGUE: <CheckIcon fontSize="small" />,
    };
    return iconStatusMap[order.situacao];
  }

  useEffect(() => {
    if (inProgressId !== -1) {
      setExpanded(inProgressId);
      setTimeout(() => {
        scrollToElement(`accordion-${inProgressId}`);
      }, 150);
    }
  }, [inProgressId]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleOpenMap = (endereco) => {
    if (endereco) {
      const url = `https://www.google.com/maps?q=${encodeURIComponent(
        endereco
      )}`;
      window.open(url, "_blank");
    } else {
      console.error("Endereço não disponível.");
    }
  };

  const handleClickMap = (order) => {
    const { streetName, streetNumber, neighborhood, city, state, postalCode } =
      order?.iFoodOrder?.deliveryAddress || {};
    const address = `${streetName} ${streetNumber} ${neighborhood} , ${city} - ${state}, ${postalCode}`;
    handleOpenMap(address);
  };

  const handleChange = (panel) => (__, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      scrollToElement(`accordion-${panel}`);
    }
  };

  function colorBackground(order) {
    if (order.situacao === "ENTREGUE") {
      return "#089d37";
    } else if (order.id === inProgressId) {
      return "#089d37";
    } else {
      return "#747f86";
    }
  }

  return (
    <Box pb="2rem">
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 },
        }}
        position="right"
      >
        {selectedOrder.map((order, index) => (
          <TimelineItem key={order.id}>
            <TimelineSeparator>
              <TimelineConnector
                sx={{
                  maxHeight: "0.5rem",
                  background: index === 0 ? "transparent" : "fill",
                }}
              />
              <TimelineDot
                sx={{
                  margin: 0,
                  padding: 0,
                  width: "1.3rem",
                  height: "1.3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: colorBackground(order),
                  color: "#FFFFFF",
                }}
              >
                {iconStatus(order)}
              </TimelineDot>
              {selectedOrder.length - 1 !== index && <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent
              sx={{ margin: 0, padding: 0, marginLeft: "0.5rem" }}
            >
              <Accordion
                expanded={expanded === order.id}
                id={`accordion-${order.id}`}
                onChange={handleChange(order.id)}
                disableGutters
                sx={{
                  boxShadow: "none",
                  borderRadius: 0,
                  background: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                <AccordionSummaryOrder
                  order={order}
                  handleClickMap={handleClickMap}
                />
                <AccordionDetailsOrder
                  order={order}
                  setExpanded={setExpanded}
                  scrollToElement={scrollToElement}
                />
              </Accordion>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default CustomizedTimeline;
