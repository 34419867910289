
import { Avatar, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import React from 'react';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsightsIcon from '@mui/icons-material/Insights';

import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { SISCHEF_ANALYTICS_PRIMARY, SISCHEF_PDV_PRIMARY, SISCHEF_RETAGUARDA_PRIMARY } from '../../theme/palette';
import AuthService from '../../service/AuthService';

export default function MenuSistema() {
    const jwtToken = AuthService.getToken();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const profile = new AuthService().getProfile();
    const isGarcom = profile?.perfilUsuario?.tipo === 'garcom';
    const acessoRetaguarda = !isGarcom;
    const acessoAnalytics = !isGarcom;
  
    const handleClickListItem = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
         <React.Fragment>
             <List
                aria-label="Sistemas"
                disablePadding
                sx={{ width: '100%' }}
            >
                <ListItemButton
                    id="lock-button-sistemas"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                      <ListItemAvatar>
                        <Avatar alt="Sischef Analytics" src={process.env.PUBLIC_URL + "/logo-sischef.svg"}  />
                    </ListItemAvatar>
                <ListItemText
                    primary="Sischef "
                    primaryTypographyProps={{
                        style: { fontSize: "20px", fontWeight: "500", letterSpacing: "0px", fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif' }
                    }}
                    secondary={'PDV'}
                    secondaryTypographyProps={{
                        style: { marginTop: "-5px" }
                    }}
                />
                <MultipleStopIcon   />
                </ListItemButton>
            </List>


        <Menu
            id="lock-button-sistemas"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
       <MenuList disablePadding>
       
       <MenuItem  selected  component="a"  href="/" >
          <ListItemIcon>
            <StorefrontIcon style={{color: SISCHEF_PDV_PRIMARY}}   />
          </ListItemIcon>
            <ListItemText
                    primary="Sischef PDV"
                    primaryTypographyProps={{
                        style: {
                            color: SISCHEF_PDV_PRIMARY                        
                        }
                    }}
                    secondary={'O PDV mais moderno do Brasil'}
                />
          
        </MenuItem>

       <MenuItem component="a" disabled={!acessoRetaguarda}
              href={process.env.REACT_APP_APPLICATION_URL + '/loginToken?token=' + jwtToken} target='_blank'>
          <ListItemIcon>
            <LaptopIcon   />
          </ListItemIcon>
            <ListItemText
                    primary="Sischef retaguarda"
                   
                    secondary={'Sistema de gestão mais inteligente'}
                />
          
        </MenuItem>

        <MenuItem  component="a"
           href={process.env.REACT_APP_SISCHEF_ANALYTICS + '/login?token=' +  jwtToken}
           disabled={!acessoAnalytics}
           target='_blank'>
          <ListItemIcon>
            <InsightsIcon />
          </ListItemIcon>
            <ListItemText
                    primary="Sischef Analytics"
                   
                    secondary={'O BI feito para seu food-service'}
                />
          
        </MenuItem>

        </MenuList>
          
      </Menu>

      </React.Fragment>
    );
}