import ClearIcon from "@mui/icons-material/Clear";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useOrderContext } from "../../../context/OrderProvider";

const SelectOrders = ({ order }) => {
    const { toggleOrder } = useOrderContext();
    const handleOrderRemove = () => {
        toggleOrder(order);
    };
    const address = order.iFoodOrder.deliveryAddress;

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
                <Avatar
                    variant="square"
                    sx={{ width: 28, height: 28 }}
                    alt={order.descricao}
                    sizes="small"
                    src={
                        process.env.PUBLIC_URL +
                        `/static/images/integrations/logo-${order.tipoImportacao}.png`
                    }
                />

                <Box ml="0.5rem">
                    <Box>
                        <Typography fontSize="0.7rem" variant="caption">
                            Pedido:{" "}
                        </Typography>
                        <Typography fontSize="0.7rem" variant="caption" fontWeight="bold">
                            #{order.id}
                        </Typography>
                    </Box>
                    <Typography
                        lineHeight="short"
                        letterSpacing="tight"
                        whiteSpace="normal"
                        style={{ wordBreak: 'break-word' }}                        
                        fontSize="0.7rem"
                        variant="h8"
                    >
                        {(address.streetName || "Sem rua") +
                            ", " +
                            (address.streetNumber || "Sem número") +
                            ", " +
                            (address.neighborhood || "Sem bairro")}
                    </Typography>
                </Box>
            </Box>
            <IconButton onClick={handleOrderRemove} size="small">
                <ClearIcon style={{ width: "0.9rem", height: "0.9rem" }} />
            </IconButton>
        </Box>
    );
};

export default SelectOrders;
