import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import ScannerCam from "../ScannerCam";

const ContentDotCheck = ({
  ordersConfirmed,
  isInvalid,
  setSearchValue,
  isConfirmed,
}) => {
  const columns = useMemo(
    () => Math.ceil(ordersConfirmed.length / 6) * 4,
    [ordersConfirmed.length]
  );

  const iconStatusMap = {
    waiting: <></>,
    confirmed: <CheckIcon fontSize="small" />,
  };

  const confirmationStatus = {
    confirmed: (
      <Typography
        variant="h4"
        color={(theme) => theme.palette.success.main}
        fontWeight="bold"
        align="center"
      >
        <strong> CONFIRMADO </strong>
      </Typography>
    ),
    waiting: (
      <Typography variant="h4" align="center">
        Aguardando...
      </Typography>
    ),
    invalid: (
      <Typography
        color={(theme) => theme.palette.error.main}
        variant="h4"
        align="center"
      >
        <strong> Código inválido. </strong>
      </Typography>
    ),
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      gap="2rem"
      height="100%"
    >
      <Box width="100%" mt="3rem" height="auto">
        <Box
          border="0.25rem solid"
          borderColor={
            isConfirmed
              ? (theme) => theme.palette.success.main
              : isInvalid
              ? (theme) => theme.palette.error.main
              : "transparent"
          }
          width="100%"
          height="auto"
        >
          <ScannerCam setSearchValue={setSearchValue} />
        </Box>
        <Box mt="1rem">
          {isConfirmed
            ? confirmationStatus.confirmed
            : !isInvalid
            ? confirmationStatus.waiting
            : confirmationStatus.invalid}
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="0.5rem"
        mt="2rem"
      >
        <Grid container spacing={2} columns={columns}>
          {ordersConfirmed.map((order, index) => (
            <Grid item xs={4} key={index}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  p="0.2rem"
                  mx="0.5rem"
                  height="1rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="1rem"
                  bgcolor={(theme) =>
                    order.confirmed
                      ? theme.palette.success.main
                      : theme.palette.secondary.main
                  }
                  borderRadius="50%"
                >
                  {iconStatusMap[order.confirmed ? "confirmed" : "waiting"]}
                </Box>
                <Box>
                  <Typography variant="h4">{order.id}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ContentDotCheck;
