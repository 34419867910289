import {
  Dialog,
  DialogContent
} from "@mui/material";
import React from "react";

import DialogTitleOrder from "./DialogTitleOrder";
import DialogCustomerDetails from "./DialogCustomerDetails";
import DialogOrderHeader from "./DialogOrderHeader";
import DialogPaymentDetails from "./DialogPaymentsDetails";
import DialogOrderItems from "./DialogOrderItems";
import DialogTotalValue from "./DialogTotalValue";

const DialogViewOrder = ({ open, onClose, order }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitleOrder onClose={onClose} />
      <DialogContent>
        <DialogOrderHeader order={order} />
        <DialogCustomerDetails order={order} />
        <DialogOrderItems items={order?.iFoodOrder?.items || []} />
        <DialogPaymentDetails order={order} />
        <DialogTotalValue order={order} />
      </DialogContent>
    </Dialog>
  );
};

export default DialogViewOrder;
