import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useState } from 'react';
import { useAppDeliveryManProvider } from "../../../../context/AppDeliveryManProvider";
import { classes, StyledAvatar, StyledTableCell, StyledTableRow } from './parts/styles';
import DialogViewOrder from './parts/DialogViewOrder';
import EmptyOrderReport from "./parts/EmptyOrderReport";

const TableOrders = () => {
  const { orderReporList } = useAppDeliveryManProvider();
  const [orderView, setOrderView] = useState(false);
  const [openView, setOpenView] = useState(false);

  if (!orderReporList.length) return <EmptyOrderReport />;

  const handleViewOrder = (order) => {
    setOrderView(order);
    setOpenView(true);
    
  };
  const handleViewOrderClose = () => {
    setOpenView(false);
  };

  return (
    <>
      <TableContainer
        sx={{ maxHeight: "45rem", position: "relative", zIndex: "1" }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>#Pedido</StyledTableCell>
              <StyledTableCell>Cliente</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderReporList.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  <StyledAvatar
                    variant="rounded"
                    className={classes.avatarEmpresa}
                    alt={row.tipoImportacao}
                    src={
                      process.env.PUBLIC_URL +
                      `/static/images/integrations/logo-${row.tipoImportacao}.png`
                    }
                  ></StyledAvatar>
                </StyledTableCell>
                <StyledTableCell>{row.id}</StyledTableCell>
                <StyledTableCell>
                  {row.iFoodOrder.customer.name}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton onClick={() => handleViewOrder(row)}>
                    <VisibilityIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogViewOrder
        open={openView}
        onClose={handleViewOrderClose}
        order={orderView}
      />
    </>
  );
};

export default TableOrders;
