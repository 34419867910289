import { Box, Button, Checkbox, Dialog, Grid, List, ListItem, ListItemIcon, ListItemText, Tab, Tabs, TextField, styled } from "@mui/material"
import React, { useEffect, useState } from "react"
import { DialogActions, DialogContent, DialogTitle } from "../CustomDialog/dialogComponents"
import { dateFormat } from "../../../util/formatter"
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBRLocale from "date-fns/locale/pt-BR";
import RemoveIcon from '@mui/icons-material/Remove';
import AuthService from "../../../service/AuthService";
import { enqueueSnackbar } from "notistack";
import { extractErrorMessage } from "../../../util/http/HttpUtil";
import { subHours } from "date-fns";

const StyledList = styled(List)(({ theme }) => ({
    '&': theme.scrollBar,
    '.MuiListItem-root:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#594D58' : '#D3D3D3'
    }
}))

export default function InterrupcoesIntegracoes({ open, setOpen, interruptionList, setInterruptionList, idConfiguracaoIntegracao }) {
    const [selectedTab, setSelectedTab] = useState(0)
    const [checked, setChecked] = useState([])
    const [newInterruption, setNewInterruption] = useState({ description: "", start: null, end: null })
    const [disabeButton, setDisableButton] = useState(false)
    const service = new AuthService()

    const onClickClose = () => {
        setOpen(false);
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    const handleToggle = (index) => {
        let copyChecked = Object.assign([], checked);
        copyChecked[index] = !!!checked[index]
        setChecked(copyChecked)
    }

    const handleChangeDescription = (event) => {
        let copyNewInterruption = Object.assign({}, newInterruption)
        copyNewInterruption.description = event.target.value;
        setNewInterruption(copyNewInterruption)
    }

    const handleChangeStart = (event) => {
        let copyNewInterruption = Object.assign({}, newInterruption)
        copyNewInterruption.start = event;
        setNewInterruption(copyNewInterruption)
    }

    const handleChangeEnd = (event) => {
        let copyNewInterruption = Object.assign({}, newInterruption)
        copyNewInterruption.end = event;
        setNewInterruption(copyNewInterruption)
    }

    const onClickCriar = () => {
        setDisableButton(true)
        //remove o timezone offset da interrupção para ficar certo no IFood
        const offset = (new Date()).getTimezoneOffset();
        let copyNewInterruption = Object.assign({}, newInterruption)
        copyNewInterruption.start = subHours(newInterruption.start, ((offset / 60)));
        copyNewInterruption.end = subHours(newInterruption.end, ((offset / 60)));

        service.post(`/api-v2/pedidoIntegracao/createInterruption/${idConfiguracaoIntegracao}`, copyNewInterruption)
            .then(resp => {
                enqueueSnackbar("Interrupção criada com sucesso", { variant: 'success', });
            }).catch(err => {
                extractErrorMessage(err, "Não foi possível realizar esta ação").then(msg => {
                    enqueueSnackbar(msg, { variant: 'error', });
                })
            }).finally(() => {
                setNewInterruption({ description: "", start: null, end: null })
                setDisableButton(false)
            })
    }

    const onClickDeletar = () => {
        setDisableButton(true)
        let interruptionToRemoveList = []

        if (!!!checked || checked.length < 1)
            return

        let copyInterruptionList = Object.assign([], interruptionList)

        //percorre o aray dos itens marcados e adiciona o id dos itens marcados na variável idList
        for (let i = 0; i < checked.length; i++) {
            if (!!checked[i]) {
                interruptionToRemoveList.push(interruptionList[i])
                //remove os itens marcados da cópia da lista de interrupção
                copyInterruptionList = copyInterruptionList.filter(interruption => interruption.id !== interruptionList[i].id)
            }
        }

        if (interruptionToRemoveList.length < 1) {
            setDisableButton(false)
            enqueueSnackbar("Nenhuma interrupção marcada para ser removida", { variant: 'error', });
            return

        }

        service.post(`/api-v2/pedidoIntegracao/deleteInterruption/${idConfiguracaoIntegracao}`, interruptionToRemoveList)
            .then(resp => {
                enqueueSnackbar("Interrupções excluidas com sucesso", { variant: 'success', });
                setInterruptionList(copyInterruptionList)
            }).catch(err => {
                extractErrorMessage(err, "Não foi possível realizar esta ação").then(msg => {
                    enqueueSnackbar(msg, { variant: 'error', });
                })
            }).finally(() => {
                setDisableButton(false)
            })
    }

    useEffect(() => {
        //toda vez que é alterado a lista de interrupções é criado um vetor preenchido com false
        //para utilizar no checkbox
        setChecked(new Array(interruptionList?.lenght).fill(false))
    }, [interruptionList])

    return (
        <Dialog
            maxWidth='xs'
            fullWidth
            open={open}
            onClose={onClickClose}>

            <DialogTitle onClose={onClickClose}>
                Interrupções IFood
            </DialogTitle>

            <DialogContent>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                >
                    <Tab label="Lista de Interrupções" tabIndex={0} />
                    <Tab label="Criar Interrupção" tabIndex={1} />
                </Tabs>

                <Box pt={2}>
                    {selectedTab === 0 ? (
                        <>
                            {(!!interruptionList && interruptionList.length > 0) ? (
                                <StyledList>
                                    {interruptionList.map((interruption, index) =>
                                        <ListItem
                                            key={interruption.id}
                                            onClick={() => handleToggle(index)}
                                            alignItems="flex-start">
                                            <ListItemIcon>
                                                <Checkbox
                                                    tabIndex={-1}
                                                    checked={!!checked[index]}
                                                    onClick={() => handleToggle(index)} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Box component="span">
                                                    {interruption.description}
                                                </Box>}
                                                secondary={`${dateFormat(interruption.start)} - ${dateFormat(interruption.end)}`} />
                                        </ListItem>
                                    )}
                                </StyledList>
                            ) : (
                                <>
                                    Nenhuma interrupção encontrada
                                </>
                            )}
                        </>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField label="Descrição" size="medium"
                                    variant="outlined"
                                    fullWidth
                                    value={newInterruption.description}
                                    name="description"
                                    onChange={handleChangeDescription}
                                />
                            </Grid>
                            <Grid marginTop={'10px'} item xs={5}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                                    <MobileDateTimePicker
                                        size="small"
                                        disablePast
                                        label="Inicio"
                                        name="start"
                                        onChange={handleChangeStart}
                                        value={newInterruption.start} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid marginTop={'10px'} item xs={2} display={"flex"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
                                <RemoveIcon />
                            </Grid>
                            <Grid marginTop={'10px'} item xs={5}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                                    <MobileDateTimePicker
                                        size="small"
                                        disablePast
                                        label="Fim"
                                        name="end"
                                        onChange={handleChangeEnd}
                                        value={newInterruption.end} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    )}
                </Box>

            </DialogContent>
            <DialogActions>
                {selectedTab === 0 ? (
                    <Button
                        disabled={disabeButton}
                        onClick={onClickDeletar}
                        variant='contained'
                        color='primary'>
                        Remover
                    </Button>
                ) : (
                    <Button
                        disabled={disabeButton}
                        onClick={onClickCriar}
                        variant='contained'
                        color='primary'>
                        Criar
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}