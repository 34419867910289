import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import AuthService from '../../../service/AuthService'
import { DialogTitle } from '../../layout-components/CustomDialog/dialogComponents'
import EditIcon from '@mui/icons-material/Edit'
import DeliveryManRegister from './DeliveryManRegister'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { green, red } from '@mui/material/colors'
import { Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material'
import { useDispatch } from 'react-redux'

export default function DeliveryMainDialog({ open, close }) {
    const service = new AuthService()
    const dispatch = useDispatch()
    const profile = service.getProfile()
    const [loading, setLoading] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [deliveryManList, setDeliveryManList] = useState([])
    const [deliveryManSelected, setDeliveryManSelected] = useState()

    const newPessoa = {
        nome: '',
        fone: '',
        email: '',
        tipo: 'F',
        ativo: true,
        cargoFuncionario: 'ENTR',
        estado: profile.pessoa?.estado || null,
        municipio: profile.pessoa?.municipio || null,
        entregadorValorDiaria: 0,
        entregadorValorFixoPedido: 0,
        entregadorPercentualTaxaEntrega: 0,
        entregadorValorMinimo: 0
    }

    function openEditDeliveryManDiolog(deliveryMan) {
        setDeliveryManSelected(deliveryMan)
        setOpenEdit(true)
    }

    function loadDeliveryManList() {
        setLoading(true)
        service.get(`/api-v2/pessoa/entregador`)
            .then(resp => {
                setDeliveryManList(resp)
                dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { listDeliveryMan: resp } })
            })
            .catch(err => console.error('Falha ao buscar entregadores', err))
            .finally(() => setLoading(false))
    }

    function afterSaveDeliveryMan() {
        loadDeliveryManList()
    }

    useEffect(() => {
        if (open) loadDeliveryManList()
        // eslint-disable-next-line
    }, [open])

    let content = null

    if (loading) {
        content = (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        )
    }

    if (!loading) {
        content = (
            <TableContainer style={{ maxHeight: 300, width: '100%' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" display="block">Nome</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6" display="block">Telefone</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="h6" display="block">Situação</Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deliveryManList.map(deliveryMan => (
                            <TableRow key={deliveryMan.id}>
                                <TableCell>
                                    {deliveryMan.nome}
                                </TableCell>
                                <TableCell>
                                    {deliveryMan.fone ? deliveryMan.fone : deliveryMan.celular}
                                </TableCell>
                                <TableCell align="center">
                                    {deliveryMan.ativo ?
                                        <CheckIcon style={{ color: green[500] }} />
                                        :
                                        <CloseIcon style={{ color: red[500] }} />
                                    }
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="large"
                                        aria-label="edit"
                                        onClick={() => openEditDeliveryManDiolog(deliveryMan)}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return <>
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth={true}
            onClose={close}>

            <DialogTitle onClose={close}>
                Entregadores
            </DialogTitle>

            <DialogContent>
                <Box pb={2}>
                    <Button
                        size="small"
                        color="primary"
                        variant='outlined'
                        endIcon={<AddIcon />}
                        onClick={() => openEditDeliveryManDiolog(newPessoa)}>
                        Novo Entregador
                    </Button>
                </Box>

                {content}

            </DialogContent>
        </Dialog>

        <DeliveryManRegister
            deliveryMan={deliveryManSelected}
            open={openEdit}
            setOpen={setOpenEdit}
            afterSave={afterSaveDeliveryMan} />
    </>
}



