import React from 'react';
import { styled } from '@mui/material/styles';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@mui/material';
import AuthService from '../../service/AuthService';
import Tooltip from '@mui/material/Tooltip';


const PREFIX = 'Profile';

const classes = {
  root: `${PREFIX}-root`,
  rootDense: `${PREFIX}-rootDense`,
  avatarSmall: `${PREFIX}-avatarSmall`,
  avatarDense: `${PREFIX}-avatarDense`,
  name: `${PREFIX}-name`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    paddingBottom: theme.spacing(2)
  },

  [`&.${classes.rootDense}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    marginTop: theme.spacing(5)
  },

  [`& .${classes.avatarSmall}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  [`& .${classes.avatarDense}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  [`& .${classes.name}`]: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, dense, ...rest } = props;

  const service = new AuthService()
  const profile = service.getProfile()

  const user = {
    name: profile ? (profile.pessoa?.nome || service.getProfile().nome) : 'NÃO LOGADO',
    avatar: `https://api.dicebear.com/7.x/bottts/svg?seed=${profile.email ? profile.email : 'SEM_EMAIL'}`,
    bio: profile ? (profile.pessoa?.funcao || 'Usuário') : 'NÃO LOGADO',
    companyName: profile ? (profile.unidade?.nome || '--') : '--',
  };

  return (
    <Root
      {...rest}
      className={dense ? classes.rootDense : classes.root} >
      <Tooltip title={dense ? user.name.substring(0, 20) : ''}>
        <Avatar
          alt="Avatar usuário"
          className={dense ? classes.avatarDense : classes.avatarSmall}
          src={user.avatar}
        />
      </Tooltip>
      {!dense && (
        <React.Fragment>
          <Typography
            className={classes.name}
            variant="h6"
            color="primary">
            {user.name.substring(0, 20)}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            align="center"
            style={{ maxWidth: 200 }}>
            {user.companyName}
          </Typography>

        </React.Fragment>

      )}

    </Root>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;