import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import HomeIcon from '@mui/icons-material/Home';
import RouteIcon from '@mui/icons-material/Route';
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { styled } from '@mui/styles';
import React from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.personal.background,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: "-12px",
  left: 0,
  right: 0,
  padding: "16px 0 24px 0",
  backgroundColor: theme.palette.personal.background,
}));

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();
  const currentPage = location.pathname.split("/")[2];
  const handleNavigation = (newValue) => {
    history.push(`/appentregador/${newValue}`);
  };
  return (
    <StyledPaper sx={{ position: "relative", zIndex: "10" }} elevation={3}>
      <StyledBottomNavigation
        value={currentPage}
        onChange={(_, newValue) => handleNavigation(newValue)}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} value="home" />
        <BottomNavigationAction
          label="Rotas"
          icon={<RouteIcon />}
          value="rota"
        />
        <BottomNavigationAction
          label="Relatórios"
          icon={<ContentPasteSearchIcon />}
          value="relatorio"
        />
      </StyledBottomNavigation>
    </StyledPaper>
  );
};

export default Navigation