import { Box, Dialog, DialogActions, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getParamByKey } from "../../../../../component/parameter";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormat } from "../../../../../util/formatter";
import { DialogContent, DialogTitle } from "../../../CustomDialog/dialogComponents";
import { DeleteOutline, Done } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import AuthService from "../../../../../service/AuthService";
import { extractErrorMessage } from "../../../../../util/http/HttpUtil";
import { LoadingButton } from "@mui/lab";

export default function TransferOrder({ open, onClose, orderList, checkboxState }) {

    const destinationIdentifierRef = useRef()
    const generalParameters = useSelector(state => state.generalParameters)
    const service = new AuthService()
    const dispatch = useDispatch()
    const isMounted = useRef(true);

    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString
    const LABEL_IDENTIFICADOR_PRINCIPAL = MODELO_IDENTIFICADOR_PRINCIPAL === 'MESA' ? 'Mesa' : 'Comanda'
    const LABEL_IDENTIFICADOR_SECUNDARIO = MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA' ? 'Mesa' : '--'

    const [removedOrders, setRemovedOrders] = useState([])
    const [loadingTargetTable, setLoadingTargetTable] = useState(false)
    const [isDestinationIdentifierEmpty, setIsDestinationIdentifierEmpty] = useState(true);

    const validCheckBoxes = useMemo(() =>
        Object.entries(checkboxState).reduce((acc, item) => {
            if (item[1]) {
                acc.push(Number(item[0]));
            }
            return acc;
        }, []), [checkboxState]
    )

    const filteredOrders = useMemo(() => orderList?.filter(order =>
        validCheckBoxes.includes(order?.id) && !removedOrders.includes(order?.id)
    ), [orderList, removedOrders, validCheckBoxes])

    const handleRemoveOrder = (orderId) => {
        setRemovedOrders((prev) => [...prev, orderId]);
    };

    const handleIdentifierChange = () => {
        const value = destinationIdentifierRef.current?.value.trim();
        setIsDestinationIdentifierEmpty(!value);
    }

    useEffect(() => {
        if (open) {
            setRemovedOrders([])
            setIsDestinationIdentifierEmpty(true)
        }
    }, [open])

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    async function searchTableBySecondaryIdentifier(identificador) {

        try {
            const response = await service.get(`/api-v2/identificador/tipo/MESA`);
            const availableTables = response || []
            const table = availableTables.find((table) => table.identificador === identificador);

            if (!table) {
                enqueueSnackbar(`Mesa '${identificador}' não encontrada.`, { variant: "error" });
            }

            return table;

        } catch (err) {
            console.error(err);
            const msg = await extractErrorMessage(err, "Não foi possível buscar pedido pelo identificador principal.");
            enqueueSnackbar(msg, { variant: "error" });

            return null;
        }
    }

    const transferAllOrders = async () => {
        const destinationIdentifier = destinationIdentifierRef.current?.value.trim();

        if (!destinationIdentifier) {
            enqueueSnackbar("Por favor, insira o número da mesa de destino.", { variant: "error" });
            return;
        }

        const isAlreadyOnTheTable = filteredOrders.some(order => order.identificadorSecundario === destinationIdentifier);

        if (isAlreadyOnTheTable) {
            enqueueSnackbar("As comandas selecionadas já estão associadas a esta mesa.", { variant: "error" });
            return;
        }

        setLoadingTargetTable(true);

        try {
            if (!filteredOrders || filteredOrders.length === 0) {
                enqueueSnackbar(`Nenhuma ${MODELO_IDENTIFICADOR_PRINCIPAL} selecionada para transferência.`, { variant: "error" });
                return;
            }

            const table = await searchTableBySecondaryIdentifier(destinationIdentifier);

            if (!table) {
                return;
            }

            const ordersCopy = filteredOrders.map((order) => ({
                ...order,
                identificadorSecundario: destinationIdentifier
            }));

            const respOrderList = await service.post('/api-v2/pedidoIntegracao/saveAll', ordersCopy);

            if (isMounted.current) {
                respOrderList.forEach((savedOrder) => {
                    const action = savedOrder.ativo ? 'ADD_OR_UPDATE_GENERIC_FROM_ORDER' : 'REMOVE_ORDER_GENERIC';
                    dispatch({ type: action, newOrder: savedOrder });
                    dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: action, newOrder: savedOrder } });
                });

                enqueueSnackbar(`Comanda(s) transferida(s) para a mesa ${destinationIdentifier}.`, { variant: "success" });
                onClose();
                dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: false, data: null } });
            }

        } catch (err) {
            console.error(err);
            const msg = await extractErrorMessage(err, "Erro ao juntar os pedidos.");
            enqueueSnackbar(msg, { variant: "error" });

        } finally {
            if (isMounted.current) {
                setLoadingTargetTable(false);
            }
        }
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth='md'
                fullWidth
                open={open}
                onClose={onClose}
            >
                <DialogTitle onClose={onClose}>
                    Tranferir comandas para outras mesas
                </DialogTitle>

                <DialogContent>
                    <Box>
                        <TextField
                            label={`Informe o número da ${LABEL_IDENTIFICADOR_SECUNDARIO} para transferência da(s) comandas(s).`}
                            variant="filled"
                            type="number"
                            inputRef={destinationIdentifierRef}
                            onChange={handleIdentifierChange}
                            autoFocus
                            fullWidth
                        >
                        </TextField>
                    </Box>
                    <Table size="small" sx={{ mt: 1 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{LABEL_IDENTIFICADOR_PRINCIPAL}</TableCell>
                                <TableCell align="center">{LABEL_IDENTIFICADOR_SECUNDARIO}</TableCell>
                                <TableCell align="center"> Valor</TableCell>
                                <TableCell align="center">Excluir</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredOrders?.map((order, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row" align="center">
                                            {order?.identificadorPrincipal}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            {order?.identificadorSecundario}
                                        </TableCell>
                                        <TableCell align="center">{currencyFormat(order?.totalPedido)} </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Excluir pedido">
                                                <IconButton onClick={() => handleRemoveOrder(order.id)}>
                                                    <DeleteOutline color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}

                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Tooltip
                        title="Por favor, informe o número da mesa para transferência da(s) comanda(s)."
                        disableHoverListener={!isDestinationIdentifierEmpty}
                        arrow
                    >
                        <span>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={transferAllOrders}
                                disabled={loadingTargetTable || isDestinationIdentifierEmpty}
                                loading={loadingTargetTable}
                                loadingPosition="start"
                                startIcon={<Done />}
                            >
                                {loadingTargetTable ? 'Transferindo' : 'Transferir'}
                            </LoadingButton>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}