import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const StyledBoxHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  height: "8rem",
  width: "100%",
}));

export const StyledSection = styled(Box)({
  textAlign: 'center', // Centraliza o conteúdo dentro da seção
});