import { enqueueSnackbar } from "notistack";
import { formatFullAddressDelivery } from "../../../../../../../util/customer/helper";

export const getShareableLink = async (initialCenter, item, setRouteLink, setIsIframeVisible, setError) => {
    try {
      const origin = `${initialCenter.lat},${initialCenter.lng}`;
      let waypoints = item.pedidos
        .map((pedido) => {
          const { complement, ...addressWithoutComplement } =
            pedido.iFoodOrder?.deliveryAddress || {};
          return (
            formatFullAddressDelivery(addressWithoutComplement).address.toLocaleLowerCase() || ""
          );
        })
        .filter((wp) => wp !== ",");
  
      if (waypoints.length === 0) {
        setError("Nenhum endereço válido encontrado para a rota");
        return;
      }
  
      let destination;
      if (waypoints.length === 1) {
        destination = waypoints[0];
        waypoints = [];
      } else {
        destination = waypoints.pop();
      }
  
      const encodedAddresses = [origin, ...waypoints, destination]
        .map(encodeURIComponent)
        .join("/");
      const shareableUrl = `https://www.google.com/maps/dir/${encodedAddresses}`;
  
      const embedUrl = `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY
        }&origin=${origin}&destination=${destination}${waypoints.length ? `&waypoints=${waypoints.join("|")}` : ""}`;
  
      setRouteLink(embedUrl);
      setIsIframeVisible(true);
      return shareableUrl;
    } catch (error) {
      setError(`Erro ao obter direções: ${error.message}`);
    }
  };
  
  export const handleClick = async (initialCenter, item, setRouteLink, setIsIframeVisible, setError, toggleIframe, setOpen) => {
    await getShareableLink(initialCenter, item, setRouteLink, setIsIframeVisible, setError);
    toggleIframe(item);
    setOpen(true);
  };
  
  export const handleCopyLink = async (initialCenter, item, setRouteLink, setIsIframeVisible, setError) => {
    try {
      const shareableLink = await getShareableLink(initialCenter, item, setRouteLink, setIsIframeVisible, setError);
      if (!shareableLink) {
        enqueueSnackbar("Link da rota não disponível", { variant: "error" });
        return;
      }
  
      await navigator.clipboard.writeText(shareableLink);
      enqueueSnackbar("Link copiado para a área de transferência.", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Erro ao copiar o link.", { variant: "error" });
    }
  };
  
  export const generateWhatsappLink = async (data, initialCenter, setRouteLink, setIsIframeVisible, setError) => {
    const { pedidos, pessoa } = data;
  
    try {
      const shareableLink = await getShareableLink(initialCenter, data, setRouteLink, setIsIframeVisible, setError);
      if (!shareableLink) {
        enqueueSnackbar("Link da rota não disponível", { variant: "error" });
        return;
      }
  
      const pedidosText = pedidos
        .map((pedido) => {
          const clienteNome = pedido?.iFoodOrder?.customer?.name || pedido.pessoaCliente?.nome || "Cliente não informado";
          const pedidoId = pedido.id;
          const produtos = pedido.iFoodOrder.items
            .map((item) => `- ${item.quantity}x ${item.name} (R$ ${item.totalPrice.toFixed(2)})`)
            .join("\n");
  
          const address = pedido.iFoodOrder?.deliveryAddress;
          const addressText = `Endereço: ${address?.streetName || "Rua não informada"}, ${address?.streetNumber || "Número não informado"} - ${address?.neighborhood || "Bairro não informado"} - ${address?.city || "Cidade não informada"}`;
  
          return `*Pedido #${pedidoId}*\nCliente: ${clienteNome}\n\nProdutos:\n${produtos}\n\n${addressText}`;
        })
        .join("\n\n");
  
      const deliveryManName = pessoa?.nome || "Entregador";
      const mensagem = `Olá ${deliveryManName}, \n\n*Pedidos:*\n\n${pedidosText} \n\n\n *Rota:* ${shareableLink}`;
      const whatsappLink = `https://wa.me/?text=${encodeURIComponent(mensagem)}`;
      window.open(whatsappLink, "_blank");
    } catch (err) {
      enqueueSnackbar("Erro ao gerar o link.", { variant: "error" });
    }
  };
  