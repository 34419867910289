import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AuthService from "./service/AuthService";
import Main from "./pages/main";
import Login from "./pages/login";
import Load from "./pages/load";
import Deliveryman from "./pages/deliveryman";
import Settings from "./pages/settings";
import RouteWithLayout from "./pages/layout-components/RouteWithLayout";
import Layout from "./pages/layout-components/Layout";
import PedidosAgendados from "./pages/pedidosAgendados";
import SalesHeatMap from "./pages/report/salesHeatMap";
import ListOrderIdentificador from "./pages/layout-components/ListOrderIdentificador/index";
import Unauthorized from "./pages/unauthorized";
import { ifAnyGranted } from "./util/auth/authUtil";
import BoardKds from "./pages/kds";
import AtendimentoBalanca from "./pages/atendimento-balanca";
import DeliveryManOrder from "./pages/deliveryman/components/DeliveryManOrder";
import Helpdesk from "./pages/helpdesk";
import { FastSale } from "./pages/fastSale";
import JobAssincrono from "./pages/sischefGerencial/jobAssincrono";
import CardapioIntegracao from "./pages/cardapio";
import AppEntregador from "./pages/appEntregador";
import AppDeliveryManLogin from "./pages/appEntregador/modules/Login";
import UnifiedLogin from "./pages/unidied-login";
import { red } from "@mui/material/colors";

export const isAuthorized = (role) => {
  if (role) {
    return ifAnyGranted(role);
  }
  return true;
};

const isAuthenticated = (role) => AuthService.loggedIn();

const PrivateRouteAppDeliveryman = ({
  component: Component,
  unauthorizedUrl,
  role,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        isAuthorized(role) ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: `/unauthorized/${role}`,
              state: { from: props.location },
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/appentregador/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PrivateRoute = ({
  component: Component,
  unauthorizedUrl,
  role,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthenticated()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      if (isAuthorized(role)) {
        return (
          <RouteWithLayout layout={Layout} {...props} component={Component} />
        );
      }

      const redirectPath = isAuthorized("ROLE_ACESSO_APP_ENTREGADORES")
        ? "/appentregador"
        : `/unauthorized/${role}`;

      return (
        <Redirect
          to={{ pathname: redirectPath, state: { from: props.location } }}
        />
      );
    }}
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path="/" component={Main} role="ROLE_PDV_DELIVERY" />
      <PrivateRoute
        exact
        path="/delivery"
        component={Main}
        role="ROLE_PDV_DELIVERY"
      />
      <Route exact path="/login" component={Login} />
      <Route exact path="/unified-login" component={UnifiedLogin} />
      <Route exact path="/helpdesk" component={Helpdesk} />
      <Route exact path="/load/:jwt" component={Load} />
      <Route
        exact
        path="/gerencial/jobAssincrono/:nome/:jwt"
        component={JobAssincrono}
      />
      <PrivateRoute
        exact
        path="/deliveryman"
        component={Deliveryman}
        role="ROLE_PDV_GESTAO_ENTREGADORES"
      />
      <PrivateRoute
        exact
        path="/dispatchDeliveryman"
        component={DeliveryManOrder}
        role="ROLE_PDV_DESPACHO_ENTREGADORES"
      />
      <PrivateRoute
        exact
        path="/dispatchDeliveryman/roteirizacao"
        component={DeliveryManOrder}
        role="ROLE_PDV_DESPACHO_ENTREGADORES"
      />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute
        exact
        path="/agendados"
        component={PedidosAgendados}
        role="ROLE_PDV_PEDIDOS_AGENDADOS"
      />
      <PrivateRoute
        exact
        path="/pedidosIdentificador"
        component={ListOrderIdentificador}
        role="ROLE_PDV_COMANDA_MESA"
      />
      <PrivateRoute
        exact
        path="/salesHeatMap"
        component={SalesHeatMap}
        role="ROLE_MAPA_DE_CALOR"
      />
      <PrivateRoute
        exact
        path="/fastSale"
        component={FastSale}
        role="ROLE_VENDA_RAPIDA_PDV_ONLINE"
      />
      <PrivateRoute
        exact
        path="/integrated-menus"
        component={CardapioIntegracao}
        role="ROLE_PDV_DELIVERY"
      />
      <PrivateRoute path="/unauthorized/:role" component={Unauthorized} />
      <Route
        exact
        path="/appentregador/login"
        component={AppDeliveryManLogin}
      />
      <PrivateRouteAppDeliveryman
        exact
        role="ROLE_ACESSO_APP_ENTREGADORES"
        path="/appentregador"
        component={AppEntregador}
      />
      <PrivateRouteAppDeliveryman
        exact
        role="ROLE_ACESSO_APP_ENTREGADORES"
        path="/appentregador/home"
        component={AppEntregador}
      />
      <PrivateRouteAppDeliveryman
        exact
        role="ROLE_ACESSO_APP_ENTREGADORES"
        path="/appentregador/rota"
        component={AppEntregador}
      />
      <PrivateRouteAppDeliveryman
        exact
        role="ROLE_ACESSO_APP_ENTREGADORES"
        path="/appentregador/relatorio"
        component={AppEntregador}
      />

      {/* Alterar o Role para ROLE_UTILIZA_KDS */}
      <PrivateRoute
        exact
        path="/kds"
        component={BoardKds}
        role="ROLE_UTILIZA_KDS"
      />
      <PrivateRoute
        exact
        path="/atendimento-balanca"
        component={AtendimentoBalanca}
        role="ROLE_AUTOATENDIMENTO_BALANCA_PDV_ONLINE"
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
