import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { addDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import AuthService from '../../../../../../service/AuthService'
import { isAddressValid } from '../../../../../../util/customer/helper'
import { dateFormat } from '../../../../../../util/formatter'
import { useOrderContext } from '../../context/OrderProvider'
import { fetchListByDeliveryPerson } from '../../services/fetchListByDeliveryPerson'
import DispatchList from "./components/DispatchList"
import ResumeListDispatch from './components/ResumeListDispatch'
import { useDispatch, useSelector } from "react-redux";

const OpenRouters = () => {
  const service = new AuthService();
  const profile = service.getProfile();
  const { refetch, setRefetch } = useOrderContext();
  const [error, setError] = useState(null);
  // const [deliveryMan, setDeliveryMan] = useState([])
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [routeLink, setRouteLink] = useState("");
  const [selectedMan, setSelectedMan] = useState(null);
  const initialCenter = {
    lat: profile.unidade.pessoa.latitude || 0,
    lng: profile.unidade.pessoa.longitude || 0,
  };
  const [openResumeList, setOpenResumeList] = useState(false);
  const dispatch = useDispatch();
  const dispatchOrdersData = useSelector((state) => state.dispatchs);

  useEffect(() => {
    const date = new Date();
    const dateFormatted = addDays(date, 1);
    const dtInicio = dateFormat(date, "yyyy-MM-dd");
    const dtFinal = dateFormat(dateFormatted, "yyyy-MM-dd");
    const carregarDados = async () => {
      try {
        const listDespachos = await fetchListByDeliveryPerson(
          dtInicio,
          dtFinal
        );
        dispatch({ type: "LOAD_DISPATCHES", orders: listDespachos });
        //setDeliveryMan(listDespachos);
      } catch (err) {
        setError("Erro ao carregar dados");
      } finally {
        setRefetch(false);
      }
    };

    carregarDados();
    //eslint-disable-next-line
  }, [refetch]);

  function getCustomerNotValidLatLng() {
    return (
      selectedMan?.pedidos.filter((pedido) => {
        const isValidAddress = isAddressValid(
          pedido.iFoodOrder?.deliveryAddress
        );
        return !isValidAddress;
      }) || []
    );
  }

  const toggleIframe = (item) => {
    if (isIframeVisible && selectedMan === item) {
      setIsIframeVisible(false);
      setRouteLink("");
      setSelectedMan(null);
    } else {
      setSelectedMan(item);
    }
  };
  const onClickListAll = () => {
    setOpenResumeList(true);
  };
  const numberOfInvalidAddresses = getCustomerNotValidLatLng().length;

  return (
    <>
      <Box>
        {(initialCenter.lat === 0 || initialCenter.lng === 0) && (
          <Alert severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            Não foi informado as coordenadas geográficas ( latitude e longitude
            ) no cadastro da empresa
          </Alert>
        )}

        {error && (
          <Typography color="error" variant="body1">
            {error}
          </Typography>
        )}

        <DispatchList
          isIframeVisible={isIframeVisible}
          routeLink={routeLink}
          setError={setError}
          deliveryMan={dispatchOrdersData.orders}
          numberOfInvalidAddresses={numberOfInvalidAddresses}
          selectedMan={selectedMan}
          toggleIframe={toggleIframe}
          onClickListAll={onClickListAll}
        />
      </Box>

      <ResumeListDispatch
        open={openResumeList}
        setOpen={setOpenResumeList}
        selectedMan={selectedMan}
      />
    </>
  );
};

export default OpenRouters;
