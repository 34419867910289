import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ErrorIcon from "@mui/icons-material/Error";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLoadEntregadores } from "../../../LoadDeliveryMan";
import { useOrderContext } from "../../context/OrderProvider";
import DeliveryPersonAutocomplete from "./DeliveryPersonAutocomplete";
import {
  generateWhatsappLink,
  handleClick,
  handleCopyLink,
} from "../OpenRouters/utils";
import AuthService from "../../../../../../service/AuthService";
import InvalidAddressAlert from "../OpenRouters/components/InvalidAddressAlert";
import ResumeListDispatch from "../OpenRouters/components/ResumeListDispatch";
import ListIcon from "@mui/icons-material/List";
import { isAddressValid } from "../../../../../../util/customer/helper";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "& svg": {
    fill: theme.palette.secondary.main,
  },
}));

const StyledBoxMap = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .actions": {
    display: "flex",
    position: "absolute",
    right: "10px",
    top: "10px",
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
  },
  "& .iframeContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "450px",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor:
    theme.palette.mode === "light"
      ? "rgb(136, 132, 132)"
      : theme.palette.grey.main,
  borderRadius: "4px",
  padding: "1rem 0.5rem",
  marginBottom: "1rem",
}));

const StyledAddLocationAltIcon = styled(AddLocationAltIcon)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

const OrderRouterDispatch = () => {
  const {
    ordersDispatched,
    handleDispatchCancel,
    handleDispatchConfirm,
    handleEditDispatch,
  } = useOrderContext();
  const [listDeliveryMan] = useLoadEntregadores();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [routeLink, setRouteLink] = useState("");
  const [openResumeList, setOpenResumeList] = useState(false);

  const service = new AuthService();
  const profile = service.getProfile();

  const initialCenter = {
    lat: profile.unidade.pessoa.latitude || 0,
    lng: profile.unidade.pessoa.longitude || 0,
  };

  function converOrder(item) {
    return {
      pedidos: item.orders,
      pessoa: { id: -1 },
    };
  }

  function getCustomerNotValidLatLng() {
    return item?.pedidos.filter(pedido => {
      const isValidAddress = isAddressValid(pedido.iFoodOrder?.deliveryAddress)
      return !isValidAddress
    }) || []
  }
  const numberOfInvalidAddresses = getCustomerNotValidLatLng().length

  function noop() { }

  function handleOpenRoute(item) {
    setItem(item);
    handleClick(initialCenter, item, setRouteLink, noop, noop, noop, setOpen);
  }

  function handleCopy(item) {
    handleCopyLink(initialCenter, item, setRouteLink, noop, noop);
  }

  function handleSendWhatsApp(item) {
    generateWhatsappLink(item, initialCenter, setRouteLink, noop, noop);
  }

  const onClickListAll = () => {
    setOpen(true);
    setOpenResumeList(true);
  };

  return (
    <>
      {ordersDispatched.map((dispatch, index) => (
        <StyledBox key={index}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
          >
            <Box display="flex" alignItems="center">
              <StyledAddLocationAltIcon fontSize="large" />
              <Box ml="1rem">
                <Typography variant="h4">Nova rota</Typography>
                <Typography mt="1rem" variant="caption">
                  Pedidos: {dispatch.orders.map((order) => order.id).join(", ")}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" height="100%" ml="0.2rem">
              <Tooltip title="Editar rota">
                <IconButton
                  onClick={() => handleEditDispatch(index)}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descrição do pedido">
                <IconButton
                  onClick={() => {
                    handleOpenRoute(converOrder(dispatch));
                  }}
                >
                  <DescriptionIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Compartilhar rota por WhatsApp">
                <IconButton
                  onClick={() => {
                    handleSendWhatsApp(converOrder(dispatch));
                  }}
                >
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copiar link da rota">
                <IconButton
                  onClick={() => {
                    handleCopy(converOrder(dispatch));
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              {dispatch.orders.some(
                (pedido) => !isAddressValid(pedido?.iFoodOrder?.deliveryAddress)
              ) && (
                <Box
                  ml="-1.2rem"
                  mt="-3rem"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Tooltip title="Rota não criada. Verifique o erro na descrição.">
                    <ErrorIcon color="error" style={{ cursor: "pointer" }} />
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>

          <Box pt={2} pb={2}>
            <DeliveryPersonAutocomplete
              value={dispatch.deliveryPerson}
              options={listDeliveryMan}
              dispatchId={dispatch.id}
              index={index}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={() => {
                handleDispatchCancel(dispatch.id);
              }}
              fullWidth
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                handleDispatchConfirm(dispatch.id, "AGUARDANDO_RETIRADA");
              }}
              color="primary"
            >
              Criar Rota
            </Button>
          </Box>
        </StyledBox>
      ))}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6">Entregador: Nome não disponível</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item sx={{ mb: 1 }}>
            <Button
              color="grey"
              disabled={!item?.pedidos?.length}
              startIcon={<ListIcon />}
              onClick={onClickListAll}
            >
              Listagem dos pedidos
            </Button>
          </Grid>

          <InvalidAddressAlert
            numberOfInvalidAddress={numberOfInvalidAddresses}
          />

          {!numberOfInvalidAddresses && (
            <StyledBoxMap>
              <Box className="actions">
                <Tooltip title="Compartilhar rota por WhatsApp">
                  <StyledIconButton onClick={() => handleSendWhatsApp(item)}>
                    <WhatsAppIcon />
                  </StyledIconButton>
                </Tooltip>
                <Tooltip title="Copiar link da rota">
                  <StyledIconButton onClick={() => handleCopy(item)}>
                    <ContentCopyIcon />
                  </StyledIconButton>
                </Tooltip>
              </Box>

              <iframe
                title="Rota"
                height="450"
                style={{
                  border: 0,
                  overflow: "hidden",
                  borderRadius: "4px",
                  width: "100%",
                }}
                loading="lazy"
                allowFullScreen
                src={routeLink}
              />
            </StyledBoxMap>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setItem(null);
            }}
            fullWidth
            autoFocus
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <ResumeListDispatch
        open={openResumeList}
        setOpen={setOpenResumeList}
        selectedMan={item}
      />
    </>
  );
};

export default OrderRouterDispatch;
