import { styled } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .actions": {
    display: "flex",
    position: "absolute",
    right: "10px",
    top: "10px",
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
  },
  "& .iframeContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "450px",
  },
}));

export const StyledRoomIcon = styled(RoomIcon)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: theme.palette.mode === "light" ? "rgb(136, 132, 132)" : theme.palette.grey.main,
  borderRadius: "4px",
  padding: "0.5rem 0",
  marginBottom: "1rem",
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "& svg": {
    fill: theme.palette.secondary.main,
  },
}));
