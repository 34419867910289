import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import CheckIcon from '@mui/icons-material/Check'
import PrintIcon from '@mui/icons-material/Print'
import SearchIcon from '@mui/icons-material/Search'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Alert, Box, Button, Checkbox, CircularProgress, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import AuthService from '../../../service/AuthService'
import { getDefaultPeriodFormated } from '../../../helpers/dateHelper'
import CustomDateFilter from '../../../component/generics/customDateFilter'
import { currencyFormat, dateFormat } from '../../../util/formatter'
import { DialogActions, DialogContent, DialogTitle } from '../../layout-components/CustomDialog/dialogComponents'
import { extractErrorMessage } from '../../../util/http/HttpUtil'
import { usePdvContext } from '../../../component/generics/PdvProvider'
import { CircularProgressWithLabelAndBackground } from '../../layout-components/elements/CircularProgressWithLabel'
import { parseISO } from 'date-fns'
import { isSameDaySischef, porcentage } from '../../../util/genericUtil'
import { isPagamentoDinheiro, totalDeliveryFeeOrigin } from '../../../util/order/orderUtil'
import { LoadingButton } from '@mui/lab'
import useCarregarFormasPagamento from '../../../store/paymentOptions'

function getTotalMoney(pedido, pedidoIntegracaoMovimentoFinanceiroList) {
    let result = 0

    for (const pedidoIntegracaoMovimentoFinanceiro of pedidoIntegracaoMovimentoFinanceiroList) {
        if (pedido.id !== pedidoIntegracaoMovimentoFinanceiro.idPedidoIntegracao) {
            continue
        }

        for (const movimentoFinanceiro of pedidoIntegracaoMovimentoFinanceiro.movimentoFinanceiroList) {
            if (!isPagamentoDinheiro(movimentoFinanceiro.conta.tipoConta)) {
                continue
            }

            result += movimentoFinanceiro.valor
        }
    }

    return result
}

// calcula os valores para pagamento do entregador
function calcValues(pessoa, pedidos, pedidoIntegracaoMovimentoFinanceiroList, hasPassedTheValue) {
    const { entregadorValorDiaria = 0, entregadorValorFixoPedido = 0, entregadorPercentualTaxaEntrega = 0, entregadorValorMinimo = 0 } = pessoa

    let valor = 0
    let totalValorEntrega = 0
    for (const pedido of pedidos) {
        valor += getTotalMoney(pedido, pedidoIntegracaoMovimentoFinanceiroList)
        totalValorEntrega += totalDeliveryFeeOrigin(pedido.iFoodOrder)
    }

    const valorMinimo = entregadorValorMinimo
    const diaria = entregadorValorDiaria
    const totalPorEntrega = entregadorValorFixoPedido * pedidos.length
    const totalPorcentagemEntrega = totalValorEntrega * (entregadorPercentualTaxaEntrega / 100)

    const valorPagar = diaria + totalPorEntrega + totalPorcentagemEntrega + valorMinimo

    return {
        valor,
        valorPagar,
        totalValorEntrega,
        valores: {
            diaria,
            totalPorEntrega,
            totalPorcentagemEntrega,
            valorMinimo,
        },
        saldo: (!hasPassedTheValue ? valor : 0) - valorPagar,
    }
}

// retorna os pedidos agrupados por data
function getPedidosByDate(order) {
    const datesArr = order.pedidos.reduce((acc, pedido) => {
        const finded = acc.find(item => isSameDaySischef(parseISO(item.dataPedido), parseISO(pedido.dataPedido)))

        if (finded) {
            finded.pedidos.push(pedido)
            return acc
        }

        acc.push({
            dataPedido: pedido.dataPedido,
            pedidos: [pedido]
        })

        return acc
    }, [])

    return {
        pessoa: order.pessoa,
        pedidos: datesArr
    }
}

function DialogAcerto({ open, acertos, onLoadList, onClose }) {
    const service = new AuthService()
    const { confirmDialog } = usePdvContext()
    const { enqueueSnackbar } = useSnackbar()
    const [paymentForm, setPaymentForm] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const paymentOptions = useSelector(state => state.paymentOptions)
    const carregarFormasPagamento = useCarregarFormasPagamento();

    useEffect(() => {

        if (!!paymentOptions && paymentOptions.length > 0) {
            return
        } else {
            carregarFormasPagamento()
        }

        // eslint-disable-next-line    
    }, [])

    function handleClose() {
        setPaymentForm('')
        onClose()
    }

    function handleChange(event, select) {
        setPaymentForm(select.props.value)
    }

    function handleConfirm() {
        if (!paymentForm) {
            enqueueSnackbar('Nenhuma forma de pagamento informado', { variant: 'error' })
            return
        }

        setIsSaving(true)

        // ao realizar os acertos com o entregador ele enviará apenas os pedidos que não contem movimento financeiro
        const acertosCopy = JSON.parse(JSON.stringify([...acertos]))

        acertosCopy.forEach(acerto => {
            acerto.formaPagamento = paymentForm
            acerto.pedidos = acerto.pedidos.map(item => item.id)
            acerto.pessoaId = acerto.pessoa.id
        })

        service.post('/api-v2/pessoa/acertoEntregador', acertosCopy).then((movimentosFinanceiros) => {
            confirmDialog({
                cancelLabel: 'Não',
                confirmLabel: 'Sim',
                content: 'Deseja imprimir um comprovante deste lançamento?',
            }).then(() => {
                const ids = movimentosFinanceiros.map(item => item.id).join(',')
                window.open(service.domain + '/admin/movimentoFinanceiro/gerarRecibo?idsGerarRecibo=' + ids, '_blank', 'noreferrer')
            }).catch(() => { })

            enqueueSnackbar('Acerto lançado com sucesso!', { variant: 'success' })
            onLoadList()
            handleClose()
        }).catch((err) => {
            extractErrorMessage(err, 'Erro ao lançar acerto do entregador').then(msg => {
                enqueueSnackbar('Não foi possível realizar acerto do entregador: ' + msg, { variant: 'error' })
            })
        }).finally(() => setIsSaving(false))
    }

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                Lançamento financeiro
            </DialogTitle>
            <DialogContent>
                <Box mt={1}>
                    <Typography>
                        Selecione uma forma de pagamento
                    </Typography>
                </Box>
                <Box mt={2}>
                    <FormControl
                        fullWidth
                        size="small"
                        variant="outlined">
                        <InputLabel>Forma de pagamento</InputLabel>
                        <Select
                            label="Forma de pagamento"
                            value={paymentForm}
                            onChange={handleChange}>
                            {paymentOptions?.map((option) => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}>
                                    {option.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={isSaving}
                    loadingPosition="start"
                    variant="contained"
                    onClick={handleConfirm}
                    startIcon={<CheckIcon />}>
                    <span>
                        {isSaving ? 'Processando...' : 'Confirmar'}
                    </span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


const DeliveryManPaymentTableSubItem = memo(function DeliveryManPaymentTableSubItem({ item, onPayment }) {
    const isSaldoNegative = item.saldo < 0
    const color = isSaldoNegative ? 'error' : 'success'

    const valorPagarLabel = []
    valorPagarLabel.push('Diária: ' + currencyFormat(item.valores.diaria))
    valorPagarLabel.push('Total por entrega: ' + currencyFormat(item.valores.totalPorEntrega))
    valorPagarLabel.push('Total porcentagem por entrega: ' + currencyFormat(item.valores.totalPorcentagemEntrega))
    valorPagarLabel.push('Valor mínimo do entregador: ' + currencyFormat(item.valores.valorMinimo))

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center">
                <Typography>
                    {dateFormat(item.dataPedido, 'dd/MM/yyyy')}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {item.pedidos.length}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Typography>
                    {currencyFormat(item.valor)}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                    <Tooltip title={valorPagarLabel.map((item, index) => <div key={index}>{item}</div>)} disableInteractive>
                        <InfoOutlinedIcon fontSize="small" color="disabled" />
                    </Tooltip>
                    <Typography>
                        {currencyFormat(item.valorPagar)}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="right">
                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                    <Tooltip title={isSaldoNegative ? 'Valor a pagar para o entregador' : 'Valor a receber do entregador'} disableInteractive>
                        <InfoOutlinedIcon fontSize="small" color="disabled" />
                    </Tooltip>
                    <Typography color={(theme) => theme.palette[color].main}>
                        {currencyFormat(item.saldo)}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="right">
                <Button
                    variant="outlined"
                    disabled={item.isAcertoDone}
                    onClick={() => onPayment([item])}
                    startIcon={<AttachMoneyIcon />}>
                    Lançar
                </Button>
            </TableCell>
        </TableRow>
    )
})

const DeliveryManPaymentTableItem = memo(function DeliveryManPaymentTableItem({ order, hasPassedTheValue, onPayment }) {
    const [open, setOpen] = useState(false)
    const orderListByDate = useMemo(() => getPedidosByDate(order), [order])

    // cria array com os acertos calculados por data
    const acertosByDate = useMemo(() => orderListByDate.pedidos.map(item => ({
        pedidos: item.pedidos,
        dataPedido: item.dataPedido,
        pessoa: orderListByDate.pessoa,
        isAcertoDone: item.pedidos.every(item => item.idMovimentoFinanceiroAcerto),
        ...calcValues(orderListByDate.pessoa, item.pedidos, order.pedidoIntegracaoMovimentoFinanceiroList, hasPassedTheValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })), [orderListByDate, hasPassedTheValue])

    // soma acertos das datas
    const totalAcertos = useMemo(() => acertosByDate.reduce((acc, item) => {
        Object.keys(item).forEach(key => {
            if (key === 'pedidos' || key === 'dataPedido') {
                return
            }

            if (key === 'isAcertoDone') {
                acc.acertosDone = item[key] ? acc.acertosDone + 1 : acc.acertosDone
                return
            }

            if (key === 'pessoa' || key === 'valores') {
                acc[key] = item[key]
                return
            }

            acc[key] = (acc[key] || 0) + item[key]
        })

        return acc
    }, { acertosDone: 0 }), [acertosByDate])

    const isSaldoNegative = totalAcertos.saldo < 0
    const color = isSaldoNegative ? 'error' : 'success'
    const progressLabel = totalAcertos.acertosDone + '/' + acertosByDate.length
    const porcentageValue = porcentage(totalAcertos.acertosDone, acertosByDate.length)

    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: 0 }}>
                <TableCell component="th" scope="row">
                    <Typography>
                        {orderListByDate.pessoa.nome}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Tooltip title={`Dias acertados ${totalAcertos.acertosDone} de ${acertosByDate.length}`} disableInteractive>
                        <Box>
                            <CircularProgressWithLabelAndBackground value={porcentageValue} label={progressLabel} />
                        </Box>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    <Typography>
                        {currencyFormat(totalAcertos.valor)}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography>
                        {currencyFormat(totalAcertos.valorPagar)}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                        <Tooltip title={isSaldoNegative ? 'Valor a pagar para o entregador' : 'Valor a receber do entregador'} disableInteractive>
                            <InfoOutlinedIcon fontSize="small" color="disabled" />
                        </Tooltip>
                        <Typography color={(theme) => theme.palette[color].main}>
                            {currencyFormat(totalAcertos.saldo)}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell align="right">
                    <Button
                        sx={{ mr: 1 }}
                        variant="outlined"
                        onClick={() => onPayment(acertosByDate)}
                        disabled={totalAcertos.acertosDone === acertosByDate.length}
                        startIcon={<AttachMoneyIcon />}>
                        Lançar
                    </Button>
                    <IconButton onClick={() => setOpen(state => !state)}>
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{ borderBottom: 0 }}>
                <TableCell sx={{ pb: 0, pt: 0, borderBottom: 0, backgroundColor: (theme) => theme.palette.mode === 'light' ? '#f7f7f7' : '#24303a' }} colSpan={6}>
                    <Collapse in={open} unmountOnExit>
                        <Table>
                            <TableHead sx={{ borderBottom: 0 }}>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography>
                                            Data
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            Qtd. de pedidos
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>
                                            Pedidos recebidos em Dinheiro
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>
                                            Valor Pagar Entregador
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>
                                            Saldo
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {acertosByDate.map(item => (
                                    <DeliveryManPaymentTableSubItem
                                        key={item.dataPedido}
                                        item={item}
                                        onPayment={onPayment}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
})

function DeliveryManPaymentTable({ loading, orderList, hasPassedTheValue, onPayment }) {

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" pt={6}>
                <CircularProgress />
            </Box>
        )
    }

    if (!orderList.length) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" pt={6}>
                <Box mb={1}>
                    <WarningAmberOutlinedIcon />
                </Box>
                <Typography variant="h5" mb={0.5}>
                    Nenhum pedido encontrado
                </Typography>
                <Typography>
                    Utilize o filtro para melhores resultados
                </Typography>
            </Box>
        )
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                Entregador
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography>
                                Dias Pagos
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography>
                                Pedidos recebidos em Dinheiro
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography>
                                Valor Pagar Entregador
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography>
                                Saldo
                            </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderList.map(order => (
                        <DeliveryManPaymentTableItem
                            key={order.pessoa.id}
                            order={order}
                            hasPassedTheValue={hasPassedTheValue}
                            onPayment={onPayment} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function AlertNoImported({ loading, pedidosNaoImportados }) {
    if (loading || pedidosNaoImportados === 0) {
        return null
    }

    if (pedidosNaoImportados === 1) {
        return (
            <Alert severity="warning" sx={{ mb: 2 }}>
                Existe {pedidosNaoImportados} pedido que ainda não foi importado.
            </Alert>
        )
    }

    return (
        <Alert severity="warning" sx={{ mb: 2 }}>
            Existem {pedidosNaoImportados} pedidos que ainda não foram importados.
        </Alert>
    )
}

export function DeliveryManPayment() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar()
    const statusMoneyBox = useSelector(state => state.statusMoneyBox.status)
    const [loading, setLoading] = useState(false)
    const [orderList, setOrderList] = useState([])
    const [openAcerto, setOpenAcerto] = useState(false)
    const [showTakeout, setShowTakeout] = useState(false)
    const [hasPassedTheValue, setHasPassedTheValue] = useState(false)
    const [pedidosNaoImportados, setPedidosNaoImportados] = useState(0)
    const [selectedAcerto, setSelectedAcerto] = useState()
    const [selectedPeriod, setSelectedPeriod] = useState(getDefaultPeriodFormated())

    useEffect(() => {
        if (selectedPeriod) {
            loadOrderByDate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePayment = useCallback((acertos) => {
        if (statusMoneyBox !== 'OPEN') {
            enqueueSnackbar('Não é possível realizar o lançamento pois o caixa está fechado.', { variant: 'error' })
            return
        }

        setOpenAcerto(true)
        setSelectedAcerto(acertos)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function loadOrderByDate() {
        if (!selectedPeriod) {
            return
        }

        setLoading(true)

        const query = `dtInicio=${selectedPeriod?.startDate}&dtFinal=${selectedPeriod?.endDate}&showTakeout=${showTakeout}&ignorarSemEntregador=true`
        service.get(`/api-v2/pedidoIntegracao/listPorEntregadorAcerto?${query}`)
            .then(resp => {
                // ordena pelo nome da pessoa
                resp.list.sort((a, b) => a.pessoa.nome.localeCompare(b.pessoa.nome))

                setOrderList(resp.list)
                setPedidosNaoImportados(resp.pedidosNaoImportados)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    function onClickSearch() {
        if (!selectedPeriod) {
            enqueueSnackbar(`Nenhum período informado.`, { variant: 'error' })
            return
        }

        loadOrderByDate()
    }

    function handleChangePeriodo(period) {
        setSelectedPeriod(period)
    }

    function handlePrint() {
        window.open(service.domain + '/admin/relatorio/relatorios?report=vendasPorEntregador&id=54', '_blank', 'noreferrer')
    }

    return (
        <Box pt={2}>
            <Box pb={2}>
                <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">
                    <Grid item xs={12} md={6}>
                        <CustomDateFilter onChange={handleChangePeriodo} />
                    </Grid>

                    <Grid item xs={0} md={2} lg={3} />

                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="outlined"
                            disabled={loading}
                            startIcon={<SearchIcon />}
                            onClick={() => onClickSearch()}>
                            {loading ? 'Buscando...' : 'Buscar'}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" alignItems="center">
                        <Checkbox
                            checked={showTakeout}
                            onClick={() => setShowTakeout(state => !state)} />
                        Mostrar pedidos para retirar
                        <Checkbox
                            checked={hasPassedTheValue}
                            onClick={() => setHasPassedTheValue(state => !state)} />
                        Entregador já repassou o valor em Dinheiro para o restaurante
                    </Grid>
                </Grid>
            </Box>

            <AlertNoImported
                loading={loading}
                pedidosNaoImportados={pedidosNaoImportados} />

            <DeliveryManPaymentTable
                loading={loading}
                orderList={orderList}
                hasPassedTheValue={hasPassedTheValue}
                onPayment={handlePayment} />

            <DialogAcerto
                open={openAcerto}
                onLoadList={loadOrderByDate}
                acertos={selectedAcerto}
                onClose={() => setOpenAcerto(false)} />

            {orderList.length && !loading ? (
                <Box display="flex" justifyContent="flex-end" pt={2}>
                    <Button
                        variant="contained"
                        onClick={handlePrint}
                        startIcon={<PrintIcon />}>
                        Imprimir
                    </Button>
                </Box>
            ) : null}

        </Box>
    )
}