import RouteIcon from "@mui/icons-material/Route";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useAppDeliveryManProvider } from "../../context/AppDeliveryManProvider";
import CustomizedTimeline from "./components/CustomTimeline";
import { StyledBoxHeader } from "./styles";
import ActionsButtons from "./components/ActionsButtons";

const RoutesPage = () => {
  const { selectedOrder } = useAppDeliveryManProvider();

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
    >
      <StyledBoxHeader>
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box
              width="3rem"
              height="3rem"
              display="flex"
              mr="1rem"
              ml="2rem"
              p="0.3rem"
              alignItems="center"
              justifyContent="center"
              bgcolor="white"
              borderRadius="50%"
            >
              <RouteIcon sx={{ color: "primary.main" }} fontSize="large" />
            </Box>
            <Box>
              <Typography color="white" variant="h2">
                Rota
              </Typography>
              <Typography color="white" variant="caption">
                Sistema de entregadores
              </Typography>
            </Box>
          </Box>
        </Box>
      </StyledBoxHeader>

      <Box
        flex="1"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p="1rem"
        pb="8rem"
      >
        <CustomizedTimeline />
      </Box>

      {!selectedOrder.length && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="70%"
            textAlign="center"
          >
            <Typography variant="h4" color="textSecondary" gutterBottom>
              Nenhum pedido selecionado
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Para adicionar um pedido, vá até a página
              <strong> Home</strong> e selecione um pedido.
            </Typography>
          </Box>
        </Box>
      )}
      <ActionsButtons />
    </Box>
  );
};

export default RoutesPage;
