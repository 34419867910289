import React, { memo, useCallback, useMemo, useState } from 'react'
import { Box, ButtonBase, Chip, IconButton, Typography, useMediaQuery } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined'
import { currencyFormat, quantityFormat } from "../../../../util/formatter"
import { InputQuantity } from './InputQuantity'
import { green, grey } from '@mui/material/colors'
import { StatusNotificationItemIcon } from '../pages/StatusItemIcon'
import styled from '@emotion/styled'
import { keyframes } from "@emotion/react"
import { totalItemReal } from '../../../../util/order/orderUtil'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const PREFIX = 'ResumeOrder'

const classes = {
    price: `${PREFIX}-price`,
    subItem: `${PREFIX}-subItem`,
    notSaved: `${PREFIX}-notSaved`,
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`

const StyledListItem = styled(Box)(({ theme }) => ({
    '&': {
        position: 'relative',
        borderBottom: theme.palette.mode === 'dark' ? '1px solid ' + theme.palette.personal.hoverList : '1px solid ' + grey[300],
        borderLeft: '1px solid transparent',
        animation: `${fadeIn} 0.5s forwards`,
    },

    [`&.${classes.notSaved}`]: {
        borderLeft: '1px solid ' + green[500],
    },

    [`& .${classes.price}`]: {
        color: theme.palette.mode === 'dark' ? grey[400] : grey[700],
    },
}))

const StyledSubItemList = styled(Box)(({ theme }) => ({
    '&': {
        margin: `${theme.spacing()} ${theme.spacing(2)} 0 ${theme.spacing(5)}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.mode === 'dark' ? '#364552' : grey[100],
    },
}))

const Value = memo(function Value({ fontSize, fontWeight, color, label, mr, value, negative = false, quantity, className }) {
    const strValue = quantity ? quantityFormat(value) : currencyFormat(value)

    return (
        <Box mr={mr} className={className}>
            <Typography className="Value-label" textAlign="right" color={color} fontSize={8} fontWeight="regular">
                {label}
            </Typography>
            <Typography textAlign="right" color={color} fontSize={fontSize} fontWeight={fontWeight} lineHeight={1}>
                {negative && '-'} {strValue}
            </Typography>
        </Box >
    )
})

const Prices = memo(({ price, discount, totalPrice, isSubItem, hasSubItens }) => (
    <Box display="flex" alignItems="flex-end" marginTop="5px">
        <Value
            mr={1}
            label="Unit."
            fontSize={11}
            value={price}
            className={classes.price} />

        {discount > 0 &&
            <Value
                negative
                mr={1}
                color="error"
                label="Desc."
                fontSize={11}
                value={discount} />}

        {isSubItem
            ? <Value label="Total" value={totalPrice} />
            : <Value
                label={hasSubItens ? 'Total + Subitens' : 'Total'}
                value={totalPrice}
                fontSize={16}
                fontWeight="bold" />}
    </Box>
))

const SubItem = ({ item }) => (
    <Box p={2}>

        <Typography>
            {item.name}
        </Typography>

        <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between">

            <Value quantity label="Qtde" value={item.quantity} />

            <Prices
                isSubItem
                price={item.price}
                discount={item.discount}
                totalPrice={item.totalPrice} />

        </Box>

    </Box>
)

export function ResumeOrderList({ items, moduloNotificacaoAtivos, onToggleMenu, onQuantityChange }) {
    const [showSaved, setShowSaved] = useState(false)
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

    // feito para filtrar os items em apenas uma iteração
    const [itemsSaved, itemsNotSaved] = useMemo(() => {
        const arr = [[], []]

        items.forEach(item => {
            if (!item.notSaved) {
                arr[0].push(item)
                return
            }

            arr[1].push(item)
        })

        return arr
    }, [items])

    const openedNotSaved = showSaved || itemsNotSaved.length === 0 || !isMobile

    function toggle() {
        setShowSaved(state => !state)
    }

    if (!items || items.length === 0) {
        return (
            <Box p={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100%">
                <ShoppingBagOutlinedIcon fontSize="large" />
                <Typography>Nenhum produto adicionado ao pedido</Typography>
            </Box>
        )
    }

    return (
        <>
            {itemsNotSaved.map(item =>
                <ResumeOrderListItem
                    key={item.referenceCode}
                    item={item}
                    moduloNotificacaoAtivos={moduloNotificacaoAtivos}
                    onToggleMenu={onToggleMenu}
                    onQuantityChange={value => onQuantityChange(item, value)} />
            )}

            {itemsSaved.length > 0 && (
                <>
                    <ButtonBase sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', width: '100%', p: 2 }} disableRipple={!isMobile} onClick={toggle}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Chip variant="outlined" size="small" label={itemsSaved.length} />
                            <Typography>Salvos e Confirmados</Typography>
                        </Box>
                        {isMobile && (openedNotSaved ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
                    </ButtonBase>
                    <Box sx={{ display: openedNotSaved ? 'block' : 'none', bgcolor: 'personal.boardList' }}>
                        {itemsSaved.map(item =>
                            <ResumeOrderListItem
                                key={item.referenceCode}
                                item={item}
                                moduloNotificacaoAtivos={moduloNotificacaoAtivos}
                                onToggleMenu={onToggleMenu}
                                onQuantityChange={value => onQuantityChange(item, value)} />
                        )}
                    </Box>
                </>
            )}
        </>
    )
}

export function ResumeOrderListItem({ item, moduloNotificacaoAtivos, onToggleMenu, onQuantityChange }) {
    const hasSubItens = item.subItems && item.subItems.length
    const handleOnQuantityChange = useCallback(value => onQuantityChange(value), [item])

    return (
        <StyledListItem className={item.notSaved ? classes.notSaved : null}>
            <Box
                px={2}
                py={1}
                pb={0}
                display="flex"
                justifyContent="space-between">

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center">

                    <Box display="flex">
                        {moduloNotificacaoAtivos.current.ambos &&
                            <Box mr={1}>
                                <StatusNotificationItemIcon item={item}
                                    impressaoAtivo={moduloNotificacaoAtivos.current.impressao}
                                    kdsAtivo={moduloNotificacaoAtivos.current.kds} />
                            </Box>}
                        <Typography>{item.name}</Typography>
                    </Box>

                    {item.observations &&
                        <Typography color='textSecondary' variant='subtitle1'>
                            {'* ' + item.observations}
                        </Typography>
                    }

                </Box>

                <Box display="flex" alignItems="center">
                    <IconButton
                        size="small"
                        color="secondary"
                        aria-label="opções"
                        aria-haspopup="true"
                        aria-controls="long-menu"
                        onClick={event => onToggleMenu(event, item)}>
                        <MoreHorizIcon />
                    </IconButton>
                </Box>

            </Box>

            {hasSubItens ?
                <StyledSubItemList>
                    {item.subItems.map((sub, index) =>
                        <SubItem key={index} item={sub} />)}
                </StyledSubItemList> : null}

            <Box
                px={2}
                pt={1}
                pb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end">

                <InputQuantity
                    value={item.quantity}
                    onChange={handleOnQuantityChange}
                    disabled={!item.notSaved}
                    onChangeOnce={item.product?.abrirPassos === 'S'} />

                <Prices
                    price={item.price}
                    discount={item.discount}
                    totalPrice={totalItemReal(item)}
                    hasSubItens={hasSubItens} />

            </Box>
        </StyledListItem>
    )
}