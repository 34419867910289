
import { convertToBoard, createCardFromPedido, sortCardBoard } from "../util/board/BoardUtil"

const emptyData = convertToBoard([])


export const initialStateBoard = () => {
  return {
    board: emptyData
  }
}


export const moveCard = (currentState, action) => {
  const currentBoard = currentState.boardState.board
  let changedBoard = Object.assign({}, currentBoard);

  const { draggedListIndex, targetListIndex, draggedIndex, targetIndex } = action.moveOptions

  //console.log("moveCard", draggedListIndex, targetListIndex, draggedIndex, targetIndex )

  const card = changedBoard.lists[draggedListIndex].cards[draggedIndex]
  //console.log("moveCard -> card " + draggedListIndex + " - " + draggedIndex , card )

  //remove card
  changedBoard.lists[draggedListIndex].cards.splice(draggedIndex, 1)
  changedBoard.lists[targetListIndex].cards.splice(targetIndex, 0, card)

  return {
    ...currentState,
    boardState: { board: changedBoard }
  }
}


export const loadBoardFromOrders = (currentState, action) => {

  return {
    ...currentState,
    boardState: { board: sortCardBoard(convertToBoard(action.orders)) }
  }
}




export const loadBoardFromData = (currentState, action) => {
  return {
    ...currentState,
    boardState: { board: action.board }
  }
}

export const updateBoardFromEvents = (currentState, action) => {

  const currentBoard = currentState.boardState.board
  const events = action.events

  const hasEvents = events && events.length > 0

  if (!hasEvents) {
    console.log("Sem eventos novos... Board não atualizado")
    return {
      ...currentState
    }
  }

  let updatedBoard = Object.assign({}, currentBoard)
  events.map(event => {
    updatedBoard = _addOrUpdateFromOrder(updatedBoard, event.pedidoIntegracao);
    return null;
  })

  return {
    ...currentState,
    boardState: { board: sortCardBoard(updatedBoard) }
  }
}


export const addOrUpdateBoardFromOrder = (currentState, action) => {
  const currentBoard = currentState.boardState.board
  //let updatedBoard = Object.assign({}, currentBoard)
  const newOrder = action.newOrder;

  let updatedBoard = _addOrUpdateFromOrder(currentBoard, newOrder);


  return {
    ...currentState,
    boardState: { board: sortCardBoard(updatedBoard) }
  }

}

const _addOrUpdateFromOrder = (currentBoard, newOrder) => {
  let updatedBoard = {
    ...currentBoard,
    lists: currentBoard.lists.map(list => {
      let updatedCards = list.cards.map(card => {
        let cardID = card?.data?.id || "-99999";
        if (newOrder.id === cardID) {
          return { ...card, outOfDate: true };
        }
        return card;
      });

      if (newOrder.situacao === list.id) {
        const newCard = createCardFromPedido(newOrder);
        updatedCards = [...updatedCards, newCard];
        console.log("Card adicionado a lista", list.id);
      }

      return { ...list, cards: updatedCards.filter(card => !card?.outOfDate) };
    })
  };

  return updatedBoard;
};

export const removeOrderFromOrder = (currentState, action) => {
  const currentBoard = currentState.boardState.board
  let updatedBoard = Object.assign({}, currentBoard)
  const newOrder = action.order;
  console.log("INICIOU", newOrder)

  updatedBoard.lists.map(list => {

    //VARRE AS LISTAS EM BUSCA DO CARD
    list.cards.map(card => {

      // console.log("PASSOU", card)
      if (newOrder.id === card.data.id) {
        console.log("ACHOU", newOrder, card)
        //notifica que card precisa ser removido
        card.outOfDate = true
      }
      return null;
    })
    return null;
  })

  /* REMOVE OS CARDS marcados 'desatualizados' */
  updatedBoard.lists.map(list => {
    let cards = list.cards.filter(card => !card.outOfDate)
    list.cards = cards
    return null;
  })

  return {
    ...currentState,
    boardState: { board: updatedBoard }
  }

}
export const filterBoard = (currentState, action) => {
  const currentBoard = currentState.boardState.originalBoard || currentState.boardState.board;

  const comparator = action.filter.comparator;
  const filteredBoard = {
    ...currentBoard,
    lists: currentBoard.lists.map(list => ({
      ...list,
      cards: list.cards.map(card => {
        const newCard = createCardFromPedido(card.data)
        newCard.founded = comparator(newCard)
        return newCard
      })
    }))
  };

  return {
    ...currentState,
    boardState: { board: filteredBoard }
  };
};

