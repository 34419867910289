import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { SITUACOES } from "../../../../../../../util/board/BoardUtil";
import { formatFullAddressDelivery } from "../../../../../../../util/customer/helper";
import { useOrderContext } from "../../../context/OrderProvider";
import { enqueueSnackbar } from "notistack";

const MarkersOrders = React.memo(({ google, map, position, order }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";


  const data = {
    PENDENTE: "dataIntegracao",
    CONFIRMADO: "dataConfirmacao",
    AGUARDANDO_RETIRADA: "dataAguardandoRetirada",
    ENVIADO: "dataEnvio",
    ENTREGUE: "dataEntrega",
  };
  const dataSituacao =
    order && order.situacao && data[order.situacao]
      ? order[data[order.situacao]]
      : null;
  const [dataLastUpdate, setDataLastUpdate] = useState(null);
  useEffect(() => {
    if (dataLastUpdate) return;
    const horaPreparo = new Date(dataSituacao);
    const agora = new Date();
    const diferencaMs = agora - horaPreparo;
    const minutos = Math.floor((diferencaMs / 1000 / 60) % 60);
    const horas = Math.floor(diferencaMs / 1000 / 60 / 60);

    setDataLastUpdate({ minutos: minutos, horas: horas });
  }, [dataSituacao, dataLastUpdate]);
  const { toggleOrder } = useOrderContext();

  const svgMarker = {
    path: "M 12 0 C 7 0 3 4 3 9 c 0 3 1 5 4 9 L 12 24 l 5 -6 C 20 14 21 12 21 9 c 0 -5 -4.03 -9 -9 -9 z M 12 12 A 3 3 0 1 1 12 6 A 3 3 0 1 1 12 12 z",
    fillColor: "blue",
    fillOpacity: 1,
    strokeColor: "white",
    strokeWeight: 1.5,
    rotation: 0,
    scale: 1.5,
    anchor: new google.maps.Point(12, 24),
  };

  const handleMarkerClick = () => {
    if (
      (order.situacao === "AGUARDANDO_RETIRADA" && order.agrupadorDespacho) ||
      (order.situacao !== "AGUARDANDO_RETIRADA" &&
        order.situacao !== "CONFIRMADO")
    ) {
      const msg =
        "Não é possível criar uma nova rota para um pedido que já foi despachado.";

      enqueueSnackbar(msg, { variant: "error" });
    } else {
      toggleOrder(order);
    }
  };

  useEffect(() => {

    if (!position) return null;
    if (google && map) {
      const fillColor =
        (order.situacao === "AGUARDANDO_RETIRADA" ||
          order.situacao === "CONFIRMADO") &&
          !!order.agrupadorDespacho
          ? "#A020F0"
          : SITUACOES.find((item) => item.key === order.situacao)?.color ||
          "#000000"; // Cor padrão caso não encontre

      const textSituation =
        (order.situacao === "AGUARDANDO_RETIRADA" ||
          order.situacao === "CONFIRMADO") &&
          !!order.agrupadorDespacho
          ? "Alocado em Rota"
          : SITUACOES.find((item) => item.key === order.situacao)
            ?.singularName || "Desconhecido";
      const marker = new google.maps.Marker({
        position: position,
        map: map,
        icon: {
          ...svgMarker,
          strokeWeight: isDarkMode ? 2 : 0,
          fillColor: fillColor,
        },
      });

      const name = order?.iFoodOrder?.customer?.name || order?.pessoaCliente?.nome || "Não informado";
      const infoWindowContainer = document.createElement("div");
      infoWindowContainer.innerHTML = `
            <div style="font-family: Arial, sans-serif; max-width: 180px;">
                <p style="margin: 0;"><strong>Pedido:</strong> #${order?.id}</p>
                <p style="margin: 0;"><strong>Tempo decorrido::</strong> ${
        dataLastUpdate?.horas
        }h ${dataLastUpdate?.minutos}m </p>
                <div style="display: flex; align-items: flex-start; margin-top: 8px;">
                    <span style="margin-right: 8px;">
                        <svg fill="black" width="16" height="16" viewBox="0 0 24 24">
                            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"></path>
                        </svg>                        
                    </span>
                    <span>${name}</span>
                </div>
                <div style="display: flex; align-items: flex-start; margin-top: 8px;">
                    <span style="margin-right: 8px;">
                        <svg fill="black" width="16" height="16" viewBox="0 0 24 24">
                            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5"></path>
                        </svg>                        
                    </span>
                    <span>${
        formatFullAddressDelivery(
          order?.iFoodOrder?.deliveryAddress
        ).address
        }</span>
                </div>
                <div style="display: inline-flex; margin: 0.5rem 0; align-items: center;justify-content: center; padding: 5px 15px; background-color: ${fillColor}; border-radius: 16px; font-size: 14px; font-family: Arial, sans-serif; width:100%; color: white; margin-top: 8px;">
                  <span>${textSituation}</span>
                </div>
            </div>
            `;

      const infowindow = new google.maps.InfoWindow({
        content: infoWindowContainer,
        ariaLabel: "Informações do Pedido",
        disableAutoPan: true,
        headerDisabled: true,
      });

      const markerClickListener = google.maps.event.addListener(
        marker,
        "click",
        handleMarkerClick
      );

      const mouseOverListener = google.maps.event.addListener(
        marker,
        "mouseover",
        () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        }
      );

      const mouseOutListener = google.maps.event.addListener(
        marker,
        "mouseout",
        () => {
          infowindow.close();
          setDataLastUpdate(null);
        }
      );

      return () => {
        google.maps.event.removeListener(markerClickListener);
        google.maps.event.removeListener(mouseOverListener);
        google.maps.event.removeListener(mouseOutListener);
        marker.setMap(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    google,
    map,
    position,
    svgMarker,
    isDarkMode,
    order,
    order.agrupadorDespacho,
  ]);

  return null;
});

export default MarkersOrders;
