import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import DeliveryManList from './components/DeliveryManList'
import { Box, Button, Grid, Tab } from '@mui/material'
import DeliveryMainDialog from './components/DeliveryManDialog'
import ReactGA from "react-ga4"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { DeliveryManPayment } from './components/DeliveryManPayment'
import { ifAnyGranted } from '../../util/auth/authUtil'
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
const PREFIX = 'Load'

const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(5),
  }
}))

export default function Load() {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState('1')
  const isROLE_ACERTO_ENTREGADORES = ifAnyGranted('ROLE_ACERTO_ENTREGADORES')

  useEffect(() => {//informa ao analytics que o usuário caiu na pagina de deliveryman
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "deliveryman" });
  }, [])

  function openListDeliveryManDiolog() {
    setOpen(true)
  }

  function closeListDeliveryManDiolog() {
    setOpen(false)
  }

  function handleTab(event, value) {
    setTab(value)
  }

  return (
    <Root className={classes.root}>
      <CssBaseline />

      <Box p={2}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <TabList onChange={handleTab}>
                {/* A aba Entregadores sempre aparece */}
                <Tab label="Entregadores" value="1" />

                {/* A aba Acerto de Entregadores aparece somente se tiver a role */}
                {isROLE_ACERTO_ENTREGADORES && (
                  <Tab label="Acerto de Entregadores" value="2" />
                )}
              </TabList>

              {/* O botão Editar entregadores sempre aparece */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                  onClick={openListDeliveryManDiolog}
                  color="grey"
                  variant="outlined"
                  size="small"
                  fullWidth
                  startIcon={<SportsMotorsportsIcon />}
                >
                  Editar entregadores
                </Button>
              </Box>
            </Box>
          </Box>


          <TabPanel value="1">
            <Container component="main" disableGutters>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={9}>
                  <Typography variant="h2" component="h1">
                    Conferência
                  </Typography>

                  <Typography variant="h5" component="h2">
                    Conferência de pedidos por entregadores
                  </Typography>

                </Grid>
              </Grid>


              <Box paddingTop={2}>
                <DeliveryManList />
              </Box>
            </Container>
          </TabPanel>

          <TabPanel value="2">
            <Container component="main" disableGutters>
              <Typography variant="h2" component="h1">
                Acerto de Entregadores
              </Typography>

              <Typography variant="h5" component="h2">
                Acertos com seus entregadores
              </Typography>

              <DeliveryManPayment />
            </Container>
          </TabPanel>

        </TabContext>
      </Box>

      <DeliveryMainDialog
        open={open}
        close={closeListDeliveryManDiolog} />
    </Root>
  );
}