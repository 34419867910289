import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentIcon from "@mui/icons-material/Payment";
import {
    Chip,
    styled
} from "@mui/material";
  
export const StyledIconCard = styled(PaymentIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "small",
}));

export const StyledIconChange = styled(AttachMoneyIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "small",
}));

export const StyledChipInitOrder = styled(Chip)(({ theme }) => ({
  background: theme.palette.success.main,
  color: "white",
}));
