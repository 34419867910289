import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, Button } from '@mui/material';
import Customer from './index'
import { useSnackbar } from 'notistack';
import { DialogTitle, DialogContent, DialogActions } from "../../CustomDialog/dialogComponents"
import AuthService from '../../../../service/AuthService';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { onlyDigits } from "../../../../util/formatter"
import DoneIcon from '@mui/icons-material/Done'

const PREFIX = 'DialogCustomer'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`&.${classes.scrollBar} .MuiDialog-paperScrollPaper`]: theme.scrollBar
}))

export default function DialogCustomer({ open, onClose, selectedCustomer, confirmCustomer }) {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar()
    const [customer, setCustomer] = useState({})

    useEffect(() => {
        if (!open) return

        if (selectedCustomer) {
            setCustomer(selectedCustomer)
        } else {
            const profile = service.getProfile()

            setCustomer({
                tipo: 'F',
                nome: String('').toUpperCase(),
                municipio: profile?.unidade?.pessoa?.municipio,
                codigoReferencial: 'SV2' + (new Date().getTime()) + parseInt(Math.random() * 99999)
            })
        }
    }, [open])

    function handleSubmit() {
        console.log("Salvando pessoa", customer)

        let customerCopy = Object.assign({}, customer)
        customerCopy.cpfCnpj = onlyDigits(customer.cpfCnpj)
        customerCopy.cep = onlyDigits(customer.cep)
        customerCopy.fone = onlyDigits(customer.fone)
        customerCopy.tipoCliente = true

        service.post("/api-v2/pessoa", customerCopy)
            .then(pessoaSalva => {
                setCustomer(pessoaSalva)
                onClose()
                enqueueSnackbar("Salvo com sucesso", { variant: 'success' });
                confirmCustomer(pessoaSalva)

            }).catch(err => {
                console.log(err)
                enqueueSnackbar("Erro ao salvar cliente", { variant: 'error' });
            })
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            onClose()
        }
    }

    return (
        <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="md" className={classes.scrollBar}>

            <DialogTitle onClose={handleClose} >
                Cadastro do cliente
            </DialogTitle>

            <ValidatorForm
                onSubmit={handleSubmit}
                instantValidate={false}
                onError={errors => console.log(errors)}>

                <DialogContent>
                    <Customer customer={customer} setCustomer={setCustomer} />
                </DialogContent>

                <DialogActions>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        endIcon={<DoneIcon />}>
                        Salvar
                    </Button>
                </DialogActions>

            </ValidatorForm>

        </StyledDialog>
    )
}