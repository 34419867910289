import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import md5 from "md5"; // Certifique-se de ter essa lib instalada
import { enqueueSnackbar } from "notistack"; // Certifique-se de usar corretamente
import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // Import do useHistory
import AuthService from "../../../../service/AuthService"; // Certifique-se de importar corretamente
import { extractErrorMessage } from "../../../../util/http/HttpUtil";
import { StyledBox } from "./style";

export default function AppDeliveryManLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({ username: "", password: "" });
  const [sending, setSending] = useState(false);
  const history = useHistory();

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleRedirect = () => {
    history.push("/appentregador");
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSending(true);

    const { username, password } = values;
    const passMD5 = md5(password);

    try {
      const service = new AuthService();
      const response = await service.login(username, passMD5);

      if (response) {
        enqueueSnackbar("Logado com sucesso", { variant: "success" });
        handleRedirect();
      }
    } catch (err) {
      console.error("Erro ao realizar login", err);
      setSending(false);

      extractErrorMessage(err, "Falha ao realizar login").then((msg) =>
        enqueueSnackbar(msg, { variant: "error" })
      );
    }
  };

  return (
    <StyledBox>
      <Box padding="2rem" maxWidth="30rem" width="100%">
        <Typography component="h1" variant="h2">
          Faça seu login
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          <FormControl variant="outlined" fullWidth sx={{ marginTop: "1rem" }}>
            <InputLabel htmlFor="outlined-adornment-username">
              Usuário
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-username"
              type="text"
              value={values.username}
              onChange={handleChange("username")}
              label="Usuário"
              sx={{
                "&:-webkit-autofill": {
                  boxShadow: "0 0 0px 1000px white inset !important",
                  WebkitTextFillColor: "black !important",
                  caretColor: "black !important",
                  borderRadius: "inherit",
                },
              }}
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth sx={{ marginTop: "1rem" }}>
            <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              sx={{
                "&:-webkit-autofill": {
                  boxShadow: "0 0 0px 1000px white inset !important",
                  WebkitTextFillColor: "black !important",
                  caretColor: "black !important",
                  borderRadius: "inherit",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Senha"
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={sending}
            sx={{
              borderRadius: "0.5rem",
              padding: "0.5rem",
              marginTop: "2rem",
            }}
          >
            {sending ? "Aguarde..." : "Acessar"}
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" pt={4}>
          <Box mr="0.5rem">
            <img
              src={process.env.PUBLIC_URL + "/logo-sischef.svg"}
              style={{ width: "2rem" }}
              alt="Logo do Sischef"
            />
          </Box>
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            Sischef
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  );
}
