import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ptBRLocale from "date-fns/locale/pt-BR";
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { getDefaultPeriodList } from '../../../helpers/dateHelper';
import { addDays, setHours } from 'date-fns';
import debounce from 'lodash/debounce';
import { dateFormat, dateFormatedToNewFormat } from '../../../util/formatter';

function CustomDateFilter({ onChange, apiFilter, onChangePeriodo }) {
    const periodOptions = getDefaultPeriodList();
    const [openFilterCustom, setOpenFilterCustom] = useState(false)


    const periodKey = apiFilter?.periodKey ? apiFilter?.periodKey : 5

    const [defaultPeriod, setDefaultPeriod] = useState(periodOptions.filter(elem => elem.key === periodKey)[0])
    const [filterSelected, setFilterSelected] = useState(defaultPeriod)
    const [selectedDate, setSelectedDate] = useState({
        startDate: defaultPeriod.startDate,
        endDate: defaultPeriod.endDate,
        periodKey: defaultPeriod.key
    })
    let datas = apiFilter?.dataPedido?.split(',')

    console.log('datas', datas)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeDate = (date, name) => {
        if (!date) return;

        let updatedDate = setHours(date, date.getHours());

        if (filterSelected.label === "Personalizado") {
            if (name === 'endDate' && updatedDate === selectedDate.startDate) {
                updatedDate = setHours(addDays(date, 1), 4);
            }
        }

        setSelectedDate({ ...selectedDate, [name]: updatedDate });
    }

    const deboucedOnChangeDate = useMemo(() => debounce(handleChangeDate, 300), [handleChangeDate])

    const initialRenderFilterSelected = useRef(true);
    useEffect(() => {

        if (initialRenderFilterSelected.current) {
            initialRenderFilterSelected.current = false;
        } else {

            if (filterSelected) {
                const { key, label, startDate, endDate } = filterSelected

                if (label === 'Personalizado') {
                    setOpenFilterCustom(true)
                } else {
                    setOpenFilterCustom(false)
                }

                setSelectedDate({ startDate, endDate, periodKey: key })
                setDefaultPeriod(filterSelected)
            }
        }


    }, [filterSelected])

    const handleChangeSelect = (event) => {
        const keySelected = event.target.value

        // eslint-disable-next-line
        if (keySelected == "Personalizado") {
            let startDate = setHours(new Date(), 4)
            let endDate = setHours(addDays(new Date(), 1), 4)

            setSelectedDate({ startDate, endDate, periodKey: keySelected });
        }

        //localiza na lista de periodos o elemento selecionado pela chave
        setFilterSelected(periodOptions.find(elem => elem.key === keySelected))

        if (onChangePeriodo) {
            const a = periodOptions.find(item => item.key === keySelected)

            onChangePeriodo(a.label)
        }
    }

    const initialRenderSelectedDate = useRef(true);
    useEffect(() => {
        if (initialRenderSelectedDate.current) {
            initialRenderSelectedDate.current = false;
        } else {
            if (selectedDate?.startDate && selectedDate?.endDate) {
                const newSelectedDate = { ...selectedDate }

                newSelectedDate.startDate = `${dateFormat(newSelectedDate.startDate, 'yyyy-MM-dd HH:mm:ss')}`
                newSelectedDate.endDate = `${dateFormat(newSelectedDate.endDate, 'yyyy-MM-dd HH:mm:ss')}`

                onChange(newSelectedDate)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    return (
        <Box sx={{ mx: "auto" }}>
            <FormControl variant="filled" fullWidth size="small">
                <InputLabel>Filtrar por período</InputLabel>
                <Select
                    id="periodSelect"
                    value={defaultPeriod.key ?? ''}
                    onChange={handleChangeSelect}>
                    {periodOptions.map((option, index) => (
                        <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                    ))}
                </Select>

                {openFilterCustom &&
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                        <Box
                            gap={2}
                            display="flex"
                            sx={{ mt: 2 }}>
                            <Box >
                                <DateTimePicker
                                    disableToolbar
                                    inputFormat="dd/MM/yyyy HH:mm"
                                    margin="normal"
                                    id="startDate"
                                    name="startDate"
                                    size='small'
                                    label="Período inicial"
                                    value={selectedDate.startDate}
                                    onChange={(date, context) => {
                                        if (context.validationError == null)
                                            deboucedOnChangeDate(date, 'startDate')
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período inicial',
                                    }}
                                />
                            </Box>
                            <Box>
                                <DateTimePicker
                                    disableToolbar
                                    inputFormat="dd/MM/yyyy HH:mm"
                                    margin="normal"
                                    size='small'
                                    id="endDate"
                                    name="endDate"
                                    label="Período final"
                                    value={selectedDate.endDate}
                                    onChange={(date, context) => {
                                        if (context.validationError == null)
                                            deboucedOnChangeDate(date, 'endDate')
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período final',
                                    }}
                                />

                            </Box>
                        </Box>
                    </LocalizationProvider>
                }
            </FormControl>
            <Typography
                variant='body2'
                textAlign="center"
                sx={{
                    margin: 'auto',
                    opacity: 0.6,
                    p: 1,
                }}
            >
                O filtro selecionado é válido no período entre
                {dateFormatedToNewFormat(selectedDate.startDate, 'ISO', " HH:mm 'de' dd/MM/yyyy")}, até
                {dateFormatedToNewFormat(selectedDate.endDate, 'ISO', " HH:mm 'de' dd/MM/yyyy")}.
            </Typography>
        </Box>
    )
}

export default CustomDateFilter;