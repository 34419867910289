import {
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Dialog,
    ListItemText,
    Typography,
    IconButton,
    TablePagination,
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
    InputAdornment,
    Box,
    Switch,
    Menu,
    MenuItem,
    Divider,
    FormControl,
    InputLabel,
    Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import CustomDateFilter from '../../../component/generics/customDateFilter';
import AuthService from '../../../service/AuthService';
import { extractErrorMessage } from '../../../util/http/HttpUtil';
import { useSnackbar } from 'notistack';
import { DialogTitle, DialogContent } from "../CustomDialog/dialogComponents"
import { currencyFormat, dateFormat } from '../../../util/formatter';
import IntegrationAvatar from '../Board/IntegrationAvatar';
import TypeOrderAvatar from '../Board/typeOrderAvatar';
import { isPaymentValid, totalOrder } from '../../../util/order/orderUtil';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchIcon from '@mui/icons-material/Search';
import { createCardFromPedido, getTypeOrderBykey, SITUACOES, TIPOS_PEDIDOS } from '../../../util/board/BoardUtil';
import { useDispatch, useSelector } from 'react-redux';
import { getParamByKey } from '../../../component/parameter';
import { Skeleton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DialogActions from '@mui/material/DialogActions';
import findIndex from 'lodash/findIndex';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { addDays, subHours } from 'date-fns';

const PREFIX = 'ListAllOrder'

const classes = {
    pedidoImportado: `${PREFIX}-pedidoImportado`,
    deleteBtn: `${PREFIX}-deleteBtn`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.pedidoImportado}`]: {
        opacity: "0.4"
    },

    [`& .${classes.deleteBtn}`]: {
        color: theme.palette.error.main
    },

    '& .MuiTableRow-root.Mui-selected': {
        backgroundColor: 'rgba(116, 127, 134, 0.16)'
    },
}))

export default function ListAllOrder() {

    const service = new AuthService()
    const [orderList, setOrderList] = useState()
    const { enqueueSnackbar } = useSnackbar()
    const startDate = `${dateFormat(subHours(new Date(), 6), 'yyyy-MM-dd')} 06:00:00`
    const endDate = `${dateFormat(addDays(subHours(new Date(), 6), 1), 'yyyy-MM-dd')} 06:00:00`
    const [selectedPeriod, setSelectedPeriod] = useState({ startDate, endDate })
    const [finding, setFinding] = React.useState(true)
    const dispatch = useDispatch()
    const [page, setPage] = useState(parseInt(0))
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [orderCount, setOrderCount] = useState(0)
    const [tiposPedido, setTiposPedido] = useState(TIPOS_PEDIDOS)
    const [mostrarExcluidos, setMostrarExcluidos] = useState(false)
    const [pedidosNaoImportados, setPedidosNaoImportados] = useState(null)
    const [pedidosNaoPagos, setPedidosNaoPagos] = useState(false)
    const [pedidosPagos, setPedidosPagos] = useState(false)
    const [pedidosPagosOnline, setPedidosPagosOnline] = useState(false)
    const [typesSelected, setTypesSelected] = useState([])
    const generalParameters = useSelector(state => state.generalParameters)
    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString
    const inputTextFilterRef = useRef(null)
    const [inputTextFilterValue, setInputTextFilterValue] = useState('')
    const [anchorElFiltros, setAnchorElFiltros] = useState(null)
    const [selected, setSelected] = useState([])
    const [situacaoPedidos, setSituacaoPedidos] = useState()

    const open = useSelector(state => state.dialogListAllOrder.open)

    const setOpen = (open) => {
        dispatch({ type: 'TOOGLE_DIALOG_ALL_ORDER', dialogListAllOrder: { open } })
    }

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {

            const newSelecteds = orderList.map((n) => n.id);
            setSelected(newSelecteds);

            return;
        }

        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleClickCheckbox = (event, id) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = Object.assign([], selected);

        if (!event.target.checked && selectedIndex >= 0) {
            newSelected.splice(selectedIndex, 1)
        } else if (event.target.checked && selectedIndex < 0) {
            newSelected.push(id)
        }

        setSelected(newSelected);
    };

    const importarTodosOsPedidos = () => {

        let idParaSeremImportados = []
        let orderNotPayed = []

        selected.map((idOrder) => {
            // eslint-disable-next-line
            const orderTemp = orderList.find((order) => order.id == idOrder)

            if (orderTemp) {
                if (!orderTemp.dataImportacao) {
                    if (!isPaymentValid(orderTemp.iFoodOrder)) {
                        enqueueSnackbar(`Existe uma diferença entre o valor do pagamento e o valor total do pedido`, { variant: 'error' });
                        orderNotPayed.push(orderTemp)
                    } else {
                        idParaSeremImportados.push({ id: idOrder })
                    }
                }
            }
            return null;
        });

        if (orderNotPayed.length > 0) {
            let msg = `${orderNotPayed.length} pedidos não poderm ser importados porque ainda não foram pagos. Pedidos ( ${orderNotPayed.map(order => ' ' + order.id)} )`

            enqueueSnackbar(msg, { variant: "warning" });
            return
        }

        let orderListCopy = Object.assign([], orderList)

        setFinding(true)
        service
            .post(`/api-v2/pedidoIntegracao/importarTodos`, idParaSeremImportados)
            .then(jsonPedidoImportadoList => {

                let countErros = 0
                let countSuccess = 0
                let erros = []

                jsonPedidoImportadoList.map(jsonPedidoImportado => {

                    if (jsonPedidoImportado.msgErro) {
                        erros.push(jsonPedidoImportado.msgErro)
                        countErros += 1
                    } else {
                        let pedidoIntegracao = jsonPedidoImportado.pedidoIntegracao

                        let indexPedido = findIndex(orderListCopy, function (elem) {
                            return elem.id === pedidoIntegracao.id
                        })

                        if (indexPedido >= 0) {
                            orderListCopy[indexPedido] = pedidoIntegracao
                        }
                        countSuccess += 1

                        //ATUALIZAR BOARD OU MESAS/COMANDAS
                        dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao })
                        dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: pedidoIntegracao } })

                    }
                    return null;
                })

                if (countErros > 0) {
                    enqueueSnackbar(`${countErros} pedidos não foram importados`, { variant: "error" });
                }

                if (countSuccess > 0) {
                    enqueueSnackbar(`${countSuccess} pedidos foram importados com sucesso`, { variant: "success" });
                }

                setOrderList(orderListCopy)
                setSelected([]);
            }).catch(err => {
                console.error("Erro", err)
                extractErrorMessage(err, "Não foi possível importar os pedidos.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            }).finally(() => {
                setFinding(false)
            })
    }

    const searchOrder = () => {

        let filter = ''

        getFilter().map((f) => {
            if (filter !== '') {
                filter += '&'
            }

            filter += f
            return null;
        })

        setFinding(true)

        service
            .request(`/api-v2/pedidoIntegracao/list?${filter}`)
            .then(response => {
                setOrderCount(parseInt(response.headers.get('totalCount'), 10))

                response.json()
                    .then(
                        retorno => {
                            setOrderList(retorno);
                        }
                    )

                setFinding(false)
            }).catch(err => {
                setFinding(false)
                console.error(err)
                extractErrorMessage(err, "Não foi possível buscar os pedidos.")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })
            })
    }

    useEffect(() => {
        onConstructor()

        if (open) {
            searchOrder()
        }

        // eslint-disable-next-line 
    }, [open])

    function getFilter() {

        let filter = []

        if (selectedPeriod) {
            filter.push(`dataPedido=${selectedPeriod?.startDate},${selectedPeriod?.endDate}`)
        }

        if (rowsPerPage >= 0) {
            filter.push(`max=${rowsPerPage}`)
        }

        if (page >= 0) {
            filter.push(`first=${rowsPerPage * page}`)
        }

        let types = '' //inicia a variavel types com '', ela irá receber os tipos de busca da checkbox
        typesSelected.map((type) => {
            if (types !== '') { // caso types já tenha algo inserido é inserido também uma virgula para separar os itens
                types += ','
            }
            types += type // concatena o valor dos tipos quando tem mais de um tipo selecionado
            return null;
        })

        if (types !== '') {
            filter.push(`tipo=${types}`)
        }

        if (inputTextFilterValue !== '') {
            filter.push(`text=${inputTextFilterValue}`)
        }

        if (!!situacaoPedidos) {
            filter.push(`situacao=${situacaoPedidos}`)
        }

        if (pedidosNaoImportados) {
            filter.push(`importados=false`)
        }

        if (pedidosNaoPagos) {
            filter.push(`pago=false`)
        }
        if (pedidosPagos) {
            filter.push(`pago=true`)
        }
        if (pedidosPagosOnline) {
            filter.push(`pagoOnline=true`)
        }

        if (mostrarExcluidos) {
            //nao inclui filro (mostra todos)
        } else {
            filter.push(`ativo=true`)
        }

        return filter
    }

    const handleChangePeriodo = (period) => {
        setSelectedPeriod(period)
    };

    function onConstructor() {
        setOrderList()
        setFinding(true)

        if (MODELO_IDENTIFICADOR_PRINCIPAL) {
            let tiposCopy = Object.assign([], tiposPedido);

            if (MODELO_IDENTIFICADOR_PRINCIPAL === 'MESA') {
                tiposCopy = tiposCopy.filter(type => (type.key !== 'COMANDA'))
            } else if (MODELO_IDENTIFICADOR_PRINCIPAL === 'COMANDA') {
                tiposCopy = tiposCopy.filter(type => (type.key !== 'MESA'))
            }

            setTiposPedido(tiposCopy)
        }
    }

    const handleClose = () => {
        onConstructor()
        handleLimparBusca()
        setOpen(false);
    };

    const handleLimparBusca = () => {
        /*Função utilizada para limpar a busca de pedidos quando é acessado a tela novamente*/

        setPage(0)
        setRowsPerPage(10)
        setOrderCount(0)
        setTiposPedido(TIPOS_PEDIDOS)
        setTypesSelected([])
        setPedidosNaoImportados(null)
        setPedidosPagos(false)
        setPedidosNaoPagos(false)
        setPedidosPagosOnline(false)
        setAnchorElFiltros(null)
        setSituacaoPedidos()
        setSelectedPeriod({ startDate, endDate })
        searchOrder()
    }

    const onClickCancel = () => {
        handleClose()
    }

    const onClickOpenDetailOrder = (event, order) => {
        if (order) {
            let cardFromOrder = createCardFromPedido(order)
            dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: cardFromOrder } })
        }

        event.stopPropagation()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        //searchOrder()
    };

    useEffect(() => {
        //setPage(0);
        if (open) {
            searchOrder()
        }
        // eslint-disable-next-line
    }, [rowsPerPage, page])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    const handleChangeType = (tipo) => {

        const newTipos = tiposPedido.map((tipos) => // dentro dos tipos de pedido atualiza o tipo selecionado com o valor checked
            tipos.key === tipo.key
                ? { ...tipos, checked: !tipo.checked }
                : tipos
        );

        setTiposPedido(newTipos)

        const name = tipo.key//tipo selecionadp
        const value = !tipo.checked//alteração de valor checked do tipo selecionado

        let typesSelectedCopy = Object.assign([], typesSelected) // recebe a lista de tipos selecionados e faz uma cópia

        if (value) { //se o valor for true insere na lista o tipo selecionado
            typesSelectedCopy.push(name)
        } else {// se o valor for false remove da lista o tipo selecionado
            typesSelectedCopy.splice(typesSelectedCopy.indexOf(name), 1);

        }

        setTypesSelected(typesSelectedCopy)
    }

    const onClickBuscar = () => {
        setPage(0);
        searchOrder()
    }

    const handleKeyDownInputTextFilter = (event) => {
        if (13 === event.keyCode) {
            handleEnterInputTextFilter(event)
        }
    }

    const handleChangeInputTextFilter = (event) => {
        setInputTextFilterValue(event.target.value)
    }

    const handleOnFocusInputTextFilter = () => {
        if (inputTextFilterRef.current) {
            inputTextFilterRef.current.select()
        }
    }

    const handleEnterInputTextFilter = () => {
        setInputTextFilterValue(inputTextFilterRef.current.value)
        searchOrder()
    }

    const handleChangeSelect = (event) => {
        setSituacaoPedidos(event.target.value)
    }

    return (
        <StyledDialog
            maxWidth='lg'
            fullWidth
            open={open}
            onClose={onClickCancel}>

            <DialogTitle onClose={onClickCancel}>
                Listagem de pedidos
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                >
                    <Grid item>
                        <Grid container spacing={2} direction='row' alignItems='flex-start' >
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size="small"
                                    label={`${getTypeOrderBykey(MODELO_IDENTIFICADOR_PRINCIPAL).descricao} ou seq ou nome`}
                                    placeholder="Pressione 'Enter' para buscar"
                                    variant="filled"
                                    autoFocus
                                    fullWidth
                                    inputRef={inputTextFilterRef}
                                    onKeyDown={handleKeyDownInputTextFilter}
                                    onChange={handleChangeInputTextFilter}
                                    onFocus={handleOnFocusInputTextFilter}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <IconButton edge="start" size="small" onClick={handleEnterInputTextFilter} >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>,
                                    }} />

                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <CustomDateFilter onChange={handleChangePeriodo} />
                            </Grid>

                            <Grid item xs={5} sm={2}>

                                <Button
                                    color="grey"
                                    onClick={event => setAnchorElFiltros(event.currentTarget)}
                                    endIcon={<KeyboardArrowDownIcon />}>
                                    Filtros
                                </Button>
                                <Menu
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    anchorEl={anchorElFiltros}
                                    open={Boolean(anchorElFiltros)}
                                    onClose={event => setAnchorElFiltros(null)}
                                >

                                    {tiposPedido.map((tipo) => (
                                        <MenuItem disableRipple key={tipo.key} >
                                            <FormControlLabel value={tipo.sigla} control={<Checkbox name={tipo.key} checked={tipo.checked} onChange={() => { handleChangeType(tipo) }} size='small' />}
                                                label={<Typography variant='button'>{tipo.descricao}</Typography>}
                                            />
                                        </MenuItem>
                                    ))}

                                    <Divider sx={{ my: 0.5 }} />
                                    <MenuItem disableRipple>
                                        <ListItemText primary="Não importados" />
                                        <Switch
                                            color="primary"
                                            checked={pedidosNaoImportados}
                                            name="pedidosNaoImportados"
                                            onChange={event => setPedidosNaoImportados(event.target.checked)} />
                                    </MenuItem>

                                    <Divider sx={{ my: 0.5 }} />

                                    <MenuItem disableRipple>
                                        <ListItemText primary="Pagos" />
                                        <Switch
                                            color="primary"
                                            checked={pedidosPagos}
                                            name="pedidosPagos"
                                            onChange={event => setPedidosPagos(event.target.checked)} />
                                    </MenuItem>

                                    <MenuItem disableRipple>
                                        <ListItemText primary="Pagos Online" />
                                        <Switch
                                            color="primary"
                                            checked={pedidosPagosOnline}
                                            name="pedidosPagosOnline"
                                            onChange={event => setPedidosPagosOnline(event.target.checked)} />
                                    </MenuItem>

                                    <MenuItem disableRipple>
                                        <ListItemText primary="Não Pagos" />
                                        <Switch
                                            color="primary"
                                            checked={pedidosNaoPagos}
                                            name="pedidosNaoPagos"
                                            onChange={event => setPedidosNaoPagos(event.target.checked)} />
                                    </MenuItem>

                                    <Divider sx={{ my: 0.5 }} />

                                    <MenuItem disableRipple>
                                        <ListItemText primary="Mostrar excluídos" />
                                        <Switch
                                            color="primary"
                                            checked={mostrarExcluidos}
                                            name="mostrarExcluidos"
                                            onChange={event => setMostrarExcluidos(event.target.checked)} />
                                    </MenuItem>

                                    <Divider sx={{ my: 0.5 }} />

                                    <MenuItem disableRipple>
                                        <FormControl variant="filled" fullWidth size="small">
                                            <InputLabel>Filtrar por situação</InputLabel>
                                            <Select
                                                value={situacaoPedidos ?? ''}
                                                onChange={handleChangeSelect}>
                                                <MenuItem key='todos' value=''>Todos</MenuItem>
                                                {SITUACOES.map((situacao, index) => (
                                                    <MenuItem key={index} value={situacao.key}>{situacao.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </MenuItem>

                                </Menu>

                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Button
                                    fullWidth
                                    color='primary'
                                    variant='outlined'
                                    disabled={finding}
                                    startIcon={<SearchIcon />}
                                    onClick={onClickBuscar}>
                                    {finding ? 'Buscando...' : 'Buscar'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                        <TableContainer>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding='checkbox'>
                                            <Checkbox onClick={handleSelectAllClick} />
                                        </TableCell>
                                        <TableCell align='left'>Origem</TableCell>
                                        <TableCell align='left'>ID</TableCell>
                                        <TableCell align='left'>Tipo/Seq</TableCell>
                                        <TableCell align='left'>Data/hora</TableCell>
                                        <TableCell align='left' component='th' scope='row' >Cliente</TableCell>
                                        <TableCell align='right'>Total</TableCell>
                                        <TableCell align='right'>Pago</TableCell>
                                        <TableCell align='right'></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {!finding ?
                                        orderList?.length > 0 ?
                                            orderList.map((order) => {

                                                const rowSelected = isSelected(order.id)

                                                return (
                                                    <TableRow
                                                        key={order.id}
                                                        className={order.dataImportacao ? classes.pedidoImportado : null}
                                                        selected={rowSelected}>

                                                        <TableCell padding='checkbox'>
                                                            <Checkbox
                                                                disabled={!!order.dataImportacao}
                                                                checked={rowSelected}
                                                                onClick={event => handleClickCheckbox(event, order.id)} />
                                                        </TableCell>

                                                        <TableCell align='left'>
                                                            <IntegrationAvatar pedido={order} />
                                                        </TableCell>

                                                        <TableCell align='left'>
                                                            #{order.id}


                                                        </TableCell>


                                                        <TableCell align='left'>
                                                            <Grid container spacing={1} alignItems='center'>
                                                                <Grid item>
                                                                    <TypeOrderAvatar order={order} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textPrimary"
                                                                    >
                                                                        {order.tipo} {order.tipo === 'COMANDA' || order.tipo === 'MESA' ?
                                                                            order.identificadorPrincipal
                                                                            : order.sequencial
                                                                        }
                                                                    </Typography>

                                                                    {
                                                                        order.situacao === 'CANCELADO' && (
                                                                            <Box paddingX={0.2} display="flex"
                                                                                color="error.main">
                                                                                cancelado
                                                                            </Box>
                                                                        )
                                                                    }

                                                                    {
                                                                        order.ativo === false && (
                                                                            <Box display="flex"
                                                                                color="warning.main" >
                                                                                excluído
                                                                            </Box>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                        </TableCell>

                                                        <TableCell align='left'>{dateFormat(order.dataPedido)}</TableCell>

                                                        <TableCell align='left'>{order.iFoodOrder?.customer?.name?.toUpperCase()}</TableCell>

                                                        <TableCell align='right'>{currencyFormat(totalOrder(order.iFoodOrder))}</TableCell>

                                                        <TableCell align='right'>
                                                            {isPaymentValid(order.iFoodOrder) ?

                                                                <CheckIcon />
                                                                :
                                                                <CloseIcon />
                                                            }
                                                        </TableCell>

                                                        <TableCell align='right'>
                                                            <Button
                                                                color="grey"
                                                                endIcon={<OpenInNewIcon />}
                                                                onClick={(event) => onClickOpenDetailOrder(event, order)} size="small">Detalhar
                                                            </Button>

                                                        </TableCell>

                                                    </TableRow>

                                                )
                                            }) : <TableRow><TableCell align='center' colSpan={8}>Nenhum pedido encontrado</TableCell></TableRow> :
                                        [1, 2, 3, 4, 5].map((e) => (
                                            < TableRow key={e} >
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton height={50} />
                                                </TableCell>
                                            </TableRow>
                                        ))

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer >

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={orderCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={selected.length === 0}
                    onClick={importarTodosOsPedidos}>
                    Importar selecionados ({selected.length})
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}