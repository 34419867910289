import { Box, Typography } from "@mui/material";
import React from "react";


const EmptyOrderReport = () => {
    return (
        <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt="2rem"
                alignItems="center"
                height="100%"
                width="70%"
                textAlign="center"
              >
                <Typography variant="h4" color="textSecondary" gutterBottom>
                    Nenhum pedido entregue hoje
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  Você ainda não entregou nenhum pedido. Assim que realizar uma entrega, ela aparecerá aqui.
                </Typography>
              </Box>
            </Box>
    );
    }

export default EmptyOrderReport;