import { Box, Button } from "@mui/material";
import React from "react";
import DeliveryPersonAutocomplete from "./DeliveryItemAutocomplete";
import { useOrderContext } from "../../../../context/OrderProvider";
import { SITUACOES } from "../../../../../../../../util/board/BoardUtil";

const ActionsButtons = ({isOrdersWait, item, listDeliveryMan, deliveryManValue, setDeliveryManValue, edit, setEdit, isOrdersSent}) => {
  const { handleDispatchSend } = useOrderContext();
  const handleEditDeliveryMan = (item, idDeliveryMan) => {
    const pedidoValido = item.pedidos.find(pedido => pedido.situacao)
    handleDispatchSend(item, pedidoValido.situacao, idDeliveryMan, item.agrupadorDespacho || '')
    setEdit(false)
  };
  const renderButtons = () => {
    if (!edit) {
      return (
        <Button
          variant="contained"
          fullWidth
          disabled={!deliveryManValue || deliveryManValue?.id === -1}
          onClick={() => {
            handleDispatchSend(
              item,
              isOrdersWait ? "ENVIADO" : "ENTREGUE",
              deliveryManValue.id,
              item.agrupadorDespacho || ''
            );
          }}
          color={isOrdersWait ? "primary" : "inherit"}
          sx={
            !isOrdersWait
              ? {
                  background: SITUACOES.find((s) => s.key === "ENTREGUE").color,
                  color: "black",
                }
              : {}
          }
        >
          {isOrdersWait ? "Despachar" : "Marcar como entregue"}
        </Button>
      );
    }
    
    return (
      <Box width="100%" display="flex" gap={1} justifyContent="center" alignItems="center">
        <Button onClick={() => setEdit(false)} fullWidth color="primary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={!deliveryManValue || deliveryManValue?.id === -1}
          fullWidth
          onClick={() => handleEditDeliveryMan(item, deliveryManValue.id)}
          color="primary"
        >
          Salvar
        </Button>
      </Box>
    );
  };
  
  const shouldShowAutocomplete = (isOrdersWait && (!!(item.pessoa.id === -1) || edit)) || (!isOrdersWait && !isOrdersSent && !(item.pessoa.id === -1) && edit);
  
  return (
    (isOrdersWait || (!isOrdersWait && !isOrdersSent)) && (
      <Box mt="0.5rem" p="0.5rem">
        {shouldShowAutocomplete && (
          <DeliveryPersonAutocomplete
            listDeliveryMan={listDeliveryMan}
            value={deliveryManValue}
            onChange={setDeliveryManValue}
            itemId={item.pessoa.id}
          />
        )}
        {renderButtons()}
      </Box>
    )
  );
}
export default ActionsButtons;