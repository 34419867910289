import { Box, styled } from "@mui/material";


export const StyledBox = styled(Box)(({ theme }) => ({
    background: theme.palette.personal.background,
    width: "100vw",
    height: "100vh",
    margin: "-8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.mode === "light" ? "#222f39" : "white",
  }));