import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import QuaggaScanner from "../../../DialogCodeBarReader";
import { useOrderContext } from "../../context/OrderProvider";

const DeliveryPersonAutocomplete = ({
  value,
  options,
  dispatchId,
  index,
}) => {
  const [openQuaggaScanner, setOpenQuaggaScanner] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { handleDispatchDeliveryMan, handleDispatchConfirm } = useOrderContext();

  function openCodeBarReaderDialog() {
    setOpenQuaggaScanner(true);
  }
  function handleDetectedCode(code) {
    if (code.length === 13 && code.startsWith("99")) {
        setSearchValue(code);      return;
    } else if (code.length === 13 && code.startsWith("98")) {
        handleDispatchConfirm(dispatchId, "AGUARDANDO_RETIRADA");
      setSearchValue("");
      return;
    }
  }

  useEffect(() => {
    const extractIdFromBarCodeDeliveryMan = (barcode) => {
      let idString = barcode.slice(2, 12);
      const id = parseInt(idString, 10);
      const deliveryMan = options.find((deliveryMan) => deliveryMan.id === id);
      return deliveryMan;
    };
    const deliveryMan = extractIdFromBarCodeDeliveryMan(searchValue);
    if (deliveryMan) {
        handleDispatchDeliveryMan(extractIdFromBarCodeDeliveryMan(searchValue), dispatchId);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  function closeCodeBarReaderDialog() {
    setOpenQuaggaScanner(false);
  }

  return (
    <>
      <Autocomplete
        blurOnSelect
        value={value}
        options={options}
        onChange={(_, newValue) => handleDispatchDeliveryMan(newValue, dispatchId)}
        getOptionLabel={(option) => (option ? option.nome : "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.nome}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            name={`dispatch.${index}`}
            {...params}
            label="Selecione o entregador"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps?.endAdornment}
                  <IconButton onClick={openCodeBarReaderDialog} size="small">
                    <CameraAltIcon />
                  </IconButton>
                </>
              ),
            }}
          />
        )}
      />
      <QuaggaScanner
        open={openQuaggaScanner}
        close={closeCodeBarReaderDialog}
        setSearchValue={setSearchValue}
        onDetected={handleDetectedCode}
      />
    </>
  );
};

export default DeliveryPersonAutocomplete;
