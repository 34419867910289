import AuthService from "../service/AuthService";
import ReconnectingWebSocket from 'reconnecting-websocket';

/**
 * Envia uma mensagem na sala atual do socket
 * @param {*} currentState 
 * @param {*} action 
 * @returns 
 */
export const sendMessageSocket = (currentState, action) =>  {
    let { socketContext } = currentState
    let { websocket } = socketContext
    let { message } = action
    console.log("Enviando mensagem no socket", message)
    //verifica o status do socket
    let readyState = websocket.connected
    if (!!readyState) {
        message.room = websocket.room
        websocket.emit('sendMessageSocket', message)
    }else{
        console.log("Socket não aberto - msg não enviada. Status", readyState)
    }
    
    return {
        ...currentState, 
        socketContext: { websocket }
    }
}

export const updateSocket = (currentState, action) =>  {

    let  { websocket } = action

    return {
        ...currentState, 
        socketContext: { websocket }
    }

}

/**
 * @deprecated - Utilize o novo socket
 */
export const initialStateSocket = () =>  {
    let profile = AuthService.profile()

    let room = "NOT_LOGGED_ROOM";

    if (profile){
        room = profile?.unidade?.uuid;
    }

    let websocket = createSockect(room)

    return { websocket } 
}

/**
 * @deprecated - Utilize o novo socket
 */
export const createSockect = (room, onClose) => {
    console.log("Criando socket na room ", room)

    const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
    let websocket = new ReconnectingWebSocket(`${SOCKET_URL}/socket-service/${room}`, [], { startClosed: true });
   
    websocket.onopen = function() {
        console.log("websocket aberto")
        
    }
    
    websocket.onerror = function(evt) {
        console.error("websocket error", evt)
    }
    
    websocket.onclose = function() {
        console.log("websocket fechado")
        if (onClose){
            onClose()
        }
    }

    return websocket
}
  
