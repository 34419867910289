import { Box, Typography } from "@mui/material";
import React from "react";
import ChecklistIcon from "@mui/icons-material/Checklist";

const InitialDialog = () => {
    return <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="100%"
  >
    <Box border="dashed 0.2rem" borderRadius="50%" p="1.5rem">
      <ChecklistIcon sx={{ width: "7rem", height: "7rem" }} />
    </Box>

    <Box mt="3rem">
      <Typography align="center" variant="h2">
        CONFIRMAÇÃO
      </Typography>
    </Box>
    <Box mt="1rem">
      <Typography align="center" variant="h6">
        Vamos iniciar a confirmação dos pedidos através do leitor de
        código de barras.
      </Typography>
    </Box>
  </Box>
}

export default InitialDialog;