

import { Box } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledBoxHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  justifyContent: "center",
  height: "8rem",
  width: "100%",
}));
