import AuthService from "../../../../../service/AuthService";

export function convertToAgrupadorDespacho(arr) {
  const newArr = [];

  for (const item of arr) {
    for (const pedido of item.pedidos) {
      if (!pedido.agrupadorDespacho) {
        continue;
      }

      const existingItem = newArr.find(
        (i) => i.agrupadorDespacho === pedido.agrupadorDespacho
      );
      if (existingItem) {
        existingItem.pedidos.push(pedido);
        continue;
      }

      newArr.push({
        pedidos: [pedido],
        pessoa: item.pessoa,
        agrupadorDespacho: pedido.agrupadorDespacho,
      });
    }
  }

  return newArr;
}

export const fetchListByDeliveryPerson = async (dtInicio, dtFinal) => {
  const service = new AuthService();

  try {
    const response = await service.get(
      `/api-v2/pedidoIntegracao/listPorEntregador?dtInicio=${dtInicio}%2004:00:00&dtFinal=${dtFinal}%2004:00:00&showTakeout=true`
    );
    return convertToAgrupadorDespacho(response);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
