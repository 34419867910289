import React, { useState, useEffect } from 'react'
import { Dialog, Box, Typography, IconButton, Tooltip, Alert, AlertTitle, Accordion, AccordionSummary, AccordionDetails, Button, Grid, CircularProgress, Skeleton } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { DialogContent, DialogTitle } from '../../layout-components/CustomDialog/dialogComponents'
import { useSnackbar } from 'notistack'
import AuthService from '../../../service/AuthService'
import { addDays } from 'date-fns'
import { dateFormat } from '../../../util/formatter'
import { formatFullAddressCustomer, formatFullAddressDelivery } from '../../../util/customer/helper'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ResumeListDispatch from '../resume'
import ListIcon from '@mui/icons-material/List'
import { isAddressValid } from '../../../util/customer/helper'
import ErrorIcon from '@mui/icons-material/Error'

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    '& .actions': {
        display: 'flex',
        position: 'absolute',
        right: '10px',
        top: '10px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
    },
    '& .iframeContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '450px',
    },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '& svg': {
        fill: theme.palette.secondary.main,
    }
}))

function convertToAgrupadorDespacho(arr) {
    const newArr = []

    for (const item of arr) {
        for (const pedido of item.pedidos) {
            if (!pedido.agrupadorDespacho) {
                continue
            }

            const existingItem = newArr.find(i => i.agrupadorDespacho === pedido.agrupadorDespacho)
            if (existingItem) {
                existingItem.pedidos.push(pedido)
                continue
            }

            newArr.push({
                pedidos: [pedido],
                pessoa: item.pessoa,
                agrupadorDespacho: pedido.agrupadorDespacho,
            })
        }
    }

    return newArr
}

function InvalidAddressAlert({ numberOfInvalidAddress }) {
    if (!numberOfInvalidAddress) {
        return null
    }

    let msg = ''
    if (numberOfInvalidAddress === 1) {
        msg = `Há ${numberOfInvalidAddress} endereço incorreto. É necessário corrigir na listagem dos pedidos para que a rota seja criada`
    } else {
        msg = `Há ${numberOfInvalidAddress} endereços incorretos. É necessário corrigir na listagem dos pedidos para que a rota seja criada`
    }

    return (
        <Alert severity="error">
            <AlertTitle>Aviso</AlertTitle>
            <Typography variant="body1">
                {msg}
            </Typography>
        </Alert>
    )
}

function DispatchList({ loading, deliveryMan, selectedMan, shareableLink, routeLink, numberOfInvalidAddresses, isIframeVisible, onClickListAll, toggleIframe }) {
    const { enqueueSnackbar } = useSnackbar()

    function handleShareWhatsApp() {
        if (!shareableLink) {
            enqueueSnackbar('Link da rota não disponível', { variant: 'error' })
        }

        const whatsappLink = `https://wa.me/?text=${encodeURIComponent(shareableLink)}`
        window.open(whatsappLink, '_blank')
    }

    function handleCopyLink() {
        if (!shareableLink) {
            enqueueSnackbar('Link da rota não disponível', { variant: 'error' })
        }

        navigator.clipboard.writeText(shareableLink).then(() => {
            enqueueSnackbar('Link copiado para a área de transferência.', { variant: 'success' })
        }).catch((err) => console.error('Erro ao copiar link:', err))
    }

    if (loading) {
        return (
            <>
                <Skeleton height={60} animation="wave" sx={{ transform: 'scale(1, 0.8)' }} />
                <Skeleton height={60} animation="wave" sx={{ transform: 'scale(1, 0.8)' }} />
                <Skeleton height={60} animation="wave" sx={{ transform: 'scale(1, 0.8)' }} />
            </>
        )
    }

    if (!deliveryMan.length) {
        return (
            <Box p={2}>
                <Typography variant="h6" textAlign="center">Nenhum despacho realizado</Typography>
            </Box>
        )
    }

    return deliveryMan.map((item) => (
        <Accordion key={item.agrupadorDespacho} expanded={selectedMan === item} onChange={() => toggleIframe(item)}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${item.agrupadorDespacho}-content`}
                id={`panel${item.agrupadorDespacho}-header`}>
                <Box display="flex" flexDirection="column">
                    <Typography variant="h6">Entregador: {item.pessoa ? item.pessoa.nome : 'Nome não disponível'}</Typography>
                    <Typography variant="h6">Pedidos: {item.pedidos.length}</Typography>
                </Box>

                <Box display="flex" justifyContent="flex-end" alignItems="center" flex={1}>
                    {item.pedidos.some(pedido => !isAddressValid(pedido.iFoodOrder?.deliveryAddress)) && (
                        <Tooltip title="Rota não criada. Verifique o erro.">
                            <ErrorIcon color="error" style={{ cursor: 'pointer' }} />
                        </Tooltip>
                    )}
                </Box>

            </AccordionSummary>
            <AccordionDetails>

                <Grid item sx={{ mb: 1 }}>
                    <Button
                        color="grey"
                        disabled={!item.pedidos.length}
                        startIcon={<ListIcon />}
                        onClick={onClickListAll}>
                        Listagem dos pedidos
                    </Button>
                </Grid>

                <InvalidAddressAlert numberOfInvalidAddress={numberOfInvalidAddresses} />

                {isIframeVisible && selectedMan === item && !numberOfInvalidAddresses && (
                    <StyledBox>
                        <Box className="actions">
                            <Tooltip title="Compartilhar rota por WhatsApp">
                                <StyledIconButton onClick={handleShareWhatsApp}>
                                    <WhatsAppIcon />
                                </StyledIconButton >
                            </Tooltip>
                            <Tooltip title="Copiar link da rota">
                                <StyledIconButton onClick={handleCopyLink}>
                                    <ContentCopyIcon />
                                </StyledIconButton >
                            </Tooltip>
                        </Box>

                        <iframe
                            title={`route-map-${selectedMan.agrupadorDespacho}`}
                            height="450"
                            style={{ border: 0, overflow: 'hidden', borderRadius: '4px', width: '100%' }}
                            loading="lazy"
                            allowFullScreen
                            src={routeLink}
                        ></iframe>
                    </StyledBox>
                )}
            </AccordionDetails>
        </Accordion>
    ))
}

const DeliveryManGrouper = ({ isOpen, handleClose, handleSubmit }) => {
    const service = new AuthService()
    const profile = service.getProfile()
    const { enqueueSnackbar } = useSnackbar()
    const [error, setError] = useState(null)
    const [deliveryMan, setDeliveryMan] = useState([])
    const [isIframeVisible, setIsIframeVisible] = useState(false)
    const [routeLink, setRouteLink] = useState('')
    const [selectedMan, setSelectedMan] = useState(null)
    const [initialCenter, setInitialCenter] = useState({ lat: profile.unidade.pessoa.latitude || 0, lng: profile.unidade.pessoa.longitude || 0 })
    const [shareableLink, setShareableLink] = useState('')
    const [refreshPositionProfile, setRefreshPositionProfile] = useState(false)
    const [openResumeList, setOpenResumeList] = useState(false)
    const [pedidos, setPedidos] = useState([])
    const [loading, setLoading] = useState(false)

    function loadListPorEntregador() {
        const date = new Date()
        const dateFormatted = addDays(date, 1)
        const dtInicio = dateFormat(date, 'yyyy-MM-dd')
        const dtFinal = dateFormat(dateFormatted, 'yyyy-MM-dd')

        setLoading(true)
        service.get(`/api-v2/pedidoIntegracao/listPorEntregador?dtInicio=${dtInicio}%2004:00:00&dtFinal=${dtFinal}%2004:00:00&showTakeout=true`).then(resp => {
            const listDespachos = convertToAgrupadorDespacho(resp)
            setDeliveryMan(listDespachos)
        }).catch(err => {
            console.error(err)
            setError('Erro ao carregar dados')
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (isOpen) {
            loadListPorEntregador()
        }
    }, [isOpen])

    useEffect(() => {
        if (selectedMan) {
            const fetchDirections = async () => {
                try {
                    const origin = `${initialCenter.lat},${initialCenter.lng}`

                    let waypoints = selectedMan.pedidos
                        .map(pedido => {
                            const address = formatFullAddressDelivery(pedido.iFoodOrder?.deliveryAddress)
                            return address.address || ''
                        })
                        .filter(wp => wp !== ',')

                    if (waypoints.length === 0) {
                        setError('Nenhum endereço válido encontrado para a rota')
                        return
                    }

                    let destination
                    if (waypoints.length === 1) {
                        destination = waypoints[0]
                        waypoints = []
                    } else {
                        destination = waypoints.pop()
                    }

                    const encodedAddresses = [origin, ...waypoints, destination].map(encodeURIComponent).join('/')
                    const shareableUrl = `https://www.google.com/maps/dir/${encodedAddresses}`

                    const embedUrl = `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY}&origin=${origin}&destination=${destination}${waypoints.length ? `&waypoints=${waypoints.join('|')}` : ''}`

                    setRouteLink(embedUrl)
                    setShareableLink(shareableUrl)
                    setIsIframeVisible(true)
                } catch (error) {
                    setError(`Erro ao obter direções: ${error.message}`)
                }
            }

            fetchDirections()
        }
    }, [selectedMan])

    async function getLatLng(customer) {
        if ((!customer.latitude || 0) || (!customer.longitude || 0)) {
            try {
                setRefreshPositionProfile(true)
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(formatFullAddressCustomer(customer).address)}&pt-BR&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
                if (!response.ok) throw new Error('Falha na obtenção da resposta da API do Google')
                const data = await response.json()
                const location = data.results[0].geometry.location

                customer.latitude = location.lat
                customer.longitude = location.lng
                customer.isValidLatLng = true

                setInitialCenter({ lat: location.lat, lng: location.lng })

                await service.post("/api-v2/pessoa", customer)
                enqueueSnackbar(`As coordenadas geográficas da empresa ${customer.nome} foram atualizadas`, { variant: "success" })
            } catch (error) {
                console.error('Erro ao atualizar coordenadas', error)
                enqueueSnackbar('Erro ao atualizar coordenadas', { variant: "error" })
            } finally {
                setRefreshPositionProfile(false)
            }
        }
    }

    const onClickRefreshLatLng = () => {
        getLatLng(profile.unidade.pessoa)
    }

    function getCustomerNotValidLatLng() {
        return selectedMan?.pedidos.filter(pedido => {
            const isValidAddress = isAddressValid(pedido.iFoodOrder?.deliveryAddress)
            return !isValidAddress
        }) || []
    }

    const toggleIframe = (item) => {
        if (isIframeVisible && selectedMan === item) {
            setIsIframeVisible(false)
            setRouteLink('')
            setSelectedMan(null)
        } else {
            setSelectedMan(item)
        }
    }

    const handleCloseDialog = () => {
        handleClose()
        setIsIframeVisible(false)
        setRouteLink('')
        setSelectedMan(null)
    }

    const onClickListAll = () => {
        setPedidos(selectedMan.pedidos)
        setOpenResumeList(true)
    }

    function handleUpdate() {
        loadListPorEntregador()
    }

    const numberOfInvalidAddresses = getCustomerNotValidLatLng().length

    return (
        <>
            <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle onClose={handleCloseDialog}>Rotas dos Pedidos</DialogTitle>
                <DialogContent>
                    <Box>
                        {(initialCenter.lat === 0 || initialCenter.lng === 0) && (
                            <Alert severity="warning">
                                <AlertTitle >Aviso</AlertTitle>
                                Não foi informado as coordenadas geográficas ( latitude e longitude ) no cadastro da empresa

                                <Button dsabled={refreshPositionProfile} onClick={onClickRefreshLatLng}>
                                    Clique aqui para atualizar
                                </Button>
                            </Alert>
                        )}

                        {error && (
                            <Typography color="error" variant="body1">
                                {error}
                            </Typography>
                        )}

                        <DispatchList
                            loading={loading}
                            isIframeVisible={isIframeVisible}
                            routeLink={routeLink}
                            shareableLink={shareableLink}
                            deliveryMan={deliveryMan}
                            numberOfInvalidAddresses={numberOfInvalidAddresses}
                            selectedMan={selectedMan}
                            toggleIframe={toggleIframe}
                            onClickListAll={onClickListAll}
                        />

                    </Box>

                </DialogContent>
            </Dialog>

            <ResumeListDispatch
                open={openResumeList}
                setOpen={setOpenResumeList}
                getLatLng={getLatLng}
                pedidos={pedidos}
                setCustomerList={setPedidos}
                handleSubmit={handleSubmit}
                onUpdate={handleUpdate} />
        </>
    )
}

export default DeliveryManGrouper