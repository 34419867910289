export function objectToQueryString(obj) {
  const mapping = {
    pedidosNaoImportados: "importados",
    pedidosPagos: "pago",
    pedidosPagosOnline: "pagoOnline",
    mostrarExcluidos: "ativo",
  };

  const params = Object.entries(obj)
    .filter(([key, value]) => value && mapping[key])
    .map(([key]) => `${mapping[key]}=true`);

  if (obj.pedidosNaoPagos) {
    params.push("pago=false");
  }

  return params.length ? `&${params.join("&")}` : "";
}

export function applyFilters(orderList, filtersType, filtersBoolean) {
  let orderFilters = [...orderList];

  if (filtersType.length > 0) {
    orderFilters = orderFilters.filter((order) =>
      filtersType.includes(order.tipo)
    );
  }

  if (filtersBoolean.pedidosPagos) {
    orderFilters = orderFilters.filter((order) => order.pago === true);
  }

  return orderFilters;
}

export function convertToDespacho(response) {
  return {
    pedidos: response,
    agrupadorDespacho: response.find((i) => i.agrupadorDespacho)
      ?.agrupadorDespacho,
    pessoa: response.find((i) => i.pessoaEntregador)?.pessoaEntregador ?? {
      id: -1,
    },
  };
}
