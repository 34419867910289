import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import AuthService from "../../../service/AuthService";
import { TextMaskPhone } from "../../../util/mask";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../layout-components/CustomDialog/dialogComponents";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

export default function DeliveryManRegister({
  open,
  setOpen,
  deliveryMan,
  afterSave,
}) {
  const service = new AuthService();
  const { enqueueSnackbar } = useSnackbar();
  const [deliveryManEdit, setDeliveryManEdit] = useState({ ativo: true });
  const [value, setValue] = useState(null);
  const [perfils, setPerfils] = useState([]);

  const [visible, setVisible] = useState(false);

  const profile = service.getProfile();
  const isPermissioned = profile.unidade.roles.includes(
    "ROLE_CADASTRO_ENTREGADORES"
  );

  function isValid() {
    if (!deliveryManEdit.nome || deliveryManEdit.nome.trim() === "") {
      enqueueSnackbar("Informe um nome para o entregador.", {
        variant: "error",
      });
      return false;
    }

    if (!deliveryManEdit.fone || deliveryManEdit.fone.trim() === "") {
      enqueueSnackbar("Informe um telefone para o entregador.", {
        variant: "error",
      });
      return false;
    }

    return true;
  }

  const handleSave = async () => {
    if (!isValid()) return;

    const { entregadorUsername, entregadorSenha, ...deliveryManEditCopy } =
      deliveryManEdit;
    deliveryManEditCopy.nome = deliveryManEditCopy.nome.toUpperCase();

    if (isPermissioned) {
      const { available } = await service.get(
        `/api-v2/pedidoIntegracao/isUsernameAvailable?username=${entregadorUsername}`
      );

      if (!available) {
        return showSnackbar("Username já está sendo utilizado", "error");
      }
    }
    const res = await service.post("/api-v2/pessoa", deliveryManEditCopy);

    if (!res?.id) {
      throw new Error("Erro ao obter ID do entregador.");
    }
    if (isPermissioned) {
      if (!entregadorSenha || !entregadorUsername || !value) {
        return handleMissingFields(entregadorUsername, entregadorSenha, value);
      }

      try {
        await service.post(
          `/api-v2/pedidoIntegracao/createUserDeliveryMan?username=${entregadorUsername}&senha=${entregadorSenha}&pessoaId=${res.id}&perfilId=${value.id}`
        );
      } catch (error) {
        if (error.response?.status === 409) {
          return showSnackbar("Username já está sendo utilizado", "error");
        }
      }
    }

    showSnackbar("Entregador salvo com sucesso.", "success");
    setOpen(false);
    afterSave();
  };
  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const handleMissingFields = (username, password, profile) => {
    if (!username)
      return showSnackbar("Informe o username do entregador.", "error");
    if (!password)
      return showSnackbar("Informe a senha do entregador.", "error");
    if (!profile)
      return showSnackbar("Selecione o perfil do entregador.", "error");
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const res = await service.get(
          "/api-v2/pedidoIntegracao/getProfilesDeliveryman"
        );
        setPerfils(res);
      } catch (error) {
        enqueueSnackbar("Erro ao buscar perfis de entregadores:", {
          variant: "error",
        });
      }
    };

    fetchProfiles();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue(null);
    setVisible(true);
    if (!deliveryMan || !deliveryMan.id) return;
    const fetchProfile = async () => {
      try {
        const res = await service.get(
          `/api-v2/pedidoIntegracao/getProfileDeliveryman?idDeliveryman=${deliveryMan?.id}`
        );
        const defaultPerfil = perfils?.find(
          (e) => e?.id === res?.perfilUsuario
        );
        setValue(defaultPerfil);

        setDeliveryManEdit((prev) => ({
          ...prev,
          entregadorUsername: res.username,
          entregadorSenha: res.password,
        }));
        if (!res.password) {
          setVisible(true);
        } else setVisible(false);
      } catch (error) {
        enqueueSnackbar("Erro ao buscar perfil do entregador", {
          variant: "error",
        });
      }
    };

    fetchProfile();
    //eslint-disable-next-line
  }, [deliveryMan, perfils]);

  const handleChange = (event) => {
    let deliveryManEditCopy = Object.assign({}, deliveryManEdit);
    const deliveryManValueFields = [
      "entregadorValorDiaria",
      "entregadorValorFixoPedido",
      "entregadorPercentualTaxaEntrega",
      "entregadorValorMinimo",
      "entregadorUsername",
      "entregadorSenha",
    ];

    if (event.target.type === "checkbox") {
      deliveryManEditCopy[event.target.name] = event.target.checked;
    } else if (deliveryManValueFields.includes(event.target.name)) {
      deliveryManEditCopy[event.target.name] = event.target.value || 0;
    } else {
      deliveryManEditCopy[event.target.name] = event.target.value;
    }

    if (event.target.name === "entregadorSenha") {
      setVisible(true);
    }

    setDeliveryManEdit(deliveryManEditCopy);
  };

  const handleClickEditPassword = () => {
    setVisible(!visible);
    setDeliveryManEdit((prev) => ({
      ...prev,
      entregadorSenha: "",
    }));
  };

  useEffect(() => {
    if (deliveryMan) {
      setDeliveryManEdit(Object.assign({}, deliveryMan));
    }
  }, [deliveryMan]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <Typography>Cadastro de entregador</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box pt={1}>
              <TextField
                required
                fullWidth
                name="nome"
                size="small"
                variant="outlined"
                label="Nome completo"
                value={deliveryManEdit.nome || ""}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              size="small"
              name="email"
              type="email"
              label="E-Mail"
              variant="outlined"
              onChange={handleChange}
              value={deliveryManEdit.email || ""}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              fullWidth
              name="fone"
              size="small"
              label="Telefone"
              variant="outlined"
              value={deliveryManEdit.fone || ""}
              onChange={handleChange}
              InputProps={{
                inputComponent: TextMaskPhone,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              type="number"
              name="entregadorValorDiaria"
              variant="outlined"
              label="Valor diária"
              onChange={handleChange}
              value={deliveryManEdit.entregadorValorDiaria || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              type="number"
              name="entregadorValorFixoPedido"
              label="Valor fixo por pedido"
              variant="outlined"
              onChange={handleChange}
              value={deliveryManEdit.entregadorValorFixoPedido || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              type="number"
              name="entregadorPercentualTaxaEntrega"
              label="Percentual sobre a taxa de entrega"
              variant="outlined"
              onChange={handleChange}
              value={deliveryManEdit.entregadorPercentualTaxaEntrega || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              type="number"
              name="entregadorValorMinimo"
              label="Valor mínimo"
              variant="outlined"
              onChange={handleChange}
              value={deliveryManEdit.entregadorValorMinimo || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Grid>
          {isPermissioned && (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="entregadorUsername"
                  label="Username"
                  variant="outlined"
                  disabled={!visible}
                  onChange={handleChange}
                  value={deliveryManEdit.entregadorUsername || ""}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  size="small"
                  type={visible ? "text" : "password"}
                  disabled={!visible}
                  name="entregadorSenha"
                  label="Senha"
                  variant="outlined"
                  onChange={handleChange}
                  value={deliveryManEdit.entregadorSenha || ""}
                />
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton
                    onClick={handleClickEditPassword}
                    disabled={visible}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  blurOnSelect
                  value={value}
                  options={perfils}
                  onChange={(_, newValue) => setValue(newValue)}
                  getOptionLabel={(option) => (option ? option.descricao : "")}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option?.id}>
                      {option.descricao}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={`dispatch.${params?.id}`}
                      label="Selecione o perfil do entregador"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps?.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="ativo" row>
                <FormControlLabel
                  label="Ativo"
                  labelPlacement="start"
                  control={
                    <Switch
                      name="ativo"
                      label="Ativo"
                      color="primary"
                      onChange={handleChange}
                      checked={deliveryManEdit.ativo}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSave(deliveryManEdit)}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
