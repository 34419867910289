import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  InputBase,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Drawer,
  IconButton,
  Menu,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';
import { useDispatch } from 'react-redux'
import { createLoadingCards, TIPOS_IMPORTACOES } from "../../util/board/BoardUtil"
import AuthService from '../../service/AuthService';
import { useSnackbar } from 'notistack';
import { isOrderPayed } from '../../util/order/orderUtil';
import { DialogTitle } from "./CustomDialog/dialogComponents"
import CustomDateFilter from '../../component/generics/customDateFilter';
import { getDefaultPeriodFormated } from '../../helpers/dateHelper';
import { CheckCircle } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const PREFIX = 'SearchBox'

const classes = {
  content: `${PREFIX}-content`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  btnSearch: `${PREFIX}-btnSearch`,
  filterGroups: `${PREFIX}-filterGroups`,
  filterGroupIntegracao: `${PREFIX}-filterGroupIntegracao`,
  filterSearch: `${PREFIX}-filterSearch`
}

const Root = styled(Box)(({ theme }) => ({

  [`& .${classes.content}`]: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  [`& .${classes.search}`]: {

    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: getBGColor(theme),
    '&:hover': {
      backgroundColor: getBGColor(theme),
    },
    '&:focus': {
      backgroundColor: getBGColor(theme),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },

  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '10ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },

  [`& .${classes.btnSearch}`]: {
    backgroundColor: getBGColor(theme),
    color: 'inherit',
  },

}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({

  [`& .${classes.filterGroups}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('xs')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '0.6fr 1fr',
    },
  },

  [`& .${classes.filterGroupIntegracao}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },

  [`& .${classes.filterSearch}`]: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '0.6fr 1fr',
    },
  },
}))

const getBGColor = (theme) => theme.palette.mode === 'light' ?
  alpha(theme.palette.common.white, 0.9) : alpha(theme.palette.common.white, 0.25)

const filterOptions = [

  {
    groupLabel: "Tipo Pedido",
    filters: [
      { label: "Delivery", type: "tipo", value: "DELIVERY", checked: false },
      { label: "Balcão", type: "tipo", value: "BALCAO", checked: false },
    ]
  },

  {
    groupLabel: "Situação",
    filters: [
      { label: "Para retirar", type: "TIPO_ENTREGA", value: "TAKEOUT", checked: false },
      { label: "Para entregar", type: "TIPO_ENTREGA", value: "DELIVERY", checked: false },
      { label: "Pagos", type: "PAGAMENTO", value: true, checked: false },
      { label: "Não pagos", type: "PAGAMENTO", value: false, checked: false },
      { label: "Agendados", type: "pedidoAgendado", value: 'AGENDADO', checked: false },

    ]
  },

  {
    groupLabel: "Canais",
    filters: TIPOS_IMPORTACOES
      .filter(elem => !elem.integracao).map(elem => {
        return { label: elem.descricao, type: "tipoImportacao", value: elem.sigla, checked: false }
      })
  },

  {
    groupLabel: "Integrações",
    filters: TIPOS_IMPORTACOES
      .filter(elem => elem.integracao).map(elem => {
        return { label: elem.descricao, type: "tipoImportacao", value: elem.sigla, checked: false }
      })

  },
]

const filterSearch = {
  groupLabel: "Buscar por",
  filters: [
    { id: "1", label: "ID pedido (#)", subLabel: "Código id do pedido", type: "id", value: "ID", checked: true },
    { id: "2", label: "Sequência do pedido", subLabel: "Código sequêncial do pedido", type: "seq", value: "SEQUENCERED", checked: true },
    { id: "3", label: "Referencia do pedido", subLabel: "Código de referência do pedido", type: "ref", value: "REFERENCECODE", checked: true },
  ]
}

//DEPRECATED
// const loadaPeriodOptions = () => [
//   { key: 0, label: 'Próximos 30 dias', value: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(30, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 1, label: 'Próximos 15 dias', value: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(15, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 2, label: 'Próximos 7 dias', value: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(7, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 3, label: 'Próximos 3 dias', value: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(3, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 4, label: 'Amanhã', value: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(2, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 5, default: true, label: 'Hoje', value: `${moment().subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(1, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 6, label: 'Últimos 2 dias', value: `${moment().subtract(2, 'days').format('yyyy-MM-DD')} 04:00:00,${moment().add(1, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 7, label: 'Últimos 3 dias', value: `${moment().subtract(3, 'days').format('yyyy-MM-DD')} 04:00:00,${moment().add(1, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 8, label: 'Últimos 7 dias', value: `${moment().subtract(7, 'days').format('yyyy-MM-DD')} 04:00:00,${moment().add(1, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 9, label: 'Últimos 15 dias', value: `${moment().subtract(15, 'days').format('yyyy-MM-DD')} 04:00:00,${moment().add(1, 'days').format('yyyy-MM-DD')} 04:00:00` },
//   { key: 10, label: 'Personalizado', value: `${moment().subtract(6, 'hours').format('yyyy-MM-DD')} 04:00:00,${moment().subtract(6, 'hours').add(1, 'days').format('yyyy-MM-DD')} 06:00:00` },
// ]

const createComparator = (filterOptions, filterTextValue) => {

  const checkedOptions = []
  const checkQuickFilter = []

  filterOptions.map((group) => (
    group.filters.filter(e => e.checked).map(checkedFilter => (
      checkedOptions.push({ type: checkedFilter.type, value: checkedFilter.value })
    ))
  ))

  return (card) => {
    filterSearch.filters.map(checkedFilter => (
      checkQuickFilter.push({ type: checkedFilter.type, checked: checkedFilter.checked })
    ))

    if (!filterTextValue && checkedOptions.length === 0) {
      return "EMPTY"
    }

    let order = card.data.iFoodOrder;

    let indexOf = order?.customer?.name?.toLowerCase().indexOf(filterTextValue.toLowerCase());
    /* SEGUNDA BUSCA */
    if (indexOf < 0 && checkQuickFilter[1].type === "seq" && checkQuickFilter[1].checked === true) {
      indexOf = card.data?.sequencial?.toString().indexOf(filterTextValue);
      console.log("Achou por Seq: ", indexOf)
    }

    /* TERCEIRA BUSCA */
    if (indexOf < 0 && checkQuickFilter[0].type === "id" && checkQuickFilter[0].checked === true) {
      indexOf = card.data?.id?.toString().indexOf(filterTextValue);
      console.log("Achou por Id: ", indexOf)
    }

    /* QUARTA BUSCA */
    if (indexOf < 0 && checkQuickFilter[2].type === "ref" && checkQuickFilter[2].checked === true) {
      indexOf = order?.shortReference?.indexOf(filterTextValue);
      console.log("Achou por Ref: ", indexOf)
    }


    const textSerach = indexOf >= 0

    var isCheckedOr = true;//Utilizado para os filtros que não foram marcados como AND
    var isCheckedAnd = true;//Utilizado para os filtros que foram marcados como AND
    var entrouOr = false;//Utilizado quando nenhum filtro OR é utilizado para exibir o card

    if (checkedOptions.length > 0) {
      isCheckedOr = false //define como falso, pois se algum for verdadeiro sempre será verdadeiro
      isCheckedAnd = true // define como verdadeiro pois se algum for falso sempre será falso
      entrouOr = false // define como falso pois nenhuma verificação OR foi encontrada
      checkedOptions.map((checkedOption) => {

        if (isCheckedAnd && checkedOption.type === 'TIPO_ENTREGA') { //Utiliza AND

          const mode = card.data?.iFoodOrder?.deliveryMethod?.mode;

          if (mode !== checkedOption.value) {
            isCheckedAnd = false
            return null;
          }

        } else if (isCheckedAnd && checkedOption.type === 'PAGAMENTO') { //Utiliza AND

          const payed = isOrderPayed(card.data?.iFoodOrder)

          if (payed !== checkedOption.value) {
            isCheckedAnd = false
            return null;
          }

        } else if (isCheckedAnd && checkedOption.type === 'pedidoAgendado') { //Utiliza AND
          const scheduled = card.data[checkedOption.type]

          if (scheduled !== checkedOption.value) {
            isCheckedAnd = false
            return null;
          }

        } else { //Utiliza OR
          entrouOr = true //Entrou em verificações que utiliza OR portanto marca como verdadeiro
          if (checkedOption.type === 'IMPORTADO') {
            if (Boolean(card.data["dataImportacao"]) === checkedOption.value) {

              isCheckedOr = true
              return null;
            }
          } else if (card.data[checkedOption.type] === checkedOption.value) {
            isCheckedOr = true
            return null;
          } else {
            if (!isCheckedOr)
              isCheckedOr = false //Caso a verificação de OR seja verdadeira não remarca como falso
          }
        }
        return null;
      })
    }
    /*marca como encontrado se o card possuir todos os parametros como verdadeiro
                  busca de texto 
                                 Pega o valor de chackedOr OU o valor de EntrouOR, caso não tenha entrado em or o resultado é verdadeiro
                                                              Pega o valor de AND */
    let founded = textSerach && (isCheckedOr || !entrouOr) && isCheckedAnd

    return founded ? "FOUNDED" : "NOT_FOUNDED"
  }
}

const SAVED_FILTERS_KEY = "_saved_filters_"
const SAVED_QUICK_FILTERS = "_saved_quick_filters"

export default function SearchBox() {

  const dispatch = useDispatch()
  const [filterTextValue, setFilterTextValue] = useState("")
  const { enqueueSnackbar } = useSnackbar();
  const savedFilters = JSON.parse(localStorage.getItem(SAVED_FILTERS_KEY)) || {}
  const savedQuickFilters = JSON.parse(localStorage.getItem(SAVED_QUICK_FILTERS)) || null
  const [buscando, setBuscando] = useState(false)
  const [anchorElMaisOpcoes, setAnchorElMaisOpcoes] = useState(null);
  const [filterState, setFilterState] = useState(filterSearch);

  const [apiFilter, setApiFilter] = useState({
    dataPedido: `${getDefaultPeriodFormated().startDate},${getDefaultPeriodFormated().endDate}`,
    max: 500,
    importados: savedFilters.importados || "undefined",
    periodKey: 5
  });

  const handleKeypressFilterTextValue = (event) => {
    if (event.key === 'Enter') {
      dispatch({
        type: 'FILTER_BOARD', filter: {
          comparator: createComparator(filterOptions, filterTextValue)
        }
      })
    }
  }

  const changeFilter = (filterOptions) => {
    dispatch({
      type: 'FILTER_BOARD', filter: {
        comparator: createComparator(filterOptions, filterTextValue)
      }
    })
  }

  const handleChangePeriodo = (date) => {

    let value = `${date.startDate},${date.endDate}`
    let key = date.periodKey ? date.periodKey : 5

    setApiFilter({ ...apiFilter, dataPedido: value, periodKey: key })

  };

  const reloadCardsFromApi = () => {
    setBuscando(true)

    dispatch({ type: 'LOAD_BOARD_FROM_DATA', board: createLoadingCards() })

    let queryString = ""
    let filterArray = Object.keys(apiFilter)

    filterArray.map(filterKey => {
      let value = apiFilter[filterKey]?.value

      if (!value) {
        value = apiFilter[filterKey]
      }

      if (value !== "undefined") {
        queryString = queryString.concat(filterKey + "=" + value + "&")
      }
      return null;
    })

    let service = new AuthService()
    service.get(`/api-v2/pedidoIntegracao/list?ativo=true&tipo=DELIVERY,BALCAO&${queryString}`)
      .then(pedidos => {

        dispatch({ type: 'LOAD_BOARD_FROM_ORDERS', orders: pedidos });

      }).catch(err => {
        enqueueSnackbar("Erro ao buscar pedidos", { variant: 'error', });
        dispatch({ type: 'LOAD_BOARD_FROM_ORDERS', orders: [] });
      }).finally(() => {
        setBuscando(false)
      })

  }

  const handleQuickFilter = (event, filter) => {
    filter.checked = event.target.checked
    const newFilterState = Object.assign([], filterState)
    newFilterState[filter.value] = filter.checked
    setFilterState(newFilterState)
    changeFilter(newFilterState)
    localStorage.setItem(SAVED_QUICK_FILTERS, JSON.stringify(filterState))
  }

  const getQuickFilters = () => {
    const checkQuickFilter = []
    filterState.filters.map(checkedFilter => (
      checkQuickFilter.push({ type: checkedFilter.type, checked: checkedFilter.checked })
    ))
    for (var i = 0; i < savedQuickFilters?.filters?.length; i++) {
      if (checkQuickFilter[i].type === savedQuickFilters.filters[i].type) {
        filterSearch.filters[i].checked = savedQuickFilters.filters[i].checked
      }
    }
  }

  useEffect(() => {

    reloadCardsFromApi()

    if (apiFilter) {

      /* DEFINE QUAIS CAMPOS SERAO PERSISTIDOS NO LOCAL STORAGE */
      let importados = apiFilter.importados

      let persistedFilter = {
        importados
      }

      localStorage.setItem(SAVED_FILTERS_KEY, JSON.stringify(persistedFilter))
    }

    getQuickFilters()
    // eslint-disable-next-line
  }, [])

  return (
    <Root display="flex" alignItems="center">
      <Box pr={3}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <div>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={filterTextValue}
              onChange={(e) => setFilterTextValue(e.target.value)}
              onKeyUp={handleKeypressFilterTextValue}
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-controls="mais-opcoes"
                    aria-haspopup="true"
                    size="small"
                    onClick={event => setAnchorElMaisOpcoes(event.currentTarget)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="mais-opcoes"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorElMaisOpcoes}
                    keepMounted
                    open={Boolean(anchorElMaisOpcoes)}
                    onClose={event => setAnchorElMaisOpcoes(null)}
                  >

                    <List dense
                      sx={{ bgcolor: 'background.paper' }}
                      subheader={<ListSubheader>Buscar por</ListSubheader>}>
                      {filterSearch.filters.map((filter) => {
                        return (
                          <ListItem
                            key={filter.id}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={(event) => handleQuickFilter(event, filter)}
                                checked={filter.checked}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton >
                              <ListItemText id={filter.label} primary={filter.label} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Menu>

                </InputAdornment>
              }
            />
          </div>
        </div>
      </Box>
      <Box>
        <FilterDrawer
          filterOptions={filterOptions}
          changeFilter={changeFilter}
          apiFilter={apiFilter}
          setApiFilter={setApiFilter}
          changePeriodo={handleChangePeriodo}
          buscando={buscando}
          reloadCardsFromApi={reloadCardsFromApi} />
      </Box>
    </Root>
  );
}

function FilterDrawer({ filterOptions, changeFilter, changePeriodo, apiFilter, setApiFilter, buscando, reloadCardsFromApi }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterState, setFilterState] = React.useState(filterOptions);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClickFilter = (event, filter) => {
    filter.checked = event.target.checked
    const newFilterState = Object.assign([], filterState)
    setFilterState(newFilterState)
    changeFilter(newFilterState)
  }

  const handleChange = (event) => {
    const newApiFilter = Object.assign([], apiFilter)
    newApiFilter[event.target.name] = event.target.value
    setApiFilter(newApiFilter)
    let importados = newApiFilter.importados

    let persistedFilter = {
      importados
    }

    localStorage.setItem(SAVED_FILTERS_KEY, JSON.stringify(persistedFilter))
  }

  const open = Boolean(anchorEl);

  const isFiltered = filterState.some(group => group.filters.some(filter => filter.checked))

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        onClick={handleClick}
        color={isFiltered ? 'primary' : 'inherit'}
        startIcon={isFiltered ? <CheckCircle /> : null}
        className={!isFiltered ? classes.btnSearch : null}>
        Filtros
      </Button>
      <StyledDrawer
        open={open}
        anchor="right"
        onClose={handleClose}
        ModalProps={{
          BackdropProps: {
            invisible: true
          }
        }}>
        <DialogTitle onClose={handleClose} />
        <Box paddingX={2} paddingY={1} className={classes.filterGroups}>
          {filterState.map(filterGroup => (
            <FormControl key={filterGroup.groupLabel} component="fieldset" margin="dense">
              <FormLabel component="legend">{filterGroup.groupLabel}</FormLabel>
              <FormGroup className={filterGroup.groupLabel === 'Integrações' ? classes.filterGroupIntegracao : null}>
                {filterGroup.filters.map(filter => (
                  <FormControlLabel
                    key={filter.label}
                    label={filter.label}
                    control={<Checkbox checked={filter.checked} />}
                    onChange={(event) => handleClickFilter(event, filter)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ))}
        </Box>
        <Box p={1}>
          <CustomDateFilter onChange={changePeriodo} apiFilter={apiFilter} />
          <Box p={1} textAlign="center">
            <FormControl component="fieldset">
              <RadioGroup row name="importados" value={apiFilter.importados} onChange={handleChange}>
                <FormControlLabel value={"true"} control={<Radio />} label="Importados" />
                <FormControlLabel value={"false"} control={<Radio />} label="Não importados" />
                <FormControlLabel value={"undefined"} control={<Radio />} label="Ambos" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box p={1} justifyContent="center" display="flex" alignItems="center">
          <Button onClick={reloadCardsFromApi}
            size="small"
            color="primary"
            fullWidth
            style={{ maxWidth: 300 }}
            disabled={buscando}
            variant='outlined'> {buscando ? 'Buscando...' : 'Buscar'}
          </Button>
        </Box>
      </StyledDrawer>
    </div>
  );
}