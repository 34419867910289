import React, { useEffect } from "react";
import { useOrderContext } from "../../../context/OrderProvider";
import { useMediaQuery } from "@mui/material";

const ButtonFullScreen = React.memo(({ google, map }) => {
    const { setIsFullScreen, isFullScreen } = useOrderContext();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

    function createFullScreenControl() {
        const controlButton = document.createElement("button");

        controlButton.style.backgroundColor = "#fff";
        controlButton.style.border = "2px solid #fff";
        controlButton.style.boxShadow = "0 2px 6px #666666";
        controlButton.style.color = "#666666";
        controlButton.style.cursor = "pointer";
        controlButton.style.margin = "8px";
        controlButton.style.width = "40px";
        controlButton.style.display = isMobile ? "none" : "block";
        controlButton.style.height = "40px";

        const fullScreenIcon = `
            <svg width="30" fill= "#666666"  height="30">
                <path d="M 4 15 L 4 7 L 12 7 L 12 10 L 7 10 L 7 15 L 4 15 M 15 10 L 15 7 L 23 7 L 23 15 L 20 15 L 20 10 L 15 10 M 4 18 L 7 18 L 7 23 L 12 23 L 12 26 L 4 26 M 15 26 L 15 23 L 20 23 L 20 18 L 23 18 L 23 26 L 15 26 M 4 26 L 4 18 M -18 6"/>
            </svg>
        `;

        const minimizeScreenIcon = `
        <svg width="30" fill="#666666" height="30">
            <path d="M 4 15 L 4 12 L 9 12 L 9 7 L 12 7 L 12 10 L 12 15 L 7 15 L 4 15 M 15 10 L 15 7 L 18 7 L 18 12 L 23 12 L 23 12 L 23 15 L 20 15 L 15 15 L 15 10 M 4 18 L 7 18 L 12 18 L 12 23 L 12 26 L 9 26 L 9 21 L 4 21 M 15 26 L 15 23 L 15 18 L 20 18 L 23 18 L 23 21 L 18 21 L 18 26 L 15 26 M 4 21 L 4 18"/>
        </svg>
    `;
        
        controlButton.innerHTML = isFullScreen ? minimizeScreenIcon : fullScreenIcon;
        controlButton.title = "Clique para deixar em tela cheia";
        controlButton.type = "button";

        controlButton.addEventListener("click", () => {
            setIsFullScreen(!isFullScreen)
        });

        return controlButton;
    }

    useEffect(() => {
        if (google && map) {
            const centerControl = createFullScreenControl();

            const centerControlDiv = document.createElement("div");
            centerControlDiv.appendChild(centerControl);

            if (map.controls) {
                map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
            }
        }
    }, [google, map]);

    return null;
});

export default ButtonFullScreen;
