import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import { Dialog, DialogContent, DialogTitle, IconButton, Button, Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';

const PREFIX = 'Cam';

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    container: `${PREFIX}-container`,
    barcodeScanner: `${PREFIX}-barcodeScanner`,
    visionCamera: `${PREFIX}-visionCamera`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,
    [`&.${classes.container}`]: {
        top: 0,
        left: 0,
        maxWidth: 'lg',
        height: '100%',
        position: 'absolute',
    },
    [`& .${classes.barcodeScanner}`]: {
        padding: '20px',
    },
    [`& .${classes.visionCamera}`]: {
        border: '0 !important'
    },
    [`& video`]: {
        width: '100%',
        height: '100%',
    }
}))

function notRepeatPlease(callback, duration = 1000) {
    if (window.noRepeatTimer) {
        return
    }

    callback()

    window.noRepeatTimer = setTimeout(() => window.noRepeatTimer = null, duration)
}

const QuaggaScanner = ({ onDetected, open, close, pedidoList }) => {
    const scannerRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar()
    const [codeDetected, setCodeDetected] = useState(null);

    const handleOnDetected = (data) => {
        const code = data.codeResult.code;

        if (onDetected) {
            notRepeatPlease(() => {
                onDetected(code)
                setCodeDetected(code);  // Exibe o código detectado na tela
            })
        }
    }

    useEffect(() => {
        Quagga.onDetected(handleOnDetected);

        return () => {
            Quagga.offDetected(handleOnDetected);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidoList, onDetected]);

    const initQuagga = () => {
        if (scannerRef.current) {
            Quagga.init({
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        facingMode: "environment",  // Usar a câmera traseira por padrão
                        aspectRatio: { min: 1, max: 2 },
                    },
                    target: scannerRef.current
                },
                locate: true,
                numOfWorkers: 4,
                decoder: {
                    readers: ['code_128_reader', 'ean_reader', 'codabar_reader', 'upc_reader', 'upc_e_reader']
                },
            }, (err) => {
                if (err) {
                    console.error('Erro ao inicializar Quagga:', err)
                    enqueueSnackbar(`${err}`, { variant: 'error' })
                    return
                }
                Quagga.start()
            })
        }
    }

    const handleClose = () => {
        Quagga.offDetected(handleOnDetected)
        Quagga.stop()
        close()
    }

    useEffect(() => {
        initQuagga();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledDialog
            open={open}
            fullWidth={true}
            sx={{
                maxHeight: "lg",
            }}
            onClose={handleClose}
            TransitionProps={{
                onEntered: () => initQuagga(),
            }}
        >
            <DialogTitle>
                Escanear Código
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent style={{ height: '480px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: '16px' }}>
                <div ref={scannerRef} style={{ width: '100%', height: '100%', position: 'relative' }}></div>

                {codeDetected && (
                    <Box style={{ textAlign: 'center', marginTop: '16px' }}>
                        <Typography variant='h5'>Código Detectado:</Typography>
                        <Typography>{codeDetected}</Typography>
                    </Box>
                )}

                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<DoneIcon />}
                    onClick={handleClose}>
                    Confirmar
                </Button>
            </DialogContent>

        </StyledDialog>
    )
}

export default QuaggaScanner;
