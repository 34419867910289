import React from "react";
import { StyledBoxButton, StyledIconButton } from "../styles";
import MapIcon from "@mui/icons-material/Map";
import { enqueueSnackbar } from "notistack";
import getShareableLink from "../../../../../utils/getShareableLink";
import { useAppDeliveryManProvider } from "../../../../../context/AppDeliveryManProvider";

const ButtonMap = ({ activatedButton }) => {
  const { selectedOrder } = useAppDeliveryManProvider();
  const handleCopyLink = async () => {
    try {
      const orders = { pedidos: selectedOrder };
      await getShareableLink({ item: orders }).then((res) => {
        if (!res) {
          enqueueSnackbar("Link da rota não disponível", { variant: "error" });
          return;
        }
        window.open(res, "_blank");
      });
    } catch (err) {
      enqueueSnackbar("Erro ao copiar o link.", { variant: "error" });
    }
  };
  return (
    <StyledBoxButton
      sx={{
        opacity: activatedButton ? 1 : 0,
        transform: activatedButton
          ? "translateX(-50%) translateY(0)"
          : "translateX(-50%) translateY(10px)",
        pointerEvents: activatedButton ? "auto" : "none",
      }}
    >
      <StyledIconButton onClick={handleCopyLink}>
        <MapIcon fontSize="large" />
      </StyledIconButton>
    </StyledBoxButton>
  );
};

export default ButtonMap;
