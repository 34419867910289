import React from "react";
import { StyledButton } from "../styles";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const ButtonFinnaly = ({
  isFinallyDelivery,
  isInitDelivery,
  handleClickFinnalyDelivery,
}) => {
  return (
    <StyledButton
      variant={isFinallyDelivery ? "contained" : "outlined"}
      sx={{
        opacity: isInitDelivery ? 1 : 0,
        transform: isInitDelivery
          ? "translateX(-50%) translateY(0)"
          : "translateX(-50%) translateY(10px)",
        pointerEvents: isInitDelivery ? "auto" : "none",

        background: (theme) =>
          isFinallyDelivery
            ? theme.palette.personal.main
            : theme.palette.personal.background,
        "&:hover": {
          background: (theme) =>
            isFinallyDelivery
              ? theme.palette.personal.main
              : theme.palette.personal.background,
        },
      }}
      onClick={handleClickFinnalyDelivery}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          mr="0.5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CheckIcon />
        </Box>
        <Typography variant="h4">Finalizar</Typography>
      </Box>
    </StyledButton>
  );
};

export default ButtonFinnaly;
