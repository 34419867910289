import React, { useState, useEffect, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
    Dialog,
    Button,
    TextField,
    CircularProgress,
    Grid,
    Typography,
    Paper,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { DialogContent, DialogActions } from "../../CustomDialog/dialogComponents"
import AuthService from '../../../../service/AuthService';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';
import AddIcon from '@mui/icons-material/Add';
import DialogCustomer from "./DialogCustomer"
import { Alert } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { formatPhoneBR } from '../../../../util/formatter';
import { messagesCustomer } from '../../../../util/customer/helper';
import { AvatarForString } from '../../elements/AvatarForString';
import { enqueueSnackbar } from 'notistack';

const PREFIX = 'CustomerSearch'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    '& .MuiFormControl-root': {
        marginTop: 0,
    }
}))

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',

        'button.MuiButton-root': {
            marginLeft: 0,
        }
    }
}))

const formatCustomerOption = (option) => {
    const elements = [];

    if (option.fone) {
        elements.push(formatPhoneBR(String(option.fone)));
    }
    if (option.endereco) {
        elements.push(option.endereco);
    }
    if (option.numero) {
        const numeroWidthPrefix = `Nº ${option.numero}`
        elements.push(numeroWidthPrefix);
    }

    return elements.join(' - ');
};

export default function CustomerSearch({ onConfirm, open, setOpen, defaultCustomer, size, mandatoryCustomer = false, order }) {
    const btnConfirmRef = useRef(null)
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [alertCustomer, setAlertCustomer] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [openDialogCustomer, setOpenDialogCustomer] = useState(false)

    useEffect(() => {
        if (selectedCustomer) {
            setAlertCustomer(messagesCustomer(selectedCustomer))
        }
    }, [selectedCustomer])

    useEffect(() => {
        if (open) {
            setSelectedCustomer(defaultCustomer)
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (btnConfirmRef.current) {
            btnConfirmRef.current.focus()
        }
    }, [selectedCustomer]);

    const hadleEditCustomer = (customer) => {
        setSelectedCustomer(customer)
        setOpenDialogCustomer(true)
    }

    const hadleSelectCustomer = (customer) => {
        setSelectedCustomer(customer)
    }

    const hadleNewCustomer = (nameValue = '', phoneValue = '') => {
        const profile = new AuthService().getProfile()

        const newCustomer = {
            tipo: "F",
            nome: String(nameValue).toUpperCase(),
            fone: phoneValue,
            municipio: (profile?.unidade?.pessoa?.municipio),
            codigoReferencial: 'SV2' + (new Date().getTime()) + parseInt(Math.random() * 99999),
        }

        setSelectedCustomer(newCustomer)
        setOpenDialogCustomer(true)
    }

    const handleConfirm = () => {

        setOpen(false)
        if (onConfirm) {
            onConfirm(selectedCustomer)
        }
    }

    const handleSearch = (value) => {
        if (!value) {
            setOptions([])
            return undefined
        }

        setLoading(true)

        const service = new AuthService()

        service.get(`/api-v2/pessoa/list?q=${value}&ativo=true`)
            .then(pessoas => {
                if (pessoas.length === 0) {
                    setOptions([]);
                } else {
                    setOptions(pessoas);
                }
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                setLoading(false)
            })
    }

    function handleClose() {
        if (!selectedCustomer?.id) {
            setSelectedCustomer(null)
        }

        setOpenDialogCustomer(false)
    }

    const handleMandatoryCustomer = (mandatoryCustomer, selectedCustomer) => {
        if (mandatoryCustomer && !!!selectedCustomer && order?.tipo === 'CAIXA_RAPIDO') {
            enqueueSnackbar(`Para prosseguir, selecione um cliente e confirme a seleção.`, { variant: "error" });
            return;
        }

        setOpen(false)
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchThrottle = useMemo(() => throttle(handleSearch, 200), [])

    return (
        <Root>
            <Dialog
                open={open}
                onClose={() => handleMandatoryCustomer(mandatoryCustomer, selectedCustomer, setOpen)}
                fullWidth maxWidth={size || 'md'}>
                <DialogContent className={classes.scrollBar}>
                    <Autocomplete
                        value={selectedCustomer}
                        fullWidth
                        selectOnFocus
                        autoHighlight
                        autoComplete={true}
                        handleHomeEndKeys={true}
                        options={options}
                        getOptionLabel={(option) => (option.nome)}
                        filterOptions={(options, params) => {
                            const filtered = Object.assign([], options);

                            if (params.inputValue !== '') {
                                filtered.push({
                                    newElement: true,
                                    inputValue: params.inputValue,
                                    nome: `Adicionar cliente "${params.inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                console.log("IF ", newValue)
                            } else if (newValue && newValue.inputValue) {
                                if (/^\d{10,11}$/.test(newValue.inputValue)) {
                                    hadleNewCustomer('', newValue.inputValue);
                                } else {
                                    hadleNewCustomer(newValue.inputValue);
                                }
                            } else {
                                hadleSelectCustomer(newValue)
                            }
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            handleSearchThrottle(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Busque o cliente pelo nome, telefone..."
                                variant="outlined"
                                autoFocus
                                margin="none"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option, value) => {
                            const matches = match(option.nome, value.inputValue)
                            const parts = parse(option.nome, matches);
                            const formattedParts = formatCustomerOption(option);

                            return (
                                <Grid {...props} key={option.id || value.index} container alignItems="center">
                                    <Grid item>
                                        {!option.newElement && (
                                            <Box p={1}>
                                                <AvatarForString size="small" value={option.nome} />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}

                                        {!option.newElement && (
                                            <Typography variant="body2" color="textSecondary">
                                                {formattedParts}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        }}
                    />

                    {selectedCustomer && selectedCustomer.id && (
                        <Paper sx={{ mt: 1, p: 2 }}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item xs>
                                    <List dense disablePadding>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <AvatarForString value={selectedCustomer.nome} />
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={`${selectedCustomer?.fone ?
                                                    formatPhoneBR(selectedCustomer?.fone) : 'telefone não informado'} 
                                                                ${selectedCustomer?.email ?
                                                        ' | ' + selectedCustomer?.email : ''}`}
                                            />
                                        </ListItem>

                                        <ListItemText >
                                            {alertCustomer && alertCustomer.length > 0 &&
                                                <Alert icon={false} severity="warning">
                                                    {alertCustomer.map((message, index) => (
                                                        <li key={index}>{message}</li>
                                                    ))}
                                                </Alert>
                                            }
                                        </ListItemText>
                                    </List>

                                </Grid>
                                <Grid item >
                                    <Button onClick={() => hadleEditCustomer(selectedCustomer)}>Editar</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                </DialogContent>

                <DialogActionsStyled>
                    <Button color="primary"
                        onClick={() => hadleNewCustomer('')}
                        endIcon={<AddIcon />}>Novo cliente</Button>

                    <Button ref={btnConfirmRef}
                        disabled={!selectedCustomer || selectedCustomer.idPessoa === null}
                        variant="contained"
                        onClick={handleConfirm}
                        endIcon={<DoneIcon />}
                        color="primary">Confirmar</Button>
                </DialogActionsStyled>

            </Dialog>

            <DialogCustomer
                open={openDialogCustomer}
                onClose={handleClose}
                selectedCustomer={selectedCustomer}
                confirmCustomer={hadleSelectCustomer} />
        </Root>
    )
}