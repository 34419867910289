import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useAppDeliveryManProvider } from "../../../../context/AppDeliveryManProvider";
import getShareableLink from "../../../../utils/getShareableLink";
import ButtonFinnaly from "./parts/ButtonFinnaly";
import ButtonInit from "./parts/ButtonInit";
import ButtonMap from "./parts/ButtonMap";
import DialogConfim from "./parts/DialogConfirm";

const ActionsButtons = () => {
  const {
    handleInitDelivery,
    isNotInitDelivery,
    isFinallyDelivery,
    handleFinnalyDelivery,
    selectedOrder,
    handleDispatchAll,
  } = useAppDeliveryManProvider();
  const activatedButton =
    selectedOrder.length && !isNotInitDelivery && !isFinallyDelivery;
  const [open, setOpen] = useState(false);
  const isInitDelivery = selectedOrder.length && !isNotInitDelivery;

  const handleClickFinnalyDelivery = () => {
    if (isFinallyDelivery) {
      handleFinnalyDelivery();
    } else setOpen(true);
  };

  return (
    <React.Fragment>
      <ButtonMap activatedButton={activatedButton} />

      <ButtonInit
        handleInitDelivery={handleInitDelivery}
        isNotInitDelivery={isNotInitDelivery}
      />
      <ButtonFinnaly
        handleClickFinnalyDelivery={handleClickFinnalyDelivery}
        isFinallyDelivery={isFinallyDelivery}
        isInitDelivery={isInitDelivery}
      />
      <DialogConfim
        onCancel={() => {
          setOpen(false);
        }}
        open={open}
        onConfirm={handleDispatchAll}
      />
    </React.Fragment>
  );
};

export default ActionsButtons;
