export const darkMapStyle = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.medical",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.place_of_worship",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.school",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.sports_complex",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "transit",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
    },
    {
        featureType: "administrative",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
    }
];


export const mapStyles = [
    {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.medical",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.attraction",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.place_of_worship",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.school",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi.sports_complex",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "transit",
        stylers: [{ visibility: "off" }]
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
    },
    {
        featureType: "administrative",
        elementType: "labels",
        stylers: [{ visibility: "on" }]
    }
];