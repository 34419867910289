import { Box, Typography } from "@mui/material";
import React from "react";
const DialogOrderHeader = ({ order }) => (
  <Box sx={{ paddingRight: "1rem", width: "95%" }}>
    <Box display="flex" width="100%" justifyContent="space-between">
      <Box my="2rem" width="100%">
        <Typography variant="h4" fontWeight="bold">
          Pedido #{order?.id}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default DialogOrderHeader;
