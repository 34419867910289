import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GoogleApiWrapper, Map } from "google-maps-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AuthService from "../../../../../../service/AuthService";
import { darkMapStyle, mapStyles } from "../../../../../../util/mapStyle";
import { useOrderContext } from "../../context/OrderProvider";
import ButtonFullScreen from "./ButtonFullScreen";
import MarkerHouseComponent from "./MarkerHouseComponent";
import MarkersOrders from "./MarkersOrders";


const MapStyled = styled(Box)(() => ({
    "&": {
        position: "relative",
    },

    "& .gm-style > div:last-child": {
        display: "none !important",
    },

    "& .gmnoprint, & .gm-style-cc": {
        display: "none !important",
    },

    [`& .gm-style-iw-d`]: {
        overflow: "hidden !important",
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 5,
        color: "#000",
    },
}));

const MapRouter = (props) => {
    const { orders, isFullScreen } = useOrderContext();
    const [markers, setMarkers] = useState([]);
    const theme = useTheme();
    const memoizedOrders = useMemo(() => orders, [orders]);
    const isDarkMode = theme.palette.mode === "dark";
    const service = new AuthService();
    const profile = service.getProfile();

    const initialCenter = {
        lat: profile.unidade.pessoa.latitude || 0,
        lng: profile.unidade.pessoa.longitude || 0,
    };
    const getLatLng = useCallback(
        (pedido) => {
            if (!props.google) return Promise.resolve(null);
            const {
                streetName,
                streetNumber,
                neighborhood,
                city,
                state,
                postalCode,
            } = pedido?.iFoodOrder?.deliveryAddress || {};
            const address = `${streetName} ${streetNumber} ${neighborhood} , ${city} - ${state}, ${postalCode}`;
            return new Promise((resolve) => {
                const geocoder = new props.google.maps.Geocoder();
                geocoder.geocode({ address }, (results, status) => {
                    if (status === "OK" && results[0]) {
                        const { lat, lng } = results[0].geometry.location;
                        resolve({ id: pedido.id, lat: lat(), lng: lng() });
                    } else {
                        console.warn(`Erro ao buscar endereço: ${status}`);
                        resolve(null);
                    }
                });
            });
        },
        [props.google]
    );

    useEffect(() => {
        const fetchData = async () => {
            const sortedOrders = memoizedOrders.sort((a, b) => {
                if (a?.agrupadorDespacho && !b?.agrupadorDespacho) {
                    return -1;
                } else if (!a?.agrupadorDespacho && b?.agrupadorDespacho) {
                    return 1;
                }
                return 0;
            });
            const newMarkers = await Promise.all(
                sortedOrders.map(async (pedido) => {
                    const { streetName, city, state } =
                        pedido?.iFoodOrder?.deliveryAddress || {};
                    if (!streetName || !city || !state) {
                        return null;
                    }

                    return getLatLng(pedido);
                })
            );
            const validMarkers = newMarkers.filter((marker) => marker !== null);
            const filteredMarkers = validMarkers.filter((newMarker) => {
                return !markers.some(
                    (existingMarker) => existingMarker.id === newMarker.id
                );
            });
            setMarkers((prevMarkers) => [...prevMarkers, ...filteredMarkers]);
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedOrders, getLatLng]);


    const markerPositions = React.useMemo(() => {
        return markers.reduce((acc, marker) => {
            acc[marker.id] = { lat: marker.lat, lng: marker.lng };
            return acc;
        }, {});
    }, [markers]);
    return (
        <>
            <MapStyled>
                <Map
                    google={props.google}
                    height="100%"
                    key={isDarkMode ? "dark" : "light"}
                    className="mapStyled"
                    id="map"
                    initialCenter={initialCenter}
                    containerStyle={{
                        position: "relative",
                        width: "100%",
                        minHeight: isFullScreen ? '84vh' : '80vh', // Condicional para altura
                    }}                  
                    zoomControl={true}
                    streetViewControl={false}
                    mapTypeControl={false}
                    fullscreenControl={false}
                    styles={isDarkMode ? darkMapStyle : mapStyles}
                    zoomControlOptions={{
                        position: props.google.maps.ControlPosition.LEFT_BOTTOM,
                    }}
                    zoom={13}
                >

                    {memoizedOrders.map((pedido) => {
                        const position = markerPositions[pedido.id];
                        return (
                            <MarkersOrders key={pedido.id} google={props.google} map={props.map} position={position} order={pedido} />
                        );
                    })}
                    <ButtonFullScreen google={props.google} map={props.map} />
                    <MarkerHouseComponent google={props.google} map={props.map} />
                </Map>
            </MapStyled>
        </>
    );
};

const Loading = () => <div>Fancy loading container</div>;

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["visualization", "places"],
    LoadingContainer: Loading,
})(MapRouter);
