import styled from '@emotion/styled'
import { Box, Button, CssBaseline, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Order from '../layout-components/NewOrder/Order'
import ImportOrder from '../layout-components/ImportOrder'
import PaymentDialog from '../layout-components/Payment'
import FiscalDocument from '../layout-components/fiscalDocument'
import { TefDialog } from '../layout-components/Payment/Tef'
import { useDispatch, useSelector } from 'react-redux'
import { OrderObj } from '../layout-components/NewOrder/helper/OrderHelper'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import ListAllOrder from '../layout-components/ListAllOrder'
import { PedidosButton } from '../layout-components/Board/Board'
import DetailOrder from '../layout-components/DetailOrder'
import SocketContext from '../../util/socket'
import { Settings } from '@mui/icons-material'
import FastSaleDialogSettings from './dialogSettings/fastSaleSettings'

const Root = styled(Box)(() => ({
    '&': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

export function FastSale() {
    const dispatch = useDispatch()
    const paymentScreensStatus = useSelector(state => state.paymentScreensStatus)
    const [beginToWatch, setBeginToWatch] = useState(false)
    const isPaymentScreensClosed = Object.keys(paymentScreensStatus).every(key => paymentScreensStatus[key] === 'CLOSED')
    const [dialogSettingsOpen, setDialogSettingsOpen] = useState(false)

    const handleOpenSettingsDialog = () => {
        setDialogSettingsOpen(true);
    }

    const handleCloseSettingsDialog = () => {
        setDialogSettingsOpen(false);
    }

    useEffect(() => {
        const fastSale = localStorage.getItem('_fast_sale_')

        if (!beginToWatch && !fastSale) {
            return
        }

        if (isPaymentScreensClosed && fastSale) {
            const fastSaleJSON = JSON.parse(fastSale)
            dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: fastSaleJSON, initActiveStep: 1 } })
            return
        }

        if (isPaymentScreensClosed && !fastSale) {
            const order = new OrderObj()
            order.tipo = 'CAIXA_RAPIDO'
            order.printOrder = false
            dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order, initActiveStep: 1 } })
            return
        }

        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: false, order: new OrderObj() } })

        return () => dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: false, order: new OrderObj() } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentScreensClosed])

    function openFastSaleDialog() {
        setBeginToWatch(true)

        localStorage.removeItem('_fast_sale_')

        const order = new OrderObj()
        order.tipo = 'CAIXA_RAPIDO'
        order.printOrder = false

        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order, initActiveStep: 1 } })
    }

    return (
        <Root>
            <CssBaseline />
            <Grid container direction="row">
                <Grid container item>
                    <Box display='flex'>
                        <Box pl={1} pt={1}>
                            <PedidosButton />
                        </Box>
                        <Box pl={1} pt={1}>
                            <Button
                                startIcon={<Settings />}
                                size='small'
                                color='grey'
                                onClick={handleOpenSettingsDialog}>
                                Configurações
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ p: 4, m: 4, mt: 12, textAlign: 'center' }}>
                        <ElectricBoltIcon fontSize="large" color="primary" />
                        <Typography variant="h1">Inicie aqui uma venda rápida</Typography>
                        <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            onClick={openFastSaleDialog}>
                            Abrir Venda Rápida
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Order />
            <TefDialog />
            <DetailOrder />
            <ImportOrder />
            <ListAllOrder />
            <PaymentDialog />
            <SocketContext />
            <FiscalDocument />
            <FastSaleDialogSettings
                maxWidth='xs'
                fullWidth
                open={dialogSettingsOpen}
                onClose={handleCloseSettingsDialog}
            />
        </Root>
    )
}