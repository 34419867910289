import { Autocomplete, TextField, IconButton, Box } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useEffect, useState } from "react";
import QuaggaScanner from "../../../../../DialogCodeBarReader";

const DeliveryPersonAutocomplete = ({ listDeliveryMan, onChange, itemId, value }) => {
  const [openQuaggaScanner, setOpenQuaggaScanner] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  function openCodeBarReaderDialog() {
    setOpenQuaggaScanner(true);
  }
  function handleDetectedCode(code) {
    if (code.length === 13 && code.startsWith("99")) {
      setSearchValue(code);
      return;
    } else if (code.length === 13 && code.startsWith("98")) {
      setSearchValue("");
      return;
    }
  }

  useEffect(() => {
    const extractIdFromBarCodeDeliveryMan = (barcode) => {
      let idString = barcode.slice(2, 12);
      const id = parseInt(idString, 10);
      const deliveryMan = listDeliveryMan.find(
        (deliveryMan) => deliveryMan.id === id
      );
      return deliveryMan;
    };
    const deliveryMan = extractIdFromBarCodeDeliveryMan(searchValue);
    if (deliveryMan) {
      onChange(extractIdFromBarCodeDeliveryMan(searchValue));
    }
    // eslint-disable-next-line
  }, [searchValue]);

  function closeCodeBarReaderDialog() {
    setOpenQuaggaScanner(false);
  }

  return (
    <Box mb="1rem">
      <Autocomplete
        blurOnSelect
        value={value}
        options={listDeliveryMan}
        onChange={(_, newValue) => onChange(newValue)}
        getOptionLabel={(option) => (option ? option.nome : "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.nome}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name={`dispatch.${itemId}`}
            label="Selecione o entregador"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps?.endAdornment}
                  <IconButton onClick={openCodeBarReaderDialog} size="small">
                    <CameraAltIcon />
                  </IconButton>
                </>
              ),
            }}
          />
        )}
      />
      <QuaggaScanner
        open={openQuaggaScanner}
        close={closeCodeBarReaderDialog}
        setSearchValue={setSearchValue}
        onDetected={handleDetectedCode}
      />
    </Box>
  );
};

export default DeliveryPersonAutocomplete;
