import React, { useState } from "react";
import { StyledButton } from "../styles";
import { Box, Typography } from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import DialogConfirmOrders from "./DialogConfirmOrders";
import { useAppDeliveryManProvider } from "../../../../../context/AppDeliveryManProvider";

const ButtonInit = ({ handleInitDelivery, isNotInitDelivery }) => {
  const [open, setOpen] = useState(false);
  const { selectedOrder } = useAppDeliveryManProvider();

  const handleStepInit = () => {
    setOpen(true);
    //handleInitDelivery();
  };

  return (
    <React.Fragment>
      <StyledButton
        variant="contained"
        onClick={handleStepInit}
        sx={{
          opacity: isNotInitDelivery ? 1 : 0,
          transform: isNotInitDelivery
            ? "translateX(-50%) translateY(0)"
            : "translateX(-50%) translateY(10px)",
          pointerEvents: isNotInitDelivery ? "auto" : "none",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            mr="0.5rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <NavigationIcon />
          </Box>
          <Typography variant="h4">Iniciar</Typography>
        </Box>
      </StyledButton>
      <DialogConfirmOrders
        open={open}
        onClose={() => setOpen(false)}
        orders={selectedOrder}
        onFinish={handleInitDelivery}
      />
    </React.Fragment>
  );
};

export default ButtonInit;
