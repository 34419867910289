import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "17rem",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({ list, value, onChange }) {
  const classes = useStyles();

  const handleChange = (event) => {
    const selectedValues = event.target.value;
    const isSelectingAll = selectedValues.includes("Todos");

    if (isSelectingAll) {
      if (value.includes("Todos")) {
        onChange(selectedValues.filter((val) => val !== "Todos"));
      } else {
        onChange(["Todos"]);
      }
    } else {
      if (value.includes("Todos")) {
        onChange(selectedValues.filter((val) => val !== "Todos"));
      } else {
        onChange(selectedValues);
      }
    }
  };


  return (
    <Box sx={{ minWidth: "16rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Situações</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          sx={{ ml:"1rem", maxWidth: "16rem" }}
          value={value}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          <MenuItem value={"Todos"}>
            <Checkbox checked={value.indexOf("Todos") > -1} />
            <ListItemText primary={"Todos"} />
          </MenuItem>

          {list.map((name) => (
            <MenuItem key={name.name} value={name.name}>
              <Checkbox checked={value.indexOf(name.name) > -1} />
              <ListItemText primary={name.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
