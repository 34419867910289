import React, { useEffect, useState } from 'react'
import AuthService from '../../service/AuthService'
import { Alert, Autocomplete, Box, Button, Chip, Collapse, Container, CssBaseline, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { extractErrorMessage } from '../../util/http/HttpUtil'
import { enqueueSnackbar } from 'notistack'
import { styled } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SendIcon from '@mui/icons-material/Send';
import { currencyFormat } from '../../util/formatter'
import { LoadingButton } from '@mui/lab'
import SyncIcon from '@mui/icons-material/Sync';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const PREFIX = 'IfoodCatalog'

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },

    [`& .${classes.main}`]: {
        marginTop: theme.spacing(5),
    }
}))

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CardapioIntegracao() {
    const service = new AuthService()
    const profile = service.getProfile()
    const [integracoesIFood, setIntegracoesIFood] = useState([])
    const [selectedIndex, setSelectedIndex] = React.useState('')
    const [selectedMerchant, setSelectedMerchant] = React.useState('')
    const [selectedCatalogs, setSelectedCatalogs] = React.useState('')
    const [catalogs, setCatalogs] = React.useState([])
    const [categories, setCategories] = React.useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (!!profile) {
            findIfoodConfig()
        }

        service.get('/api-v2/produtoCardapio/listAllProducts')
            .then(products => {
                let productsWithoutServices = products.filter(product => product.tipoProduto !== 'S')
                setProducts(productsWithoutServices)
            }).catch(err => {
                console.error(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function findIfoodConfig() {
        service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=IF')
            .then(integracoesIF => {
                if (!integracoesIF?.length) {
                    return
                }
                integracoesIF.forEach(integracao => {
                    integracao.dados = JSON.parse(integracao.dados)
                    integracao.dados.IFOOD_MERCHANT_ID = integracao.dados?.IFOOD_MERCHANT_ID.split(",") || []
                })
                setIntegracoesIFood(integracoesIF)
            }).catch(err => {
                console.error(err)
                extractErrorMessage(err, '')
                    .then(msg => {
                        enqueueSnackbar('Não foi possível buscar a configuração do widget do iFood. ' + msg, { variant: 'error' })
                    })
            })
    }

    function buscarTipo(tipo) {

        let integracao
        if (selectedIndex === '' && integracoesIFood.length > 1) {
            enqueueSnackbar('Nenhuma integração selecionada', { variant: 'error' })
            return
        } else if (selectedIndex === '' && integracoesIFood.length === 1) {
            integracao = integracoesIFood[0]
        } else {
            integracao = integracoesIFood[selectedIndex]
        }

        let query = null

        if (selectedMerchant !== '') {
            let merchantId = integracao.dados?.IFOOD_MERCHANT_ID[selectedMerchant]
            query = !!merchantId ? `merchantId=${merchantId}` : ""
        }

        if (selectedCatalogs !== '') {
            query = !!query ? query + '&' : ''

            let CatalogId = catalogs[selectedCatalogs].catalogId
            query = query + (!!CatalogId ? `catalogId=${CatalogId}` : '')
        }



        service.get(`/api-v2/pedidoIntegracao/getIfoodAction/${integracao.id}/${tipo}` + (!!query ? '?' + query : ''))
            .then(response => {
                const parsedObject = JSON.parse(response.message);
                if (tipo === "PRODUCTS") { //inativado
                    //setProducts(parsedObject)
                } else if (tipo === "CATALOGS") {
                    setCatalogs(parsedObject)
                } else if (tipo === "CATEGORIES") {
                    setCategories(parsedObject)
                }
            }).catch(err => {
                console.error(err)
                extractErrorMessage(err, '')
                    .then(msg => {
                        enqueueSnackbar('Não foi possível buscar os produtos do iFood. ' + msg, { variant: 'error' })
                    })
            })
    }

    const handleMenuItemClick = (event) => {
        setSelectedIndex(event.target.value);
    };

    const handleMenuItemClickMerchant = (event) => {
        setSelectedMerchant(event.target.value);
    };

    const handleMenuItemClickCatalogs = (event) => {
        setSelectedCatalogs(event.target.value);
    };

    useEffect(() => {
        if (selectedMerchant !== '') {
            buscarTipo("CATALOGS")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMerchant])

    useEffect(() => {
        if (selectedCatalogs !== '') {
            buscarTipo("CATEGORIES")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCatalogs])

    return (
        <Root className={classes.root}>
            <Container component="main" maxWidth={false} className={classes.main}>

                <CssBaseline />

                <Typography variant="h2" component="h1" marginBottom={'20px'}>
                    Gestão de cardápios integrados
                </Typography>
                <Typography variant="h5" component="h2">
                    Ative ou desative itens dos seus cardápios integrados
                </Typography>

                <Box pt={2}>
                    <Alert variant="outlined" severity="warning">
                        A gestão de cardápios esta disponível apenas para clientes que possuem integração ativa com o iFood
                    </Alert>
                </Box>

                <Box pt={2}>

                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                        <Grid item xs={2} sm={4} md={4} >

                    {integracoesIFood?.length > 0 && (
                                <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Integração</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedIndex}
                                onChange={(event) => handleMenuItemClick(event)}
                                label={"Integração"}
                            >
                                <MenuItem value="">
                                    <em>Selecione a integração</em>
                                </MenuItem>
                                {integracoesIFood.map((integracao, indexBrand) => {

                                    return (
                                        <MenuItem key={indexBrand} value={indexBrand}>{integracao.descricao}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )}
                        </Grid>

                        <Grid item xs={2} sm={4} md={4} >
                    {selectedIndex !== '' && integracoesIFood[selectedIndex]?.dados?.IFOOD_MERCHANT_ID.length > 0 && (
                                <FormControl fullWidth >
                                    <InputLabel id="label-merchant">Loja da integração</InputLabel>
                            <Select
                                labelId="label-merchant"
                                id="select-merchant"
                                label={"ID da loja do Ifood"}
                                value={selectedMerchant}
                                onChange={(event) => handleMenuItemClickMerchant(event)}
                            >
                                <MenuItem value="">
                                    <em>Selecione a loja do Ifood</em>
                                </MenuItem>
                                {integracoesIFood[selectedIndex].dados.IFOOD_MERCHANT_ID.map((merchantId, indexBrand) => {
                                    return (
                                        <MenuItem key={indexBrand} value={indexBrand}>Loja {indexBrand + 1}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )}
                        </Grid>

                        <Grid item xs={2} sm={4} md={4} >

                    {!!catalogs && catalogs.length > 0 && (
                                <FormControl fullWidth>
                                    <InputLabel id="label-catalog">Cardápio</InputLabel>
                            <Select
                                labelId="label-catalog"
                                id="select-catalog"
                                label={"Cardápio do Ifood"}
                                value={selectedCatalogs}
                                onChange={(event) => handleMenuItemClickCatalogs(event)}
                            >
                                <MenuItem value="">
                                    <em>Selecione o catálogo</em>
                                </MenuItem>
                                {catalogs.map((catalog, indexBrand) => {

                                    return (
                                        <MenuItem key={indexBrand} value={indexBrand}>Catálogo {indexBrand + 1}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )}
                        </Grid>

                    </Grid>

                </Box>

                <IfoodCategories categories={categories} setCategories={setCategories} integracao={integracoesIFood[selectedIndex]} merchantId={integracoesIFood[selectedIndex]?.dados?.IFOOD_MERCHANT_ID[selectedMerchant]} products={products} />

            </Container>
        </Root>
    )
}

function IfoodCategories({ categories, setCategories, integracao, merchantId, products }) {
    const service = new AuthService()
    const [collapsed, setCollapsed] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [externalCodes, setExternalCodes] = React.useState({})

    function atualizarElemento(tipo, item, checked) {

        setLoading(true)

        let body = {
            integracaoId: integracao.id,
            merchantId: merchantId,
            itemId: item.id,
            optionId: item.id,
            externalCode: !!externalCodes[item.id] ? String(externalCodes[item.id]) : item.externalCode,
            status: checked ? "AVAILABLE" : "UNAVAILABLE"
        }

        service.post(`/api-v2/pedidoIntegracao/postIfoodAction/${tipo}`, body)
            .then(response => {

                if (tipo === "STATUS" || tipo === "SUB_STATUS") {

                    let categoriesCopy = Object.assign([], categories)
                    let alterado = false

                    for (const categoria of categoriesCopy) {

                        for (const itemToCompare of categoria.items || []) {

                            if (!alterado && itemToCompare.id === item.id) {
                                itemToCompare.status = checked ? "AVAILABLE" : "UNAVAILABLE";
                                alterado = true;
                                break;
                            }

                            for (const optionGroups of itemToCompare.optionGroups) {
                                for (const option of optionGroups.options) {
                                    if (option.id === item.id) {
                                        option.status = checked ? "AVAILABLE" : "UNAVAILABLE";
                                        alterado = true;
                                        break;
                                    }
                                }
                                if (alterado) break;
                            }

                            if (alterado) break;
                        }


                        if (alterado) break;
                    }

                    if (alterado) setCategories(categoriesCopy)
                }

            }).catch(err => {
                extractErrorMessage(err, '')
                    .then(msg => {
                        enqueueSnackbar('Não foi possível atualizar o item no IFOOD ' + msg, { variant: 'error' })
                    })
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            {!!categories && categories.length > 0 && (

                <>
                    <Box pb={2}>
                        <Typography variant="h4" component="h4" sx={{ marginTop: '15px', marginBottom: '5px' }}>
                            Categorias
                        </Typography>
                    </Box>
                    {categories?.map((categoria) => {
                        return (
                            <Accordion key={categoria.id} elevation={1}>

                                <AccordionSummary>
                                    <Typography>
                                        {categoria.name}
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails >
                                    {categoria?.items?.length > 0 && (
                                        <>
                                            {categoria.items.map((item) => {
                                                return (
                                                    <Box key={item.id} pt={1}  >
                                                        <ActionButtons item={item} collapsed={collapsed} setCollapsed={setCollapsed} loading={loading} externalCodes={externalCodes} setExternalCodes={setExternalCodes} atualizarElemento={atualizarElemento} products={products} />

                                                        <Collapse in={collapsed[item.id]} timeout="auto" unmountOnExit>

                                                            {item.optionGroups.map((option) => {
                                                                return (
                                                                    <Box key={option.id} >
                                                                        {option.options.map((subOption) => {
                                                                            return (
                                                                                <Box key={subOption.id} p={1} >
                                                                                    <ActionButtons
                                                                                        option={true}
                                                                                        optionName={option.name}
                                                                                        item={subOption}
                                                                                        collapsed={collapsed} setCollapsed={setCollapsed}
                                                                                        loading={loading} externalCodes={externalCodes}
                                                                                        setExternalCodes={setExternalCodes} atualizarElemento={atualizarElemento}
                                                                                        products={products} />
                                                                                        </Box>

                                                                                    )
                                                                                })}
                                                                        </Box>
                                                                    )
                                                                })}

                                                        </Collapse>
                                                    </Box>
                                                )
                                            })}
                                        </>
                                    )}

                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </>
            )}
        </>
    )
}

function ActionButtons({ item, collapsed, setCollapsed, loading, externalCodes, setExternalCodes, atualizarElemento, products, option, optionName }) {

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showExternalId, setShowExternalId] = useState(false)

    const toggleCollapse = (itemId) => {
        setCollapsed(prevState => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const changeExternalId = (item, value) => {
        setExternalCodes(prevState => ({
            ...prevState,
            [item.id]: value,
        }));
    };

    const changeStatus = (item, event) => {
        if (option) {
            atualizarElemento('SUB_STATUS', item, event.target.checked)
        } else {
            atualizarElemento('STATUS', item, event.target.checked)
        }

    };

    const updateExternalId = (item) => {
        if (option) {
            atualizarElemento('SUB_EXTERNALID', item)
        } else {
            atualizarElemento('EXTERNALID', item)
        }

    };

    useEffect(() => {
        if (!!products && !!item.externalCode && item.externalCode !== '') {
            let productSelected = products.find(product => String(product.id) === item.externalCode) || null
            setSelectedProduct(productSelected)

            if (!!!productSelected) {
                setShowExternalId(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container spacing={0} >
            <Grid item xs={4}>
                {option && (<Chip label={optionName} size="small" color="primary" variant='outlined' />)}

                {option ? (<Typography
                    variant={"body1"}>
                    {item.name}
                </Typography>) : (<Typography
                    sx={{ fontWeight: 'bold' }}
                    variant={"subtitle1"}>
                    {item.name}
                </Typography>)}

                {!!item.description && (
                    <Typography
                        display="block"
                        variant="body2"
                        color="textSecondary" >
                        {item.description}
                    </Typography>
                )}
            </Grid>

            <Grid item xs={1}>
                <Typography
                    display="block"
                    variant="body1" >
                    {currencyFormat(item?.price?.value || 0.0)}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={loading}
                            checked={item?.status === 'AVAILABLE'}
                            onChange={(event) => { changeStatus(item, event) }}
                            name="ItemAtivo"
                            color="primary" />
                    }
                    label="Ativo"
                    labelPlacement="start"
                />
            </Grid>

            <Grid item xs={3}>
                <Box sx={{ display: "flex" }} >
                    {showExternalId ? (
                        <TextField
                            id="externalCode"
                            label="ID externo IFOOD"
                            name='externalCode'
                            size='small'
                            value={externalCodes[item?.id] || item?.externalCode || ''}
                            variant="outlined"
                            onChange={(event) => { changeExternalId(item, event.target.value) }}
                        />
                    ) : (
                        <Autocomplete
                            sx={{ flex: 1 }}
                            loading={loading}
                            size='small'
                            options={products}
                            value={selectedProduct}
                            isOptionEqualToValue={(option, value) => {
                                return option && option?.id === value?.id
                            }}
                            onChange={(event, newValue) => {
                                setSelectedProduct(newValue);
                                changeExternalId(item, newValue?.id)
                            }}
                            getOptionLabel={(option) => option.descricao || null}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.descricao}</li>}
                            renderInput={(params) => <TextField fullWidth {...params} label="Produto associado" />}
                        />
                    )}

                    <Tooltip title="Alternar entre ID externo e produto associado">
                        <IconButton onClick={() => { setShowExternalId(!showExternalId) }} color="primary" variant="contained" aria-label="alternar">
                            <SyncIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <LoadingButton
                    variant="outlined"
                    endIcon={<SendIcon />}
                    loading={loading}
                    onClick={() => { updateExternalId(item) }}
                    loadingPosition="end">
                    Atualizar
                </LoadingButton>
            </Grid>

            <Grid item xs={1}>
                {item?.optionGroups?.length > 0 && (
                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={() => { toggleCollapse(item.id) }}>
                            {collapsed[item.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                )}
            </Grid>

        </Grid>
    )
}