import React, { useEffect, useState } from 'react'

import { IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AuthService from '../../service/AuthService'

export function AlertNotification() {
    const theme = useTheme()
    const profile = new AuthService().getProfile()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'), { defaultMatches: true })
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (window.sischefNotificationWidget || isMobile) return

        const script = document.createElement('script')
        script.async = true
        script.type = 'text/javascript'
        script.src = 'https://monitor-impressao.sischef.com/libs/sischef-notification.js'
        script.onload = () => setLoaded(true)

        document.body.appendChild(script)

        return () => {
            const sischefWidgetIframe = document.querySelector('.sischef-widget-iframe')
            if (sischefWidgetIframe) {
                sischefWidgetIframe.remove()
            }

            document.body.removeChild(script)
        }
    }, [isMobile])

    useEffect(() => {
        if (!loaded || !window.sischefNotificationWidget) return

        window.sischefNotificationWidget.init({
            theme: theme.palette.mode,
            application: 'PDV_ONLINE',
            username: profile.username,
        })

        return () => {
            if (window.sischefNotificationWidget) {
                window.sischefNotificationWidget.destroy()
            }
        }
    }, [loaded])

    if (isMobile) {
        return null
    }

    return (
        <Tooltip disableInteractive title="Notificações">
            <IconButton id="sischef-widget-wrapper" size="large">
                <NotificationsIcon />
            </IconButton>
        </Tooltip>
    )
}