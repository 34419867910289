import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Quagga from "quagga";
import React, { useEffect, useState } from "react";
import ContentDotCheck from "./parts/ContentDotCheck";
import InitialDialog from "./parts/InitialDialog";
const DialogConfirmOrders = ({ open, onClose, orders, onFinish }) => {
  const [step, setStep] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const [ordersConfirmed, setOrdersConfirmed] = useState([]);

  useEffect(() => {
    setOrdersConfirmed(
      orders.map((order) => ({
        id: order.id,
        confirmed: false,
      }))
    );
  }, [orders]);

  useEffect(() => {
    if (!ordersConfirmed.length) return;
    setIsFinished(ordersConfirmed.every((order) => order.confirmed));
  }, [ordersConfirmed]);

  const buttonText = (() => {
    if (step === 0) return "Começar";
    if (isFinished) return "Concluir";
    return "Escanear";
  })();

  useEffect(() => {
    if (isInvalid) {
      const timer = setTimeout(() => setIsInvalid(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isInvalid]);

  useEffect(() => {
    if (isConfirmed) {
      const timer = setTimeout(() => setIsConfirmed(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isConfirmed]);

  const iconButtonProp = {
    Começar: <PlayArrowIcon />,
    Escanear: <DocumentScannerIcon sx={{ transform: "rotate(90deg)" }} />,
    Concluir: <CheckIcon />,
  };

  const handleConfirm = () => {
    if (step === 0) {
      setStep(1);
    } else {
      const indexOrder = ordersConfirmed.findIndex(
        (order) => order.id === Number(searchValue)
      );
      if (indexOrder !== -1) {
        ordersConfirmed[indexOrder].confirmed = true;
        setOrdersConfirmed([...ordersConfirmed]);
        setSearchValue("");
        setIsConfirmed(true);
        setIsInvalid(false);
      } else {
        setIsInvalid(true);
        setIsConfirmed(false);
        setSearchValue("");
      }
    }
  };

  useEffect(() => {
    if (step && !open) Quagga && Quagga.stop();
    //eslint-disable-next-line
  }, [open]);

  const handleClickButton = () => {
    if (isFinished) {
      onFinish();
      onClose();
    } else {
      handleConfirm();
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        {!!step && (
          <Box mt="1rem">
            <Typography variant="h4">Confirmação dos pedidos</Typography>
          </Box>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "1.5rem",
            right: "1rem",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: "100vh" }}>
        {step === 0 ? (
          <InitialDialog />
        ) : (
          <ContentDotCheck
            setSearchValue={setSearchValue}
            isInvalid={isInvalid}
            isConfirmed={isConfirmed}
            ordersConfirmed={ordersConfirmed}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{ padding: "2rem", display: "flex", justifyContent: "center" }}
      >
        <Button
          sx={{
            borderRadius: "4rem",
            width: "40vw",
            maxWidth: "16rem",
            height: "4rem",
          }}
          onClick={handleClickButton}
          color="primary"
          variant="contained"
        >
          <Box
            mr="0.5rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {iconButtonProp[buttonText]}
          </Box>
          <Typography variant="h4">{buttonText}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmOrders;
