export const initialStateDispatchsOrders = () => {
  return {
    orders: [],
  };
};

export const updateDispatchOrders = (currentState, action) => {
  const index = currentState.dispatchs.orders.findIndex(
    (dispatch) => dispatch.agrupadorDespacho === action.order.agrupadorDespacho
  );
  const ordersUpdate = currentState.dispatchs.orders[index].pedidos.map(
    (pedido) =>
      pedido.id === action.order.id ? { ...pedido, ...action.order } : pedido
  );
  return {
    ...currentState,
    dispatchs: {
      ...currentState.dispatchs,
      orders: [
        ...currentState.dispatchs.orders.filter(
          (dispatch) =>
            dispatch.agrupadorDespacho !== action.order.agrupadorDespacho
        ),
        {
          ...currentState.dispatchs.orders[index],
          pedidos: ordersUpdate,
        },
      ],
    },
  };
};

export const deleteDispatchOrders = (currentState, action) => {
  return {
    ...currentState,
    dispatchs: {
      ...currentState.dispatchs,
      orders: currentState.dispatchs.orders.filter(
        (order) => order.id !== action.order.id
      ),
    },
  };
};

export const finishDispatchOrders = (currentState, action) => {
  return {
    ...currentState,
    dispatchs: {
      ...currentState.dispatchs,
      orders: currentState.dispatchs.orders.map((order) =>
        order.agrupadorDespacho !== action.agrupadorDespacho
          ? order
          : { ...order, visible: false }
      ),
    },
  };
};

export const addDispatchOrders = (currentState, action) => {
  const existingOrders = currentState?.dispatchs?.orders || [];
  const updatedOrders = [...existingOrders];
  const index = updatedOrders.findIndex(
    (order) => order.agrupadorDespacho === action.order.agrupadorDespacho
  );
  const ordersVisible = action.order.pedidos.every(
    (pedido) => pedido.situacao === "ENTREGUE"
  );
  action.order.visible = !ordersVisible;

  if (index !== -1) {
    updatedOrders[index] = { ...updatedOrders[index], ...action.order };
  } else {
    updatedOrders.push(action.order);
  }

  return {
    ...currentState,
    dispatchs: {
      ...currentState.dispatchs,
      orders: updatedOrders,
    },
  };
};
  
  
  export const loadDispatchOrders = (currentState, action) => {
    const orders = action.orders.map((order) => ({
      ...order,
      visible: !order.pedidos.every((pedido) => pedido.situacao === "ENTREGUE"), 
    }));
  
    return {
      ...currentState,
      dispatchs: {
        ...currentState.dispatchs, 
        orders: orders,
      },
    };
  };
  