import React, { memo, useCallback, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { currencyFormat } from '../../../../util/formatter'

const PREFIX = 'ProductList'

const classes = {
    productCardTitle: `${PREFIX}-productCardTitle`,
    productCardPrice: `${PREFIX}-productCardPrice`
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.85)',
    boxShadow: 'none',
    marginBottom: 0,
    borderRadius: 0,

    '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0.85)',
    },

    '& p': {
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const ProductCardGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridGap: theme.spacing(),
    gridAutoRows: '85px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',

    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
}))

const ProductCard = styled('div')(({ theme }) => {
    const spacing = theme.spacing()
    const priceSpacing = theme.spacing(0.5)

    return {
        position: 'relative',
        cursor: 'pointer',
        padding: spacing,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',

        [`& .${classes.productCardTitle}`]: {
            overflow: 'hidden',
            fontSize: '13px',
        },

        [`& .${classes.productCardPrice}`]: {
            position: 'absolute',
            right: spacing,
            bottom: spacing,
            padding: priceSpacing,
            border: `1px solid ${theme.palette.grey[400]}`,
            outline: `3px solid ${theme.palette.common.white}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.white,
        },
    }
})

const GroupItem = memo(function GroupItem({ group, products, handleClick }) {
    const filteredProducts = useMemo(() => products.filter(item => item.produto.idGrupoProduto === group.id), [group, products])

    return (
        <StyledAccordion
            square
            elevation={0}
            TransitionProps={{
                // mountOnEnter: true,
                unmountOnExit: true,
            }}
            style={{ backgroundColor: group.cor || '#cfd8dc' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`panel${group.id}bh-header`}
                aria-controls={`panel${group.id}bh-content`}>
                {group.descricao}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 1 }}>
                <ProductList products={filteredProducts} handleClick={handleClick} />
            </AccordionDetails>
        </StyledAccordion>
    )
})

const ProductItem = memo(function ProductItem({ item, handleClick }) {
    const { produto } = item
    const productPrice = useMemo(() => currencyFormat(produto.valorUnitarioVenda), [produto])

    return (
        <ProductCard onClick={() => handleClick(item)}>
            <div className={classes.productCardTitle}>{produto.descricao}</div>
            <div className={classes.productCardPrice}>{productPrice}</div>
        </ProductCard>
    )
})

export const ProductList = memo(function ProductList({ products, handleClick }) {
    const handleSelectedItem = useCallback(item => handleClick(item), [handleClick])

    if (!products.length) {
        return <Typography>Nenhum produto para o grupo selecionado</Typography>
    }

    // ordenação feita para ficar correta a listagem de produtos no iPhone
    products.sort((a, b) => {
        const descricaoProductA = a.produto.descricao
        const descricaoProductB = b.produto.descricao

        return descricaoProductA.localeCompare(descricaoProductB)
    })

    const itens = products.map(item =>
        <ProductItem
            key={item.produto.idProduto}
            item={item}
            handleClick={handleSelectedItem} />)

    return <ProductCardGrid>{itens}</ProductCardGrid>
})

export const GroupAccordions = memo(function GroupAccordions({ groups, products, handleProductClick }) {
    const handleClick = useCallback(item => handleProductClick(item), [handleProductClick])

    if (!groups.length) {
        return <Typography>Nenhum grupo encontrado</Typography>
    }

    return groups.map(group =>
        <GroupItem
            key={group.id}
            group={group}
            products={products}
            handleClick={handleClick} />
    )
})