import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AuthService from "../../../../../../../../service/AuthService";
import { isAddressValid } from "../../../../../../../../util/customer/helper";
import { useLoadEntregadores } from "../../../../../LoadDeliveryMan";
import { useOrderContext } from "../../../../context/OrderProvider";
import InvalidAddressAlert from "../InvalidAddressAlert";
import DeliveryPersonAutocomplete from "./DeliveryItemAutocomplete";
import EditIcon from "@mui/icons-material/Edit";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorIcon from "@mui/icons-material/Error";
import ListIcon from "@mui/icons-material/List";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { SITUACOES } from "../../../../../../../../util/board/BoardUtil";
import { generateWhatsappLink, handleClick, handleCopyLink } from "../../utils";

import {
  StyledBox,
  StyledRoomIcon,
  StyledContainer,
  StyledIconButton,
} from "./styles";
import ActionsButtons from "./ActionsButtons";

const DispatchItem = ({
  item,
  toggleIframe,
  onClickListAll,
  numberOfInvalidAddresses,
  selectedMan,
  setError,
}) => {
  const [open, setOpen] = useState(false);
  const [deliveryManValue, setDeliveryManValue] = useState(
    item?.pessoa.id === -1 ? null : item.pessoa
  );
  const [routeLink, setRouteLink] = useState("");
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [listDeliveryMan] = useLoadEntregadores();
  const [edit, setEdit] = useState(false);
  const service = new AuthService();
  const profile = service.getProfile();
  const { handleDispatchSend } = useOrderContext();

  const initialCenter = {
    lat: profile.unidade.pessoa.latitude || 0,
    lng: profile.unidade.pessoa.longitude || 0,
  };

  const handleOpenRoute = () => {
    handleClick(
      initialCenter,
      item,
      setRouteLink,
      setIsIframeVisible,
      setError,
      toggleIframe,
      setOpen
    );
  };

  const handleCopy = () => {
    handleCopyLink(
      initialCenter,
      item,
      setRouteLink,
      setIsIframeVisible,
      setError
    );
  };

  const handleSendWhatsApp = () => {
    generateWhatsappLink(
      item,
      initialCenter,
      setRouteLink,
      setIsIframeVisible,
      setError
    );
  };

  const handleEditDispatch = (item) => {
    setEdit(!edit);
    console.log(item);
  };

  const handleEditDeliveryMan = (item, idDeliveryMan) => {
    const pedidoValido = item.pedidos.find((pedido) => pedido.situacao);
    handleDispatchSend(
      item,
      pedidoValido.situacao,
      idDeliveryMan,
      item.agrupadorDespacho || ""
    );
    setEdit(false);
  };

  function statusOrder() {
    const statuses = item.pedidos.map((value) => value.situacao);
    const status = [...new Set(statuses)];
    return status.map((value, index) => {
      const sit = SITUACOES.find((item) => value === item.key);
      if (!sit) return null;
      return (
        <Chip
          key={index}
          sx={{
            borderRadius: "4px",
            marginTop: "0.2rem",
            background: sit.color,
            color: "black",
          }}
          label={sit.name}
          size="small"
        />
      );
    });
  }
  const isOrdersWait = item.pedidos.some(
    (pedido) => pedido.situacao === "AGUARDANDO_RETIRADA"
  );
  const isOrdersSent = item.pedidos.every(
    (pedido) => pedido.situacao === "ENTREGUE"
  );

  return (
    <StyledContainer>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="start"
      >
        <Box
          px="0.5rem"
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <StyledRoomIcon fontSize="large" />
            <Box ml="1rem">
              <Typography variant="inherit">Entregador</Typography>
              <Typography variant="h5">
                {item.pessoa ? item.pessoa.nome : "Nome não disponível"}
              </Typography>
              <Box>{statusOrder()}</Box>
            </Box>
          </Box>

          <Box display="flex">
            {item?.pessoa?.id !== -1 && (
              <Tooltip title="Editar entregador">
                <IconButton onClick={() => handleEditDispatch(item)} size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Descrição do pedido">
              <IconButton onClick={handleOpenRoute}>
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Compartilhar rota por WhatsApp">
              <IconButton onClick={handleSendWhatsApp}>
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copiar link da rota">
              <IconButton onClick={handleCopy}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box
          ml="-1.2rem"
          mr="0.5rem"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {item.pedidos.some(
            (pedido) => !isAddressValid(pedido?.iFoodOrder?.deliveryAddress)
          ) && (
            <Tooltip title="Rota não criada. Verifique o erro na descrição.">
              <ErrorIcon color="error" style={{ cursor: "pointer" }} />
            </Tooltip>
          )}
        </Box>
      </Box>

      <ActionsButtons
        deliveryManValue={deliveryManValue}
        edit={edit}
        isOrdersSent={isOrdersSent}
        isOrdersWait={isOrdersWait}
        item={item}
        listDeliveryMan={listDeliveryMan}
        setEdit={setEdit}
        setDeliveryManValue={setDeliveryManValue}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6">
            Entregador: {item.pessoa ? item.pessoa.nome : "Nome não disponível"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item sx={{ mb: 1 }}>
            <Button
              color="grey"
              disabled={!item.pedidos.length}
              startIcon={<ListIcon />}
              onClick={onClickListAll}
            >
              Listagem dos pedidos
            </Button>
          </Grid>

          <InvalidAddressAlert
            numberOfInvalidAddress={numberOfInvalidAddresses}
          />

          {isIframeVisible &&
            selectedMan === item &&
            !numberOfInvalidAddresses && (
              <StyledBox>
                <Box className="actions">
                  <Tooltip title="Compartilhar rota por WhatsApp">
                  <StyledIconButton onClick={() => handleSendWhatsApp(item)}>
                      <WhatsAppIcon />
                    </StyledIconButton>
                  </Tooltip>
                  <Tooltip title="Copiar link da rota">
                  <StyledIconButton onClick={handleOpenRoute}>
                      <ContentCopyIcon />
                    </StyledIconButton>
                  </Tooltip>
                </Box>

                <iframe
                  title="Rota"
                  height="450"
                  style={{
                    border: 0,
                    overflow: "hidden",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  loading="lazy"
                  allowFullScreen
                  src={routeLink}
              />
              </StyledBox>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} fullWidth autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default DispatchItem;
