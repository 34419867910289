import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getParamByKey } from '../../../../component/parameter';
import {
    Button, Checkbox, Dialog, DialogActions, List, ListItem, ListItemAvatar,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box
} from '@mui/material';
import { currencyFormat, dateFormat } from '../../../../util/formatter';
import { pendingAmount } from '../../../../util/order/orderUtil';
import { createCardFromPedido } from '../../../../util/board/BoardUtil';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IntegrationAvatar from '../../Board/IntegrationAvatar';
import { AvatarForIdentificador } from '../../elements/AvatarForIdentificador';
import { DialogTitle, DialogContent } from "../../../layout-components/CustomDialog/dialogComponents";
import { OrderObj } from '../../NewOrder/helper/OrderHelper';
import { getSettings } from '../../../settings/settings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import FecharPedidoButton from '../CardIdentificador/Principal/FecharPedidoButton';
import { useConnectionStatus } from '../../../../util/http/HttpUtil';
import { getKey } from '../../../../util/indexedDB/IdbUtils';
import { LongText } from '../../../../util/formatter';
import TransferOrder from './DialogTransferOrder';

function OrderListByIdentificadorDialogContent({ handleClose, checkboxState, setCheckboxState }) {
    const dispatch = useDispatch()

    const generalParameters = useSelector(state => state.generalParameters)
    const MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString

    const dialogData = useSelector(state => state.dialogOrderListByIdentificador.data)
    const identificadorCard = dialogData ? dialogData.identificadorCard : null
    const openCloseOrder = dialogData?.openCloseOrder ? dialogData.openCloseOrder : false
    const orderList = identificadorCard?.orderList
    const [orderElements, setOrderElements] = useState(orderList)

    const [disableBtnJuntar, setDisableBtnJuntar] = useState(true)
    const [disableBtnAlterarMesa, setDisableBtnAlterarMesa] = useState(true)
    const [orderSischefList, setOrderSischefList] = useState(null)
    const isConnected = useConnectionStatus()
    const [DialogTransferOrderOpen, setDialogTransferOrderOpen] = useState(false)

    const handleOpenTransferDialog = () => {
        setDialogTransferOrderOpen(true);
    };

    const handleCloseTransferDialog = () => {
        setDialogTransferOrderOpen(false);
    };

    const handleChangeCheckbox = (event) => {
        setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });
    };

    const handleChangeCheckall = (event) => {
        let checked = event.target.checked;
        let checkValues = {}
        orderList.map(order => {
            checkValues = { ...checkValues, [order.id]: checked }
            return null;
        })
        setCheckboxState(checkValues);
    };

    const handleClickDetalhar = (order) => {
        if (!isConnected) {
            getKey('Cards').then(result => {
                const idbCard = result.find(item => item.identificador.id === identificadorCard.identificador.id)
                const idbOrder = idbCard.orderList.find(item => item.id === order.id)

                dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG_OFFLINE', detailOrderOffline: { open: true, order: idbOrder } })
            })
            return
        }

        let updatedCard = createCardFromPedido(order)
        dispatch({ type: 'TOOGLE_DETAIL_ORDER_DIALOG', detailOrder: { open: true, card: updatedCard } })
        handleClose()
    }

    const hasSelectedCheckbox = () => {
        let keys = Object.keys(checkboxState)
        let selected = false;
        keys.map(key => {
            if (checkboxState[key]) {
                selected = true;
            }
            return null;
        })
        return selected
    }

    const handleClickJuntarPedidosSelecionados = () => {
        let selectedOrders = []
        let keys = Object.keys(checkboxState)
        keys.map(key => {
            if (checkboxState[key]) {
                selectedOrders.push(orderList.find(order => order.id === parseInt(key)))
            }
            return null;
        })

        let data = { orderList: selectedOrders }
        dispatch({ type: 'TOOGLE_DIALOG_JOIN_ORDERS', dialogJoinOrders: { open: true, data: data, openCloseOrder, identificadorCard } })
        handleClose()
    }

    const handleClickAlterarMesaPedidosSelecionados = () => {
        let selectedOrders = []
        let keys = Object.keys(checkboxState)
        keys.map(key => {
            if (checkboxState[key]) {
                selectedOrders.push(orderList.find(order => order.id === parseInt(key)))
            }
            return null
        })
        handleOpenTransferDialog()
    }

    const sortOrderList = () => { //ordena os pedidos do card para deixar os pedidos sischef primeiro na exibição
        let pedidoSischef = { order: '' }
        let pedidoOutros = { order: '' }

        if (orderList) { // verifica se as ordens não são vazias para poder fazer a busca

            orderList.map(order => { //percorre as ordens e salva os pedidos sischef e os outros pedidos em vetores diferentes
                if (order.tipoImportacao === 'SO' && order.tipoImportacaoOriginal === undefined) {
                    pedidoSischef = { order: [...pedidoSischef.order, order] }
                } else {
                    pedidoOutros = { order: [...pedidoOutros.order, order] }
                }
                return null;
            })


            if (pedidoSischef.order !== '') { //caso não tenha pedidos sischef só seta os outros pedidos nas ordens
                if (pedidoOutros.order !== '') {//caso somente tenha pedidos sischef seta somente os pedidos sischef nas ordens
                    setOrderElements(pedidoSischef.order.concat(pedidoOutros.order))//caso tenha os 2 tipos de pedidos concatena as arrays com os pedidos sischef primeiro
                } else {
                    setOrderElements(pedidoSischef.order)
                }

            } else {
                setOrderElements(pedidoOutros.order)
            }

        }
    }

    const handleEditOrder = (order) => {
        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: order } })
        handleClose()
    }

    const handleOpenNewOrder = () => {
        let orderCopy = new OrderObj()
        orderCopy.tipo = identificadorCard.identificador.tipo
        orderCopy.identificadorPrincipal = identificadorCard.identificador.identificador
        orderCopy.printOrder = getSettings.printAutomaticallyOnCreate === "ENABLED";
        dispatch({ type: 'TOOGLE_DIALOG_ORDER', dialogOrder: { open: true, order: orderCopy, initActiveStep: 0 } })
        handleClose()
    }

    useEffect(() => {
        setDisableBtnJuntar(!hasSelectedCheckbox())
        // eslint-disable-next-line
    }, [checkboxState])

    useEffect(() => {
        setDisableBtnAlterarMesa(!hasSelectedCheckbox())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkboxState])

    useEffect(() => {//toda vez que orderList for alterado executa a ordenação da ordem
        sortOrderList()

        if (orderList) {
            let sischefOrderList = orderList.filter(order => order.tipoImportacao === 'SO' && order.tipoImportacaoOriginal === undefined)

            setOrderSischefList(sischefOrderList)
            // if (sischefOrderList && sischefOrderList.length > 0){
            //     setFirtstSischefOrder(sischefOrderList[0])
            // }else{
            //     setFirtstSischefOrder(null)
            // }
        }
        // eslint-disable-next-line
    }, [orderList])

    if (!identificadorCard) {
        return null
    }

    return (
        <>
            <DialogTitle onClose={handleClose}>
                {`${orderList.length > 1 ? 'Existem' : 'Existe'} ${orderList.length} ${orderList.length > 1 ? 'pedidos' : 'pedido'} na ${identificadorCard.identificador.tipo} ${identificadorCard.identificador.identificador}`}
            </DialogTitle>

            <DialogContent>

                {orderElements && orderElements.length > 0 ? (
                    <TableContainer>

                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox onChange={handleChangeCheckall} disabled={!isConnected} />
                                    </TableCell>
                                    <TableCell align='left'>Origem</TableCell>
                                    <TableCell component="th" scope="row" padding="none" align="center">{MODELO_IDENTIFICADOR_PRINCIPAL}</TableCell>
                                    <TableCell>Criado</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderElements.map((order, index) => (

                                    <TableRow key={order.id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                name={order.id.toString()}
                                                checked={!!checkboxState[order.id]}
                                                disabled={!isConnected}
                                                onChange={handleChangeCheckbox}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <IntegrationAvatar pedido={order} />
                                        </TableCell>

                                        <TableCell align='center'>
                                            <List>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <AvatarForIdentificador order={order} />
                                                    </ListItemAvatar>
                                                    <LongText text={order.complementoIdentificador || order?.pessoaCliente?.nome} maxLength={35} />
                                                </ListItem>
                                            </List>
                                        </TableCell>

                                        <TableCell>
                                            {dateFormat(order.dataPedido)}
                                        </TableCell>

                                        <TableCell align="right">{currencyFormat(pendingAmount(order.iFoodOrder))}</TableCell>
                                        <TableCell style={{ minWidth: '200px' }}>
                                            <Box mr={1} display="inline">
                                                <FecharPedidoButton identificadorCard={{ identificador: { ...order }, orderList: [order] }} />
                                            </Box>
                                            <Button
                                                color="grey"
                                                endIcon={<OpenInNewIcon />}
                                                onClick={() => handleClickDetalhar(order)} size="small">Detalhar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>
                        Nenhum pedido aberto para a {identificadorCard.identificador.tipo} {identificadorCard.identificador.identificador}
                    </Typography>
                )}


            </DialogContent>

            <DialogActions>

                <Button
                    size="small"
                    variant="contained"
                    disabled={disableBtnAlterarMesa || !isConnected}
                    onClick={handleClickAlterarMesaPedidosSelecionados}
                    color="secondary"> Alterar Mesas </Button>

                {!orderSischefList && MODELO_IDENTIFICADOR_PRINCIPAL === identificadorCard.identificador.tipo && (
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        disabled={!isConnected}
                        onClick={handleOpenNewOrder}
                        color="primary"> Novo pedido - {identificadorCard.identificador.tipo} {identificadorCard.identificador.identificador}</Button>
                )}

                {orderSischefList && (
                    <Button
                        size="small"
                        variant="contained"
                        disabled={!(orderSischefList.length === 1) || !isConnected}
                        startIcon={<EditIcon />}
                        onClick={() => handleEditOrder(orderSischefList[0])}
                        color="primary"> Editar pedido - {identificadorCard.identificador.tipo} {identificadorCard.identificador.identificador}</Button>
                )}

                <Button
                    size="small"
                    disabled={disableBtnJuntar || !isConnected}
                    variant="contained"
                    onClick={handleClickJuntarPedidosSelecionados}
                    color="secondary">Agrupar pedidos</Button>
            </DialogActions>

            <TransferOrder
                maxWidth='md'
                fullWidth
                open={DialogTransferOrderOpen}
                onClose={handleCloseTransferDialog}
                checkboxState={checkboxState} orderList={orderList}
            />


        </>
    )
}

function OrderListByIdentificador() {
    const dispatch = useDispatch()
    const [checkboxState, setCheckboxState] = useState([])
    const open = useSelector(state => state.dialogOrderListByIdentificador.open)

    useEffect(() => {
        if (open) {
            setCheckboxState([])
        }
    }, [open])

    const handleClose = () => {
        dispatch({ type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR', dialogOrderListByIdentificador: { open: false } })
        setCheckboxState([]); //limpa os valores da variavel checkBoxState ao fechar a tela que mostra os pedidos das comandas
    }

    return (
        <Dialog
            maxWidth='md'
            fullWidth
            open={open}
            onClose={handleClose}>

            <OrderListByIdentificadorDialogContent handleClose={handleClose} checkboxState={checkboxState} setCheckboxState={setCheckboxState} />

        </Dialog>
    )
}

export default OrderListByIdentificador;