import { Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { DialogTitle, DialogContent } from "../CustomDialog/dialogComponents"
import React from 'react';
import { TextMaskCNPJ, TextMaskCPF } from '../../../util/mask';
import CustomerSearch from '../NewOrder/Customer/CustomerSearch';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, AlertTitle } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import CustomerSelector from '../NewOrder/Customer/CustomerSelector';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useFunction } from './functions';

export default function FiscalDocument() {
    
    const {
        isPJ,
        openSelectCustomer,
        setOpenSelectCustomer,
        isAproved,
        enviando,
        getDescriptionDocumentByModelo,
        handleClickPrintNF,
        handleClickPrintNFApp,
        handleClickGerar,
        handleClickCancel,
        handleSelectCustomer,
        handleChange,
        open,
        respNf,
        infoNf,
        MODELOS_DE_DOCUMENTOS_EMITIDOS,
        selectedCustomer,
        setSelectedCustomer
    } = useFunction()

    return (
        <Dialog
            maxWidth={"xs"}
            fullWidth
            open={open}
            onClose={handleClickCancel}
            scroll="paper">

            <DialogTitle onClose={handleClickCancel}>
                Emissão fiscal
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    {respNf && respNf?.statusDescricao &&
                        <Grid item xs={12}>
                            <Alert icon={false} severity={isAproved ? "success" : "warning"}>

                                <AlertTitle>{respNf?.statusDescricao}</AlertTitle>
                                {!isAproved ? respNf?.msgStatus : null}

                            </Alert>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <FormControl component="fieldset" disabled={isAproved}>
                            <FormLabel component="legend">Selecione o modelo</FormLabel>
                            <RadioGroup
                                row
                                name='modelo'
                                value={infoNf.modelo || ''}
                                onChange={handleChange}
                            >
                                {
                                    MODELOS_DE_DOCUMENTOS_EMITIDOS?.map((modelo, index) =>
                                    (
                                        <FormControlLabel
                                            value={modelo}
                                            label={getDescriptionDocumentByModelo(modelo)}
                                            control={< Radio color="primary" />}
                                            key={index}
                                        />)
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {infoNf.modelo === '55' ?
                        (
                            <Grid item xs={12}>
                                <CustomerSelector
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    customer={selectedCustomer}
                                    setCustomer={setSelectedCustomer}
                                    disableElevation
                                />
                            </Grid>
                        )
                        :
                        (
                            <React.Fragment>
                                {infoNf.modelo === '65' &&
                                    <Grid item xs={12} >
                                        <Tooltip title='Emissão em contigência deve ser utilizado quando a SEFAZ está indisponível.'>
                                            <FormControl component="fieldset" disabled={isAproved} >
                                                <FormGroup row>
                                                    <FormControlLabel
                                                        onChange={handleChange}
                                                        name='tipoEmissao'
                                                        value={infoNf.tipoEmissao === 1 ? 9 : 1}
                                                        control={<Checkbox color="primary" />}
                                                        label="Emitir em contingência"
                                                        labelPlacement="end"
                                                    />

                                                </FormGroup>
                                            </FormControl>
                                        </Tooltip>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <FormControl component="fieldset" disabled={isAproved}>
                                        <RadioGroup row name="tipo" value={infoNf.tipo || 'F'} onChange={handleChange}>
                                            <FormControlLabel value="F" control={<Radio size="small" />} label="Física" />
                                            <FormControlLabel value="J" control={<Radio size="small" />} label="Jurídica" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        disabled={isAproved}
                                        label="Nome"
                                        name="nome"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        defaultValue={infoNf.nome || ''}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={() => setOpenSelectCustomer(!openSelectCustomer)}
                                                    disabled={isAproved}
                                                    size="large">
                                                    <SearchIcon />
                                                </IconButton>)
                                        }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        disabled={isAproved}
                                        label={isPJ ? "CNPJ" : "CPF"}
                                        name="cpfCnpj"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        defaultValue={infoNf.cpfCnpj || ''}
                                        InputProps={{
                                            inputComponent: (isPJ ? TextMaskCNPJ : TextMaskCPF)
                                        }}
                                        onChange={handleChange} />
                                </Grid>
                            </React.Fragment>
                        )
                    }
                </Grid>
            </DialogContent>

            <DialogActions >
                <Button
                    variant='text'
                    style={{ color: isAproved ? '#49afcd' : 'default' }}
                    disabled={!isAproved}
                    onClick={handleClickPrintNF}
                    startIcon={<OpenInNewIcon />}>
                    Abrir
                </Button>
                <Button
                    variant='text'
                    style={{ color: isAproved ? '#49afcd' : 'default' }}
                    disabled={!isAproved}
                    onClick={handleClickPrintNFApp}
                    startIcon={<PrintIcon />}>
                    Imprimir
                </Button>

                {
                    !isAproved && <Button
                        variant='contained'
                        color='primary'
                        disabled={enviando}
                        onClick={handleClickGerar}>
                        {enviando ? 'Enviando...' : 'Enviar'}
                    </Button>
                }

            </DialogActions>


            <CustomerSearch
                open={openSelectCustomer}
                setOpen={setOpenSelectCustomer}
                size="xs"
                color="primary"
                variant="contained"
                defaultCustomer={null}
                onConfirm={handleSelectCustomer}
                disableElevation
            />
        </Dialog >
    );
}