import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Box, Container, Grid, ListItem, ListItemIcon } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AuthService from '../../service/AuthService';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useHistory } from "react-router-dom";
import { sistemas } from '.';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function SelectSystemDialog({open, setOpen, loggedUser, openSytem, isSystemDisabledFor, handleLogout}) {

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoutSelect = () => {
    handleLogout()
    handleClose()
    history.push("/unified-login")
  }

  return (
    <React.Fragment>
 
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Container maxWidth="md">

        <Box pt={4} >
            <Grid container alignItems="center" >
              <Grid item>
                <img src={process.env.PUBLIC_URL + "/logo-sischef.svg"} style={{ width: '64px' }} alt="Logo do Sischef"></img>
              </Grid>

              <Grid item xs={8}>
                <Typography variant='h2' component="h2" >Sischef</Typography>
              </Grid>

            </Grid>
          </Box>

       <Box pt={3}>
        <Typography variant="h1" gutterBottom>
        Olá, {loggedUser?.nome}
        </Typography>
        <Typography variant="h4" gutterBottom>
        Selecione o sistema que deseja acessar
      </Typography>
      </Box> 
        
        <List>
          {sistemas.map(sistema => (
            <React.Fragment key={sistema.value}>
            <ListItemButton onClick={() => openSytem(sistema.value)}  
              disabled={isSystemDisabledFor(sistema.value)} >
              <ListItem  key={sistema.label}
              secondaryAction={
                 <IconButton>
                    <ArrowForwardIosIcon/>
                  </IconButton>
              }>
                <ListItemIcon>
                    {sistema.icon}
                </ListItemIcon>
                <ListItemText primary={sistema.label} secondary={sistema.description} />
              </ListItem>
          </ListItemButton>
            <Divider />
            </React.Fragment>
          ))}
         
        </List>

        <Box pt={2}>
        <Button
            fullWidth
            color="error"
            variant="text"
            onClick={handleLogoutSelect}
            endIcon={<ExitToAppIcon/>}
          >
            Acessar como outro usuário
        </Button>
        </Box>
        </Container>
      </Dialog>
      
    </React.Fragment>
  );
}