import { Box, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import Quagga from "quagga";
import React, { useEffect, useRef } from "react";
import MaskScanner from "../MaskScanner";

function notRepeatPlease(callback, duration = 1000) {
  if (window.noRepeatTimer) {
    return;
  }

  callback();

  window.noRepeatTimer = setTimeout(
    () => (window.noRepeatTimer = null),
    duration
  );
}
const ScannerCam = ({ setSearchValue }) => {
  const scannerRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  function handleDetectedCode(code) {
    setSearchValue(code);
  }
  const handleOnDetected = (data) => {
    const code = data.codeResult.code;

    if (handleDetectedCode) {
      notRepeatPlease(() => {
        handleDetectedCode(code);
      });
    }
  };

  useEffect(() => {
    Quagga.onDetected(handleOnDetected);

    return () => {
      Quagga.offDetected(handleOnDetected);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDetectedCode]);

  const initQuagga = () => {
    if (scannerRef.current) {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: {
              facingMode: "environment",
              width: { min: 200 },
              height: { min: 200 },
              aspectRatio: { min: 1, max: 2 },
            },
            target: scannerRef.current,
          },
          area: {
            top: "0%",
            right: "0%",
            left: "0%",
            bottom: "0%",
          },
          locate: true,
          numOfWorkers: 4,
          decoder: {
            readers: [
              "code_128_reader",
              "ean_reader",
              "codabar_reader",
              "upc_reader",
              "upc_e_reader",
            ],
          },
        },
        (err) => {
          if (err) {
            console.error("Erro ao inicializar Quagga:", err);
            enqueueSnackbar(`${err}`, { variant: "error" });
            return;
          }
          Quagga.start();
        }
      );
    }
  };

  useEffect(() => {
    initQuagga();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        height: "20rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "16px",
        position: "relative",
      }}
    >
      <div
        ref={scannerRef}
        style={{
          position: "absolute",
          top: "120%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "100%",
          minHeight: "100%",
        }}
      ></div>
      <MaskScanner />
    </Box>
  );
};

export default ScannerCam;
