import { Box } from "@mui/material";
import React from "react";

const MaskScanner = () => {
    return <>
    <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          opacity: 0.7,
          height: "60%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            width: "10px",
            height: "10px",
            border: "0.5rem solid",
            borderColor: (theme) => theme.palette.secondary.main,
          },
          "&::before": {
            top: 0,
            left: 0,
            borderRight: "none",
            borderBottom: "none",
          },
          "&::after": {
            top: 0,
            right: 0,
            borderLeft: "none",
            borderBottom: "none",
          },
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%) rotate(180deg)",
          width: "80%",
          height: "60%",
          opacity: 0.7,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            width: "10px",
            height: "10px",
            border: "0.5rem solid",
            borderColor: (theme) => theme.palette.secondary.main,
          },
          "&::before": {
            top: 0,
            left: 0,
            borderRight: "none",
            borderBottom: "none",
          },
          "&::after": {
            top: 0,
            right: 0,
            borderLeft: "none",
            borderBottom: "none",
          },
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "40%",
          opacity: 0.7,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <Box
          width="100%"
          height="0.5rem"
          alignItems="center"
          justifyContent="center"
          backgroundColor={(theme) => theme.palette.secondary.main}
        ></Box>
      </Box>
    
    </>;
    }

export default MaskScanner;
