import React from "react";

import { Box, Typography } from "@mui/material";

const DialogPaymentDetails = ({ order }) => (
  <Box sx={{ marginTop: "2rem" }}>
    <Box sx={{ marginBottom: "0.5rem" }}>
      <Typography variant="h5" fontWeight="bold">
        Detalhes de pagamento:
      </Typography>
    </Box>
    <Typography variant="body1">
      Taxa de Entrega: R${" "}
      <strong>{`R$ ${order?.iFoodOrder?.productDeliveryFee?.valorUnitarioVenda?.toFixed(2) || 0}`}</strong>
    </Typography>
    {!!order?.levarTrocoPara && (
      <Typography variant="body1">
        Troco para: <strong>{order?.levarTrocoPara}</strong>
      </Typography>
    )}
    <Typography variant="body1">
      Forma de Pagamento: <strong>{order?.pago ? "Pago" : "Não Pago"}</strong>
    </Typography>
  </Box>
);

export default DialogPaymentDetails;
