import { Room } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  currencyFormat,
  dateFormatedToNewFormat,
} from "../../../../../../util/formatter";
import { useAppDeliveryManProvider } from "../../../../context/AppDeliveryManProvider";
import {
  StyledChipInitOrder,
  StyledIconCard,
  StyledIconChange,
} from "./styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CardRouter = ({ order }) => {
  const { setSelectedDispatch } = useAppDeliveryManProvider();
  const isInitOrder = order.pedidos.some(
    (pedido) => pedido?.situacao === "ENVIADO"
  );
  const history = useHistory();
  const handleClickCard = () => {
    setSelectedDispatch(order);
    history.push("/appentregador/rota");
  };

  const formattedDate = (date) =>
    dateFormatedToNewFormat(date, "ISO", "HH:mm:ss dd/MM/yyyy");

  const takeTheCardMachine = order.pedidos.some(
    (pedido) => pedido?.iFoodOrder?.levarMaquinaCartao
  );

  const takeChangeMoney = order.pedidos
    .map((pedido) =>
      pedido?.iFoodOrder?.levarTrocoPara
        ? pedido?.iFoodOrder?.levarTrocoPara - pedido?.iFoodOrder?.totalPedido
        : null
    )
    .filter(Boolean);

  return (
    <React.Fragment>
      <Card
        sx={{
          background: "transparent",
          backgroundColor: "transparent",
          height: "100%",
          boxShadow: "none",
        }}
      >
        <CardActionArea
          sx={{
            background: "transparent",
            backgroundColor: "transparent",
            height: "100%",
          }}
          onClick={handleClickCard}
        >
          <CardContent
            sx={{
              background: "transparent",
              backgroundColor: "transparent",
              height: "100%",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="start"
              >
                <Box
                  px="0.5rem"
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Room fontSize="large" />
                    <Box ml="1rem">
                      <Box>
                        <Typography variant="h5">
                          Pedidos:{" "}
                          <strong>
                            {order?.pedidos
                              .map((pedido) => pedido.id)
                              .join(", ")}
                          </strong>
                        </Typography>
                      </Box>
                      <Box mt="0.5rem"></Box>
                      <Box>
                        <Typography color="textSecondary" variant="caption">
                          Última atualização:{" "}
                          {formattedDate(order?.pedidos[0]?.updatedAt)}
                        </Typography>
                      </Box>
                      {takeTheCardMachine && (
                        <Box
                          mt="0.5rem"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <StyledIconCard />
                          <Box ml="0.5rem">
                            <Typography variant="inherit">
                              Levar máquina de cartão
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {!!takeChangeMoney.length && (
                        <Box
                          mt="0.5rem"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <StyledIconChange />
                          <Box ml="0.5rem">
                            <Typography variant="inherit">
                              {`Levar troco para ${takeChangeMoney
                                .map((money) => currencyFormat(money))
                                .join(", ")}`}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {!!isInitOrder && (
                        <Box mt="0.5rem">
                          <StyledChipInitOrder
                            size="small"
                            label="Rota já iniciada"
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <KeyboardArrowRightIcon fontSize="large" />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
};

export default CardRouter;
