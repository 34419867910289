import React from "react";

import { Box, styled, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import PaymentIcon from "@mui/icons-material/Payment";

import { formatFullAddressDelivery } from "../../../../../../../util/customer/helper";

const StyledIconCard = styled(PaymentIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const DialogCustomerDetails = ({ order }) => (
  <Box>
    <Box display="flex" alignItems="center" gap={2}>
      <PersonIcon fontSize="small" />
      <Typography variant="h6">{order?.pessoaCliente?.nome}</Typography>
    </Box>
    <Box mt="0.5rem" display="flex" alignItems="center" gap={2}>
      <LocationOnIcon fontSize="small" />
      <Typography variant="h6">
        {formatFullAddressDelivery(order?.iFoodOrder?.deliveryAddress)?.address}
      </Typography>
    </Box>
    {!!order?.pessoaCliente?.fone && (
      <Box mt="0.5rem" display="flex" gap={2}>
        <SmartphoneIcon fontSize="small" />
        <Typography variant="h6">{order?.pessoaCliente?.fone}</Typography>
      </Box>
    )}
    {!!order?.iFoodOrder?.levarMaquinaCartao && (
      <Box mt="0.5rem" display="flex" gap={1}>
        <StyledIconCard />
        <Box ml="0.2rem">
          <Typography variant="h6">Pagamento com cartão</Typography>
        </Box>
      </Box>
    )}
  </Box>
);

export default DialogCustomerDetails;
