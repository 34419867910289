import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import SocketContext from "../../util/socket";
import AppBody from "./AppBody";
import { AppDeliveryManProvider } from "./context/AppDeliveryManProvider";
import Navigation from "./Navigate";

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.personal.background,
  flexDirection: "column",
  color: theme.palette.text.primary,
  width: "100vw",
  margin: "-8px",
  overflowY: "auto",
  height: "100vh",
}));

const AppEntregador = () => {
  //TODO - Utilizar a localização e as notificações do navegador para permitir o funcionamento do aplicativo.

  // const [locationPermission, setLocationPermission] = useState(null);
  // const [notificationPermission, setNotificationPermission] = useState(null);

  // useEffect(() => {
  //   serviceWorkerRegistration.register();

  //   const link = document.querySelector('link[rel="manifest"]');
  //   if (link) {
  //     link.href = "/manifest-entregador.json";
  //   }

  //   if ("Notification" in window) {
  //     Notification.requestPermission().then((permission) => {
  //       setNotificationPermission(permission === "granted");
  //     });
  //   }

  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLocationPermission(true);
  //       },
  //       (error) => {
  //         setLocationPermission(false);
  //       }
  //     );
  //   }
  // }, []);

  // if (locationPermission === null || notificationPermission === null) {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       height="80vh"
  //       width="100%"
  //     >
  //       <CircularProgress size="3rem" />
  //     </Box>
  //   );
  // } else if (!locationPermission || !notificationPermission) {
  //   return (
  //     <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="center"
  //       flexDirection="column"
  //       height="100vh"
  //       bgcolor="white"
  //     >
  //       <Typography variant="h6" align="center" color="error">
  //         Para usar o aplicativo, conceda acesso à localização e notificações.
  //       </Typography>
  //       <Button
  //         variant="contained"
  //         sx={{ marginTop: "1rem" }}
  //         color="primary"
  //         onClick={() => window.location.reload()}
  //       >
  //         Tentar novamente
  //       </Button>
  //     </Box>
  //   );
  // }

  return (
    <>
      <SocketContext />
      <AppDeliveryManProvider>
        <StyledBox>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <AppBody />
            <Navigation />
          </Box>
        </StyledBox>
      </AppDeliveryManProvider>
    </>
  );
};

export default AppEntregador;
