import React from 'react'
import { Box, Button, Dialog } from "@mui/material"
import MuiDialogContent from '@mui/material/DialogContent'
import { DialogActions, DialogTitle } from "../../CustomDialog/dialogComponents"
import ResumeOrder from "../pages/ResumeOrder"
import TotalOrder from "../pages/TotalOrder"
import DoneIcon from '@mui/icons-material/Done'
import { LoadingButton } from '@mui/lab'

export function ResumeOrderDialog({ open, order, saving, onClose, setOrder, onConfirm, handleClickOpenSteps }) {

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle onClose={onClose}>
                Resumo do Pedido
            </DialogTitle>
            <MuiDialogContent sx={{ p: 0 }}>
                <ResumeOrder
                    order={order}
                    setOrder={setOrder}
                    handleClickOpenSteps={handleClickOpenSteps} />
            </MuiDialogContent>
            <DialogActions>
                <Box sx={{ width: "100%", pb: 1 }}>
                    <TotalOrder order={order} showPendingAmount={false} />
                    <LoadingButton
                        fullWidth
                        sx={{ mt: 1 }}
                        loading={saving}
                        disabled={saving}
                        variant="contained"
                        onClick={onConfirm}
                        startIcon={<DoneIcon />}>
                        Confirmar
                    </LoadingButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
}