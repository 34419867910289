import { EditNote, Refresh } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Checkbox, Dialog, DialogActions, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { lime } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { getParamByKey } from '../../../../component/parameter';
import { currencyFormat, quantityFormat } from '../../../../util/formatter';
import { roundUp } from "../../../../util/genericUtil";
import { addAllTaxaServicoByItems, addTaxaServicoItem, getTotalItem, removeAllTaxaServicoByItems, totalItemReal, totalTaxaServicoItem } from '../../../../util/order/orderUtil';
import { DialogContent, DialogTitle } from "../../CustomDialog/dialogComponents";

const PREFIX = 'ServiceFee'

const classes = {
    scrollBar: `${PREFIX}-scrollBar`,
    subitem: `${PREFIX}-subitem`,
    paddingLeft: `${PREFIX}-paddingLeft`,
    deliveryFree: `${PREFIX}-deliveryFree`
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.scrollBar}`]: theme.scrollBar,

    [`& .${classes.subitem}`]: {
        opacity: "0.8",
        fontSize: "0.9em"
    },

    [`& .${classes.paddingLeft}`]: {
        paddingLeft: '4px'
    },

    [`& .${classes.deliveryFree}`]: {
        '& label, & input, & .MuiIconButton-label, & .MuiInputAdornment-root p': {
            color: theme.palette.mode === 'dark' ? lime[700] : lime[700],
        },
        '& input': {
            textAlign: "right",
            MozAppearance: "textfield"
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            WebkitAppearance: "none",
            margin: 0,
        }
    }
}))

const StyledCheckbox = styled(Checkbox)(() => ({
    '&.MuiCheckbox-indeterminate': {
        color: 'grey',
    },
}));

export default function ServiceFee({ open, setOpen, order }) {

    const dispatch = useDispatch()
    const btnConfirmRef = useRef();
    const generalParameters = useSelector(state => state.generalParameters)
    const PERCENTUAL_TAXA_SERVICO = getParamByKey(generalParameters, 'PERCENTUAL_TAXA_SERVICO')?.valor
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = useState([])
    const [stateValues, setStateValues] = useState({})
    const [serviceFeeValue, setServiceFeeValue] = useState(0);
    const [editServiceFeeManually, setEditServiceFeeManually] = useState(false);
    const [hasServiceFeeChanged, setHasServiceFeeChanged] = useState(false);

    const isAllChecked = items
        .filter((item) => item.productChargeServiceFee === true)
        .every((item) => item.checked)

    const isNotAllChecked = items
        .filter((item) => item.productChargeServiceFee === true)
        .some((item) => item.checked)

    useHotkeys('f10', (event) => {

        event.preventDefault()
        event.stopPropagation()

        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
            confirmServiceFee()
        }
    })

    function handleClose() {
        setOpen(false)
    }

    const confirmServiceFee = () => {
        let orderCopy = JSON.parse(JSON.stringify(order))
        orderCopy.iFoodOrder.items = items

        if (hasServiceFeeChanged) {
            const checkedItems = items.filter(item => item.checked);
            const valueTotalChecked = checkedItems.reduce((acc, item) => acc + getTotalItem(item), 0);

            orderCopy.iFoodOrder.items = orderCopy.iFoodOrder.items.map(item => {
                if (item.checked) {
                    const valueTotalItem = getTotalItem(item);
                    const newValorTaxa = Number(serviceFeeValue) * (valueTotalItem / valueTotalChecked);
                    item.totalServico = newValorTaxa;
                } else {
                    item.totalServico = 0;
                }
                return item;
            });
        }

        dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { open: true, order: orderCopy } })
        handleClose()
    }

    const onChangeCheckBoxAll = (event) => {
        if (event.target.checked) {
            handleAddServiceFee()
        } else {
            onClickRemoveTaxaDeServico()
        }
    }

    const onClickRemoveTaxaDeServico = () => {
        const itemsCopy = JSON.parse(JSON.stringify(items))

        removeAllTaxaServicoByItems(itemsCopy)

        itemsCopy.forEach((item) => {
            item.checked = false
        })

        setItems(itemsCopy)
        setHasServiceFeeChanged(false)
        setServiceFeeValue(0.0)

        enqueueSnackbar('A taxa de serviço foi removida dos itens.', { variant: 'success', })
    }

    const handleAddServiceFee = () => {
        const itemsCopy = JSON.parse(JSON.stringify(items))
        const COBRAR_TAXA_VALOR_BRUTO_PEDIDO = getParamByKey(generalParameters, 'COBRAR_TAXA_VALOR_BRUTO_PEDIDO')

        addAllTaxaServicoByItems(itemsCopy, stateValues.percentualTaxaServico, COBRAR_TAXA_VALOR_BRUTO_PEDIDO)

        itemsCopy.forEach((item) => {
            item.checked = true
        })

        setItems(itemsCopy)
        setEditServiceFeeManually(false)
        setServiceFeeValue(roundUp(totalTaxaServicoItem(itemsCopy), 2))

        enqueueSnackbar('A taxa de serviço foi adicionado aos itens.', { variant: 'success', })
    }

    useEffect(() => {

        if (order && open === true) {
            setStateValues({
                ...stateValues,
                percentualTaxaServico: PERCENTUAL_TAXA_SERVICO,
                recalcularTaxaDeServico: false,
            });

            let orderCopy = JSON.parse(JSON.stringify(order))
            let itemsTemp = []

            orderCopy.iFoodOrder.items.forEach((item) => {
                if (item.productChargeServiceFee === true) {
                    item.checked = true;
                } else {
                    if (item.chargeServiceFee !== undefined) {
                        item.checked = item.chargeServiceFee;
                    } else {
                        item.checked = false;
                    }
                }
                itemsTemp.push(item);
            })

            setItems(itemsTemp)
            setEditServiceFeeManually(false)
            setServiceFeeValue(roundUp(totalTaxaServicoItem(itemsTemp), 2))
        }
        // eslint-disable-next-line
    }, [order, open])

    const onChange = (event) => {
        setStateValues({ ...stateValues, [event.target.name]: event.target.value });
    }

    const handleChangeCheckItem = (event, item) => {
        let itemsCopy = Object.assign([], items)

        let itemTemp = itemsCopy?.find(element => element.referenceCode === item.referenceCode)

        itemTemp.checked = event.target.checked
        itemTemp.chargeServiceFee = event.target.checked

        if (itemTemp.checked === true) {
            addTaxaServicoItem(itemTemp, stateValues.percentualTaxaServico)
        } else {
            itemTemp.totalServico = 0.0
        }

        setItems(itemsCopy)
        setHasServiceFeeChanged(false);
        setServiceFeeValue(roundUp(totalTaxaServicoItem(itemsCopy), 2))
    }

    const handleCheckboxTaxaManual = (event) => {
        setServiceFeeValue(roundUp(totalTaxaServicoItem(items), 2))

        setEditServiceFeeManually(event.target.checked)
        setHasServiceFeeChanged(event.target.checked)
    }

    const handleTaxaChange = (event) => {
        const rawValue = event.target.value.replace(/[^0-9,.]/g, '').replace(',', '.')
        let value = Number(rawValue)

        if (value < 0) return

        value = parseFloat(value.toFixed(2))
        setServiceFeeValue(rawValue)
        setHasServiceFeeChanged(true);
    }

    const updateServiceFee = () => {
        if (stateValues.recalcularTaxaDeServico) {
            handleAddServiceFee();
        }
        setStateValues(prev => ({
            ...prev,
            recalcularTaxaDeServico: !prev.recalcularTaxaDeServico
        }));
    }

    return (
        <StyledDialog
            open={open}
            maxWidth={"sm"}
            fullWidth
            onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Typography display="block" gutterBottom>
                    Taxa de serviço
                </Typography>
            </DialogTitle>

            <DialogContent style={{ paddingBottom: 0 }}>
                <React.Fragment>

                    <Box sx={{ paddingTop: 1 }}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ gap: 1, mb: 2 }}>
                            <Box>
                                <Typography variant="body1">
                                    Taxa de serviço
                                </Typography>
                            </Box>
                            <Box >
                                <TextField
                                    variant='outlined'
                                    name='percentualTaxaServico'
                                    type='number'
                                    sx={{ width: "147px" }}
                                    fullWidth
                                    size='small'
                                    disabled={!stateValues.recalcularTaxaDeServico}
                                    value={stateValues.percentualTaxaServico}
                                    className={classes.deliveryFree}
                                    onChange={(event) => onChange(event)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ mr: "3px" }} > % </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <Tooltip title={stateValues.recalcularTaxaDeServico ? "Atualizar percentual da taxa de serviço!" : "Editar percentual da taxa de serviço!"}>
                                                    <IconButton
                                                        onClick={() => { updateServiceFee() }}
                                                        style={{ padding: 0 }}
                                                        size="small"
                                                        edge="end">
                                                        {stateValues.recalcularTaxaDeServico ? <Refresh /> : <EditNote />}
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="none" className={classes.paddingLeft} >Item</TableCell>
                                <TableCell align='right'>Qtde</TableCell>
                                <TableCell align='right'>Total</TableCell>
                                <TableCell align='center'>Tx. Serviço</TableCell>
                                <TableCell align='center'>
                                    <StyledCheckbox
                                        color="primary"
                                        checked={isAllChecked}
                                        indeterminate={isNotAllChecked && !isAllChecked}
                                        onChange={onChangeCheckBoxAll}
                                        name='checked' />
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {items?.map((item, index) => (
                                <React.Fragment key={index}>
                                    <TableRow>

                                        <TableCell component='th' scope='row' padding="none" className={classes.paddingLeft} >
                                            <Typography>{item.name}</Typography>
                                        </TableCell>

                                        <TableCell align='right'>{quantityFormat(item.quantity)}</TableCell>

                                        <TableCell align='right'>
                                            {`${currencyFormat(totalItemReal(item), { simbol: '' })}`}
                                        </TableCell>

                                        <TableCell align='center'>
                                            {currencyFormat(item.totalServico, { simbol: '' })}
                                        </TableCell>

                                        <TableCell align='center'>
                                            {item?.productChargeServiceFee === false ? (
                                                <Tooltip title="Item marcado ou configurado para não cobrar taxa de serviço.">
                                                    <IconButton size="large">
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Checkbox
                                                    color="primary"
                                                    checked={item.checked}
                                                    onClick={(event) => handleChangeCheckItem(event, item)}
                                                    name='checked' />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>


                </React.Fragment >
            </DialogContent>

            <DialogActions disableSpacing>
                <Box width="100%" sx={{ p: 1 }}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ gap: 1, mb: 2 }}>
                        <Box>
                            <Typography variant="body1">
                                Total Taxa de Serviço
                            </Typography>
                        </Box>
                        <Box >
                            <TextField
                                variant="outlined"
                                fullWidth
                                type='number'
                                size='small'
                                sx={{ width: "147px" }}
                                value={serviceFeeValue}
                                onChange={handleTaxaChange}
                                disabled={!editServiceFeeManually}
                                className={classes.deliveryFree}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" style={{ mr: "3px" }} > R$ </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <Tooltip title="Editar Taxa de Serviço">
                                                <IconButton
                                                    onClick={() => setEditServiceFeeManually(!editServiceFeeManually)}
                                                    onChange={handleCheckboxTaxaManual}
                                                    style={{ padding: 0 }}
                                                    size="small"
                                                    edge="end">
                                                    <EditNote />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Box>
                    </Box>


                    <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="Atalho F10" aria-label="Finalizar">
                            <Button
                                ref={btnConfirmRef}
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={confirmServiceFee}
                                endIcon={<DoneIcon />}
                            >
                                Confirmar
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </DialogActions>
        </StyledDialog>
    );
}
