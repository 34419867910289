import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import AuthService from "../../../../../../../service/AuthService";

const MarkerHouseComponent = React.memo(({ google, map }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const service = new AuthService();
    const profile = service.getProfile();

    const initialCenter = {
        lat: profile.unidade.pessoa.latitude || 0,
        lng: profile.unidade.pessoa.longitude || 0,
    };

    const svgMarkerHouse = {
        path: "M6295 9146 c-40 -19 -286 -260 -1282 -1257 l-1232 -1234 -16 -59 c-18 -61 -16 -129 5 -150 7 -7 116 -13 343 -16 l332 -5 5 -1295 c6 -1444 -1 -1325 76 -1410 21 -23 56 -51 78 -63 40 -22 49 -22 542 -25 l500 -3 55 29 c39 20 62 41 84 77 l30 48 5 521 5 521 27 51 c33 63 91 116 154 140 45 17 80 19 394 19 314 0 349 -2 394 -19 63 -24 121 -77 154 -140 l27 -51 5 -520 5 -520 25 -45 c16 -28 43 -55 74 -75 l49 -30 511 0 c506 0 511 0 552 22 22 12 57 40 78 63 77 85 70 -34 76 1410 l5 1295 332 5 c227 3 336 9 343 16 21 21 23 89 5 150 l-16 59 -1232 1234 c-1336 1337 -1275 1281 -1387 1281 -37 0 -71 -8 -105 -24z",
        fillColor: "blue",
        fillOpacity: 1,
        strokeColor: "white",
        strokeWeight: 2,
        rotation: 180,
        scale: 0.005,
        anchor: new google.maps.Point(6295, 9146),
    };

    useEffect(() => {
        if (google && map) {
            const marker = new google.maps.Marker({
                position: initialCenter,
                map: map,
                icon: {
                    ...svgMarkerHouse,
                    strokeWeight: isDarkMode ? 2 : 0,
                },
            });

            return () => {
                marker.setMap(null);
            };
        }
    }, [google, map, initialCenter, svgMarkerHouse, isDarkMode]);

    return null;
});

export default MarkerHouseComponent;
