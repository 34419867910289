import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthService from '../../service/AuthService';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '../../util/http/HttpUtil';
import {  Container } from '@mui/material';
import { ifAnyGrantedFromProfile } from '../../util/auth/authUtil';
import ReactGA from "react-ga4";
import StorefrontIcon from '@mui/icons-material/Storefront';
import LaptopIcon from '@mui/icons-material/Laptop';
import InsightsIcon from '@mui/icons-material/Insights';
import SelectSystemDialog from './selectSystemDialog';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const md5 = require('md5');

const PREFIX = 'unified-login'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  loginBox: `${PREFIX}-loginBox`,
  
  frasePrincipal: `${PREFIX}-frasePrincipal`
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/layout/login-bg.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.header}`]: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    }, 
  },

  [`& .${classes.loginBox}`]: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.personal.menu,
    maxWidth: "500px",
    margin: "0 auto",
  },
  [`& .${classes.frasePrincipal}`]: {
    lineHeight: '1em',
    color: 'white',
    fontSize: "6em",
    [theme.breakpoints.down('md')]: {
      fontSize: "3em",
    },   
	  fontWeight: "bold",
  }
}))

export const sistemas = [
  { label: 'Sischef PDV', description: "O PDV online mais moderno do Brasil", value: "PDV", icon:  <StorefrontIcon/> },
  { label: 'Sischef retaguarda (gestão)', description: "Sistema de gestão mais inteligente", value: "GERENCIAL",  icon: <LaptopIcon/> },
  { label: 'Sischef Analytics', description: "O BI feito para seu food-service", value: "ANALYTICS", icon: <InsightsIcon/> }
];

export default function UnifiedLogin() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({ 
    username: '',
    password: ''
  })
  const [sending, setSending] = useState(false)
  const [logged, setLogged] = useState(AuthService.loggedIn())
  const [openSelectSystem, setOpenSelectSystem] = React.useState(false);
  const [loggedUser, setLoggedUser] = React.useState(null);
 

  const profile = new AuthService().getProfile();

  useEffect(() => {//informa ao analytics que o usuário caiu na pagina de login
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "login-unificado" });

      if (logged){
        const authService = new AuthService();
        setLoggedUser(authService.getProfile())
        setOpenSelectSystem(true)
      }
  }, [])

 

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    setValues({ ...values, [name]: value})
  }

  const hadleSubmit = (event) => {
    setSending(true)
    event.preventDefault();

    let username = values.username
    let password = values.password
    let passMD5 = md5(password)

    let service = new AuthService();
    service.login(username, passMD5)
    .then(profile => {

      setLoggedUser(profile)

      setSending(false)
      setOpenSelectSystem(true)

      console.log("Dados do usuário/empresa carregados com sucesso.")
       
      enqueueSnackbar("Logado com sucesso", { variant: 'success', });

    }).catch(err => {
        console.error("Falha ao carregar dados do usuário/empresa carregados", err)
        setSending(false)

        extractErrorMessage(err, "Falha ao realizar login")
                    .then(msg => {
                        enqueueSnackbar(msg, { variant: "error" });
                    })

    })
  }

  const handleLogout = () => {
    new AuthService().logout()
    setLogged(false)
  }

  const handleGoToMainPage = () => {
    history.push("/");
  }


  const acessarPDV = (profile) =>{
    const acessoTelaDelivery = ifAnyGrantedFromProfile("ROLE_PDV_DELIVERY", profile)
    const acessoTelaComandaMesa = ifAnyGrantedFromProfile("ROLE_PDV_COMANDA_MESA", profile)
      
    if (acessoTelaDelivery){
      history.push("/delivery");
    } else if (acessoTelaComandaMesa){
      history.push("/pedidosIdentificador");
    } else {
      history.push("/delivery");
    }
  }

  
  const acessarRetaguarda = (profile) => {
    const isGarcom = profile?.perfilUsuario?.tipo === 'garcom';

      if (isGarcom) {
        enqueueSnackbar("Seu usário não tem acesso ao sistema retaguarda.", { variant: 'error', });
        return;
      }

      enqueueSnackbar("Direcionando para o sistema gerencial", { variant: 'success', });

      setTimeout(() => {
        const URL_BASE = process.env.REACT_APP_APPLICATION_URL
        // Redireciona para a URL externa sem abrir uma nova aba
        window.location.href = `${URL_BASE}/loginToken?token=${AuthService.getToken()}`;  
      }, (700));

      return; // Impede a execução do restante do código
  }

  const acessarAnalytics = (profile) => {

    enqueueSnackbar("Direcionando para o Sischef Analytics", { variant: 'success', });

    setTimeout(() => {
      const URL_ANALYTICS_BASE = process.env.REACT_APP_SISCHEF_ANALYTICS
      // Redireciona para a URL externa sem abrir uma nova aba
      window.location.href = `${URL_ANALYTICS_BASE}/login?token=${AuthService.getToken()}`;  
    }, (700));

  

  }

  const openSytem = (sistema) =>{
    if ("PDV" === sistema){
      acessarPDV(loggedUser)
    }else if ("GERENCIAL" === sistema){
      acessarRetaguarda(loggedUser)
    }else if ("ANALYTICS" === sistema){
      acessarAnalytics(loggedUser)
    } else {
      acessarPDV(loggedUser)
    }
  }

  const isSystemEnabledFor = (sistema) =>{
    if ("PDV" === sistema){
      const acessoTelaDelivery = ifAnyGrantedFromProfile("ROLE_PDV_DELIVERY", loggedUser)
      const acessoTelaComandaMesa = ifAnyGrantedFromProfile("ROLE_PDV_COMANDA_MESA", loggedUser)
      return acessoTelaDelivery || acessoTelaComandaMesa;

    }else if ("GERENCIAL" === sistema){

      const isGarcom = profile?.perfilUsuario?.tipo === 'garcom';
      return !isGarcom;
       
    }else if ("ANALYTICS" === sistema){

      const acessoAnalytics = ifAnyGrantedFromProfile("ROLE_SISCHEF_ANALYTICS", loggedUser)

      return acessoAnalytics;
    }  

    return false;
  }

  const isSystemDisabledFor = (sistema) =>{
    return !isSystemEnabledFor(sistema);
  }


  return (
    <>
      <StyledContainer className={classes.root} disableGutters maxWidth={false}>
        <Container>
          <CssBaseline />

          <Box pt={4} >
            <Grid container alignItems="center" spacing={2} >
              <Grid item>
                <img src={process.env.PUBLIC_URL + "/logo-sischef.svg"} style={{ width: '96px' }} alt="Logo do Sischef"></img>
              </Grid>

              <Grid item xs={8}>
                <Typography  style={{ color: 'white', fontSize: "3em", fontWeight: "bold", letterSpacing: "0px" }}>Sischef</Typography>
              </Grid>

            </Grid>
          </Box>


          <Grid container>

            <Grid item sm={6} xs={12} className={classes.header} >
              <Box p={1} >
                <Typography variant="h1" className={classes.frasePrincipal}>Gestão inteligente</Typography>
              </Box>
            </Grid>

            <Grid item sm={6} xs={12}>

              <Paper variant="outlined" elevation={0} className={classes.loginBox}>
                <Box p={1}>

                  <Typography component="h2" variant="h3" paddingBottom={2}>
                    Acesso unificado
                  </Typography>

                  {logged ? (
                    <React.Fragment>

                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenSelectSystem(true)}
                      >
                        Acessando como {profile.nome} <br />Clique continuar
                      </Button>

                      <Box pt={2}>

                      <Button
                        fullWidth
                        variant="text"
                        color="error"
                        onClick={handleLogout}
                        endIcon={<ExitToAppIcon/>}
                      >
                        Acessar como outro usuário
                      </Button>

                      </Box>
                    </React.Fragment>


                  ) : (
                    <form  onSubmit={hadleSubmit}>
                       
                      <TextField
                        variant="filled"
                        margin="normal"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        required
                        fullWidth
                        id="username"
                        label="Usuário"
                        name="username"
                        onChange={handleChange}
                        autoComplete="username"
                        value={values.username}
                        autoFocus
                      />
                      <TextField
                        variant="filled"
                        margin="normal"
                        required
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={handleChange}
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        value={values.password}
                        autoComplete="current-password"
                      />

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={sending}
                        >
                          {sending ? 'Enviando...' : 'Acessar'}
                        </Button>
                        {sending}
                      </form>

                  )}

                </Box>
              </Paper>

              <SelectSystemDialog open={openSelectSystem} setOpen={setOpenSelectSystem} 
                loggedUser={loggedUser} openSytem={openSytem}
                isSystemDisabledFor={isSystemDisabledFor} handleLogout={handleLogout}/>

              <Box pt={3}>
                <Typography align="center" color="secondary">
                  Parseint Desenvolvimento de Sistemas Ltda
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </StyledContainer>
    </>
  );
}