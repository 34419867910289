import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SITUACOES } from "../../../../../../util/board/BoardUtil";
import { useOrderContext } from "../../context/OrderProvider";
import { applyFilters } from "../../context/utils";

const BoardUpdate = () => {
  const boardData = useSelector(
    (state) => state.boardState.board,
    shallowEqual
  );
  const {
    filtersType,
    setDataOrders,
    filterSituation,
    filtersBoolean,
    delay,
    refetch,
    setRefetch,
  } = useOrderContext();
  useEffect(() => {
    if (!boardData) return;
    if (refetch) setRefetch(false);
    const filteredLists = !filterSituation.includes("Todos")
      ? boardData.lists.filter((list) =>
        filterSituation.includes(
          SITUACOES.find((sit) => sit.key === list.id)?.name
        )
      )
      : boardData.lists;

    const boardOrders = filteredLists
      .flatMap((list) => list.cards)
      .filter((card) => card.founded !== "NOT_FOUNDED");

    const orderList = boardOrders.map((card) => card.data);
    const newOrders = applyFilters(orderList, filtersType, filtersBoolean);

    setDataOrders(newOrders);
    //eslint-disable-next-line
  }, [filtersType, filtersBoolean, filterSituation, boardData, delay, refetch]);

  return null;
};

export default BoardUpdate;
