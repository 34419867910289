import { Box } from "@mui/material";
import { styled } from "@mui/styles";


export const StyledBoxHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  justifyContent: "center",
  height: "14rem",
  display: "flex",
  width: "100%",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.personal.background,
  marginTop: "-3rem",
  borderRadius: "1rem",
  width: "85%",
}));