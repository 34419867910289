import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    ListItemText,
    Menu,
    MenuItem,
    Switch,
    Typography
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SITUACOES, TIPOS_PEDIDOS } from "../../../../../../util/board/BoardUtil";
import { useOrderContext } from "../../context/OrderProvider";
import MultipleSelect from "./MultipleSelect";

const FilterButton = () => {
    const [anchorElFiltros, setAnchorElFiltros] = useState(null);
    const tiposFiltrados = TIPOS_PEDIDOS.filter(item => item.key === 'DELIVERY' || item.key === 'BALCAO');
    const [tiposPedido, setTiposPedido] = useState(tiposFiltrados);
    const { setFiltersType, setFiltersBoolean, setFilterSituation, filterSituation, filtersBoolean, filtersType } = useOrderContext();
    useEffect(() => {
        const marcarDelivery = () => {
            const novosTiposPedido = tiposPedido.map((pedido) =>
                ({ ...pedido, checked: filtersType.some(type => type === pedido.key) })
            );

            setTiposPedido(novosTiposPedido);
        };
        marcarDelivery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeType = useCallback((tipo) => {
        setTiposPedido((prevTipos) =>
            prevTipos.map((t) =>
                t.key === tipo.key ? { ...t, checked: !tipo.checked } : t
            )
        );

        setFiltersType((prevTypes) =>
            tipo.checked
                ? prevTypes.filter((name) => name !== tipo.key)
                : [...prevTypes, tipo.key]
        );
    }, []);

    const handleFiltroChange = useCallback((event) => {
        setFiltersBoolean((prev) => ({
            ...prev,
            [event.target.name]: event.target.checked
        }));
    }, []);

    const tiposPedidoComponent = useMemo(
        () =>
            tiposPedido.map((tipo) => (
                <MenuItem disableRipple key={tipo.key}>
                    <FormControlLabel
                        value={tipo.sigla}
                        control={
                            <Checkbox
                                name={tipo.key}
                                checked={tipo.checked}
                                onChange={() => handleChangeType(tipo)}
                                size="small"
                            />
                        }
                        label={<Typography variant="button">{tipo.descricao}</Typography>}
                    />
                </MenuItem>
            )),
        [tiposPedido, handleChangeType]
    );

    return (
        <Box >
            <Button
                color="grey"
                onClick={(event) => setAnchorElFiltros(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Filtros
            </Button>
            <Menu
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                anchorEl={anchorElFiltros}
                open={Boolean(anchorElFiltros)}
                onClose={() => setAnchorElFiltros(null)}
            >
                {tiposPedidoComponent}
                <Divider sx={{ my: 0.5 }} />

                {[
                    { label: "Pagos", key: "pedidosPagos" },
                    { label: "Pagos Online", key: "pedidosPagosOnline" },
                ].map(({ label, key }) => (
                    <MenuItem disableRipple key={key}>
                        <ListItemText primary={label} />
                        <Switch
                            color="primary"
                            checked={filtersBoolean[key]}
                            name={key}
                            onChange={handleFiltroChange}
                        />
                    </MenuItem>
                ))}

                <Divider sx={{ my: 0.5 }} />
                <MultipleSelect list={SITUACOES} value={filterSituation} onChange={setFilterSituation} />
            </Menu>
        </Box>
    );
};

export default FilterButton;
