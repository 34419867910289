import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { Box, Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { totalOrder } from "../../../../util/order/orderUtil";
import { useAppDeliveryManProvider } from "../../context/AppDeliveryManProvider";
import TableOrders from "./components/TableOrders";
import { StyledBoxHeader } from "./style";

const Reports = () => {
  const {
    loadingReport,
    orderReporList,
    setLoadingReport,
    loadOrderByDate,
    deliverymanProfile,
  } = useAppDeliveryManProvider();

  useEffect(() => {
    setLoadingReport(true);
    loadOrderByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateTotalValue = (pedidoIntegracao) => {
    var valorTotal = 0;
    pedidoIntegracao.map((pedido) => {
      if (pedido.iFoodOrder) {
        valorTotal += totalOrder(pedido.iFoodOrder);
      }
      return null;
    });

    return valorTotal;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <StyledBoxHeader>
        <Box width="100%" height="100%" display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box
              width="3rem"
              height="3rem"
              display="flex"
              mr="1rem"
              ml="2rem"
              p="0.3rem"
              alignItems="center"
              justifyContent="center"
              bgcolor="white"
              borderRadius="50%"
            >
              <ContentPasteSearchIcon
                sx={{ color: "primary.main" }}
                fontSize="large"
              />
            </Box>
            <Box>
              <Typography color="white" variant="h2">
                Relatórios
              </Typography>
              <Typography color="white" variant="caption">
                Histórico das entregas do dia
              </Typography>
            </Box>
          </Box>
        </Box>
      </StyledBoxHeader>
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <Box>
          <Box mt="4rem" mx="2rem">
            <Typography variant="h4">
              Olá, <strong>{deliverymanProfile.nome}</strong>
            </Typography>
          </Box>
          <Box my="0.5rem" display="flex" gap="1rem" p="2rem">
            <Paper sx={{ width: "100%" }} variant="elevation">
              <Box
                width="100%"
                py="1rem"
                display="flex"
                minHeight="4rem"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                {loadingReport ? (
                  <Skeleton variant="rounded" width="100%" height="100%" />
                ) : (
                  <>
                    <Typography> Pedidos entregues </Typography>

                    <Box mt="0.5rem">
                      <Typography variant="h3">
                        {orderReporList.length}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Paper>
            <Paper sx={{ width: "100%" }} variant="elevation">
              <Box
                width="100%"
                py="1rem"
                minHeight="4rem"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                {loadingReport ? (
                  <Skeleton variant="rounded" width="100%" height="100%" />
                ) : (
                  <>
                    <Typography> Valor total dos pedidos </Typography>
                    <Box mt="0.5rem">
                      <Typography variant="h3">
                        R$ {calculateTotalValue(orderReporList).toFixed(2)}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Paper>
          </Box>
          <Box my="2rem" mx="2rem">
            <Typography variant="h4">
              Seu histórico de pedidos do dia
            </Typography>
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            {loadingReport ? (
              <Skeleton variant="rounded" width="90%" height="20rem" />
            ) : (
              <Box width="90%">
                <TableOrders />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
