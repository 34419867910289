import React from "react";

import { Box, Typography } from "@mui/material";

const DialogTotalValue = ({ order }) => (
  <Box my="3rem">
    <Typography variant="h4">
      Valor Total: R${" "}
      <strong>{`R$ ${order?.totalPedido?.toFixed(2)}`}</strong>
    </Typography>
  </Box>
);

export default DialogTotalValue;
