import React, { useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Avatar, Card, CardActionArea, CardContent, CardMedia, Container, IconButton, styled } from '@mui/material';
import { CalendarMonth, Call, WhatsApp } from '@mui/icons-material';

const PREFIX = 'helpDesk'

const classes = {
  box: `${PREFIX}-box`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
    },
  },

  [`&.${classes.boxPadding}`]: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '90px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
      paddingBottom: 0,
    },
  },
}))

export default function Helpdesk() {
  const scriptRef = useRef(null)
  const scriptIARef = useRef(null)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    mountChat();
    mountIAChat();

    return () => {
      unmountChat()
    }
  }, []);

  const mountChat = () => {
    scriptRef.current = document.createElement('script');
    scriptRef.current.src = "https://kong.tallos.com.br:18000/megasac-api/widget/65c27b9cd142585857989238-65cd048770ef7053bc4ee1af-1.min.js";
    scriptRef.current.async = true;
    document.body.appendChild(scriptRef.current);
    //document.body.appendChild(scriptRef.current);
  }

  const mountIAChat = () => {
    window.embeddedChatbotConfig = {
      chatbotId: "zAPrEbHDR2JIXbZu0Yrd2",
      domain: "www.chatbase.co"
    }

    scriptIARef.current = document.createElement('script');
    scriptIARef.current.src = "https://www.chatbase.co/embed.min.js";
    scriptIARef.current.async = true;
    scriptIARef.current.chatbotId = "zAPrEbHDR2JIXbZu0Yrd2";
    scriptIARef.current.domain = "www.chatbase.co";
    document.body.appendChild(scriptIARef.current);
  }


  const unmountChat = () => {

  }

  const openChatSuport = () => {
    if (verificaDisponibilidadeChatHumano()) {
      const element = document.getElementById('megasac-button');
      if (element) {
        element.click()
      }
    } else {
      enqueueSnackbar(`Fora de horário do atendimento humano. Redirecionando para o atendente de IA`, { variant: "warning" });
      openIAChatSuport()
    }
  }

  const openIAChatSuport = () => {
    const element = document.getElementById('chatbase-message-bubbles');
    if (element) {
      element.click()
    }
  }

  const verificaDisponibilidadeChatHumano = () => {
    const now = new Date();
    const day = now.getDay(); // 0 = Domingo, 1 = Segunda, ..., 6 = Sábado
    const hour = now.getHours();
    const minutes = now.getMinutes();

    const isWithinTime = (startHour, startMinute, endHour, endMinute) => {
      const currentTime = hour * 60 + minutes;
      const startTime = startHour * 60 + startMinute;
      const endTime = endHour * 60 + endMinute;
      return currentTime >= startTime && currentTime <= endTime;
    };

    let available = false;

    switch (day) {
      case 0: // Domingo
        available = isWithinTime(11, 0, 12, 0);
        break;
      case 1: // Segunda-feira
      case 2: // Terça-feira
      case 3: // Quarta-feira
      case 4: // Quinta-feira
      case 5: // Sexta-feira
        available = isWithinTime(8, 45, 22, 0);
        break;
      case 6: // Sábado
        available = isWithinTime(10, 0, 13, 0) || isWithinTime(18, 0, 20, 0);
        break;
      default:
        break;
    }

    return available;
  };


  function ActionAreaCard({ urlImage, header, subtitle, onClick }) {
    return (
      <Card style={{ flex: 0.4 }}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            component="img"
            height="240"
            image={urlImage}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {header}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{ paddingTop: { xs: 2, sm: 10 } }}>
          <StyledBox
            className={classes.box}
            sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'center',
            }}>
            <ActionAreaCard
              urlImage={"/static/images/layout/atendenteSischefBruce.jpg"}
              header="Iniciar uma conversa com Bruce"
              subtitle="Falar diretamente com um atendente de IA Bruce"
              onClick={openIAChatSuport}
            />
            <ActionAreaCard
              urlImage={"/static/images/layout/helpdesk.svg"}
              header="Iniciar uma conversa"
              subtitle="Clique aqui para falar diretamente com um atendente"
              onClick={openChatSuport} />
          </StyledBox>
          <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginTop: 1 }}>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems='center' >
              <Typography variant='h5' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 1 }}>
                <IconButton color='primary' size='medium'>
                  <CalendarMonth />
                </IconButton>
                Horários de atendimento:
              </Typography>
              <Typography variant='body1' sx={{ marginBottom: 1 }}>
                - Segunda a Sexta: 09:00 às 22:00 <br />
                - Sábado: 10:00 às 13:45 e 16:00 às 19:45 <br />
                - Domingos e Feriados: 10:00 às 12:00
              </Typography>
            </Box>
            <StyledBox className={`${classes.box} ${classes.boxPadding}`} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 0.5 }}>
              <Typography variant='h6' >
                <IconButton color='primary' size='medium'>
                  <WhatsApp />
                </IconButton>
                +55 (46) 99139-0992
              </Typography>
              <Typography variant='h6'>
                <IconButton color='primary' size='medium'>
                  <Call />
                </IconButton>
                +55 (46) 3199-0986
              </Typography>
            </StyledBox>
          </Box >
        </Box>
      </Container >
    </>
  );
}