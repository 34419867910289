import { Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FixedSizeList as List } from 'react-window';
import { DialogTitle } from '../../../../../../../layout-components/CustomDialog/dialogComponents';
import DialogAddress from '../../../../../DialogAddress';
import Cell from './Cell';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
    typography: {
        textAlign: 'right',
    },
}))

export default function ResumeListDispatch({ open, setOpen, selectedMan }) {
    const pedidos = selectedMan?.pedidos || []
    const classes = useStyles()
    const totalHeight = pedidos.length * 60; // 60 é a altura de cada item
    const [openDialogCustomer, setOpenDialogCustomer] = useState(false)
    const [selectedPedido, setSelectedPedido] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (event) => {
        const attr = event.target.name
        const value = event.target.value
        setSelectedPedido({ ...pedidos, [attr]: value })
    }

    const handleEditCustomer = (pedido) => {
        setSelectedPedido(pedido)
        setOpenDialogCustomer(true)
    }

    function handleUpdate() {
        setOpen(false)
        //onUpdate()
    }

    return ( 
       <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={handleClose}>
                Listagem de clientes e vendas
            </DialogTitle>
            <DialogContent>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={6}>
                        <Typography sx={{ fontWeight: 'bold' }}>Nome</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.typography}>
                        <Typography sx={{ fontWeight: 'bold' }}>Total</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.typography}>
                        <Typography sx={{ fontWeight: 'bold' }}>Entregador</Typography>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                {!!pedidos.length && (
                    <List
                        height={Math.min(totalHeight, 700)} 
                        width="100%" 
                        itemSize={60} 
                        itemCount={pedidos.length} 
                        itemData={pedidos} 
                    >
                         {({ index, style }) => (
                            <Cell index={index} style={style} pedidos={pedidos} handleEditCustomer={handleEditCustomer} />
                        )}
                    </List>
                )}
                <Typography variant="caption">{pedidos.length} pedidos</Typography>
            </DialogContent>

            <DialogAddress
                open={openDialogCustomer}
                setOpen={setOpenDialogCustomer}
                pedido={selectedPedido}
                setPedido={setSelectedPedido}
                handleChange={handleChange}
                setSelectedPedido={setSelectedPedido}
                onUpdate={handleUpdate}
            />
        </Dialog>
    )
}