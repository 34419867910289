import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatFullAddressDelivery, isAddressValid } from '../../../../../../../../../util/customer/helper';
import { currencyFormat } from '../../../../../../../../../util/formatter';
import { totalOrder } from '../../../../../../../../../util/order/orderUtil';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    list: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
    },
    border: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: '8px'
    }

}))

const Cell = ({ index, style, pedidos, handleEditCustomer }) => {
  const classes = useStyles();
  const pedido = pedidos[index];
  const addressValid = isAddressValid(pedido.iFoodOrder?.deliveryAddress);

  return (
      <Grid container style={{ ...style }} className={classes.border} alignItems="center">
          <Grid item xs={6}>
              <Typography>{pedido.iFoodOrder?.customer?.name}</Typography>
              <Typography variant="caption">{formatFullAddressDelivery(pedido.iFoodOrder?.deliveryAddress).address}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.list}>
              <Typography>{currencyFormat(totalOrder(pedido.iFoodOrder))}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.list}>
              <Typography>{pedido.pessoaEntregador ? pedido.pessoaEntregador.nome : "Sem entregador"}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.list}>
              <Tooltip title={addressValid ? 'Endereço completo.' : 'Endereço incompleto. Clique para editar'}>
                  {addressValid ? (
                      <CheckIcon />
                  ) : (
                      <ErrorIcon color="error" onClick={() => handleEditCustomer(pedido)} style={{ cursor: 'pointer' }} />
                  )}
              </Tooltip>
          </Grid>
      </Grid>
  );
};


export default Cell;
