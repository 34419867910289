import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DialogActions, DialogContent, DialogTitle } from "../../layout-components/CustomDialog/dialogComponents";

export default function FastSaleDialogSettings({ open, onClose }) {

    const [selectCustomerDisplay, setSelectCustomerDisplay] = useState("NAO_EXIBIR");

    useEffect(() => {
        const storedSettings = JSON.parse(localStorage.getItem('_fast_sale_settings_') || '{}')
        if (storedSettings.fast_sale_settings) {
            setSelectCustomerDisplay(storedSettings.fast_sale_settings);
        }
    }, [])

    const customerChoiceDisplay = (event) => {
        setSelectCustomerDisplay(event.target.value)
    }

    function handleCustomerChoiceDisplay() {
        let settings = {}
        settings.fast_sale_settings = selectCustomerDisplay
        localStorage.setItem("_fast_sale_settings_", JSON.stringify(settings))
        onClose()
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth='xs'
                fullWidth
                open={open}
                onClose={onClose}
            >
                <DialogTitle onClose={onClose}>
                    Configurações de venda rápida
                </DialogTitle>

                <DialogContent>
                    <Box>
                        <Box sx={{ marginBottom: 1.5 }}>
                            <Typography variant="body1">
                                Selecione o modo de exibição para informar o cliente no pedido:
                            </Typography>
                        </Box>
                        <FormControl fullWidth>
                            <InputLabel id='exibicao_cliente'>Exibição</InputLabel>
                            <Select
                                value={selectCustomerDisplay}
                                onChange={customerChoiceDisplay}
                                label="Exibição"
                                labelId="exibicao_cliente"
                            >
                                <MenuItem
                                    value='NAO_EXIBIR'
                                >Não exibir
                                </MenuItem>
                                <MenuItem
                                    value='EXIBIR_COMECO'
                                >Exibir no inicio da venda
                                </MenuItem>
                                <MenuItem
                                    value='EXIBIR_FINAL'
                                >Exibir no final da venda
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCustomerChoiceDisplay}>
                        Salvar
                    </Button>
                </DialogActions>

            </Dialog>

        </React.Fragment >
    )
}