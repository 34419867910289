import React, { useEffect, Fragment, useState } from 'react';
import {
    Dialog, Grid, IconButton, RadioGroup, FormControlLabel, Radio, MenuItem, ListItemAvatar, Avatar,
    ListItemText, List, ListItem, Menu, ListItemSecondaryAction, Box, Paper, Accordion, AccordionSummary, AccordionDetails,
    TableRow, TableCell, Table, FormControl, Typography, TextField, Hidden, ListItemButton, Button,
    TableBody
} from '@mui/material';
import { TIPOS_IMPORTACOES, TIPOS_PEDIDOS, SITUACOES, getTipoImportacaoBySigla, getSituacaoBySigla, getTypeOrderBykey } from "../../../../util/board/BoardUtil"
import { DialogContent, DialogTitle } from "../../CustomDialog/dialogComponents"
import CustomerSelector from '../Customer/CustomerSelector';
import { Alert, Skeleton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import DoneIcon from '@mui/icons-material/Done';
import AuthService from '../../../../service/AuthService';
import { currencyFormat, dateFormat, quantityFormat } from "../../../../util/formatter";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSnackbar } from 'notistack';
import { messagesCustomer } from '../../../../util/customer/helper';
import { useSelector } from 'react-redux';
import { getParamByKey } from '../../../../component/parameter';
import { isDeliveryOrder } from '../../../../util/order/orderUtil';
import IntegrationAvatar from '../../Board/IntegrationAvatar';
import DialogCustomerValues from '../Customer/DialogCustomerValues';
import { ifAnyGranted } from '../../../../util/auth/authUtil';

export default function TypeOrderComponent({ order, setOrder, selectedCustomer, handleSelectCustomer, handleCopyItem }) {

    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElSituacao, setAnchorElSituacao] = React.useState(null);
    const [tipoImportacao, setTipoImportacao] = React.useState(null);
    const [mostrarIconOrigem, setMostrarIconOrigem] = React.useState(false)
    const [mostrarIconSituacao, setMostrarIconSituacao] = React.useState(false)
    const [ultimosPedidos, setUltimosPedidos] = React.useState([]);
    const [expanded, setExpanded] = React.useState(false);
    const [openPedidosAntigos, setOpenPedidosAntigos] = React.useState(false);
    const [carregandoUltimosPedidos, setCarregandoUltimosPedidos] = React.useState(false);
    const [alertCustomer, setAlertCustomer] = useState([])
    const typeOrder = getTypeOrderBykey(order?.tipo)
    const generalParameters = useSelector(state => state.generalParameters)
    const [tiposPedidos, setTiposPedidos] = useState(TIPOS_PEDIDOS)
    const isDelivery = isDeliveryOrder(order)
    //const ROLE_VISUALIZAR_VALORES_PENDENTES = ifAnyGranted('ROLE_VISUALIZAR_VALORES_PENDENTES')

    useEffect(() => {
        setTipoImportacao(getTipoImportacaoBySigla(order?.tipoImportacao))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedCustomer) {
            setAlertCustomer(messagesCustomer(selectedCustomer))
        }
    }, [selectedCustomer])

    useEffect(() => {
        /* ESSA LOGICA SERVE PARA DEIXAR APENAS O TIPO DO MODELO PRINCIPAL 
           PARA SER SELECIONADO NO PEDIDO 
        */
        let modeloIdenPrincipal = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString

        if (modeloIdenPrincipal) {
            let tiposCopy = Object.assign([], tiposPedidos);

            if (modeloIdenPrincipal === 'MESA') {
                tiposCopy = tiposCopy.filter(type => (type.key !== 'COMANDA'))
            } else if (modeloIdenPrincipal === 'COMANDA') {
                tiposCopy = tiposCopy.filter(type => (type.key !== 'MESA'))
            }
            setTiposPedidos(tiposCopy)
        }
        // eslint-disable-next-line
    }, [generalParameters])

    const handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value
        //console.log(name, value, order)

        if (name === 'identificadorSecundario') { // adicionada verificação para não inserir numeros negativos

            if (value > -1) {
                setOrder({ ...order, [name]: value })
            } else {
                enqueueSnackbar('Não é possível inserir um valor negativo para Mesa.', { variant: "error" })
                value = ""
            }

        } else {
            setOrder({ ...order, [name]: value })
        }
    }

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickListItemSituacao = (event) => {
        setAnchorElSituacao(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseSituacao = () => {
        setAnchorElSituacao(null);
    };

    const changeTipoImportacaoItens = (sigla) => {
        let iFoodOrder = order.iFoodOrder;

        iFoodOrder.items.map((item) => {
            let tipoImportacaoItem = item.tipoImportacao ? item.tipoImportacao : 'SO'
            if (!getTipoImportacaoBySigla(tipoImportacaoItem).integracao) {
                item.tipoImportacao = sigla;
                if (item.subItems !== null && item.subItems !== undefined) {
                    item.subItems.map((subItem) => {
                        subItem.tipoImportacao = sigla;
                        if (subItem.subItems !== null && subItem.subItems !== undefined) {
                            subItem.subItems.map((subSubItem) => {
                                subSubItem.tipoImportacao = sigla;
                                return null;
                            })
                        }
                        return null;
                    })
                }
            }
            return null;
        })

        let name = "iFoodOrder"
        setOrder({ ...order, [name]: iFoodOrder })
    }

    const handleMenuItemClick = (event, option) => {
        let name = "tipoImportacao"
        let value = option.sigla
        changeTipoImportacaoItens(value)
        setOrder({ ...order, [name]: value })
        setAnchorEl(null);
        setTipoImportacao(getTipoImportacaoBySigla(option.sigla))
    }

    const handleMenuItemSituacaoClick = (event, option) => {
        let name = "situacao"
        let value = option.key

        setOrder({ ...order, [name]: value })
        handleCloseSituacao()
    }

    const buscarUltimosPedidos = () => {
        let service = new AuthService();

        setCarregandoUltimosPedidos(true);
        service
            .get(`/api-v2/pedidoIntegracao/listByPessoa/${selectedCustomer.id}`)
            .then(resp => {
                setUltimosPedidos(resp);
                setCarregandoUltimosPedidos(false);
            }).catch(err => {
                console.error(err);
                setCarregandoUltimosPedidos(false);
                enqueueSnackbar("Erro ao buscar pedidos", { variant: 'error', });
            })
    }

    const handleChangeExpansion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOpenPedidosAntigos = () => {
        if (ultimosPedidos.length > 0) {
            setUltimosPedidos([]);
        } else {
            buscarUltimosPedidos();
        }
        setOpenPedidosAntigos(!openPedidosAntigos);
    }

    function disableTypeSelectorByTypeOrder(type) {

        if (order.tipo === 'DELIVERY') {
            if (type === 'BALCAO') {
                return false
            }
            return true;
        } else if (order.tipo === 'BALCAO') {
            if (type === 'DELIVERY') {
                return false
            }
            return true;
        } else if (order.tipo === 'CAIXA_RAPIDO') {
            return true
        } else {
            if (type === 'DELIVERY' || type === 'BALCAO' || type === 'CAIXA_RAPIDO') {
                return true
            }
            return false;
        }

    }

    return (
        <Box >
            <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center">

                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <Box px={2} py={1}>
                            <FormControl component="fieldset" >
                                {/* <FormLabel component="legend">Tipo do pedido</FormLabel> */}
                                <RadioGroup row name="tipo" value={(order && order.tipo) || ''} onChange={handleChange}>
                                    {
                                        tiposPedidos.map((tipo, index) => {
                                            return (
                                            <Box p={0} key={index} >
                                                <FormControlLabel disabled={disableTypeSelectorByTypeOrder(tipo.key)} value={tipo.sigla} control={<Radio size='small' />} label={
                                                        <div>
                                                        <Typography variant='button'>{tipo.descricao}</Typography>
                                                    </div>}
                                                />
                                            </Box>
                                            )
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Paper>
                </Grid>

                {isDelivery &&
                    <Grid item xs={12}>
                        <Paper variant="outlined">
                            <Grid
                                container
                                spacing={0}
                                justifyContent="center"
                                alignItems="center">
                                <Grid item xs={12} sm={6}>

                                    <List component="nav"
                                        aria-label="Origem do pedido"
                                        disablePadding>
                                        <ListItemButton
                                            aria-haspopup="true"
                                            aria-controls="lock-menu"
                                            aria-label="Selecionar origem do pedido"
                                            onClick={handleClickListItem}
                                            onMouseLeave={() => { setMostrarIconOrigem(false) }}
                                            onMouseEnter={() => { setMostrarIconOrigem(true) }}
                                            disabled={tipoImportacao && tipoImportacao.integracao}
                                        >
                                            {tipoImportacao ? (
                                                <React.Fragment>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="square"
                                                            alt={tipoImportacao.descricao}
                                                            src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${tipoImportacao.sigla}.png`}>
                                                            {tipoImportacao.sigla}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={tipoImportacao.descricao} secondary="Origem" />

                                                    <ListItemSecondaryAction >
                                                        {mostrarIconOrigem ?
                                                            // <IconButton edge="end" >
                                                            <EditIcon />
                                                            // </IconButton>
                                                            :
                                                            null}
                                                    </ListItemSecondaryAction>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            variant="square"
                                                            alt="Selecione a origem" >
                                                            --
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary=" - selecione a origem -" />
                                                </React.Fragment>
                                            )}

                                        </ListItemButton>
                                    </List>

                                    <Menu
                                        id="lock-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        value={(order && order.situacao) || ''}>

                                        {TIPOS_IMPORTACOES.map((option) => (
                                            <MenuItem
                                                key={option.key}
                                                value={option.sigla}
                                                disabled={option.integracao}
                                                onClick={(event) => handleMenuItemClick(event, option)}
                                                selected={option.sigla === order.tipoImportacao} >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        variant="square"
                                                        alt={option.descricao}
                                                        src={process.env.PUBLIC_URL + `/static/images/integrations/logo-${option.sigla}.png`}>
                                                        {option.sigla}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={option.descricao} />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <List component="nav"
                                        aria-label="Selecionar a situação"
                                        disablePadding>

                                        <ListItemButton
                                            aria-haspopup="true"
                                            aria-controls="lock-menu"
                                            onClick={handleClickListItemSituacao}
                                            onMouseLeave={() => { setMostrarIconSituacao(false) }}
                                            onMouseEnter={() => { setMostrarIconSituacao(true) }}>
                                            <React.Fragment>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{ background: getSituacaoBySigla(order.situacao).color }}
                                                        alt={order.situacao}
                                                    >
                                                        <CheckIcon />
                                                    </Avatar>
                                                </ListItemAvatar>

                                                <ListItemText primary={(order && order.situacao) || ''} secondary="Situação" />
                                                <ListItemSecondaryAction>
                                                    {mostrarIconSituacao ?
                                                        < EditIcon />
                                                        : null}
                                                </ListItemSecondaryAction>
                                            </React.Fragment>
                                        </ListItemButton>
                                    </List>

                                    <Menu
                                        id="lock-menu"
                                        anchorEl={anchorElSituacao}
                                        keepMounted
                                        open={Boolean(anchorElSituacao)}
                                        onClose={handleCloseSituacao}
                                    >
                                        {SITUACOES.map((option) => (
                                            <MenuItem
                                                key={option.key}
                                                value={option.key}
                                                onClick={(event) => handleMenuItemSituacaoClick(event, option)}
                                                selected={option.key === order.situacao} >

                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: option.color }}>
                                                        <DoneIcon fontSize="small" />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={option.name}
                                                    disableTypography />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }

                {(order.tipo === 'COMANDA' || order.tipo === 'MESA') &&
                    <React.Fragment>
                        <Grid item xs={6}>
                            <TextField
                                id="identificadorPrincipal"
                                label={typeOrder?.descricao}
                                fullWidth
                                name='identificadorPrincipal'
                                value={order.identificadorPrincipal}
                                variant="outlined"
                                onChange={handleChange}
                                autoFocus={order.identificadorPrincipalEditavel}
                                disabled={!order.identificadorPrincipalEditavel}
                            />
                        </Grid>

                        {order.tipo === 'COMANDA' &&
                            <Grid item xs={6} >
                                <TextField
                                    id="identificadorSecundario"
                                    label='Mesa'
                                    inputProps={{
                                        type: "number",
                                        autoComplete: "off",
                                        inputMode: 'numeric',
                                        min: "0"
                                    }}
                                    fullWidth
                                    autoFocus={!!order.identificadorPrincipal}
                                    onChange={handleChange}
                                    name='identificadorSecundario'
                                    value={order.identificadorSecundario}
                                    variant="outlined"
                                />
                            </Grid>}

                    </React.Fragment>
                }

                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <Box px={1} py={1} >
                            <CustomerSelector
                                size="large"
                                color="primary"
                                variant="contained"
                                customer={selectedCustomer}
                                setCustomer={handleSelectCustomer}
                                disableElevation
                            />

                            {selectedCustomer ? (
                                <React.Fragment>
                                    <Grid container spacing={2} alignItems={'center'}>

                                        <Grid item>
                                                <DialogCustomerValues
                                                    size="large"
                                                    color="primary"
                                                    style={{ color: 'white' }}
                                                    variant="contained"
                                                    selectedCustomer={selectedCustomer}
                                                    setCustomer={handleSelectCustomer}
                                                    disableElevation
                                                />
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                color="inherit"
                                                onClick={handleOpenPedidosAntigos}
                                                startIcon={<HistoryIcon />}>
                                                Histórico de pedidos
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <ListItemText>
                                        {alertCustomer && alertCustomer.length > 0 &&
                                            <Alert icon={false} severity="warning">
                                                {alertCustomer.map((message) => (
                                                    <li>{message}</li>
                                                ))}
                                            </Alert>
                                        }
                                    </ListItemText>

                                    <Dialog
                                        open={openPedidosAntigos}
                                        onClose={handleOpenPedidosAntigos}
                                        fullWidth maxWidth="sm">

                                        <DialogTitle onClose={handleOpenPedidosAntigos} >
                                            Últimos pedidos de {selectedCustomer.nome}
                                        </DialogTitle>

                                        <DialogContent>
                                            {!carregandoUltimosPedidos ?
                                                ultimosPedidos.length > 0 ?
                                                    ultimosPedidos.map(pedido => (
                                                        <React.Fragment key={pedido.id}>
                                                            <Accordion expanded={expanded === pedido.codigoIntegracao} onChange={handleChangeExpansion(pedido.codigoIntegracao)}>
                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={2}>
                                                                            <IntegrationAvatar pedido={pedido} />
                                                                        </Grid>
                                                                        <Grid item xs={7}>
                                                                            {dateFormat(pedido.dataPedido)}
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            #{pedido.id}
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Table size="small">
                                                                        <TableBody>
                                                                            {pedido.iFoodOrder?.items?.map((item, index) => (
                                                                                <Fragment key={index}>
                                                                                    <TableRow >
                                                                                        <TableCell> {item.name} {item.observations ? (<span style={{ opacity: "0.5" }}><br />{item.observations}</span>) : null} </TableCell>
                                                                                        <TableCell>{quantityFormat(item.quantity)}</TableCell>
                                                                                        <TableCell> {currencyFormat(item.price, { simbol: "" })} </TableCell>
                                                                                        <TableCell> {currencyFormat(item.totalPrice * item.quantity, { simbol: "" })} </TableCell>
                                                                                        <TableCell align="right" size="small"><IconButton color="primary" onClick={() => handleCopyItem(item)} size="large"><AddCircleOutlineIcon /></IconButton></TableCell>
                                                                                    </TableRow>

                                                                                    {item.subItems ? item.subItems.map((subItem, index) => (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell > &nbsp;	+ {subItem.name} {subItem.observations ? (<span style={{ opacity: "0.5" }}><br /> &nbsp; {subItem.observations}</span>) : null} </TableCell>
                                                                                            <TableCell style={{ opacity: "0.7" }} > {quantityFormat(subItem.quantity)}</TableCell>
                                                                                            <TableCell style={{ opacity: "0.7" }} > {currencyFormat(subItem.price, { simbol: "" })} </TableCell>
                                                                                            <TableCell style={{ opacity: "0.7" }} > {currencyFormat(subItem.totalPrice * subItem.quantity, { simbol: "" })} </TableCell>
                                                                                            <TableCell style={{ opacity: "0.7" }} ></TableCell>
                                                                                        </TableRow>
                                                                                    )) : null}
                                                                                </Fragment>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </React.Fragment>
                                                    )) : (
                                                        <List>
                                                            <ListItem>
                                                                <ListItemText primary="Nenhum pedido encontrado para esta pessoa." />
                                                            </ListItem>
                                                        </List>

                                                    ) : <Skeleton variant="rectangular" width={477} height={118} animation="wave" />}

                                        </DialogContent>
                                    </Dialog>
                                </React.Fragment>
                            ) : null}

                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    );
}
