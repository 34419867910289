import Brightness4Icon from "@mui/icons-material/Brightness4";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import AuthService from "../../../../service/AuthService";
import { useAppDeliveryManProvider } from "../../context/AppDeliveryManProvider";
import CardRouter from "./components/CardRouter";
import { StyledBox, StyledBoxHeader } from "./styles";
const RouterList = () => {
  const service = new AuthService();
  const dispatch = useDispatch();
  const { ordersForDeliveryMan, ordersNoDeliveryMan } =
    useAppDeliveryManProvider();
  const profile = service.getProfile();
  const toogleThemeMode = () => {
    dispatch({ type: "TOOGLE_THEME_MODE" });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <StyledBoxHeader>
        <Box
          display="flex"
          width="100%"
          height="60%"
          alignItems="flex-end"
          sx={{ px: "2rem" }}
          justifyContent="center"
        >
          <Box
            width="100%"
            px="1rem"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="100%" display="flex" alignItems="flex-start">
              <Box
                width="3rem"
                height="3rem"
                display="flex"
                p="0.3rem"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                borderRadius="50%"
              >
                <TwoWheelerIcon
                  sx={{ color: "primary.main" }}
                  fontSize="large"
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                marginLeft="1rem"
              >
                <Typography color="white" variant="caption">
                  Olá {profile.nome},
                </Typography>
                <Typography color="white" variant="h3">
                  Sistema de entregadores
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                color="inherit"
                onClick={() => toogleThemeMode()}
                size="large"
              >
                <Brightness4Icon sx={{ color: "white" }} fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </StyledBoxHeader>
      <StyledBox>
        <Box>
          <Box padding="1.5rem 1.5rem 1rem 1.5rem">
            <Typography variant="h4" component="h1">
              Minhas rotas pendentes
            </Typography>
          </Box>
          <Box>
            {ordersForDeliveryMan?.map((order, index) => (
              <Box key={order.agrupadorDespacho}>
                <CardRouter order={order} />
                {!!index && (
                  <Box>
                    <Divider />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          {!!ordersNoDeliveryMan.length && (
            <>
              <Box m="2rem 0 1rem 2rem">
                <Typography variant="h4" component="h1">
                  Rotas sem entregador
                </Typography>
              </Box>
              <Box>
                {ordersNoDeliveryMan?.map((order, index) => (
                  <Box key={order.agrupadorDespacho}>
                    <CardRouter order={order} />
                    {!!index && (
                      <Box>
                        <Divider />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </>
          )}

          {!ordersForDeliveryMan.length && !ordersNoDeliveryMan.length && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt="4rem"
                alignItems="center"
                height="100%"
                width="70%"
                textAlign="center"
              >
                <Typography variant="h4" color="textSecondary" gutterBottom>
                  Nenhum pedido para entrega
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                >
                  Aguarde até que um pedido seja despachado para entrega
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </StyledBox>
    </Box>
  );
};

export default RouterList;
