import AuthService from "../../../service/AuthService";
import { formatFullAddressDelivery } from "../../../util/customer/helper";

const getShareableLink = async ({item, setError, setRouteLink}) => {
    const service = new AuthService()
    const profile = service.getProfile()
    const initialCenter = {
        lat: profile.unidade.pessoa.latitude || 0,
        lng: profile.unidade.pessoa.longitude || 0,
    };

    try {
      const origin = `${initialCenter.lat},${initialCenter.lng}`;
      let waypoints = item.pedidos
        .map((pedido) => {
          const { complement, ...addressWithoutComplement } =
            pedido.iFoodOrder?.deliveryAddress || {};
          return (
            formatFullAddressDelivery(
              addressWithoutComplement
            ).address.toLocaleLowerCase() || ""
          );
        })
        .filter((wp) => wp !== ",");
  
      if (waypoints.length === 0) {
        if(setError)setError("Nenhum endereço válido encontrado para a rota");
        return;
      }
  
      let destination;
      if (waypoints.length === 1) {
        destination = waypoints[0];
        waypoints = [];
      } else {
        destination = waypoints.pop();
      }
  
      const encodedAddresses = [origin, ...waypoints, destination]
        .map(encodeURIComponent)
        .join("/");
      const shareableUrl = `https://www.google.com/maps/dir/${encodedAddresses}`;
  
      const embedUrl = `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_GOOGLE_API_KEY
        }&origin=${origin}&destination=${destination}${waypoints.length ? `&waypoints=${waypoints.join("|")}` : ""
        }`;
        if(setRouteLink) setRouteLink(embedUrl);
      return shareableUrl;
    } catch (error) {
      setError(`Erro ao obter direções: ${error.message}`);
    }
  };

  export default getShareableLink;