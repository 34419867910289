import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
    Accordion,
    AccordionDetails,
    Alert,
    AlertTitle,
    Box,
    Chip,
    Container,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import MuiAccordionSummary, {
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import { styled } from "@mui/styles";
import React, { useMemo } from "react";
import SocketContext from "../../../../../../util/socket";
import { useOrderContext } from "../../context/OrderProvider";
import FilterButton from "../FilterButton";
import MapRouter from "../MapRouter";
import OpenRouters from "../OpenRouters";
import FullScreenWrapper from "./components/FullScreenWrapper";
import ListOrders from "./components/ListOrders";
import { SITUACOES } from '../../../../../../util/board/BoardUtil';

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.personal.menu,
    position: "absolute",
    bottom: "0%",
    height: "auto",
    boxShadow: "1rem",
    padding: "0.2rem",
    borderRadius: "0.5rem 0.5rem 0 0",
    overflow: "hidden",
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowBackIosNewIcon sx={{ transform: 'rotate(90deg)' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
        transform: 'rotate(180deg)',
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const MapContainer = (props) => {
    const memoizedMapRouter = useMemo(() => {
        return <MapRouter {...props} />;
    }, []);

    return memoizedMapRouter;
};

const OrderRouter = () => {
    const {
        qtdOrders,
        orderDeliveryOnly,
        invalidAddress,
        isFullScreen,
        setIsFullScreen,
    } = useOrderContext();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <>
            <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                width="100%"
            >
                <Box
                    width={isMobile ? "98%" : "70%"}
                    pr={isMobile ? "0" : "1rem"}
                    mb={isMobile ? "1.5rem" : "0"}
                >
                    <Box
                        display="flex"
                        width="100%"
                        flexDirection={isMobile ? "column" : "row"}
                        alignItems={isMobile ? "flex-start" : "flex-end"}
                        justifyContent="space-between"
                    >
                        <Container component="main" maxWidth={false}>
                            <Typography variant="h2" component="h1">
                                Roteirização
                            </Typography>

                            <Typography variant="h5" component="h2">
                                Crie rotas a partir dos pedidos pelo mapa
                            </Typography>
                        </Container>
                        <Box mt={isMobile ? "1.5rem" : 0} ml={isMobile ? "0.5rem" : 0}>
                            <FilterButton />
                        </Box>
                    </Box>
                    {!!invalidAddress.length && (
                        <Box ml="1.5rem" mt="2rem">
                            <Alert severity="warning">
                                <AlertTitle>Aviso</AlertTitle>
                                O(s) pedido(s) {invalidAddress.join(", ")} possuem informações
                                incompletas no endereço. Por favor, revise os dados antes de
                                prosseguir.
                            </Alert>
                        </Box>
                    )}
                    {orderDeliveryOnly && (
                        <Box ml="1.5rem" mt="2rem">
                            <Alert severity="warning">
                                <AlertTitle>Aviso</AlertTitle>
                                Você está visualizando pedidos que não são de delivery. Para uma
                                visualização precisa, por favor, aplique o filtro para exibir
                                apenas os pedidos de delivery.
                            </Alert>
                        </Box>
                    )}
                    <Box
                        flex="1"
                        mt={isMobile ? "1rem" : "2rem"}
                        pl={isMobile ? "1rem" : "1.5rem"}
                        position="relative"
                    >
                        <Box display="flex" justifyContent="space-between"> 
                            <Typography variant="caption" component="h1">
                                Quantidade de pedidos : {qtdOrders}
                            </Typography>

                        </Box>

                        <FullScreenWrapper
                            setOpen={setIsFullScreen}
                            isFullScreen={isFullScreen}
                        >
                            <Box display="flex">
                                <Box width="100%" >
                                    <Box width="100%" position="relative">
                                        <Box flex="1">
                                           {/* <MapContainer /> */} 

                                           <Box p={5}>
                                                <Chip label="FUNÇÃO INATIVADO TEMPORARIAMENTE" color="error" />
                                           </Box>
                                           
                                        </Box>
                                        <StyledBox flex={1} sx={{ height: "auto", right: isFullScreen ? "2%" : "1rem" }}>
                                            <Accordion
                                                disableGutters
                                                defaultExpanded
                                                sx={{
                                                    boxShadow: "none",
                                                    borderRadius: 0,
                                                    height: "auto",
                                                    background: "none",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <AccordionSummary>
                                                    <Typography fontWeight="bold" variant="h5">
                                                        Pedidos
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ margin: 0, padding: 0, width: "20rem" }}>

                                                    <ListOrders />
                                                </AccordionDetails>
                                            </Accordion>
                                        </StyledBox>
                                    </Box>
                                    <Box m="2rem">
                                        <Grid container spacing={2} alignItems="center">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                mr={2}
                                            >
                                                <Box
                                                    width="12px"
                                                    height="12px"
                                                    bgcolor="#A020F0"
                                                    borderRadius="50%"
                                                    mr={1}
                                                />
                                                <Typography variant="caption">Alocado em Rota</Typography>
                                            </Box>
                                            {SITUACOES.map((situacao) => (
                                                <Box
                                                    key={situacao.key}
                                                    display="flex"
                                                    alignItems="center"
                                                    mr={2}
                                                >
                                                    <Box
                                                        width="12px"
                                                        height="12px"
                                                        bgcolor={situacao.color}
                                                        borderRadius="50%"
                                                        mr={1}
                                                    />
                                                    <Typography variant="caption">{situacao.name}</Typography>
                                                </Box>
                                            ))}

                                        </Grid>
                                    </Box>
                                </Box>
                                {isFullScreen && (
                                    <Box
                                        ml="1rem"
                                        display="flex"
                                        flexDirection="column"
                                        height={isMobile ? "auto" : "85vh"}
                                        sx={{
                                            overflowY: isMobile ? "visible" : "scroll",
                                            overflowX: "hidden",
                                        }}
                                        width={isMobile ? "100%" : "30%"}
                                        maxWidth={isMobile ? "100%" : "30%"}
                                    >
                                        <OpenRouters />
                                    </Box>
                                )}
                            </Box>

                        </FullScreenWrapper>
                    </Box>
                </Box>
                <Divider
                    orientation={isMobile ? "horizontal" : "vertical"}
                    variant="middle"
                    flexItem
                />
                <Box
                    display="flex"
                    flexDirection="column"
                    height={isMobile ? "auto" : "95vh"}
                    sx={{
                        overflowY: isMobile ? "visible" : "scroll",
                        overflowX: "hidden",
                    }}
                    width={isMobile ? "100%" : "30%"}
                    maxWidth={isMobile ? "100%" : "30%"}
                    bg="white"
                    boxShadow="md"
                    marginTop={isMobile ? "1rem" : "0"}
                    paddingX="1rem"
                    borderRadius="md"
                >
                    <Box>
                        <Typography variant="h2" component="h1">
                            Rotas
                        </Typography>

                        <Typography variant="h5" component="h3">
                            Criação de rotas para despacho
                        </Typography>
                    </Box>
                    <Box mt="2rem">
                        <OpenRouters />
                    </Box>
                </Box>
            </Box>
            <SocketContext />
        </>
    );
};

export default OrderRouter;
