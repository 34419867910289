import AuthService from '../../../../../service/AuthService'; // Ajuste o caminho conforme sua estrutura de pastas

export const postSituacaoMultiplosPedidosOnServer = (ids, situacao, idDeliveryMan, idDespacho) => {
  return new Promise((resolve, reject) => {
    let queryParams = new URLSearchParams();

    if (idDeliveryMan) {
      queryParams.append('idDeliveryMan', idDeliveryMan);
    }

    if (idDespacho) {
      queryParams.append('idDespacho', idDespacho);
    }

    const queryString = queryParams.toString() ? `?${queryParams.toString()}` : '';

    const service = new AuthService();

    service.post(`/api-v2/pedidoIntegracao/associar-despacho/multiple/${ids}/${situacao}${queryString}`)
      .then(pedidoResp => resolve(pedidoResp))
      .catch(err => reject(err));
  });
};
