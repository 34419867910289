import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useOrderContext } from "../../../context/OrderProvider";
const CheckOrders = ({ order }) => {
    const { toggleOrder, selectedOrders } = useOrderContext();

    const check = useMemo(
        () => selectedOrders?.some((allOrder) => allOrder.id === order.id) ?? false,
        [selectedOrders, order]
    );

    const handleOrderCheck = () => {
        toggleOrder(order);
    };
    const block =
        (order.situacao === "AGUARDANDO_RETIRADA" && order.agrupadorDespacho) ||
        (order.situacao !== "AGUARDANDO_RETIRADA" &&
            order.situacao !== "CONFIRMADO");
    const address = order.iFoodOrder.deliveryAddress;
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Tooltip title={block ? "Já existe uma rota vinculada a este pedido" : ""}>
                <Box
                    display="flex"
                    onClick={handleOrderCheck}
                    sx={{
                        cursor: block ? "not-allowed" : "pointer",
                        opacity: block ? 0.6 : 1,
                    }}
                    alignItems="center"
                >
                    <Checkbox
                        disabled={!!block}
                        checked={check}
                    />
                <Box ml="0.5rem">
                    <Box>
                        <Typography fontSize="0.7rem" variant="caption">
                            Pedido:{" "}
                        </Typography>
                        <Typography fontSize="0.7rem" variant="caption" fontWeight="bold">
                            #{order.id}
                        </Typography>
                    </Box>
                    <Typography
                        lineHeight="short"
                        letterSpacing="tight"
                        whiteSpace="normal"
                            style={{ wordBreak: "break-word" }}
                        fontSize="0.7rem"
                        variant="h6"
                        >
                            {(address.streetName || "Sem rua") +
                            ", " +
                            (address.streetNumber || "Sem número") +
                            ", " +
                            (address.neighborhood || "Sem bairro")}
                    </Typography>
                </Box>
            </Box>
            </Tooltip>
        </Box>
    );
};

export default CheckOrders;
