import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HouseIcon from '@mui/icons-material/House';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Skeleton,
  Tab,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import DispatchItem from "../DispatchItem";
import OrderRouterDispatch from '../../../OrderRouterDispatch';
import { useOrderContext } from '../../../../context/OrderProvider';

const DispatchList = ({
  loading,
  deliveryMan,
  selectedMan,
  numberOfInvalidAddresses,
  onClickListAll,
  setError,
  toggleIframe,
}) => {
  const { ordersDispatched } = useOrderContext();
  const [tab, setTab] = useState("1");
  function handleTab(_, value) {
    setTab(value);
  }

  if (loading) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton
            key={index}
            height={60}
            animation="wave"
            sx={{ transform: "scale(1, 0.8)" }}
          />
        ))}
      </>
    );
  }

  const deliveredOrders = deliveryMan.filter((item) => item.pedidos.every(
    (pedido) => pedido.situacao === "ENTREGUE"
  ));
  const nonDeliveredOrders = deliveryMan.filter((item) => item.pedidos.every(
    (pedido) => pedido.situacao !== "ENTREGUE"
  ));
  const sortedPedidos = nonDeliveredOrders.sort((a, b) => {
    if (a.pessoa.id === -1 && b.pessoa.id !== -1) return -1;
    if (a.pessoa.id !== -1 && b.pessoa.id === -1) return 1;

    if (a.situacao !== "ENVIADO" && b.situacao === "ENVIADO") return 1;
    if (a.situacao === "ENVIADO" && b.situacao !== "ENVIADO") return -1;

    return new Date(b.pedidos[0].updatedAt) - new Date(a.pedidos[0].updatedAt);
  });

  return (
    <Box>
      <TabContext value={tab}>
        <TabList
          sx={{
            minHeight: 48,
            height: "auto",
            minWidth: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "space-between",
          }}
          onChange={handleTab}
        >
          <Tab
            sx={{
              minWidth: "48%",
              padding: "0px 0.5rem",
              fontSize: "0.75rem",
              minHeight: 36,
              height: "auto",
              lineHeight: 1,
              gap: 1,
            }}
            icon={<DeliveryDiningIcon />}
            iconPosition="start"
            label="Despachos"
            value="1"
          />
          <Tab
            sx={{
              minWidth: "45%",
              padding: "0px 0.5rem",
              fontSize: "0.75rem",
              minHeight: 36,
              height: "auto",
              gap: 1,
              lineHeight: 1,
            }}
            icon={<HouseIcon />}
            iconPosition="start"
            label="Entregues"
            value="2"
          />
        </TabList>

        <TabPanel sx={{ paddingX: 0 }} value="1">
          <OrderRouterDispatch />
          {sortedPedidos.map((item) => (
            <DispatchItem
              item={item}
              setErro={setError}
              numberOfInvalidAddresses={numberOfInvalidAddresses}
              onClickListAll={onClickListAll}
              selectedMan={selectedMan}
              toggleIframe={toggleIframe}
              key={item.agrupadorDespacho}
            />
          ))}
          {!sortedPedidos.length && !ordersDispatched.length && (
            <Typography sx={{ opacity: 0.6 }} variant="h6" textAlign="center">
              Nenhum despacho realizado
            </Typography>
          )}
        </TabPanel>

        <TabPanel sx={{ paddingX: 0, opacity: 0.6 }} value="2">
          {deliveredOrders.map((item) => (
            <DispatchItem
              item={item}
              setErro={setError}
              numberOfInvalidAddresses={numberOfInvalidAddresses}
              onClickListAll={onClickListAll}
              selectedMan={selectedMan}
              toggleIframe={toggleIframe}
              key={item.agrupadorDespacho}
            />
          ))}
          {!deliveredOrders.length && (
            <Typography variant="h6" textAlign="center">
              Nenhum despacho entregue
            </Typography>
          )}
        </TabPanel>
      </TabContext>
    </Box>)
};

export default DispatchList;
