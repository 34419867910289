import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import React from "react";

const DialogTitleOrder = ({ onClose }) => {

    return (
        <DialogTitle>
        <Box mt="1rem">
          <Typography variant="h4">Detalhes do pedido</Typography>
        </Box>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "1.5rem",
            right: "1rem",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );
    }

export default DialogTitleOrder;
